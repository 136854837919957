import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "bottom",
    },
    title: {
      display: false,
    },
  },
};

const ModalityActivity = ({ studyData, title, className }) => {
  const data = {
    labels: studyData?.studiesModality?.map((x) => x.modality),
    datasets: [
      {
        label: "",
        data: studyData?.studiesModality?.map((x) => x.count),
        backgroundColor: "#021BFA",
      },
    ],
  };
  return (
    <>
      <div
        className={`${className} space-y-dicom-30 rounded-dicom-10 bg-white p-dicom-30`}
      >
        <div className="text-dicom-headline4 text-dicom-secondary-100 font-semibold">
          {title}
        </div>
        <Bar options={options} data={data} />
      </div>
    </>
  );
};

export default ModalityActivity;
