import React from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import CheckoutForm from "./CheckoutForm"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

export default function Stripe({ price }) {
  const options = {
    mode: "subscription",
    amount: parseFloat(price) * 100,
    currency: "gbp"
  }

  return (
    <>
      {price ? (
        <Elements
          stripe={stripePromise}
          options={{
            ...options,
            appearance: {
              theme: "stripe",
              variables: {
                colorPrimary: "#EAF0F7",
                colorBackground: "#EAF0F7",
                colorText: "#575757"
              }
            }
          }}>
          <CheckoutForm />
        </Elements>
      ) : (
        <div className='animate-pulse h-[330px] bg-gray-200 rounded-lg'></div>
      )}
    </>
  )
}
