import React from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

// components
import UseDocumentTitle from "../../utils/useDocumentTitle";
import Layout from "../../containers/Layout";
import { useUserActions } from "../../services/AuthActions";
import { RemoveLocalStorage } from "../../services/LocalStorage";

const Emailconfirmation = () => {
  UseDocumentTitle("Email confirmation", true);
  const UserActions = useUserActions();
  const location = useLocation();
  const navigate = useNavigate();

  let hash = location?.search?.split("&")?.[0]?.split?.("=")?.[1];
  let email = location?.search?.split("&")?.[1]?.split?.("=")?.[1];

  if ((hash, email)) {
    UserActions.UserVerify(`user/${email}/verify/${hash}`)
      .then((res) => {
        if (res.data.data.user) {
          toast.success(
            "Your account has been successfully verified, please login"
          );
          navigate("/auth/login", { replace: true });
        }
      })
      .catch((err) => {
        if (err.response.status === 409) {
          toast.error(err.response.data.message + " Please login");
          RemoveLocalStorage("login_key");
          RemoveLocalStorage("login_user");
          navigate("/auth/login", { replace: true });
        }
      });
  }

  return (
    <>
      <Layout isAuthPage={true} isRegisterPage={true}>
        <div className="py-16 grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-12 gap-dicom-30">
          <div className="relative space-y-dicom-30 col-span-3 md:col-span-3 lg:col-span-4 xl:col-span-7 mt-20">
            <div className="absolute !z-0 -top-36 -left-44">
              <svg
                className="!w-[100%]"
                width="547"
                height="560"
                viewBox="0 0 547 560"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.2" filter="url(#filter0_f_56_1543)">
                  <circle cx="267" cy="280" r="174" fill="#1DD3B0" />
                </g>
                <defs>
                  <filter
                    id="filter0_f_56_1543"
                    x="-13"
                    y="0"
                    width="560"
                    height="560"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="53"
                      result="effect1_foregroundBlur_56_1543"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
            <div className="relative !z-10 space-y-dicom-30">
              <h1 className="w-1/4 text-dicom-headline1 md:text-dicom-headline1 lg:text-[40px] xl:text-dicom-headline1 font-bold text-dicom-primary-200">
                Email confirmation
              </h1>
              <p className="text-dicom-text-100 md:w-4/6 lg:w-4/6 xl:w-3/6">
                A confirmation email has been sent to your email address. Please
                Check Your e-mail
              </p>
            </div>
          </div>
          <div className="relative col-span-3 md:col-span-3 lg:col-span-4 xl:col-span-5">
            <img className="md:w-[351px]" src="/images/auth.png" alt="" />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Emailconfirmation;
