import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
    },
    title: {
      display: false,
    },
  },
};

const AverageStorage_Modality = ({ studyData, title, className }) => {
  const data = {
    labels: studyData?.modalitySpace?.map((x) => x.modality),
    datasets: [
      {
        label: "",
        data: studyData?.modalitySpace?.map((x) => x.space),
        backgroundColor: ["#021BFA", "#1DD3B0", "#FFE500", "#D02828"],
      },
    ],
  };

  return (
    <>
      <div
        className={`${className} space-y-dicom-30 rounded-dicom-10 bg-white p-dicom-30`}
        title="Average storage used by the file modality"
      >
        <div className="text-dicom-headline4 text-dicom-secondary-100 font-semibold">
          {title}
        </div>
        <Doughnut data={data} options={options} />
      </div>
    </>
  );
};

export default AverageStorage_Modality;
