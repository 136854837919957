// Capsule.js

import React, { useEffect, useRef, useState } from "react";
import FolderSkelliton from "../folders/folder";
import StudyCardContainer from "../study/studyContainer";

import { NoRowsOverlay } from "../../../pages/dashboard/all-studies/StudyList";
import { userSelectedData } from "../../../atom/userStudies";
import { useSetRecoilState } from "recoil";

import { PaginationFooter } from "../PaginationFooter";
import { contextMenuAtom } from "../../../atom/contextMenu";
import toast from "react-hot-toast";
import { IconBlackCopyed } from "../../DesignSystem/svgIcons";
import useWindowDimensions from "../../../utils/windowDimensions";
function Capsule({
  StudyInfo,
  handleFolderClick,
  handleContextMenu,
  handleMoveAndCopy,
  handleFolderDownload,
  handleDragDropMove,
  handleStudyClick
}) {
  const setSelectedData = useSetRecoilState(userSelectedData);
  const [selectedStudies, setSelectedStudies] = useState([]);
  const setContextMenu = useSetRecoilState(contextMenuAtom);
  const { width } = useWindowDimensions();

  const isXlScreen = () => {
    return width >= 1280;
  };

  const handleSelectedStudiesOrFolder = (studyOrFolder, isCtrlOrCmdPressed) => {

    if (isCtrlOrCmdPressed) {
      setSelectedStudies((prevSelectedStudies) => {
        const newSelectedStudies = new Set(prevSelectedStudies);
        if (
          newSelectedStudies.has(`${studyOrFolder.type}-${studyOrFolder.id}`)
        ) {
          newSelectedStudies.delete(
            `${studyOrFolder.type}-${studyOrFolder.id}`
          );
        } else {
          newSelectedStudies.add(`${studyOrFolder.type}-${studyOrFolder.id}`);
        }
        return [...newSelectedStudies];
      });
      setSelectedData((prevSelectedStudies) => {
        const hasMatchingStudy = prevSelectedStudies.some(
          (selectedStudy) =>
            selectedStudy.id === studyOrFolder.id &&
            selectedStudy.type === studyOrFolder.type
        );
        if (hasMatchingStudy) {
          return prevSelectedStudies.filter(
            (selectedStudy) =>
              !(selectedStudy.id === studyOrFolder.id &&
                selectedStudy.type === studyOrFolder.type)
          );
        } else {
          return [...prevSelectedStudies, studyOrFolder];
        }
        
      });
    } else {
      
      setSelectedStudies([`${studyOrFolder.type}-${studyOrFolder.id}`]);
      setSelectedData([studyOrFolder]);
    }
  };

  const handleClickOutside = (event) => {
    const containerId = "grid-view-container";
    const clickedElement = event.target;
    
    if (event.button === 2) {
      return;
    }

    if(!isXlScreen() &&  event.target.classList.contains('checkboxmeta')) return

    if (event.ctrlKey || event.metaKey) return;


    if (
      clickedElement.id === "right-click-context-menu" ||
      clickedElement.closest(`#right-click-context-menu`)
    ) {
      return;
    }
    if (
      clickedElement.id === containerId ||
      clickedElement.closest(`#${containerId}`)
    ) {
      setSelectedStudies([]);
      setSelectedData([]);
      return;
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "a") {
        event.preventDefault();
      }
    };

    const handleKeyUp = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "a") {
        event.preventDefault();
        let selectedList = [];
        StudyInfo.studies.map((study) => {
          selectedList.push(`study-${study.id}`);
        });
        StudyInfo.folders.map((study) => {
          selectedList.push(`folder-${study.id}`);
        });
        setSelectedStudies(selectedList);
        setSelectedData([...StudyInfo.folders, ...StudyInfo.studies]);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  // handle ctrl + c
  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "c") {
        event.preventDefault();
      }
    };

    const handleKeyUp = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "c") {
        event.preventDefault();
        if (selectedStudies.length === 0)return toast.error("No selected item found");
        let ActionList = [];
        selectedStudies.map((item) => {
          let splitItem = item.split("-");
          ActionList.push({
            action: "copy",
            objectId: splitItem[1],
            type: splitItem[0],
          });
        });
        toast("Copied to clipboard", { icon: <IconBlackCopyed /> });
        sessionStorage.setItem("user_action", JSON.stringify(ActionList));
        setContextMenu(ActionList);
        setSelectedStudies([]);
        setSelectedData([]);
      }
      
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [selectedStudies]);

  // handle ctrl + X 

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "x") {
        event.preventDefault();
      }
    };

    const handleKeyUp = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "x") {
        event.preventDefault();
        if (selectedStudies.length === 0) return toast.error("No selected item found");
        let ActionList = [];
        selectedStudies.map((item) => {
          let splitItem = item.split("-");
          ActionList.push({
            action: "move",
            objectId: splitItem[1],
            type: splitItem[0],
          });
        });
        toast("Ready to move", { icon: <IconBlackCopyed /> });
        sessionStorage.setItem("user_action", JSON.stringify(ActionList));
        setContextMenu(ActionList);
        setSelectedStudies([]);
        setSelectedData([]);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [selectedStudies]);


  return (
    <section
      onContextMenu={handleContextMenu}
      className="xl:p-5 py-[10px] xl:min-h-[80vh] h-full min-h-[60vh] relative max-xl:mb-10"
      id="grid-view-container"
      // onTouchStart={handleTouchStart}
    >
      <>
        {StudyInfo?.folders.length === 0 && StudyInfo?.studies.length === 0 ? (
          <NoRowsOverlay />)
      :
        <>
          <div className="grid grid-cols-3 md:grid-cols-6 gap-5 3xl:flex 3xl:flex-wrap ">
            {StudyInfo?.folders !== undefined &&
              StudyInfo?.folders.map((folder) => (
                <FolderSkelliton
                  handleDragDropMove={handleDragDropMove}
                  handleSelectedFolder={handleSelectedStudiesOrFolder}
                  onClick={() => handleFolderClick(folder.id)}
                  folderMeta={folder}
                  isSelected={selectedStudies.includes(`folder-${folder.id}`)}
                  handleMoveAndCopy={handleMoveAndCopy}
                  selectedCount={selectedStudies.length}
                  handleFolderDownload={handleFolderDownload}
                  isXlScreen={isXlScreen}
                />
              ))}
            <StudyCardContainer
              handleStudyClick={handleStudyClick}
              handleMoveAndCopy={handleMoveAndCopy}
              studyList={StudyInfo?.studies}
              selectedStudies={selectedStudies}
              handleSelectedStudies={handleSelectedStudiesOrFolder}
              isXlScreen={isXlScreen}
            />
          </div>


          <PaginationFooter />

        </>
          }
              
      </>
    </section>
  );
}

export default Capsule;
