import React from "react";
import AdminLayout from "../../containers/AdminLayout";
import UseDocumentTitle from "../../utils/useDocumentTitle";

const NotFound = () => {
  UseDocumentTitle("404 | not Fount Page", true);
  return (
    <>
      <AdminLayout>
        <div className="flex items-center justify-center py-24">
          <h1 className="text-dicom-headline1 font-bold">404</h1>
        </div>
      </AdminLayout>
    </>
  );
};

export default NotFound;
