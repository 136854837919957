import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";

import { DicomButton } from "../../components/DesignSystem/buttons";
import {
  Input,
  Select,
  SelectCountry,
} from "../../components/DesignSystem/inputs";
import AdminLayout from "../../containers/AdminLayout";
import UseDocumentTitle from "../../utils/useDocumentTitle";
import { fetchData } from "../../services/fetching";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  UseDocumentTitle("dashboard | profile", true);
  const [isLoading, setisLoading] = useState(false);
  const [UserProfile, setUserProfile] = useState("");
  const [CountryLists, setCountryLists] = useState([]);
  const [ErrorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  let Professingroup = [
    "Clinicians",
    "Academic Instructors",
    "Medical students/ Trainees",
    "Clinical Researchers",
    "Dentists",
    "Veterinarians",
    "Law Firms",
    "MedTech Companies",
    "Patients",
    "Others",
  ];

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("The Email Address is required")
      .email("Email is invalid."),
    firstName: Yup.string().required("The First Name is required"),
    lastName: Yup.string().required("The Last Name is required"),
    country: Yup.string().required("The Country is required"),
    professionalGroups: Yup.string().required("The Professional group is required"),
    password: Yup.string()
      .min(6, "password length must be at least 6 characters long")
      .required("The Password is required"),
    rules: Yup.boolean().oneOf(
      [true],
      "You must accept the terms and conditions"
    ),
  });

  const onSubmit = () => {
    setisLoading(true);
    const { firstName, lastName, countryId, professionalGroup } = formik.values;

    fetchData("PATCH", "/user/profile", {
      firstName,
      lastName,
      countryId,
      professionalGroup,
    })
      .then((res) => {
        if (res?.data?.data) {
          setisLoading(false);
          toast.success("Account has been updated successfully");

          navigate("/dashboard/my-dicom-wallet");
          // fetchData("GET", "/user/profile").then((res) =>
          //   setUserProfile(res.data.data)
          // );
          setErrorMessage("");
        }
      })
      .catch((err) => {
        if (err?.response?.data) {
          setisLoading(false);
          setErrorMessage(err?.response?.data?.data);
          toast.error(err?.response?.data?.message);
        }
      });
  };

  const formik = useFormik({
    initialValues: UserProfile?.user,
    validationSchema,
    onSubmit,
    validateOnMount: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    fetchData("GET", "/user/profile").then((res) =>
      setUserProfile(res.data.data)
    );

    fetchData("GET", "/country").then((res) => setCountryLists(res));
  }, []);

  return (
    <>
      <AdminLayout>
        <div className="flex flex-col bg-dicom-surface-100 rounded-dicom-10 items-center justify-center py-24">
          <div className="!space-y-dicom-30 w-4/5 lg:w-1/3">
            <Input
              error={ErrorMessage?.firstName}
              label={"First Name"}
              classInput={"!bg-transparent border-dicom-primary-100"}
              nameId={"firstName"}
              placeholder={"First name"}
              formik={formik}
            />

            <Input
              error={ErrorMessage?.lastName}
              label={"Last Name"}
              classInput={"!bg-transparent border-dicom-primary-100"}
              nameId={"lastName"}
              placeholder={"Last name"}
              formik={formik}
            />
            <Input
              error={ErrorMessage?.email}
              label={"Email Address"}
              disable={true}
              nameId={"email"}
              placeholder={"Enter Email address"}
              formik={formik}
            />
            <Select
              label="Professional Group"
              error={ErrorMessage?.professionalGroup}
              classInput={"!bg-transparent !border-dicom-primary-100"}
              nameId={"professionalGroup"}
              data={Professingroup}
              placeholder={"--Select professional group:"}
              formik={formik}
            />

            <SelectCountry
              label="Country"
              error={ErrorMessage?.countryId}
              classInput={"!bg-transparent !border-dicom-primary-100"}
              nameId={"countryId"}
              data={
                CountryLists?.data?.data
                  ? CountryLists?.data?.data?.countries
                  : []
              }
              placeholder={"--Select country:"}
              formik={formik}
            />

            <DicomButton
              isLoading={isLoading}
              type={"submit"}
              onClick={onSubmit}
              title={"Save"}
              btnClass={"w-full"}
            />
          </div>
        </div>
      </AdminLayout>
    </>
  );
};

export default Profile;
