import React from "react";

const AcceptableUse = () => {
  return (
    <>
      <h2>
        <strong>DICOMWallet Acceptable Use Policy</strong>
      </h2>

      <p>
        <br />
        DICOMWallet is used by community of clinicians, students, researchers,
        academics, administrators, lawyers and patient. We are proud of the
        trust placed in us. In exchange, we trust you to use our services
        responsibly.
        <br />
        You may not access or use the Site for any purpose other than that for
        which we make the Site available. The Site may not be used in connection
        with any commercial endeavors except those that are specifically
        endorsed or approved by us. You agree not to misuse the Dropbox services
        (&quot;Services&quot;) or help anyone else to do so.
      </p>

      <p>
        For example, you must not even try to do any of the following in
        connection with the Services:
      </p>

      <ul>
        <li>
          Systematically retrieve data or other content from the Site to create
          or compile, directly or indirectly, a collection, compilation,
          database, or directory without written permission from us.
        </li>
        <li>
          Trick, defraud, or mislead us and other users, especially in any
          attempt to learn sensitive account information such as user passwords.
        </li>
        <li>
          Circumvent, disable, or otherwise interfere with security-related
          features of the Site, including features that prevent or restrict the
          use or copying of any Content or enforce limitations on the use of the
          Site and/or the Content contained therein.
        </li>
        <li>
          Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
          Site.
        </li>
        <li>
          Use any information obtained from the Site in order to harass, abuse,
          or harm another person.
        </li>
        <li>
          Make improper use of our support services or submit false reports of
          abuse or misconduct.
        </li>
        <li>
          Use the Site in a manner inconsistent with any applicable laws or
          regulations.
        </li>
        <li>Engage in unauthorized framing of or linking to the Site.</li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) viruses,
          Trojan horses, or other material, including excessive use of capital
          letters and spamming (continuous posting of repetitive text), that
          interferes with any party&rsquo;s uninterrupted use and enjoyment of
          the Site or modifies, impairs, disrupts, alters, or interferes with
          the use, features, functions, operation, or maintenance of the Site.
        </li>
        <li>
          Engage in any automated use of the system, such as using scripts to
          send comments or messages, or using any data mining, robots, or
          similar data gathering and extraction tools.
        </li>
        <li>
          Delete the copyright or other proprietary rights notice from any
          Content.
          <br />
          Attempt to impersonate another user or person or use the username of
          another user.
          <br />
          &nbsp;
        </li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) any material
          that acts as a passive or active information collection or
          transmission mechanism, including without limitation, clear graphics
          interchange formats (&ldquo;gifs&rdquo;), 1&times;1pixels, web bugs,
          cookies, or other similar devices (sometimes referred to as
          &ldquo;spyware&rdquo; or &ldquo;passive collection mechanisms&rdquo;
          or &ldquo;pcms&rdquo;).
        </li>
        <li>
          Interfere with, disrupt, or create an undue burden on the Site or the
          networks or services connected to the Site.
        </li>
        <li>
          Harass, annoy, intimidate, or threaten any of our employees or agents
          engaged in providing any portion of the Site to you.
        </li>
        <li>
          Attempt to bypass any measures of the Site designed to prevent or
          restrict access to the Site, or any portion of the Site.
        </li>
        <li>
          Copy or adapt the Site&rsquo;s software, including but not limited to
          Flash, PHP, HTML, JavaScript, or other code.
        </li>
        <li>
          Except as permitted by applicable law, decipher, decompile,
          disassemble, or reverse engineer any of the software comprising or in
          any way making up apart of the Site.
        </li>
        <li>
          Except as may be the result of standard search engine or Internet
          browser usage, use, launch, develop, or distribute any automated
          system, including without limitation, any spider, robot, cheat
          utility, scraper, or offline reader that accesses the Site, or using
          or launching any unauthorized script or other software.
        </li>
        <li>
          Use a buying agent or purchasing agent to make purchases on the Site.
        </li>
        <li>
          Make any unauthorized use of the Site, including collecting usernames
          and/or email addresses of users by electronic or other means for the
          purpose of sending unsolicited email, or creating user accounts by
          automated means or under false pretenses.
        </li>
        <li>
          Use the Site as part of any effort to compete with us or otherwise use
          the Site and/or the Content for any revenue-generating endeavor or
          commercial enterprise.
        </li>
        <li>
          Use the Site to advertise or offer to sell goods and services.&nbsp;
        </li>
        <li>Sell or otherwise transfer your profile</li>
        <li>
          violate the law in any way, including storing, publishing or sharing
          material that&rsquo;s fraudulent, defamatory or misleading or that
          violates the intellectual property rights of others;
        </li>
        <li>violate the privacy or infringe the rights of others;</li>
        <li>
          use the Services to back up, or as infrastructure for, your own cloud
          services;
        </li>
        <li>
          use the storage space provided by the Services as the basis for
          cryptographic proof-of-space or proof-of-storage, or any similar proof
          system.
        </li>
      </ul>

      <p>
        We reserve the right to take appropriate action in response to
        violations of this policy, which could include removing or disabling
        access to content, suspending a user&rsquo;s access to the Services or
        terminating an account.
      </p>
    </>
  );
};

export default AcceptableUse;
