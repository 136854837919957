import React from "react";

let data = [
  {
    id: 0,
    title: "Security",
    image: "/images/security.png",
    description:
      "Security of patient data and our users is our top priority. We keep the data in a physically secure place at the DICOMWallet Data Center, shielded by robust network security and under constant physical plant observation. All accesses to or attempts to access any information on the DICOMWallet system need the username and password authentication through SSL-encrypted web sessions.",
  },
  {
    id: 1,
    title: "PACS Viewer",
    image: "/images/Pacs Viewer.png",
    description:
      "Use a user friendly and intelligent cloud PACS viewer to view clinical records and medical images. The service works with internet browsers which allows you to view your files across different devices without installing any software. You can easily track your measurements, draw annotations and create reports using DICOMWallet.",
  },
  {
    id: 2,
    title: "Easy to share",
    image: "/images/Esay to share.png",
    description:
      "You can share your medical images and documents publicly and privately. You can anonymize the files before storing and sharing, however, you have the option to securely identify the patients for further follow-up. You do not compromise patient identity through video calls for second opinion. The service is faster and safer than CD/DVD if you want to share the files with patients, referring physicians, law offices, insurance companies, etc.",
  },
];

const ChooseUs = () => {
  return (
    <>
      <div className="flex flex-col space-y-dicom-30">
        <div className="space-y-dicom-15">
          <p className="text-dicom-headline2 font-bold">
            Why should you choose DICOMWallet?
          </p>
          <p className="text-base text-dicom-text-100 lg:w-2/3">
            With DICOMWallet, you can store, view, and share medical images
            securely and with ease. DICOMWallet is an HIPAA/GDPR compliant
            service which supports all images obtained from imaging devices such
            as X-rays, CT scans, Ultrasounds and MRIs.
          </p>
          <svg
            width="147"
            height="2"
            viewBox="0 0 147 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="147" height="2" fill="#021BFA" />
          </svg>
        </div>
        <div className="grid grid-cols-6 lg:grid-cols-12 gap-dicom-30">
          {data.map((item) => (
            <div
              key={item.id}
              className="relative transition-all duration-300 ease-in-out group hover:h-fit h-[256px] min-h-fit flex flex-col items-center col-span-6 lg:col-span-4 p-dicom-30 rounded-dicom-10 text-dicom-primary-100 bg-dicom-background-100 hover:bg-[#6877FE] hover:!text-white hover:!fill-white"
            >
              <img className="!h-[160px]" src={item.image} alt="" />
              <div className="flex flex-col items-center">
                <div className="flex items-center space-x-[5px] text-dicom-body1 font-bold">
                  <svg
                    width="13"
                    height="14"
                    viewBox="0 0 13 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="fill-dicom-primary-100 group-hover:fill-white"
                      d="M0.5 0H5.5C9.36599 0 12.5 3.13401 12.5 7C12.5 10.866 9.36599 14 5.5 14H0.5V0Z"
                    />
                  </svg>
                  <p>{item.title}</p>
                </div>
                <p className="transition-all duration-300 ease-in-out hidden group-hover:flex text-dicom-caption text-white font-normal">
                  {item.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ChooseUs;
