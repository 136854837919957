import React, { useEffect, useState } from "react"
import { fetchData } from "../../services/fetching"
import { formatCurrency } from "../../utils/functions"
import { IconDownload } from "../DesignSystem/svgIcons"
import moment from "moment"
export default function Invlices() {
  const [transactions, setTransactions] = useState([])
  const getAllTransactions = async () => {
    try {
      const res = await fetchData("GET", "/payments/transactions")
      setTransactions(res.data.data)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getAllTransactions()
  }, [])

  return (
    <div>
      <h3 className='font-bold text-xl py-3'>Invoice</h3>
      <div className='overflow-x-auto'>
        <table className='table'>
          <thead className='bg-[#f5f5f5] text-sm'>
            <tr>
              <th>Invlice number</th>
              <th>Date</th>
              <th>Description</th>
              <th>Amount</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transcation) => {
              return (
                <tr key={transcation?.invoiceId}>
                  <td>{transcation?.invoiceId}</td>
                  <td>{moment(transcation?.createDate).format("LLL")}</td>
                  <td>{transcation?.description}</td>
                  <td>{formatCurrency(transcation?.amountPayed / 100)}</td>
                  <td>
                    <a href={transcation?.invoucePdf} download>
                      <IconDownload color={"#575757"} height={24} width={24} />
                    </a>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
