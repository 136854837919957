function FormatSizeUnits(bytes) {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + " GB";
  } else if (bytes >= 1048576) {
    bytes = Math.round(bytes / 1048576) + " MB";
  } else if (bytes >= 1024) {
    bytes = Math.round(bytes / 1024) + " KB";
  } else if (bytes > 1) {
    bytes = Math.round(bytes) + " bytes";
  } else if (bytes === 1) {
    bytes = Math.round(bytes) + " byte";
  } else {
    bytes = "0 bytes";
  }
  return bytes;
}
// function FormatSizeUnits(bytes) {
//   var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
//   if (bytes === 0 || bytes === "") return "0 Byte";
//   var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
//   return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
// }

function FormatSizeUnitsSpace(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0 || bytes === "") return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i >= 3) {
    return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
  }
  return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
}

function FormatSizeUnitsSpaceSetLimit(bytes) {
  var sizes = ["MB", "GB", "TB"];
  if (bytes === "" || bytes === 0) return 0;
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i >= 1) {
    return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
  }
  return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
}

export { FormatSizeUnits, FormatSizeUnitsSpace, FormatSizeUnitsSpaceSetLimit };
