import React from "react";

const RecommendationForUserCompliance = () => {
  return (
    <>
      <h2>
        <strong>DICOMWallet recommendation for user compliance</strong>
      </h2>

      <p>
        DICOMWallet users subject to laws like the General Law Protection
        Regulation (GDPR), Health Insurance Portability and Accountability Act
        (HIPAA) and the Health Information Technology for Economic and Clinical
        Health Act (HITECH Act) should take special care to make sure their use
        is consistent with legal requirements.&nbsp;
      </p>

      <p>
        We want to make it as easy as possible for you to learn how to keep your
        account secure and meet your legal requirements. While ultimately,
        it&#39;s up to you make sure that you&#39;re complying with your
        regulatory obligations, we&#39;ve put together some recommendations to
        help you keep your data safe and your accounts secured.
      </p>

      <p>&nbsp;</p>

      <ul>
        <li>
          We made it very easy to make the studies anonymized. You can anonymize
          any study that you store or share. Make sure that anonymize the
          studies before sharing them if you are legally required to do
          so.&nbsp;
        </li>
        <li>
          You should frequently review devices linked to your account and remove
          unused or unauthorized devices.
        </li>
        <li>
          You can improve your authentication security by understanding risks to
          authentication and follow the recommendation of The European Union
          Agency for Cybersecurity: &nbsp;
          <a href="https://www.enisa.europa.eu/news/enisa-news/tips-for-secure-user-authentication">
            https://www.enisa.europa.eu/news/enisa-news/tips-for-secure-user-authentication
          </a>
        </li>
      </ul>

      <p>&nbsp;</p>
    </>
  );
};

export default RecommendationForUserCompliance;
