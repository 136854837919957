import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { DicomButtonOutlineLabel } from "../../../components/DesignSystem/buttons";
import Modal from "../../../components/DesignSystem/Modal";
import { fetchData } from "../../../services/fetching";
import DeleteFolder from "./deleteFolder";
import EditFolder from "./editFolder";
import NewFolder from "./newFolder";

const FolderSection = ({ className }) => {
  const [foldersData, setFoldersData] = useState([]);
  useEffect(() => {
    fetchData("GET", "/folder").then((res) =>
      setFoldersData(res.data.data.folders)
    );
  }, []);
  return (
    <>
      <Modal modalId={"add-folder-modal"} title={"Create New Folder"}>
        <NewFolder
          modalId={"add-folder-modal"}
          setFoldersData={setFoldersData}
        />
      </Modal>
      {foldersData.length ? (
        <div className="space-y-dicom-15">
          <div className="flex items-center w-full justify-between">
            <p className="text-dicom-headline2 font-bold">folders</p>
            <DicomButtonOutlineLabel
              htmlFor={"add-folder-modal"}
              btnClass={
                "!px-[10px] !border-dicom-secondary-100 !text-dicom-secondary-100 hover:!bg-dicom-secondary-100/5"
              }
              title={"Add New Folder"}
              iconL={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H13V7C13 6.44771 12.5523 6 12 6C11.4477 6 11 6.44771 11 7V11H7C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H11V17Z"
                    fill="#1DD3B0"
                  />
                </svg>
              }
            />
          </div>
          <div className="grid grid-cols-6 lg:grid-cols-12 gap-dicom-30">
            {foldersData.map((folder) => (
              <div
                to={`/dashboard/folders/${folder.id}`}
                className={`${className} col-span-3 md:col-span-2 lg:col-span-1`}
                key={folder.id}
              >
                <Modal
                  modalId={`folder-rename-${folder.id}`}
                  title={"Rename Folder"}
                >
                  <EditFolder
                    setFoldersData={setFoldersData}
                    modalId={`folder-rename-${folder.id}`}
                    item={folder}
                  />
                </Modal>
                <Modal
                  modalId={`folder-delete-${folder.id}`}
                  title={"Delete Folder"}
                >
                  <DeleteFolder
                    setFoldersData={setFoldersData}
                    modalId={`folder-delete-${folder.id}`}
                    item={folder}
                  />
                </Modal>
                <NavLink to={`/dashboard/folders/${folder.id}`}>
                  <img
                    className="!w-24 !h-[102px]"
                    src="/images/Folders.svg"
                    alt=""
                  />
                </NavLink>
                <div className="flex items-center justify-between">
                  <p className="!truncate text-[12px]">{folder.name}</p>
                  <div className="flex dropdown">
                    <label title={"Click to active operations"} tabIndex="0" className="cursor-pointer !px-1">
                      <svg
                        width="3"
                        height="14"
                        viewBox="0 0 3 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="1.5" cy="2" r="1.5" fill="#D9D9D9" />
                        <circle cx="1.5" cy="7" r="1.5" fill="#D9D9D9" />
                        <circle cx="1.5" cy="12" r="1.5" fill="#D9D9D9" />
                      </svg>
                    </label>
                    <div
                      tabIndex="0"
                      className="dropdown-content menu shadow bg-base-100 rounded-box w-52"
                    >
                      <NavLink
                        to={`/dashboard/folders/${folder.id}`}
                        className="flex cursor-pointer gap-dicom-10 !py-dicom-10 !px-dicom-15"
                      >
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_735_5096)">
                            <path
                              d="M5.47656 4.72897H9.47656L12.4766 7.72897H19.4766C20.007 7.72897 20.5157 7.93969 20.8908 8.31476C21.2658 8.68983 21.4766 9.19854 21.4766 9.72897V17.729C21.4766 18.2594 21.2658 18.7681 20.8908 19.1432C20.5157 19.5183 20.007 19.729 19.4766 19.729H5.47656C4.94613 19.729 4.43742 19.5183 4.06235 19.1432C3.68728 18.7681 3.47656 18.2594 3.47656 17.729V6.72897C3.47656 6.19854 3.68728 5.68983 4.06235 5.31476C4.43742 4.93969 4.94613 4.72897 5.47656 4.72897"
                              stroke="#575757"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_735_5096">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                transform="translate(0.476562 0.728973)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <p>Open</p>
                      </NavLink>

                      <label
                        htmlFor={`folder-rename-${folder.id}`}
                        className="flex cursor-pointer gap-dicom-10 !py-dicom-10 !px-dicom-15"
                      >
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_735_5101)">
                            <path
                              d="M6.47656 10.729C8.13342 10.729 9.47656 9.38583 9.47656 7.72897C9.47656 6.07212 8.13342 4.72897 6.47656 4.72897C4.81971 4.72897 3.47656 6.07212 3.47656 7.72897C3.47656 9.38583 4.81971 10.729 6.47656 10.729Z"
                              stroke="#575757"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.47656 20.729C8.13342 20.729 9.47656 19.3858 9.47656 17.729C9.47656 16.0721 8.13342 14.729 6.47656 14.729C4.81971 14.729 3.47656 16.0721 3.47656 17.729C3.47656 19.3858 4.81971 20.729 6.47656 20.729Z"
                              stroke="#575757"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.07617 9.32897L19.4762 19.729"
                              stroke="#575757"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.07617 16.129L19.4762 5.72897"
                              stroke="#575757"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_735_5101">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                transform="translate(0.476562 0.728973)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <p>Rename</p>
                      </label>
                      <label
                        htmlFor={`folder-delete-${folder.id}`}
                        className="flex cursor-pointer gap-dicom-10 !py-dicom-10 !px-dicom-15"
                      >
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.64221 10.8824C5.55286 9.72078 6.4713 8.72897 7.63632 8.72897H17.3168C18.4818 8.72897 19.4003 9.72078 19.3109 10.8824L18.6186 19.8824C18.5385 20.9244 17.6696 21.729 16.6245 21.729H8.32862C7.28355 21.729 6.41467 20.9244 6.33452 19.8824L5.64221 10.8824Z"
                            stroke="#575757"
                            strokeWidth="2"
                          />
                          <path
                            d="M19.9766 5.72897H4.97656"
                            stroke="#575757"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                          <path
                            d="M10.4766 3.72897C10.4766 3.17669 10.9243 2.72897 11.4766 2.72897H13.4766C14.0288 2.72897 14.4766 3.17669 14.4766 3.72897V5.72897H10.4766V3.72897Z"
                            stroke="#575757"
                            strokeWidth="2"
                          />
                          <path
                            d="M14.4766 12.729V17.729"
                            stroke="#575757"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                          <path
                            d="M10.4766 12.729V17.729"
                            stroke="#575757"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </svg>
                        <p>Delete</p>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="flex items-center w-full justify-between">
                  <p className="text-[12px] truncate">Study</p>
                  <p className="text-[12px] truncate">{folder?.studiesCount}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center space-y-dicom-15 py-[87px] px-[68px]">
          <img src="/images/Folders.svg" className="w-[152px]" alt="" />
          <p className="text-dicom-body1 text-dicom-secondary-100">
            You have not added any folder yet
          </p>
          <DicomButtonOutlineLabel
            htmlFor={"add-folder-modal"}
            btnClass={
              "!px-[10px] !border-dicom-secondary-100 !text-dicom-secondary-100 hover:!bg-dicom-secondary-100/5"
            }
            title={"Add New Folder"}
            iconL={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H13V7C13 6.44771 12.5523 6 12 6C11.4477 6 11 6.44771 11 7V11H7C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H11V17Z"
                  fill="#1DD3B0"
                />
              </svg>
            }
          />
        </div>
      )}
    </>
  );
};

export default FolderSection;
