import { BaseInput } from "../DesignSystem/inputs"
import { IconSearch, IconSearchTag } from "../DesignSystem/svgIcons"
import { SwitchButton } from "../DesignSystem/switch"
import DatePickerComponent from "../DatePicker"
import { useEffect, useRef, useState } from "react"
// import DIcomTags from "../../utils/DicomTags.json"
import { useFormik } from "formik"
import { fetchData } from "../../services/fetching"
const SearchBox = ({ searchValue, setSearchValue, setDateRange, Sorting, handleSortingChange }) => {
  const [isTagSearch, setIsTagSearch] = useState(false)
  const [queries, setQueries] = useState([1])
  const formRef = useRef(null)
  const [DIcomTags, setDicomTags] = useState([])
  let FilterSwitching = [
    {
      id: 1,
      title: "MR",
      value: "MR"
    },
    {
      id: 2,
      title: "CT",
      value: "CT"
    },
    {
      id: 3,
      title: "PT",
      value: "PT"
    },
    {
      id: 4,
      title: "CR",
      value: "CR"
    },
    {
      id: 5,
      title: "DX",
      value: "DX"
    },
    {
      id: 6,
      title: "US",
      value: "US"
    },
    {
      id: 7,
      title: "MG",
      value: "MG"
    },
    {
      id: 8,
      title: "OT",
      value: "OT"
    },
    {
      id: 9,
      title: "Other",
      value: "Other"
    }
  ]

  const handleClose = (state) => {
    setSearchValue("")
    setIsTagSearch(state)
  }

  useEffect(() => {
    fetchData("GET", "/study/dicom-tags").then((res) => {
      setDicomTags(res.data.data.tags)
    })
  }, [])

  const handleAddQuery = () => {
    formik.setFieldValue("queries", [...formik.values.queries, { tag: "", searchQuery: "" }])
  }
  const handleRemoveQuery = (indexToRemove) => {
    const newQueries = [...formik.values.queries]
    newQueries.splice(indexToRemove, 1)
    formik.setFieldValue("queries", newQueries)
  }
  const initialValues = {
    queries: [{ tag: "", searchQuery: "" }]
  }
  const validate = (values) => {
    const errors = {}
    values.queries.forEach((query, index) => {
      if (!query.tag) {
        errors[`queries.${index}.tag`] = "Select a tag"
      }
      if (!query.searchQuery) {
        errors[`queries.${index}.searchQuery`] = "Required"
      }
    })
    return errors
  }

  const handleSubmit = (values) => {
    const set = removeDuplicates(values.queries, "tag")

    setSearchValue(set)
    function removeDuplicates(array, prop) {
      return array.filter((obj, index, self) => index === self.findIndex((o) => o[prop] === obj[prop]))
    }
  }

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: handleSubmit
  })

  useEffect(() => {
    if (!formRef.current) return
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault()
        document.getElementById("submitAdvancedSearch").click()
      }
    }
    const formElement = formRef.current
    formElement.addEventListener("keypress", handleKeyPress)

    return () => {
      formElement.removeEventListener("keypress", handleKeyPress)
    }
  }, [formRef, isTagSearch])

  return (
    <>
      <div className=' xl:w-full xl:max-w-3xl mx-auto  xl:flex-row xl:flex-1'>
        {!isTagSearch && (
          <BaseInput
            classInput={"!bg-white !border-dicom-secondary-100"}
            InputClassName={"col-span-6 md:col-span-4"}
            value={searchValue}
            placeholder={"Search..."}
            titleAttr={"Search"}
            nameId={"search-input"}
            onChange={(e) => setSearchValue(e.target.value)}
            badgeTitle={
              <span
                onClick={() => handleClose(true)}
                className='flex cursor-pointer bg-dicom-secondary-hover-100/20 text-dicom-secondary-100 px-3 py-2 text-sm font-semibold gap-1 rounded-full'>
                <IconSearchTag />
                Search in dicom tags
              </span>
            }
          />
        )}

        {isTagSearch && (
          <div className='px-3 py-2 border-2 rounded-xl border-dicom-secondary-100'>
            <span className='text-dicom-secondary-100 font-semibold'>Advanced Search</span>
            <div className='py-2'>
              <span className='font-semibold'>Find all studies with...</span>
              <form ref={formRef} onSubmit={formik.handleSubmit}>
                <div className='mt-1 max-h-[230px] overflow-auto'>
                  {formik.values.queries.map((query, index) => {
                    const isLast = formik.values.queries.length - 1
                    return (
                      <div key={index} className='mb-5'>
                        <div className='flex items-center gap-2'>
                          <div className='w-full relative'>
                            <select
                              name={`queries.${index}.tag`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className='font-normal select  text-gray-500 bg-[#eaf0f7] !ring-0 !outline-none rounded-dicom-10 focus-within:border-none w-full'>
                              <option value='' disabled selected>
                                Select a tag
                              </option>
                              {DIcomTags.map((tag, key) => (
                                <option key={key} value={tag.key}>
                                  {tag.key}
                                </option>
                              ))}
                            </select>
                            {formik.errors[`queries.${index}.tag`] && (
                              <p className='text-xs text-red-500 italic absolute'>Select a tag</p>
                            )}
                          </div>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='w-12 h-6'>
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3'
                            />
                          </svg>
                          <div className='text-gray-500 bg-[#eaf0f7] rounded-dicom-10 w-full  relative'>
                            <div>
                              <input
                                name={`queries.${index}.searchQuery`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder='Search query'
                                className='font-normal !ring-0   focus-within:border-none bg-transparent !outline-none input  w-[95%]'
                              />
                              {formik.errors[`queries.${index}.searchQuery`] && (
                                <p className='text-xs text-red-500 italic absolute'>Search query required</p>
                              )}
                            </div>
                            <button
                              onClick={() => handleRemoveQuery(index)}
                              disabled={formik.values.queries.length === 1}
                              className='absolute right-1 cursor-pointer  top-1/2 -translate-y-1/2 disabled:cursor-not-allowed disabled:opacity-50'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth={1.5}
                                stroke='currentColor'
                                className='w-6 h-6 '>
                                <path strokeLinecap='round' strokeLinejoin='round' d='M6 18 18 6M6 6l12 12' />
                              </svg>
                            </button>
                          </div>

                          {index === isLast && (
                            <svg
                              onClick={handleAddQuery}
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth={1.5}
                              stroke='currentColor'
                              className='w-12 h-6 bg-dicom-secondary-100 rounded-md text-white cursor-pointer'>
                              <path strokeLinecap='round' strokeLinejoin='round' d='M12 4.5v15m7.5-7.5h-15' />
                            </svg>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className='flex items-center gap-2 mt-2 w-full max-w-xs ml-auto'>
                  <button
                    type='button'
                    onClick={() => handleClose(false)}
                    className='!border-dicom-secondary-100 border !bg-white  hover:!bg-white cursor-pointer flex-1 font-normal  !capitalize flex text-black items-center btn px-6      !rounded-xl'>
                    Cancel
                  </button>
                  <button
                    id='submitAdvancedSearch'
                    type='submit'
                    className='!border-dicom-secondary-100 !bg-dicom-secondary-100 hover:!bg-dicom-secondary-hover-100 flex-1 font-normal  !capitalize flex text-white items-center btn px-6 !border-0 !rounded-xl'>
                    Apply
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <div className='py-dicom-10'>
        <div className='flex flex-wrap items-center justify-center space-x-dicom-15 col-span-6 md:col-span-8'>
          {FilterSwitching.map((filter) => (
            <SwitchButton
              titleAttr={"Search by modality"}
              key={filter.id}
              Ltitle={filter.title}
              nameId={filter.value}
              checked={Sorting[filter.value]}
              onChange={handleSortingChange}
            />
          ))}
          <DatePickerComponent titleAttr={"Search by date"} setDateRange={setDateRange} />
        </div>
      </div>
    </>
  )
}

export default SearchBox
