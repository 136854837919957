import React, { memo, useState } from "react";
import {
  // ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography,
} from "react-simple-maps";

import { Tooltip as ReactTooltip } from "react-tooltip";

const Country = ({ userData, title, className }) => {
  const [TooltipContent, setTooltipContent] = useState("");
  return (
    <>
      <div
        className={`${className} space-y-dicom-30 rounded-dicom-10 bg-white p-dicom-30`}
      >
        <div className="text-dicom-headline4 text-dicom-secondary-100 font-semibold">
          {title}
        </div>
        <div data-tooltip-id="tooltip-id" data-tooltip-content="">
          <ReactTooltip id="tooltip-id">{TooltipContent}</ReactTooltip>
          <ComposableMap>
            {/* <ZoomableGroup> */}
            <Geographies geography="/features.json">
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    stroke="#23232310"
                    onMouseEnter={() => {
                      setTooltipContent(
                        `${geo.properties.name} ${
                          userData?.usersCountry?.find(
                            (count) => count?.alpha3 === geo?.id
                          )?.count
                            ? `: ${
                                userData?.usersCountry?.find(
                                  (count) => count?.alpha3 === geo?.id
                                )?.count
                              } user`
                            : ""
                        }`
                      );
                    }}
                    fill={
                      userData?.usersCountry?.find(
                        (count) => count?.alpha3 === geo?.id
                      )
                        ? "#021BFA"
                        : "#D6D6DA"
                    }
                    onMouseLeave={() => {
                      setTooltipContent("");
                    }}
                    style={{
                      hover: {
                        fill: "#1DD3B0",
                        outline: "none",
                      },
                      pressed: {
                        fill: "#17AD90",
                        outline: "none",
                      },
                    }}
                  />
                ))
              }
            </Geographies>
            {/* </ZoomableGroup> */}
          </ComposableMap>
        </div>
      </div>
    </>
  );
};

export default memo(Country);
