import React from "react";

// components
import AdminLayout from "../../../containers/AdminLayout";
import FolderSection from "./FolderSection";

const Folders = () => {
  return (
    <>
      <AdminLayout>
        <div className="bg-white rounded-dicom-10 p-dicom-30 !min-h-[60%]">
          <FolderSection />
        </div>
      </AdminLayout>
    </>
  );
};

export default Folders;
