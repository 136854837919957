import React from "react";

const Security = () => {
  return (
    <>
      <div className="grid items-center grid-cols-3 lg:grid-cols-6 xl:grid-cols-12 gap-x-28 space-y-dicom-30">
        <div className="space-y-dicom-30 col-span-3 lg:col-span-3 xl:col-span-6">
          <div className="space-y-dicom-15">
            <p className="text-dicom-body2 text-dicom-primary-100">
              How to use DICOMWallet
            </p>
            <p className="text-dicom-headline2 font-bold">
              Hyper-Secure Cloud Storage and File Sharing Service
            </p>
            {/* <p className="text-dicom-body2 text-dicom-text-100 lg:w-2/3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus
              non sollicitudin enim, nisi.
            </p> */}
            <svg
              width="147"
              height="2"
              viewBox="0 0 147 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="147" height="2" fill="#021BFA" />
            </svg>
          </div>
          <div className="space-y-dicom-15">
            <p className="text-base text-dicom-text-100">
              <span className="font-bold">Archive: </span>Patients, care givers,
              medical students, medical instructors, clinicians, radiologists
              and veterinarian, can archive and share their medical images.
            </p>
            <p className="text-base text-dicom-text-100">
              <span className="font-bold">Education: </span>Upload your
              Dicom libraries, view and share them with your students.  
            </p>
            <p className="text-base text-dicom-text-100">
              <span className="font-bold">Content creation: </span>Use
              DICOMWallet to create content for social media, conference
              materials, teaching purposes, etc.
            </p>
            <p className="text-base text-dicom-text-100">
              <span className="font-bold">Research: </span>Anonymize
              clinical images, store them and share them with you research team
            </p>
            <p className="text-base text-dicom-text-100">
              <span className="font-bold">Second Opinion: </span>Archive
              interesting cases and share them with your colleagues anonymously
              to get a second opinion
            </p>
          </div>
        </div>
        <div className="-order-1 lg:order-1 col-span-3 lg:col-span-3 xl:col-span-6">
          <img
            className="min-w-full"
            src={"images/Security-Section.png"}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default Security;
