import React, { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import DragableElement from "./dragableElement"
import { useRecoilValue } from "recoil"
import { FolderShared, StudyShareByLink } from "../../../atom/studyShare"
import { GetLocalStorage } from "../../../services/LocalStorage"
import { authAtom } from "../../../atom/auth"

const OhifViewer = ({ setOpenViewer, study, code }) => {
  const { id } = useParams()
  const studyShareByLink = useRecoilValue(StudyShareByLink)
  const folerShared = useRecoilValue(FolderShared)
  const user = useRecoilValue(authAtom)
  const location = useLocation()
  const getCurrentPath = (myLocation) => {
    let locationPath = myLocation.pathname
    return locationPath.endsWith("/") ? locationPath.slice(0, -1) : locationPath
  }

  const searchQuery = new URLSearchParams(location.search)

  let iframeLink = `${
    process.env.REACT_APP_DICOMWALLET_VIEWER_URL
  }/viewer?StudyInstanceUIDs=${code}&x-session=${GetLocalStorage("login_key")}&study-code=${study.code}`

  if (getCurrentPath(location) == `/dashboard/shared/study/${id}` && !searchQuery.get("shareId")) {
    iframeLink = `${
      process.env.REACT_APP_DICOMWALLET_VIEWER_URL
    }/viewer?StudyInstanceUIDs=${code}&x-session=${GetLocalStorage("login_key")}&study-code=${
      study.code
    }&shared=${true}&p_id=${searchQuery.get("p_id")}`
  }
  if (getCurrentPath(location) == `/dashboard/shared/study/${id}` && searchQuery.get("shareId") != null) {
    iframeLink = `${
      process.env.REACT_APP_DICOMWALLET_VIEWER_URL
    }/viewer?StudyInstanceUIDs=${code}&shareId=${searchQuery.get("shareId")}&study-code=${
      study.code
    }&shared=${true}&p_id=${searchQuery.get("p_id")}`
  }

  if (studyShareByLink.linkId != "")
    iframeLink = `${process.env.REACT_APP_DICOMWALLET_VIEWER_URL}/viewer?StudyInstanceUIDs=${studyShareByLink.viewId}&shareId=${studyShareByLink.linkId}`

  const [bufferLoading, setBufferLoading] = useState(false)
  const [timeoutCount, setTimeoutCount] = useState(2)

  useEffect(() => {
    let timeoutId

    const checkSharedArrayBuffer = () => {
      if (typeof SharedArrayBuffer === "undefined") {
        if (!sessionStorage.getItem("sharedArrayBufferCheck")) {
          setBufferLoading(true)
          sessionStorage.setItem("sharedArrayBufferCheck", "true")
          timeoutId = setTimeout(() => {
            setBufferLoading(false)
            window.location.reload()
          }, 2000)
        } else {
          setBufferLoading(false)
        }
      } else {
        setBufferLoading(false)
        sessionStorage.removeItem("sharedArrayBufferCheck")
      }
    }

    checkSharedArrayBuffer()

    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    if (bufferLoading) {
      const intervalId = setInterval(() => {
        setTimeoutCount((prevCount) => prevCount - 1)
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [bufferLoading])

  return (
    <>
      {user &&
        (user.id === study?.userId ||
          study?.subscribers?.some((item) => item.userId === user.id && item.access) ||
          studyShareByLink?.accessLevel === 2 ||
          folerShared?.accessLevel == 2) && <DragableElement study={study} setOpenViewer={setOpenViewer} />}
      {bufferLoading && (
        <div className='w-full h-screen bg-black fixed top-0 left-0 z-[999]'>
          <p className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-dicom-secondary-100'>
            Loading frames {timeoutCount}...
          </p>
        </div>
      )}
      <iframe
        title={id}
        className='w-full h-screen'
        allow='cross-origin-isolated'
        src={iframeLink}
        frameBorder='0'></iframe>
    </>
  )
}

export default OhifViewer
