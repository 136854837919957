import React from "react"
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom"
import { SetLocalStorage } from "../services/LocalStorage"

const ProtectedRoute = ({ isAllowed, redirectPath = "/auth/login", children }) => {
  const location = useLocation()
  const param = useParams()
  const queryParams = new URLSearchParams(location.search)
  let shareRoute =
    (location.pathname.split("/").includes("my-dicom-wallet") &&
      param?.id !== "" &&
      queryParams.get("shareId") != null) ||
    (location.pathname.split("/").includes("folder") &&
      location.pathname.split("/").includes("shared") &&
      param?.sharedFolderId !== "" &&
      queryParams.get("shareId") != null &&
      queryParams.get("p_id") != null) ||
    (location.pathname.split("/").includes("study") &&
      location.pathname.split("/").includes("shared") &&
      param?.sharedFolderId !== "" &&
      queryParams.get("shareId") != null &&
      queryParams.get("p_id") != null)

  if (shareRoute) {
    return children ? children : <Outlet />
  }

  if (!isAllowed) {
    // if not allowed print to console
    if (window.location.pathname.includes("dashboard")) {
      SetLocalStorage("toShowed", true)
      SetLocalStorage("after_Route", `${window.location.pathname}${window.location.search}`)
    }
    return <Navigate to={redirectPath} replace={true} />
  }

  return children ? children : <Outlet />
}

export default ProtectedRoute
