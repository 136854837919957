import { debounce } from "lodash"
import { useRef } from "react"

const queryFilter = (data) => {
  let filterList = []
  for (let key in data) {
    if (key === "Other" && data[key] === true) {
      filterList.length = 0
      filterList.push(`modalityExclude=1`)
    } else if (data[key] === true) {
      filterList.push(`modalities[]=${key}`)
    } else if (data[key] === false) {
      filterList.filter(function (item) {
        return item !== key
      })
    }
  }
  return filterList.join("&")
}

const queryDeleteFilter = (data) => {
  let filterList = []
  for (let key in data) {
    if (data[key]) {
      filterList.push(`codes[]=${data[key]}`)
    } else if (data[key] === false) {
      filterList.filter(function (item) {
        return item !== key
      })
    }
  }
  return filterList.join("&")
}

const useDebounce = (callback = () => {}, time = 500) => {
  return useRef(debounce(callback, time)).current
}

// uses a useState hook to set the sate to true
// Params must be useState
const checkImageLoadingError = (setState) => {
  setState(true)
}

const formatBytes = (bytes) => {
  const units = ["B", "KB", "MB", "GB", "TB"]
  let unitIndex = 0
  while (bytes >= 1024 && unitIndex < units.length - 1) {
    bytes /= 1024
    unitIndex++
  }

  return `${bytes.toFixed(1)} ${units[unitIndex]}`
}

const formatCurrency = (amount, maximumFractionDigits, minimumFractionDigits) => {
  return Intl.NumberFormat("en-us", {
    currency: "gbp",
    style: "currency",
    maximumFractionDigits: maximumFractionDigits || 2,
    minimumFractionDigits: minimumFractionDigits || 0
  }).format(amount)
}

export { queryFilter, useDebounce, queryDeleteFilter, checkImageLoadingError, formatBytes, formatCurrency }
