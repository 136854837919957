import { useEffect, useRef, useState } from "react"
import {
  IconAnonomize2Black,
  IconBlackCopy,
  IconBlackCopyed,
  IconBlackCut,
  IconBlackStart,
  IconCommentsBlack,
  IconDownloadBlack,
  IconEye,
  IconListTrashBlack,
  IconOptionsDotted,
  IconPropertiesBlack,
  IconShareBlack
} from "../../DesignSystem/svgIcons"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useNavigate, useLocation } from "react-router-dom"
import { fetchDownloadFile } from "../../../services/fetching"
import fileDownload from "js-file-download"
import { GetLocalStorage, SetLocalStorage } from "../../../services/LocalStorage"
import { UserStudies, userSelectedData } from "../../../atom/userStudies"
import { authAtom } from "../../../atom/auth"
import toast from "react-hot-toast"
export const FileOptionsListView = (params) => {
  let { id, code, title } = params.study
  const navigate = useNavigate()
  const [selected, setSelected] = useState(false)
  const selectedData = useRecoilValue(userSelectedData)
  const dropdownRef = useRef()
  const user = useRecoilValue(authAtom)
  const location = useLocation()
  const [selectedStudies, setSelectedStudeis] = useRecoilState(userSelectedData)
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown()
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (selected) {
      setSelectedStudeis([params.study])
    }
  }, [selected])

  const closeDropdown = () => {
    setSelected(false)
  }

  return (
    <div
      ref={dropdownRef}
      onClick={(e) => {
        setSelected(!selected)
      }}
      id={`study-dropdown-list-${id}`}
      className={`relative cursor-pointer`}>
      <div className='flex space-x-2'>
        <IconOptionsDotted />
      </div>
      {selected && (
        <div className='absolute -right-2 z-[10] xl:z-[1] !font-normal cursor-pointer shadow bg-base-100 rounded-box w-28 xl:w-40  max-xl:text-xs '>
          <div className='overflow-hidden bg-slate-50/50 rounded-t-lg '>
            <button
              onClick={() => navigate(`${code}`)}
              disabled={selectedData.length == 0 || selectedData.findIndex((val) => val.id == id) == -1}
              className='flex anim  hover:bg-slate-100 px-2 xl:px-4  py-2 gap-2 items-center justify-start w-full disabled:opacity-30 disabled:cursor-not-allowed'>
              <IconEye color='black' width={20} height={20} /> View
            </button>
          </div>
          <div className='overflow-hidden bg-slate-50/50  '>
            <button
              onClick={() => {
                document.getElementById(`anonymize-study-with-table`).checked = true
              }}
              disabled={
                selectedData.length == 0 ||
                selectedData.findIndex((val) => val.id == id) == -1 ||
                selectedData.some((val) => val.userId !== user.id)
              }
              className='flex anim  hover:bg-slate-100 px-2 xl:px-4 py-2 gap-2 items-center justify-start w-full disabled:opacity-30 disabled:cursor-not-allowed'>
              <IconAnonomize2Black color='black' width={20} height={18} /> Anonymize
            </button>
          </div>
          <div className='overflow-hidden bg-slate-50/50  '>
            <button
              onClick={() => (document.getElementById("share-study-with-table").checked = true)}
              disabled={
                selectedData.length == 0 ||
                selectedData.findIndex((val) => val.id == id) == -1 ||
                selectedData.some((val) => val.userId !== user.id)
              }
              className='flex anim  hover:bg-slate-100 px-2 xl:px-4 py-2 gap-2 items-center justify-start w-full disabled:opacity-30 disabled:cursor-not-allowed'>
              <IconShareBlack color='black' width={18} height={18} /> Share
            </button>
          </div>
          <div className='overflow-hidden bg-slate-50/50  '>
            <button
              onClick={() => (document.getElementById("comments-study-with-table").checked = true)}
              disabled={selectedData.length == 0 || selectedData.findIndex((val) => val.id == id) == -1}
              className='flex anim  hover:bg-slate-100 px-2 xl:px-4 py-2 gap-2 items-center justify-start w-full disabled:opacity-30 disabled:cursor-not-allowed'>
              <IconCommentsBlack color='black' width={18} height={18} /> Comment
            </button>
          </div>
          <div className='overflow-hidden bg-slate-50/50 '>
            <button
              // disabled={
              //   selectedData.length == 0 ||
              //   selectedData.findIndex((val) => val.id == id) == -1
              // }
              disabled={
                !selectedData.every(
                  (val) =>
                    val.type !== "folder" &&
                    ((val.accessLevelByEmail !== undefined && val.accessLevelByEmail === 2) ||
                      val.id === user.id ||
                      location.pathname.split("/").includes("shared"))
                )
              }
              onClick={() => {
                let url = `/study/${code}/download`
                if (location.pathname.split("/").includes("shared")) {
                  url = `${url}?p_id=${GetLocalStorage("p_id")}`
                }
                fetchDownloadFile("GET", url)
                  .then((response) => {
                    fileDownload(response.data, `${title}.zip`)
                  })
                  .catch((err) => {
                    console.log(err)
                    if (err.response.status == 400) {
                      return toast.error("Access required")
                    }
                    toast.error("Problem downloading study")
                  })
              }}
              className='flex anim  hover:bg-slate-100 px-2 xl:px-4 py-2 gap-2 items-center justify-start w-full disabled:opacity-30 disabled:cursor-not-allowed'>
              <IconDownloadBlack color='black' width={18} height={18} /> Download
            </button>
          </div>
          <div className='overflow-hidden bg-slate-50/50  '>
            <button
              disabled={
                selectedData.length == 0 ||
                selectedData.findIndex((val) => val.id == id) == -1 ||
                location.pathname.split("/").includes("shared")
              }
              onClick={() => (document.getElementById("delete-studies").checked = true)}
              className='flex anim  hover:bg-slate-100 px-2 xl:px-4  py-2 gap-2 items-center justify-start w-full disabled:opacity-30 disabled:cursor-not-allowed'>
              <IconListTrashBlack color='black' width={18} height={18} />
              {location.pathname.split("/").includes("shared-with-you") ? "Remove" : "Delete"}
            </button>
          </div>
          <div className='overflow-hidden bg-slate-50/50  '>
            <button
              disabled={
                selectedData.length == 0 ||
                selectedData.findIndex((val) => val.id == id) == -1 ||
                selectedData.some((val) => val.accessLevelByEmail !== 2)
              }
              onClick={() => {
                navigate(`${code}`)
                SetLocalStorage("viIsOp", false)
              }}
              className='flex anim  hover:bg-slate-100 px-2 xl:px-4  py-2 gap-2 items-center justify-start w-full disabled:opacity-30 disabled:cursor-not-allowed'>
              <IconPropertiesBlack color='black' width={18} height={18} /> Properties
            </button>
          </div>

          <div className='border border-slate-100' />
          <div className='  bg-slate-50/50 rounded-b-lg'>
            <button
              disabled={selectedData.some((val) => val.userId !== user.id)}
              onClick={() => {
                params.handleMoveAndCopy("copy", id, "study")
              }}
              className='flex anim hover:bg-slate-100 px-2 xl:px-4 py-2 gap-2 items-center justify-start w-full disabled:opacity-30 disabled:cursor-not-allowed'>
              <IconBlackCopy /> <span>Copy</span>
            </button>
            <button
              disabled={selectedData.some((val) => val.userId !== user.id)}
              onClick={() => {
                params.handleMoveAndCopy("move", id, "study")
              }}
              className='flex anim hover:bg-slate-100 px-2 xl:px-4 py-2 gap-2  items-center justify-start w-full disabled:opacity-30 disabled:cursor-not-allowed'>
              <IconBlackCut /> Cut
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
