import React, { useEffect, useRef, useState } from "react"
import {
  IconAnonomize0,
  IconAnonomize1,
  IconAnonomize2,
  IconAnonomize2Black,
  IconBadge,
  IconBlackCopy,
  IconBlackCopyed,
  IconBlackCut,
  IconBlackStart,
  IconCheckBox,
  IconCheckBoxChecked,
  IconCommentsBlack,
  IconDownloadBlack,
  IconEditOnly,
  IconEye,
  IconFullAccess,
  IconListTrashBlack,
  IconOptionsDotted,
  IconPropertiesBlack,
  IconShareBlack,
  IconStarredSec,
  IconViewOnly
} from "../../DesignSystem/svgIcons"
import { useNavigate, useLocation } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { contextMenuAtom } from "../../../atom/contextMenu"
import { fetchData, fetchDownloadFile } from "../../../services/fetching"
import { latestStudyUploaded, UserStudies, UserStudiesURL } from "../../../atom/userStudies"
import fileDownload from "js-file-download"
import { GetLocalStorage, SetLocalStorage } from "../../../services/LocalStorage"
import { authAtom } from "../../../atom/auth"
import toast from "react-hot-toast"
export default function StudyCard({
  studyMeta,
  handleSelect,
  isSelected,
  handleMoveAndCopy,
  selectedCount,
  isXlScreen,
  handleStudyClick
}) {
  const highlightSelect = `bg-[#E7F9FF] !hover:bg-[#E7F9FF]`
  const user = useRecoilValue(authAtom)
  const [isReadytoMove, setIsReadyToMove] = useState(false)
  const LatestStudyUploaded = useRecoilValue(latestStudyUploaded)
  const handleContextMenu = (e) => {
    if (selectedCount > 1) return
    e.preventDefault()
    document.getElementById(`study-dropdown-${studyMeta.id}`).click()
  }

  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", JSON.stringify({ id: studyMeta.id, type: "study" }))
  }

  const checkFreeze = () => {
    if (parseInt(user.usedSpace) > parseInt(user.totalSpace) || user.freezeAccount) return true
    else return false
  }
  return (
    <>
      <div
        draggable={true}
        onDragStart={handleDragStart}
        onContextMenu={handleContextMenu}
        onDoubleClick={() => {
          if (!checkFreeze()) handleStudyClick(studyMeta)
        }}
        onClick={(event) => handleSelect(studyMeta, event)}
        id={`study-${studyMeta?.id || "null"}`}
        className={`
      ${isSelected ? highlightSelect : "hover:bg-slate-50"} border ${
          LatestStudyUploaded == studyMeta.id ? "bg-[#e7f9ff]" : ""
        } border-gray-100 xl:w-[182px] w-[104px] rounded-[5px] p-[10px] cursor-pointer study-card relative`}>
        <div className='flex items-center  self-stretch w-full'>
          <span
            title={studyMeta.title}
            className={` ${
              isReadytoMove && "opacity-50"
            }  font-semibold items-center flex gap-1 text-xs flex-1 w-[90%]`}>
            <span className='flex-initial'>
              {isSelected ? (
                <IconCheckBoxChecked className='checkboxmeta' />
              ) : !isXlScreen() ? (
                <IconCheckBox className='checkboxmeta' />
              ) : (
                ""
              )}
            </span>
            <span className='flex-1 line-clamp-1  '>{studyMeta.title}</span>
          </span>
          {!checkFreeze() && (
            <div className='flex-initial cursor-pointer md:block hidden'>
              <FileOptions handleMoveAndCopy={handleMoveAndCopy} setReadytoMove={setIsReadyToMove} study={studyMeta} />
            </div>
          )}
        </div>
        <div className={` ${isReadytoMove && "opacity-50"} `}>
          <div className='rounded-[5px] my-[5px] overflow-hidden'>
            <img
              draggable={false}
              src={`${process.env.REACT_APP_API_URL}/study/${studyMeta.code}/preview`}
              alt={`${studyMeta.title}-${studyMeta.code}`}
              title={`${studyMeta.title}-${studyMeta.code}`}
              className='xl:h-[110px] h-[60px] object-cover w-full'
            />
          </div>
          <div className='flex gap-[5px] xl:items-start justify-start py-1'>
            <span
              title={studyMeta?.patientName}
              className='text-xs xl:flex-1 hidden font-semibold xl:line-clamp-1 cursor-default'>
              {studyMeta?.patientName}
            </span>
            <div className='flex xl:flex-initial gap-1'>
              <span className='xl:w-7 w-6 h-6 xl:h-7 rounded-md bg-[#EAF0F7] flex justify-center items-center p-1 '>
                <AnonyomizeStatus anonymization={studyMeta?.anonymization} />
              </span>
              <span
                title={`Modality ${studyMeta?.modality}`}
                className={`xl:w-7 w-6 h-6 xl:h-7 rounded-md cursor-default bg-dicom-secondary-100 text-dicom-surface-100 flex justify-center items-center  ${
                  +studyMeta?.modality.length > 3 ? "xl:text-[9px] text-[8px]" : "xl:text-[12px] text-[11px]"
                } font-normal`}>
                {studyMeta?.modality}{" "}
              </span>
            </div>
          </div>
          <div className='xl:flex flex-col cursor-default hidden text-[#575757]'>
            <span className='text-xs'> Series: {studyMeta?.series?.length}</span>
            <span className='text-xs'> Instances: {studyMeta?.instances?.length}</span>
            {studyMeta?.user?.email !== GetLocalStorage("login_user")?.email && (
              <span className='text-xs'>
                Access:{" "}
                {studyMeta?.accessLevelByEmail != undefined ? ShareAccessStatus(studyMeta?.accessLevelByEmail) : ""}
              </span>
            )}
          </div>
          <span className='text-xs mt-1  font-semibold flex gap-1'>
            <span className='flex-initial'>
              <IconBadge />
            </span>
            <span className='flex-1 line-clamp-1 capitalize'>
              {studyMeta?.user?.firstName} {studyMeta?.user?.lastName}
            </span>

            {studyMeta.starred && <IconStarredSec />}
          </span>
        </div>
      </div>
    </>
  )
}

const ShareAccessStatus = (accessLevel) => {
  return <>{accessLevel === 2 ? "Full Access" : accessLevel === 1 ? "Edit only" : "View Only"}</>
}

const AnonyomizeStatus = ({ anonymization }) => {
  return <>{anonymization === 2 ? <IconAnonomize2 /> : anonymization === 1 ? <IconAnonomize1 /> : <IconAnonomize0 />}</>
}

const FileOptions = (params) => {
  let { id, code, starred, title } = params.study
  const contextMenu = useRecoilValue(contextMenuAtom)
  const [selected, setSelected] = useState(false)
  const dropdownRef = useRef()
  const [studies, setStudies] = useRecoilState(UserStudies)
  const studyUrl = useRecoilValue(UserStudiesURL)
  const navigate = useNavigate()
  const location = useLocation()
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown()
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)
    if (contextMenu !== null && contextMenu.action === "move") {
      if (contextMenu.type === "study" && id === contextMenu.objectId) {
        params.setReadytoMove(true)
      } else params.setReadytoMove(false)
    } else params.setReadytoMove(false)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [contextMenu])
  const closeDropdown = () => {
    setSelected(false)
  }

  const StarAction = () => {
    fetchData("PATCH", "/study/bulk-toggle-starred", {
      codes: [code]
    }).then((res) => {
      fetchData("GET", studyUrl).then((res) => {
        setStudies(res.data.data)
      })
    })
  }
  const user = useRecoilValue(authAtom)

  return (
    <>
      <div
        ref={dropdownRef}
        onClick={() => setSelected(!selected)}
        id={`study-dropdown-${id}`}
        className={`relative cursor-pointer`}>
        <div className='flex space-x-2'>
          <IconOptionsDotted />
        </div>
        {selected && (
          <div className='absolute -top-24 md:top-0 -right-3 md:-right-2 z-[3] xl:z-[1] cursor-pointer shadow bg-base-100 rounded-box w-28 xl:w-40  max-xl:text-xs '>
            <div className='overflow-hidden bg-slate-50/50 rounded-t-lg '>
              <span
                onClick={() => navigate(`${code}`)}
                className='flex anim  hover:bg-slate-100 px-2 xl:px-4  py-2 gap-2 items-center justify-start'>
                <IconEye color='black' width={20} height={20} /> View
              </span>
            </div>

            <div className='overflow-hidden bg-slate-50/50  '>
              <button
                disabled={params.study?.userId !== user?.id}
                onClick={() => {
                  document.getElementById(`anonymize-study-with-table`).checked = true
                }}
                className='flex anim  hover:bg-slate-100 px-2 xl:px-4 py-2 gap-2 items-center justify-start w-full disabled:opacity-50 disabled:cursor-not-allowed'>
                <IconAnonomize2Black color='black' width={20} height={18} /> Anonymize
              </button>
            </div>
            <div className='overflow-hidden bg-slate-50/50  '>
              <button
                disabled={params.study?.userId !== user?.id}
                onClick={() => (document.getElementById("share-study-with-table").checked = true)}
                className='flex anim  hover:bg-slate-100 px-2 xl:px-4 py-2 gap-2 items-center justify-start w-full disabled:opacity-50 disabled:cursor-not-allowed'>
                <IconShareBlack color='black' width={18} height={18} /> Share
              </button>
            </div>
            <div className='overflow-hidden bg-slate-50/50  '>
              <button
                onClick={() => (document.getElementById("comments-study-with-table").checked = true)}
                className='flex anim  hover:bg-slate-100 px-2 xl:px-4 py-2 gap-2 items-center justify-start w-full disabled:opacity-50 disabled:cursor-not-allowed'>
                <IconCommentsBlack color='black' width={18} height={18} /> Comment
              </button>
            </div>
            <div className='overflow-hidden bg-slate-50/50 '>
              <button
                disabled={
                  !(
                    (params.study.accessLevelByEmail !== undefined && params.study.accessLevelByEmail === 2) ||
                    params.study?.userId === user?.id ||
                    location.pathname.split("/").includes("shared")
                  )
                }
                onClick={() => {
                  let url = `/study/${code}/download`
                  if (location.pathname.split("/").includes("shared")) {
                    url = `${url}?p_id=${GetLocalStorage("p_id")}`
                  }
                  fetchDownloadFile("GET", url)
                    .then((response) => {
                      fileDownload(response.data, `${title}.zip`)
                    })
                    .catch((err) => {
                      console.log(err)
                      if (err.response.status == 400) {
                        return toast.error("Access required")
                      }
                      toast.error("Problem downloading study")
                    })
                }}
                className='flex anim  hover:bg-slate-100 px-2 xl:px-4 py-2 gap-2 items-center justify-start w-full disabled:opacity-50 disabled:cursor-not-allowed'>
                <IconDownloadBlack color='black' width={18} height={18} /> Download
              </button>
            </div>
            <div className='overflow-hidden bg-slate-50/50  '>
              <button
                disabled={location.pathname.split("/").includes("shared")}
                onClick={() => (document.getElementById("delete-studies").checked = true)}
                className='flex anim  hover:bg-slate-100 px-2 xl:px-4 w-full py-2 gap-2 items-center justify-start disabled:opacity-50 disabled:cursor-not-allowed'>
                <IconListTrashBlack color='black' width={18} height={18} />
                {location.pathname.split("/").includes("shared-with-you") ? "Remove" : "Delete"}
              </button>
            </div>
            <div className='overflow-hidden bg-slate-50/50  '>
              <button
                disabled={
                  !(
                    (params.study.accessLevelByEmail !== undefined && params.study.accessLevelByEmail === 2) ||
                    params.study?.userId === user?.id
                  )
                }
                onClick={() => {
                  SetLocalStorage("viIsOp", false)
                  setTimeout(() => {
                    navigate(`${code}`)
                  }, 100)
                }}
                className='flex anim  hover:bg-slate-100 px-2 xl:px-4 w-full py-2 gap-2 items-center justify-start  disabled:opacity-50 disabled:cursor-not-allowed'>
                <IconPropertiesBlack color='black' width={18} height={18} /> Properties
              </button>
            </div>
            <div className='overflow-hidden bg-slate-50/50  '>
              <span
                onClick={() => {
                  StarAction()
                }}
                className='flex anim  hover:bg-slate-100 pl-2 xl:pl-4 py-2 gap-2 items-center justify-start'>
                <IconBlackStart /> {starred ? "Remove Starred" : "Add to Starred"}
              </span>
            </div>
            <div className='border border-slate-100' />
            <div className='  bg-slate-50/50 rounded-b-lg'>
              <button
                disabled={params.study?.userId !== user?.id}
                onClick={() => {
                  params.handleMoveAndCopy("copy", id, "study")
                }}
                className='flex anim  hover:bg-slate-100 px-2 xl:px-4 py-2 gap-2 items-center justify-start w-full disabled:opacity-50 disabled:cursor-not-allowed'>
                <IconBlackCopy /> <span>Copy</span>
              </button>
              <button
                disabled={params.study?.userId !== user?.id}
                onClick={() => {
                  params.handleMoveAndCopy("move", id, "study")
                  params.setReadytoMove(true)
                }}
                className='flex anim  hover:bg-slate-100 px-2 xl:px-4 py-2 gap-2 items-center justify-start w-full disabled:opacity-50 disabled:cursor-not-allowed'>
                <IconBlackCut /> Cut
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
