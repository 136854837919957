export const IconRecycleBin = () => {
  return (
    <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.16565 10.1534C5.07629 8.99181 5.99473 8 7.15975 8H16.8402C18.0053 8 18.9237 8.9918 18.8344 10.1534L18.142 19.1534C18.0619 20.1954 17.193 21 16.1479 21H7.85206C6.80699 21 5.93811 20.1954 5.85795 19.1534L5.16565 10.1534Z'
        stroke='black'
        strokeWidth='2'
      />
      <path d='M19.5 5H4.5' stroke='black' strokeWidth='2' strokeLinecap='round' />
      <path d='M10 3C10 2.44772 10.4477 2 11 2H13C13.5523 2 14 2.44772 14 3V5H10V3Z' stroke='black' strokeWidth='2' />
      <path d='M14 12V17' stroke='black' strokeWidth='2' strokeLinecap='round' />
      <path d='M10 12V17' stroke='black' strokeWidth='2' strokeLinecap='round' />
    </svg>
  )
}
export const IconRecycleBinLightGray = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.16565 10.1534C5.07629 8.99181 5.99473 8 7.15975 8H16.8402C18.0053 8 18.9237 8.9918 18.8344 10.1534L18.142 19.1534C18.0619 20.1954 17.193 21 16.1479 21H7.85206C6.80699 21 5.93811 20.1954 5.85795 19.1534L5.16565 10.1534Z'
        stroke='#1DD3B0'
        strokeWidth='2'
      />
      <path d='M19.5 5H4.5' stroke='#1DD3B0' strokeWidth='2' strokeLinecap='round' />
      <path d='M10 3C10 2.44772 10.4477 2 11 2H13C13.5523 2 14 2.44772 14 3V5H10V3Z' stroke='#1DD3B0' strokeWidth='2' />
    </svg>
  )
}

export const IconFolderBlack = () => {
  return (
    <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_919_5354)'>
        <path
          d='M5.41667 4.33334H9.75L13 7.58334H20.5833C21.158 7.58334 21.7091 7.81161 22.1154 8.21794C22.5217 8.62427 22.75 9.17537 22.75 9.75V18.4167C22.75 18.9913 22.5217 19.5424 22.1154 19.9487C21.7091 20.3551 21.158 20.5833 20.5833 20.5833H5.41667C4.84203 20.5833 4.29093 20.3551 3.8846 19.9487C3.47827 19.5424 3.25 18.9913 3.25 18.4167V6.5C3.25 5.92537 3.47827 5.37427 3.8846 4.96794C4.29093 4.56161 4.84203 4.33334 5.41667 4.33334'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_919_5354'>
          <rect width='26' height='26' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export const IconUploadBlack = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H13V7C13 6.44771 12.5523 6 12 6C11.4477 6 11 6.44771 11 7V11H7C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H11V17Z'
        fill='black'></path>
    </svg>
  )
}
export const IconAddFolderWhite = () => {
  return (
    <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.54501 12.9375C9.23751 12.9375 8.98251 12.6825 8.98251 12.375V8.625C8.98251 8.3175 9.23751 8.0625 9.54501 8.0625C9.85251 8.0625 10.1075 8.3175 10.1075 8.625V12.375C10.1075 12.6825 9.85251 12.9375 9.54501 12.9375Z'
        fill='white'
      />
      <path
        d='M11.375 11.0625H7.625C7.3175 11.0625 7.0625 10.8075 7.0625 10.5C7.0625 10.1925 7.3175 9.9375 7.625 9.9375H11.375C11.6825 9.9375 11.9375 10.1925 11.9375 10.5C11.9375 10.8075 11.6825 11.0625 11.375 11.0625Z'
        fill='white'
      />
      <path
        d='M13.25 17.0625H5.75C2.4425 17.0625 1.4375 16.0575 1.4375 12.75V5.25C1.4375 1.9425 2.4425 0.9375 5.75 0.9375H6.875C8.1875 0.9375 8.6 1.365 9.125 2.0625L10.25 3.5625C10.4975 3.8925 10.535 3.9375 11 3.9375H13.25C16.5575 3.9375 17.5625 4.9425 17.5625 8.25V12.75C17.5625 16.0575 16.5575 17.0625 13.25 17.0625ZM5.75 2.0625C3.0725 2.0625 2.5625 2.5725 2.5625 5.25V12.75C2.5625 15.4275 3.0725 15.9375 5.75 15.9375H13.25C15.9275 15.9375 16.4375 15.4275 16.4375 12.75V8.25C16.4375 5.5725 15.9275 5.0625 13.25 5.0625H11C10.04 5.0625 9.725 4.7325 9.35 4.2375L8.225 2.7375C7.835 2.22 7.7225 2.0625 6.875 2.0625H5.75V2.0625Z'
        fill='white'
      />
    </svg>
  )
}
export const IconAddFolderBlack = () => {
  return (
    <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.54501 12.9375C9.23751 12.9375 8.98251 12.6825 8.98251 12.375V8.625C8.98251 8.3175 9.23751 8.0625 9.54501 8.0625C9.85251 8.0625 10.1075 8.3175 10.1075 8.625V12.375C10.1075 12.6825 9.85251 12.9375 9.54501 12.9375Z'
        fill='black'
      />
      <path
        d='M11.375 11.0625H7.625C7.3175 11.0625 7.0625 10.8075 7.0625 10.5C7.0625 10.1925 7.3175 9.9375 7.625 9.9375H11.375C11.6825 9.9375 11.9375 10.1925 11.9375 10.5C11.9375 10.8075 11.6825 11.0625 11.375 11.0625Z'
        fill='black'
      />
      <path
        d='M13.25 17.0625H5.75C2.4425 17.0625 1.4375 16.0575 1.4375 12.75V5.25C1.4375 1.9425 2.4425 0.9375 5.75 0.9375H6.875C8.1875 0.9375 8.6 1.365 9.125 2.0625L10.25 3.5625C10.4975 3.8925 10.535 3.9375 11 3.9375H13.25C16.5575 3.9375 17.5625 4.9425 17.5625 8.25V12.75C17.5625 16.0575 16.5575 17.0625 13.25 17.0625ZM5.75 2.0625C3.0725 2.0625 2.5625 2.5725 2.5625 5.25V12.75C2.5625 15.4275 3.0725 15.9375 5.75 15.9375H13.25C15.9275 15.9375 16.4375 15.4275 16.4375 12.75V8.25C16.4375 5.5725 15.9275 5.0625 13.25 5.0625H11C10.04 5.0625 9.725 4.7325 9.35 4.2375L8.225 2.7375C7.835 2.22 7.7225 2.0625 6.875 2.0625H5.75V2.0625Z'
        fill='black'
      />
    </svg>
  )
}
export const IconListSecondary = () => {
  return (
    <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6.5 4.5L16.25 4.5' stroke='#1DD3B0' stroke-width='1.5' stroke-linecap='round' />
      <path d='M6.5 9L16.25 9' stroke='#1DD3B0' stroke-width='1.5' stroke-linecap='round' />
      <path d='M6.5 13.5L16.25 13.5' stroke='#1DD3B0' stroke-width='1.5' stroke-linecap='round' />
      <path
        d='M2.9375 9.9375C3.45527 9.9375 3.875 9.51777 3.875 9C3.875 8.48223 3.45527 8.0625 2.9375 8.0625C2.41973 8.0625 2 8.48223 2 9C2 9.51777 2.41973 9.9375 2.9375 9.9375Z'
        fill='#1DD3B0'
      />
      <path
        d='M2.9375 5.4375C3.45527 5.4375 3.875 5.01777 3.875 4.5C3.875 3.98223 3.45527 3.5625 2.9375 3.5625C2.41973 3.5625 2 3.98223 2 4.5C2 5.01777 2.41973 5.4375 2.9375 5.4375Z'
        fill='#1DD3B0'
      />
      <path
        d='M2.9375 14.4375C3.45527 14.4375 3.875 14.0178 3.875 13.5C3.875 12.9822 3.45527 12.5625 2.9375 12.5625C2.41973 12.5625 2 12.9822 2 13.5C2 14.0178 2.41973 14.4375 2.9375 14.4375Z'
        fill='#1DD3B0'
      />
    </svg>
  )
}

export const IconListBlack = () => {
  return (
    <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6.5 4.5L16.25 4.5' stroke='black' stroke-width='1.5' stroke-linecap='round' />
      <path d='M6.5 9L16.25 9' stroke='black' stroke-width='1.5' stroke-linecap='round' />
      <path d='M6.5 13.5L16.25 13.5' stroke='black' stroke-width='1.5' stroke-linecap='round' />
      <path
        d='M2.9375 9.9375C3.45527 9.9375 3.875 9.51777 3.875 9C3.875 8.48223 3.45527 8.0625 2.9375 8.0625C2.41973 8.0625 2 8.48223 2 9C2 9.51777 2.41973 9.9375 2.9375 9.9375Z'
        fill='black'
      />
      <path
        d='M2.9375 5.4375C3.45527 5.4375 3.875 5.01777 3.875 4.5C3.875 3.98223 3.45527 3.5625 2.9375 3.5625C2.41973 3.5625 2 3.98223 2 4.5C2 5.01777 2.41973 5.4375 2.9375 5.4375Z'
        fill='black'
      />
      <path
        d='M2.9375 14.4375C3.45527 14.4375 3.875 14.0178 3.875 13.5C3.875 12.9822 3.45527 12.5625 2.9375 12.5625C2.41973 12.5625 2 12.9822 2 13.5C2 14.0178 2.41973 14.4375 2.9375 14.4375Z'
        fill='black'
      />
    </svg>
  )
}
export const IconGridBlack = () => {
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' opacity={0.9} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.375 5.3125V2.375C1.375 1.54657 2.04657 0.875 2.875 0.875H5.8125C6.64093 0.875 7.3125 1.54657 7.3125 2.375V5.3125C7.3125 6.14093 6.64093 6.8125 5.8125 6.8125H2.875C2.04657 6.8125 1.375 6.14093 1.375 5.3125Z'
        stroke='black'
        stroke-width='1.5'
      />
      <path
        d='M1.375 13.625V10.6875C1.375 9.85907 2.04657 9.1875 2.875 9.1875H5.8125C6.64093 9.1875 7.3125 9.85907 7.3125 10.6875V13.625C7.3125 14.4534 6.64093 15.125 5.8125 15.125H2.875C2.04657 15.125 1.375 14.4534 1.375 13.625Z'
        stroke='black'
        stroke-width='1.5'
      />
      <path
        d='M9.6875 5.3125V2.375C9.6875 1.54657 10.3591 0.875 11.1875 0.875H14.125C14.9534 0.875 15.625 1.54657 15.625 2.375V5.3125C15.625 6.14093 14.9534 6.8125 14.125 6.8125H11.1875C10.3591 6.8125 9.6875 6.14093 9.6875 5.3125Z'
        stroke='black'
        stroke-width='1.5'
      />
      <path
        d='M9.6875 13.625V10.6875C9.6875 9.85907 10.3591 9.1875 11.1875 9.1875H14.125C14.9534 9.1875 15.625 9.85907 15.625 10.6875V13.625C15.625 14.4534 14.9534 15.125 14.125 15.125H11.1875C10.3591 15.125 9.6875 14.4534 9.6875 13.625Z'
        stroke='black'
        stroke-width='1.5'
      />
    </svg>
  )
}
export const IconClipBoardPast = ({ color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth={1.5}
      stroke={color || "currentColor"}
      className='w-5 h-5'>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z'
      />
    </svg>
  )
}
export const IconStarEmpty = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20' title='Not starred'>
      <path
        stroke='#000'
        strokeWidth='2'
        d='M9.075 2.256c.34-.828 1.51-.828 1.85 0L12.65 6.46a1 1 0 00.85.617l4.531.341c.892.067 1.254 1.181.572 1.76l-3.465 2.939a1 1 0 00-.325 1l1.076 4.413c.212.87-.736 1.558-1.497 1.088l-3.866-2.387a1 1 0 00-1.05 0l-3.866 2.387c-.761.47-1.709-.219-1.497-1.088l1.076-4.414a1 1 0 00-.325-1L1.398 9.179c-.682-.579-.32-1.693.572-1.76l4.53-.34a1 1 0 00.85-.618l1.725-4.204z'></path>
    </svg>
  )
}
export const IconOptionsDotted = () => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_2940_3343)'>
        <path
          d='M5.5 6C5.5 6.13261 5.55268 6.25979 5.64645 6.35355C5.74021 6.44732 5.86739 6.5 6 6.5C6.13261 6.5 6.25979 6.44732 6.35355 6.35355C6.44732 6.25979 6.5 6.13261 6.5 6C6.5 5.86739 6.44732 5.74021 6.35355 5.64645C6.25979 5.55268 6.13261 5.5 6 5.5C5.86739 5.5 5.74021 5.55268 5.64645 5.64645C5.55268 5.74021 5.5 5.86739 5.5 6Z'
          stroke='black'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M5.5 9.5C5.5 9.63261 5.55268 9.75979 5.64645 9.85355C5.74021 9.94732 5.86739 10 6 10C6.13261 10 6.25979 9.94732 6.35355 9.85355C6.44732 9.75979 6.5 9.63261 6.5 9.5C6.5 9.36739 6.44732 9.24021 6.35355 9.14645C6.25979 9.05268 6.13261 9 6 9C5.86739 9 5.74021 9.05268 5.64645 9.14645C5.55268 9.24021 5.5 9.36739 5.5 9.5Z'
          stroke='black'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M5.5 2.5C5.5 2.63261 5.55268 2.75979 5.64645 2.85355C5.74021 2.94732 5.86739 3 6 3C6.13261 3 6.25979 2.94732 6.35355 2.85355C6.44732 2.75979 6.5 2.63261 6.5 2.5C6.5 2.36739 6.44732 2.24021 6.35355 2.14645C6.25979 2.05268 6.13261 2 6 2C5.86739 2 5.74021 2.05268 5.64645 2.14645C5.55268 2.24021 5.5 2.36739 5.5 2.5Z'
          stroke='black'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2940_3343'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export const IconStarFilled = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20' title='Starred'>
      <path
        fill='#FFAF14'
        stroke='#FFAF14'
        strokeWidth='2'
        d='M9.075 2.256c.34-.828 1.51-.828 1.85 0L12.65 6.46a1 1 0 00.85.617l4.531.341c.892.067 1.254 1.181.572 1.76l-3.465 2.939a1 1 0 00-.325 1l1.076 4.413c.212.87-.736 1.558-1.497 1.088l-3.866-2.387a1 1 0 00-1.05 0l-3.866 2.387c-.761.47-1.709-.219-1.497-1.088l1.076-4.414a1 1 0 00-.325-1L1.398 9.179c-.682-.579-.32-1.693.572-1.76l4.53-.34a1 1 0 00.85-.618l1.725-4.204z'></path>
    </svg>
  )
}

export const IconRightChevron = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.6825 15.5021C6.54 15.5021 6.3975 15.4496 6.285 15.3371C6.0675 15.1196 6.0675 14.7596 6.285 14.5421L11.175 9.65215C11.535 9.29215 11.535 8.70715 11.175 8.34715L6.285 3.45715C6.0675 3.23965 6.0675 2.87965 6.285 2.66215C6.5025 2.44465 6.8625 2.44465 7.08 2.66215L11.97 7.55215C12.3525 7.93465 12.57 8.45215 12.57 8.99965C12.57 9.54715 12.36 10.0646 11.97 10.4471L7.08 15.3371C6.9675 15.4421 6.825 15.5021 6.6825 15.5021Z'
        fill='#C4C4C4'
      />
    </svg>
  )
}
export const IconStarLoading = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20' title='Loading...'>
      <path
        fill='#1DD3B0'
        fillOpacity='0.4'
        stroke='#1DD3B0'
        strokeWidth='2'
        d='M9.075 2.256c.34-.828 1.51-.828 1.85 0L12.65 6.46a1 1 0 00.85.617l4.531.341c.892.067 1.254 1.181.572 1.76l-3.465 2.939a1 1 0 00-.325 1l1.076 4.413c.212.87-.736 1.558-1.497 1.088l-3.866-2.387a1 1 0 00-1.05 0l-3.866 2.387c-.761.47-1.709-.219-1.497-1.088l1.076-4.414a1 1 0 00-.325-1L1.398 9.179c-.682-.579-.32-1.693.572-1.76l4.53-.34a1 1 0 00.85-.618l1.725-4.204z'></path>
    </svg>
  )
}
export const IconEye = ({ color, height, width }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9ZM11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12Z'
        fill={color || "white"}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.83 11.2807C19.542 7.15186 15.8122 5 12 5C8.18777 5 4.45796 7.15186 2.17003 11.2807C1.94637 11.6844 1.94361 12.1821 2.16029 12.5876C4.41183 16.8013 8.1628 19 12 19C15.8372 19 19.5882 16.8013 21.8397 12.5876C22.0564 12.1821 22.0536 11.6844 21.83 11.2807ZM12 17C9.06097 17 6.04052 15.3724 4.09173 11.9487C6.06862 8.59614 9.07319 7 12 7C14.9268 7 17.9314 8.59614 19.9083 11.9487C17.9595 15.3724 14.939 17 12 17Z'
        fill={color || "white"}
      />
    </svg>
  )
}
export const IconBlackEye = () => {
  return (
    <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.5 6C15.75 3.75 12.375 0.75 9 0.75C5.625 0.75 2.25 3.75 1.5 6'
        stroke='#121219'
        stroke-width='1.5'
        stroke-linecap='round'
      />
      <path
        d='M1.5 6C2.25 8.25 5.625 11.25 9 11.25C12.375 11.25 15.75 8.25 16.5 6'
        stroke='#121219'
        stroke-width='1.5'
        stroke-linecap='round'
      />
      <path
        d='M11.0597 5.96041C11.0597 7.11092 10.1391 8.06241 9 8.06241C7.86093 8.06241 6.93384 7.11092 6.93384 5.96041C6.93384 4.80989 7.86093 3.93741 9 3.93741C10.1391 3.93741 11.0597 4.80989 11.0597 5.96041Z'
        stroke='#121219'
        stroke-width='1.5'
      />
    </svg>
  )
}
export const IconBlackAnonomize = () => {
  return (
    <svg width='22' height='18' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.291 10.5557C14.5293 10.5557 13.0483 11.8465 12.7184 13.5533C12.2481 13.3453 11.6515 13.1804 10.9496 13.1804C10.2266 13.1732 9.60895 13.3453 9.13166 13.5533C8.80177 11.8465 7.32778 10.5557 5.55899 10.5557C3.55156 10.5557 1.91614 12.2266 1.91614 14.2776C1.91614 16.3286 3.55156 17.9995 5.55899 17.9995C7.47518 17.9995 9.05445 16.4792 9.18781 14.5501C9.60193 14.3206 10.1985 14.0983 10.9426 14.1055C11.6655 14.1126 12.2481 14.3278 12.6552 14.5501C12.7956 16.472 14.3678 17.9995 16.284 17.9995C18.2914 17.9995 19.9269 16.3286 19.9269 14.2776C19.9339 12.2266 18.2985 10.5557 16.291 10.5557ZM5.56601 17.0744C4.05693 17.0744 2.82861 15.8194 2.82861 14.2776C2.82861 12.7357 4.05693 11.4808 5.56601 11.4808C7.0751 11.4808 8.30342 12.7357 8.30342 14.2776C8.30342 15.8194 7.0751 17.0744 5.56601 17.0744ZM16.291 17.0744C14.782 17.0744 13.5536 15.8194 13.5536 14.2776C13.5536 12.7357 14.782 11.4808 16.291 11.4808C17.8001 11.4808 19.0284 12.7357 19.0284 14.2776C19.0284 15.8194 17.8001 17.0744 16.291 17.0744Z'
        fill='#212121'
      />
      <path
        d='M21.4079 8.54785H0.456235C0.203551 8.54785 0 8.75582 0 9.01399C0 9.27215 0.203551 9.48012 0.456235 9.48012H21.4079C21.6606 9.48012 21.8642 9.27215 21.8642 9.01399C21.8571 8.75582 21.6536 8.54785 21.4079 8.54785Z'
        fill='#212121'
      />
      <path
        d='M5.56601 7.4576H16.298C16.4384 7.4576 16.5648 7.39305 16.656 7.28548C16.7402 7.17792 16.7753 7.02732 16.7402 6.89106L15.554 1.72054C15.4277 1.1827 15.1189 0.709389 14.6696 0.393851C14.2204 0.0783134 13.68 -0.0579416 13.1465 0.0209428C12.4165 0.121341 11.6655 0.17154 10.9285 0.17154C10.9215 0.17154 10.9145 0.17154 10.9075 0.17154C10.1775 0.17154 9.43347 0.121341 8.71051 0.0209428C8.17005 -0.0507703 7.62959 0.0783134 7.18739 0.393851C6.73818 0.709389 6.42934 1.17552 6.303 1.72054L5.12381 6.89106C5.09573 7.02732 5.12381 7.17074 5.20804 7.28548C5.29928 7.39305 5.42563 7.4576 5.56601 7.4576ZM7.18739 1.92851C7.33479 1.27592 7.93843 0.845644 8.59119 0.938871C9.35626 1.04644 10.1354 1.09664 10.9075 1.09664C10.9145 1.09664 10.9215 1.09664 10.9285 1.09664C11.7076 1.09664 12.4938 1.03927 13.2729 0.938871C13.9256 0.845644 14.5222 1.27592 14.6767 1.92851L15.7295 6.5325H6.13454L7.18739 1.92851Z'
        fill='#212121'
      />
    </svg>
  )
}
export const IconBlackShare = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.5 3.75C16.5 2.50736 15.4926 1.5 14.25 1.5C13.0074 1.5 12 2.50736 12 3.75C12 4.99264 13.0074 6 14.25 6C15.4926 6 16.5 4.99264 16.5 3.75Z'
        stroke='#121219'
        stroke-width='1.5'
      />
      <path
        d='M6 9C6 7.75736 4.99264 6.75 3.75 6.75C2.50736 6.75 1.5 7.75736 1.5 9C1.5 10.2426 2.50736 11.25 3.75 11.25C4.99264 11.25 6 10.2426 6 9Z'
        stroke='#121219'
        stroke-width='1.5'
      />
      <path
        d='M16.5 14.25C16.5 13.0074 15.4926 12 14.25 12C13.0074 12 12 13.0074 12 14.25C12 15.4926 13.0074 16.5 14.25 16.5C15.4926 16.5 16.5 15.4926 16.5 14.25Z'
        stroke='#121219'
        stroke-width='1.5'
      />
      <path d='M12 4.125L5.625 7.5' stroke='#121219' stroke-width='1.5' stroke-linecap='round' />
      <path d='M12 13.875L5.625 10.5' stroke='#121219' stroke-width='1.5' stroke-linecap='round' />
    </svg>
  )
}
export const IconBlackComment = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 17.0625H2.25C1.53 17.0625 0.9375 16.47 0.9375 15.75V6C0.9375 2.685 2.685 0.9375 6 0.9375H12C15.315 0.9375 17.0625 2.685 17.0625 6V12C17.0625 15.315 15.315 17.0625 12 17.0625ZM6 2.0625C3.315 2.0625 2.0625 3.315 2.0625 6V15.75C2.0625 15.855 2.145 15.9375 2.25 15.9375H12C14.685 15.9375 15.9375 14.685 15.9375 12V6C15.9375 3.315 14.685 2.0625 12 2.0625H6Z'
        fill='#1A2128'
      />
      <path
        d='M12.75 7.6875H5.25C4.9425 7.6875 4.6875 7.4325 4.6875 7.125C4.6875 6.8175 4.9425 6.5625 5.25 6.5625H12.75C13.0575 6.5625 13.3125 6.8175 13.3125 7.125C13.3125 7.4325 13.0575 7.6875 12.75 7.6875Z'
        fill='#1A2128'
      />
      <path
        d='M10.5 11.4375H5.25C4.9425 11.4375 4.6875 11.1825 4.6875 10.875C4.6875 10.5675 4.9425 10.3125 5.25 10.3125H10.5C10.8075 10.3125 11.0625 10.5675 11.0625 10.875C11.0625 11.1825 10.8075 11.4375 10.5 11.4375Z'
        fill='#1A2128'
      />
    </svg>
  )
}
export const IconBlackDownload = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.75 13.3125C6.675 13.3125 6.6075 13.2975 6.5325 13.2675C6.3225 13.185 6.1875 12.975 6.1875 12.75V8.25C6.1875 7.9425 6.4425 7.6875 6.75 7.6875C7.0575 7.6875 7.3125 7.9425 7.3125 8.25V11.3925L7.8525 10.8525C8.07 10.635 8.43 10.635 8.6475 10.8525C8.865 11.07 8.865 11.43 8.6475 11.6475L7.1475 13.1475C7.0425 13.2525 6.8925 13.3125 6.75 13.3125Z'
        fill='#1A2128'
      />
      <path
        d='M6.74996 13.3126C6.60746 13.3126 6.46496 13.2601 6.35246 13.1476L4.85246 11.6476C4.63496 11.4301 4.63496 11.0701 4.85246 10.8526C5.06996 10.6351 5.42996 10.6351 5.64746 10.8526L7.14746 12.3526C7.36496 12.5701 7.36496 12.9301 7.14746 13.1476C7.03496 13.2601 6.89246 13.3126 6.74996 13.3126Z'
        fill='#1A2128'
      />
      <path
        d='M11.25 17.0625H6.75C2.6775 17.0625 0.9375 15.3225 0.9375 11.25V6.75C0.9375 2.6775 2.6775 0.9375 6.75 0.9375H10.5C10.8075 0.9375 11.0625 1.1925 11.0625 1.5C11.0625 1.8075 10.8075 2.0625 10.5 2.0625H6.75C3.2925 2.0625 2.0625 3.2925 2.0625 6.75V11.25C2.0625 14.7075 3.2925 15.9375 6.75 15.9375H11.25C14.7075 15.9375 15.9375 14.7075 15.9375 11.25V7.5C15.9375 7.1925 16.1925 6.9375 16.5 6.9375C16.8075 6.9375 17.0625 7.1925 17.0625 7.5V11.25C17.0625 15.3225 15.3225 17.0625 11.25 17.0625Z'
        fill='#1A2128'
      />
      <path
        d='M16.5 8.06237H13.5C10.935 8.06237 9.9375 7.06487 9.9375 4.49987V1.49987C9.9375 1.27487 10.0725 1.06487 10.2825 0.982369C10.4925 0.892369 10.7325 0.94487 10.8975 1.10237L16.8975 7.10237C17.055 7.25987 17.1075 7.50737 17.0175 7.71737C16.9275 7.92737 16.725 8.06237 16.5 8.06237ZM11.0625 2.85737V4.49987C11.0625 6.43487 11.565 6.93737 13.5 6.93737H15.1425L11.0625 2.85737Z'
        fill='#1A2128'
      />
    </svg>
  )
}
export const IconBlackDelete = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.75 5.04785C15.735 5.04785 15.7125 5.04785 15.69 5.04785C11.7225 4.65035 7.7625 4.50035 3.84 4.89785L2.31 5.04785C1.995 5.07785 1.7175 4.85285 1.6875 4.53785C1.6575 4.22285 1.8825 3.95285 2.19 3.92285L3.72 3.77285C7.71 3.36785 11.7525 3.52535 15.8025 3.92285C16.11 3.95285 16.335 4.23035 16.305 4.53785C16.2825 4.83035 16.035 5.04785 15.75 5.04785Z'
        fill='#1A2128'
      />
      <path
        d='M6.37495 4.29C6.34495 4.29 6.31495 4.29 6.27745 4.2825C5.97745 4.23 5.76745 3.9375 5.81995 3.6375L5.98495 2.655C6.10495 1.935 6.26995 0.9375 8.01745 0.9375H9.98245C11.7374 0.9375 11.9024 1.9725 12.0149 2.6625L12.1799 3.6375C12.2324 3.945 12.0224 4.2375 11.7224 4.2825C11.4149 4.335 11.1224 4.125 11.0774 3.825L10.9124 2.85C10.8074 2.1975 10.7849 2.07 9.98995 2.07H8.02495C7.22995 2.07 7.21495 2.175 7.10245 2.8425L6.92995 3.8175C6.88495 4.095 6.64495 4.29 6.37495 4.29Z'
        fill='#1A2128'
      />
      <path
        d='M11.4075 17.0627H6.59255C3.97505 17.0627 3.87005 15.6152 3.78755 14.4452L3.30005 6.89268C3.27755 6.58518 3.51755 6.31518 3.82505 6.29268C4.14005 6.27768 4.40255 6.51018 4.42505 6.81768L4.91255 14.3702C4.99505 15.5102 5.02505 15.9377 6.59255 15.9377H11.4075C12.9825 15.9377 13.0125 15.5102 13.0875 14.3702L13.575 6.81768C13.5975 6.51018 13.8675 6.27768 14.175 6.29268C14.4825 6.31518 14.7225 6.57768 14.7 6.89268L14.2125 14.4452C14.13 15.6152 14.025 17.0627 11.4075 17.0627Z'
        fill='#1A2128'
      />
      <path
        d='M10.2451 12.9375H7.74756C7.44006 12.9375 7.18506 12.6825 7.18506 12.375C7.18506 12.0675 7.44006 11.8125 7.74756 11.8125H10.2451C10.5526 11.8125 10.8076 12.0675 10.8076 12.375C10.8076 12.6825 10.5526 12.9375 10.2451 12.9375Z'
        fill='#1A2128'
      />
      <path
        d='M10.875 9.9375H7.125C6.8175 9.9375 6.5625 9.6825 6.5625 9.375C6.5625 9.0675 6.8175 8.8125 7.125 8.8125H10.875C11.1825 8.8125 11.4375 9.0675 11.4375 9.375C11.4375 9.6825 11.1825 9.9375 10.875 9.9375Z'
        fill='#1A2128'
      />
    </svg>
  )
}
export const IconBlackCopy = ({ color }) => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.325 17.0625H5.175C2.2425 17.0625 0.9375 15.7575 0.9375 12.825V9.675C0.9375 6.7425 2.2425 5.4375 5.175 5.4375H8.325C11.2575 5.4375 12.5625 6.7425 12.5625 9.675V12.825C12.5625 15.7575 11.2575 17.0625 8.325 17.0625ZM5.175 6.5625C2.85 6.5625 2.0625 7.35 2.0625 9.675V12.825C2.0625 15.15 2.85 15.9375 5.175 15.9375H8.325C10.65 15.9375 11.4375 15.15 11.4375 12.825V9.675C11.4375 7.35 10.65 6.5625 8.325 6.5625H5.175Z'
        fill={color || "#1A2128"}
      />
      <path
        d='M12.825 12.5625H12C11.6925 12.5625 11.4375 12.3075 11.4375 12V9.675C11.4375 7.35 10.65 6.5625 8.325 6.5625H6C5.6925 6.5625 5.4375 6.3075 5.4375 6V5.175C5.4375 2.2425 6.7425 0.9375 9.675 0.9375H12.825C15.7575 0.9375 17.0625 2.2425 17.0625 5.175V8.325C17.0625 11.2575 15.7575 12.5625 12.825 12.5625ZM12.5625 11.4375H12.825C15.15 11.4375 15.9375 10.65 15.9375 8.325V5.175C15.9375 2.85 15.15 2.0625 12.825 2.0625H9.675C7.35 2.0625 6.5625 2.85 6.5625 5.175V5.4375H8.325C11.2575 5.4375 12.5625 6.7425 12.5625 9.675V11.4375Z'
        fill={color || "#1A2128"}
      />
    </svg>
  )
}
export const IconBlackCopyed = () => {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M22 11.1V6.9C22 3.4 20.6 2 17.1 2H12.9C9.4 2 8 3.4 8 6.9V8H11.1C14.6 8 16 9.4 16 12.9V16H17.1C20.6 16 22 14.6 22 11.1Z'
        stroke='#1A2128'
        strokeLinecap='round'
        strokeWidth={1.3}
        strokeLinejoin='round'
      />
      <path
        d='M16 17.1V12.9C16 9.4 14.6 8 11.1 8H6.9C3.4 8 2 9.4 2 12.9V17.1C2 20.6 3.4 22 6.9 22H11.1C14.6 22 16 20.6 16 17.1Z'
        stroke='#1A2128'
        strokeLinecap='round'
        strokeWidth={1.3}
        strokeLinejoin='round'
      />
      <path
        d='M6.08008 15L8.03008 16.95L11.9201 13.05'
        strokeWidth={1.3}
        stroke='#1A2128'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
export const IconBlackCut = ({ color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth={1.5}
      stroke={color || "currentColor"}
      className='w-4 h-4'>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='m7.848 8.25 1.536.887M7.848 8.25a3 3 0 1 1-5.196-3 3 3 0 0 1 5.196 3Zm1.536.887a2.165 2.165 0 0 1 1.083 1.839c.005.351.054.695.14 1.024M9.384 9.137l2.077 1.199M7.848 15.75l1.536-.887m-1.536.887a3 3 0 1 1-5.196 3 3 3 0 0 1 5.196-3Zm1.536-.887a2.165 2.165 0 0 0 1.083-1.838c.005-.352.054-.695.14-1.025m-1.223 2.863 2.077-1.199m0-3.328a4.323 4.323 0 0 1 2.068-1.379l5.325-1.628a4.5 4.5 0 0 1 2.48-.044l.803.215-7.794 4.5m-2.882-1.664A4.33 4.33 0 0 0 10.607 12m3.736 0 7.794 4.5-.802.215a4.5 4.5 0 0 1-2.48-.043l-5.326-1.629a4.324 4.324 0 0 1-2.068-1.379M14.343 12l-2.882 1.664'
      />
    </svg>
  )
}
export const IconCommentChecked = () => {
  return (
    <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.3572 17.0625H2.60718C1.88718 17.0625 1.29468 16.47 1.29468 15.75V6C1.29468 2.685 3.04218 0.9375 6.35718 0.9375H12.3572C15.6722 0.9375 17.4197 2.685 17.4197 6V12C17.4197 15.315 15.6722 17.0625 12.3572 17.0625ZM6.35718 2.0625C3.67218 2.0625 2.41968 3.315 2.41968 6V15.75C2.41968 15.855 2.50218 15.9375 2.60718 15.9375H12.3572C15.0422 15.9375 16.2947 14.685 16.2947 12V6C16.2947 3.315 15.0422 2.0625 12.3572 2.0625H6.35718Z'
        fill='#1DD3B0'
      />
      <path
        d='M5 9.06738L6.91206 11.3017C7.73704 12.2657 9.21584 12.2257 9.98984 11.2185L14 6'
        stroke='#FF0000'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export const IconBlackRename = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.25 17.0625H6.75C2.6775 17.0625 0.9375 15.3225 0.9375 11.25V6.75C0.9375 2.6775 2.6775 0.9375 6.75 0.9375H8.25C8.5575 0.9375 8.8125 1.1925 8.8125 1.5C8.8125 1.8075 8.5575 2.0625 8.25 2.0625H6.75C3.2925 2.0625 2.0625 3.2925 2.0625 6.75V11.25C2.0625 14.7075 3.2925 15.9375 6.75 15.9375H11.25C14.7075 15.9375 15.9375 14.7075 15.9375 11.25V9.75C15.9375 9.4425 16.1925 9.1875 16.5 9.1875C16.8075 9.1875 17.0625 9.4425 17.0625 9.75V11.25C17.0625 15.3225 15.3225 17.0625 11.25 17.0625Z'
        fill='#1A2128'
      />
      <path
        d='M6.375 13.2674C5.9175 13.2674 5.4975 13.1024 5.19 12.8024C4.8225 12.4349 4.665 11.9024 4.7475 11.3399L5.07 9.08242C5.13 8.64742 5.415 8.08492 5.7225 7.77742L11.6325 1.86742C13.125 0.374922 14.64 0.374922 16.1325 1.86742C16.95 2.68492 17.3175 3.51742 17.2425 4.34992C17.175 5.02492 16.815 5.68492 16.1325 6.35992L10.2225 12.2699C9.915 12.5774 9.3525 12.8624 8.9175 12.9224L6.66 13.2449C6.5625 13.2674 6.465 13.2674 6.375 13.2674ZM12.4275 2.66242L6.5175 8.57242C6.375 8.71492 6.21 9.04492 6.18 9.23992L5.8575 11.4974C5.8275 11.7149 5.8725 11.8949 5.985 12.0074C6.0975 12.1199 6.2775 12.1649 6.495 12.1349L8.7525 11.8124C8.9475 11.7824 9.285 11.6174 9.42 11.4749L15.33 5.56492C15.8175 5.07742 16.0725 4.64242 16.11 4.23742C16.155 3.74992 15.9 3.23242 15.33 2.65492C14.13 1.45492 13.305 1.79242 12.4275 2.66242Z'
        fill='#1A2128'
      />
      <path
        d='M14.8875 7.3727C14.835 7.3727 14.7825 7.3652 14.7375 7.3502C12.765 6.7952 11.1975 5.2277 10.6425 3.2552C10.56 2.9552 10.7325 2.6477 11.0325 2.5577C11.3325 2.4752 11.64 2.6477 11.7225 2.9477C12.1725 4.5452 13.44 5.8127 15.0375 6.2627C15.3375 6.3452 15.51 6.6602 15.4275 6.9602C15.36 7.2152 15.135 7.3727 14.8875 7.3727Z'
        fill='#1A2128'
      />
    </svg>
  )
}
export const IconBlackStart = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.11256 2.56994C8.4792 1.82605 9.53988 1.82605 9.90651 2.56994L11.3329 5.46403C11.4786 5.75959 11.7605 5.96442 12.0866 6.01163L15.2501 6.4696C16.0694 6.58821 16.3981 7.59405 15.8068 8.17349L13.5036 10.4307C13.2695 10.6602 13.1627 10.9899 13.2178 11.3131L13.7609 14.4969C13.9004 15.3149 13.0403 15.9372 12.307 15.5487L9.4777 14.0497C9.18487 13.8945 8.8342 13.8945 8.54137 14.0497L5.7121 15.5487C4.97878 15.9372 4.11863 15.3149 4.25818 14.4969L4.80104 11.3145C4.85631 10.9905 4.74884 10.6601 4.51357 10.4305L2.20153 8.1751C1.60803 7.59614 1.93639 6.58794 2.75701 6.46954L5.93215 6.01143C6.25845 5.96435 6.54058 5.75947 6.68632 5.46376L8.11256 2.56994Z'
        stroke='#121219'
        stroke-width='1.5'
        stroke-miterlimit='10'
      />
    </svg>
  )
}

export const IconEditOnly = ({ width, height, color }) => {
  return (
    <svg
      className=''
      xmlns='http://www.w3.org/2000/svg'
      width={width || 18}
      height={height || 18}
      viewBox='0 0 18 18'
      fill='none'>
      <path
        d='M11.25 17.0625H6.75C2.6775 17.0625 0.9375 15.3225 0.9375 11.25V6.75C0.9375 2.6775 2.6775 0.9375 6.75 0.9375H8.25C8.5575 0.9375 8.8125 1.1925 8.8125 1.5C8.8125 1.8075 8.5575 2.0625 8.25 2.0625H6.75C3.2925 2.0625 2.0625 3.2925 2.0625 6.75V11.25C2.0625 14.7075 3.2925 15.9375 6.75 15.9375H11.25C14.7075 15.9375 15.9375 14.7075 15.9375 11.25V9.75C15.9375 9.4425 16.1925 9.1875 16.5 9.1875C16.8075 9.1875 17.0625 9.4425 17.0625 9.75V11.25C17.0625 15.3225 15.3225 17.0625 11.25 17.0625Z'
        fill={color || "#1A2128"}
      />
      <path
        d='M6.375 13.2674C5.9175 13.2674 5.4975 13.1024 5.19 12.8024C4.8225 12.4349 4.665 11.9024 4.7475 11.3399L5.07 9.08242C5.13 8.64742 5.415 8.08492 5.7225 7.77742L11.6325 1.86742C13.125 0.374922 14.64 0.374922 16.1325 1.86742C16.95 2.68492 17.3175 3.51742 17.2425 4.34992C17.175 5.02492 16.815 5.68492 16.1325 6.35992L10.2225 12.2699C9.915 12.5774 9.3525 12.8624 8.9175 12.9224L6.66 13.2449C6.5625 13.2674 6.465 13.2674 6.375 13.2674ZM12.4275 2.66242L6.5175 8.57242C6.375 8.71492 6.21 9.04492 6.18 9.23992L5.8575 11.4974C5.8275 11.7149 5.8725 11.8949 5.985 12.0074C6.0975 12.1199 6.2775 12.1649 6.495 12.1349L8.7525 11.8124C8.9475 11.7824 9.285 11.6174 9.42 11.4749L15.33 5.56492C15.8175 5.07742 16.0725 4.64242 16.11 4.23742C16.155 3.74992 15.9 3.23242 15.33 2.65492C14.13 1.45492 13.305 1.79242 12.4275 2.66242Z'
        fill={color || "#1A2128"}
      />
      <path
        d='M14.8875 7.3727C14.835 7.3727 14.7825 7.3652 14.7375 7.3502C12.765 6.7952 11.1975 5.2277 10.6425 3.2552C10.56 2.9552 10.7325 2.6477 11.0325 2.5577C11.3325 2.4752 11.64 2.6477 11.7225 2.9477C12.1725 4.5452 13.44 5.8127 15.0375 6.2627C15.3375 6.3452 15.51 6.6602 15.4275 6.9602C15.36 7.2152 15.135 7.3727 14.8875 7.3727Z'
        fill={color || "#1A2128"}
      />
    </svg>
  )
}

export const IconFullAccess = ({ width, height, color }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width || 18} height={height || 18} viewBox='0 0 18 18' fill='none'>
      <path
        d='M11.25 17.0625H6.75C2.6775 17.0625 0.9375 15.3225 0.9375 11.25V6.75C0.9375 2.6775 2.6775 0.9375 6.75 0.9375H11.25C15.3225 0.9375 17.0625 2.6775 17.0625 6.75V11.25C17.0625 15.3225 15.3225 17.0625 11.25 17.0625ZM6.75 2.0625C3.2925 2.0625 2.0625 3.2925 2.0625 6.75V11.25C2.0625 14.7075 3.2925 15.9375 6.75 15.9375H11.25C14.7075 15.9375 15.9375 14.7075 15.9375 11.25V6.75C15.9375 3.2925 14.7075 2.0625 11.25 2.0625H6.75Z'
        fill={color || "#1A2128"}
      />
      <path
        d='M7.935 11.6842C7.785 11.6842 7.6425 11.6242 7.5375 11.5192L5.415 9.39672C5.1975 9.17922 5.1975 8.81922 5.415 8.60172C5.6325 8.38422 5.9925 8.38422 6.21 8.60172L7.935 10.3267L11.79 6.47172C12.0075 6.25422 12.3675 6.25422 12.585 6.47172C12.8025 6.68922 12.8025 7.04922 12.585 7.26672L8.3325 11.5192C8.2275 11.6242 8.085 11.6842 7.935 11.6842Z'
        fill={color || "#1A2128"}
      />
    </svg>
  )
}

export const IconViewOnly = ({ width, height, color }) => {
  return (
    <svg width={width || 18} height={height || 12} viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.5 6C15.75 3.75 12.375 0.75 9 0.75C5.625 0.75 2.25 3.75 1.5 6'
        stroke={color || "#121219"}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M1.49996 6C2.24996 8.25 5.62496 11.25 8.99996 11.25C12.375 11.25 15.75 8.25 16.5 6'
        stroke={color || "#121219"}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M11.0596 5.96041C11.0596 7.11092 10.139 8.06241 8.99996 8.06241C7.86089 8.06241 6.9338 7.11092 6.9338 5.96041C6.9338 4.80989 7.86089 3.93741 8.99996 3.93741C10.139 3.93741 11.0596 4.80989 11.0596 5.96041Z'
        stroke={color || "#121219"}
        strokeWidth='1.5'
      />
    </svg>
  )
}

export const IconCheckMark = ({ width, height, color }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width || 10} height={height || 8} viewBox='0 0 8 6' fill={"none"}>
      <path
        d='M2.935 5.68422C2.785 5.68422 2.6425 5.62422 2.5375 5.51922L0.415002 3.39672C0.197502 3.17922 0.197502 2.81922 0.415002 2.60172C0.632502 2.38422 0.992502 2.38422 1.21 2.60172L2.935 4.32672L6.79 0.471719C7.0075 0.254219 7.3675 0.254219 7.585 0.471719C7.8025 0.689219 7.8025 1.04922 7.585 1.26672L3.3325 5.51922C3.2275 5.62422 3.085 5.68422 2.935 5.68422Z'
        fill={color || "#1A2128"}
      />
    </svg>
  )
}
export const IconAnonomize2 = () => {
  return (
    <svg width='23' height='18' viewBox='0 0 23 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.791 10.5566C15.0293 10.5566 13.5483 11.8475 13.2184 13.5543C12.7481 13.3463 12.1515 13.1813 11.4496 13.1813C10.7266 13.1742 10.109 13.3463 9.63166 13.5543C9.30177 11.8475 7.82778 10.5566 6.05899 10.5566C4.05156 10.5566 2.41614 12.2276 2.41614 14.2786C2.41614 16.3295 4.05156 18.0005 6.05899 18.0005C7.97518 18.0005 9.55445 16.4801 9.68781 14.5511C10.1019 14.3216 10.6985 14.0993 11.4426 14.1064C12.1655 14.1136 12.7481 14.3288 13.1552 14.5511C13.2956 16.473 14.8678 18.0005 16.784 18.0005C18.7914 18.0005 20.4269 16.3295 20.4269 14.2786C20.4339 12.2276 18.7985 10.5566 16.791 10.5566ZM6.06601 17.0754C4.55693 17.0754 3.32861 15.8204 3.32861 14.2786C3.32861 12.7367 4.55693 11.4817 6.06601 11.4817C7.5751 11.4817 8.80342 12.7367 8.80342 14.2786C8.80342 15.8204 7.5751 17.0754 6.06601 17.0754ZM16.791 17.0754C15.282 17.0754 14.0536 15.8204 14.0536 14.2786C14.0536 12.7367 15.282 11.4817 16.791 11.4817C18.3001 11.4817 19.5284 12.7367 19.5284 14.2786C19.5284 15.8204 18.3001 17.0754 16.791 17.0754Z'
        fill='#1DD3B0'
      />
      <path
        d='M21.9079 8.54883H0.956235C0.703551 8.54883 0.5 8.7568 0.5 9.01496C0.5 9.27313 0.703551 9.4811 0.956235 9.4811H21.9079C22.1606 9.4811 22.3642 9.27313 22.3642 9.01496C22.3571 8.7568 22.1536 8.54883 21.9079 8.54883Z'
        fill='#1DD3B0'
      />
      <path
        d='M6.06601 7.45857H16.798C16.9384 7.45857 17.0648 7.39403 17.156 7.28646C17.2402 7.17889 17.2753 7.02829 17.2402 6.89204L16.054 1.72152C15.9277 1.18367 15.6189 0.710366 15.1696 0.394828C14.7204 0.0792899 14.18 -0.0569651 13.6465 0.0219194C12.9165 0.122318 12.1655 0.172517 11.4285 0.172517C11.4215 0.172517 11.4145 0.172517 11.4075 0.172517C10.6775 0.172517 9.93347 0.122318 9.21051 0.0219194C8.67005 -0.0497937 8.12959 0.0792899 7.68739 0.394828C7.23818 0.710366 6.92934 1.1765 6.803 1.72152L5.62381 6.89204C5.59573 7.02829 5.62381 7.17172 5.70804 7.28646C5.79928 7.39403 5.92563 7.45857 6.06601 7.45857ZM7.68739 1.92949C7.83479 1.2769 8.43843 0.846621 9.09119 0.939848C9.85626 1.04742 10.6354 1.09762 11.4075 1.09762C11.4145 1.09762 11.4215 1.09762 11.4285 1.09762C12.2076 1.09762 12.9938 1.04025 13.7729 0.939848C14.4256 0.846621 15.0222 1.2769 15.1767 1.92949L16.2295 6.53347H6.63454L7.68739 1.92949Z'
        fill='#1DD3B0'
      />
    </svg>
  )
}
export const IconAnonomize1 = () => {
  return (
    <svg width='23' height='18' viewBox='0 0 30 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_548_11032)'>
        <path
          d='M22.3601 14.9889C19.942 14.9889 17.9093 16.7606 17.4565 19.1032C16.811 18.8178 15.9921 18.5914 15.0287 18.5914C14.0364 18.5816 13.1887 18.8178 12.5335 19.1032C12.0808 16.7606 10.0576 14.9889 7.62988 14.9889C4.87458 14.9889 2.62988 17.2823 2.62988 20.0974C2.62988 22.9125 4.87458 25.2059 7.62988 25.2059C10.2599 25.2059 12.4276 23.1192 12.6106 20.4714C13.179 20.1564 13.9979 19.8513 15.0191 19.8612C16.0114 19.871 16.811 20.1663 17.3698 20.4714C17.5624 23.1093 19.7204 25.2059 22.3505 25.2059C25.1058 25.2059 27.3505 22.9125 27.3505 20.0974C27.3601 17.2823 25.1154 14.9889 22.3601 14.9889ZM7.63952 23.9361C5.56823 23.9361 3.88229 22.2136 3.88229 20.0974C3.88229 17.9811 5.56823 16.2586 7.63952 16.2586C9.71081 16.2586 11.3967 17.9811 11.3967 20.0974C11.3967 22.2136 9.71081 23.9361 7.63952 23.9361ZM22.3601 23.9361C20.2888 23.9361 18.6029 22.2136 18.6029 20.0974C18.6029 17.9811 20.2888 16.2586 22.3601 16.2586C24.4314 16.2586 26.1174 17.9811 26.1174 20.0974C26.1174 22.2136 24.4314 23.9361 22.3601 23.9361Z'
          fill='#FFB341'
        />
        <path
          d='M29.3834 12.2328H0.626204C0.279383 12.2328 0 12.5183 0 12.8726C0 13.227 0.279383 13.5124 0.626204 13.5124H29.3834C29.7303 13.5124 30.0096 13.227 30.0096 12.8726C30 12.5183 29.7206 12.2328 29.3834 12.2328Z'
          fill='#FFB341'
        />
        <path
          d='M7.63945 10.7367H22.3697C22.5624 10.7367 22.7358 10.6481 22.861 10.5005C22.9766 10.3528 23.0248 10.1461 22.9766 9.95911L21.3485 2.86232C21.1751 2.1241 20.7512 1.47446 20.1346 1.04137C19.5181 0.60828 18.7763 0.421263 18.0441 0.529536C17.0422 0.667338 16.0113 0.736239 14.9998 0.736239C14.9901 0.736239 14.9805 0.736239 14.9709 0.736239C13.9689 0.736239 12.9477 0.667338 11.9554 0.529536C11.2136 0.431106 10.4718 0.60828 9.86489 1.04137C9.24832 1.47446 8.82443 2.11426 8.65101 2.86232L7.03252 9.95911C6.99398 10.1461 7.03252 10.343 7.14812 10.5005C7.27336 10.6481 7.44678 10.7367 7.63945 10.7367ZM9.86489 3.14777C10.0672 2.25206 10.8957 1.66148 11.7917 1.78944C12.8418 1.93708 13.9111 2.00598 14.9709 2.00598C14.9805 2.00598 14.9901 2.00598 14.9998 2.00598C16.0691 2.00598 17.1481 1.92724 18.2175 1.78944C19.1134 1.66148 19.9323 2.25206 20.1443 3.14777L21.5894 9.46696H8.4198L9.86489 3.14777Z'
          fill='#FFB341'
        />
      </g>
      <defs>
        <clipPath id='clip0_548_11032'>
          <rect width='30' height='24.7059' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  )
}
export const IconAnonomize0 = () => {
  return (
    <svg width='23' height='18' viewBox='0 0 30 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_548_11033)'>
        <path
          d='M22.3601 14.4889C19.942 14.4889 17.9093 16.2606 17.4565 18.6032C16.811 18.3178 15.9921 18.0914 15.0287 18.0914C14.0364 18.0816 13.1887 18.3178 12.5335 18.6032C12.0808 16.2606 10.0576 14.4889 7.62988 14.4889C4.87458 14.4889 2.62988 16.7823 2.62988 19.5974C2.62988 22.4125 4.87458 24.7059 7.62988 24.7059C10.2599 24.7059 12.4276 22.6192 12.6106 19.9714C13.179 19.6564 13.9979 19.3513 15.0191 19.3611C16.0114 19.371 16.811 19.6663 17.3698 19.9714C17.5624 22.6093 19.7204 24.7059 22.3505 24.7059C25.1058 24.7059 27.3505 22.4125 27.3505 19.5974C27.3601 16.7823 25.1154 14.4889 22.3601 14.4889ZM7.63952 23.4361C5.56823 23.4361 3.88229 21.7136 3.88229 19.5974C3.88229 17.4811 5.56823 15.7586 7.63952 15.7586C9.71081 15.7586 11.3967 17.4811 11.3967 19.5974C11.3967 21.7136 9.71081 23.4361 7.63952 23.4361ZM22.3601 23.4361C20.2888 23.4361 18.6029 21.7136 18.6029 19.5974C18.6029 17.4811 20.2888 15.7586 22.3601 15.7586C24.4314 15.7586 26.1174 17.4811 26.1174 19.5974C26.1174 21.7136 24.4314 23.4361 22.3601 23.4361Z'
          fill='#FF4141'
        />
        <path
          d='M29.3834 11.7328H0.626204C0.279383 11.7328 0 12.0183 0 12.3726C0 12.727 0.279383 13.0124 0.626204 13.0124H29.3834C29.7303 13.0124 30.0096 12.727 30.0096 12.3726C30 12.0183 29.7206 11.7328 29.3834 11.7328Z'
          fill='#FF4141'
        />
        <path
          d='M7.63945 10.2367H22.3697C22.5624 10.2367 22.7358 10.1481 22.861 10.0005C22.9766 9.85282 23.0248 9.64612 22.9766 9.4591L21.3485 2.36231C21.1751 1.62409 20.7512 0.974455 20.1346 0.541364C19.5181 0.108272 18.7763 -0.0787443 18.0441 0.0295285C17.0422 0.16733 16.0113 0.236231 14.9998 0.236231C14.9901 0.236231 14.9805 0.236231 14.9709 0.236231C13.9689 0.236231 12.9477 0.16733 11.9554 0.0295285C11.2136 -0.0689013 10.4718 0.108272 9.86489 0.541364C9.24832 0.974455 8.82443 1.61425 8.65101 2.36231L7.03252 9.4591C6.99398 9.64612 7.03252 9.84298 7.14812 10.0005C7.27336 10.1481 7.44678 10.2367 7.63945 10.2367ZM9.86489 2.64776C10.0672 1.75205 10.8957 1.16147 11.7917 1.28943C12.8418 1.43707 13.9111 1.50598 14.9709 1.50598C14.9805 1.50598 14.9901 1.50598 14.9998 1.50598C16.0691 1.50598 17.1481 1.42723 18.2175 1.28943C19.1134 1.16147 19.9323 1.75205 20.1443 2.64776L21.5894 8.96696H8.4198L9.86489 2.64776Z'
          fill='#FF4141'
        />
      </g>
      <defs>
        <clipPath id='clip0_548_11033'>
          <rect width='30' height='24.7059' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IconShare = () => {
  return (
    <svg width='17' height='18' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.8572 3.75C15.8572 2.50736 14.8498 1.5 13.6072 1.5C12.3645 1.5 11.3572 2.50736 11.3572 3.75C11.3572 4.99264 12.3645 6 13.6072 6C14.8498 6 15.8572 4.99264 15.8572 3.75Z'
        stroke='#1DD3B0'
        stroke-width='1.5'
      />
      <path
        d='M5.35718 9C5.35718 7.75736 4.34982 6.75 3.10718 6.75C1.86454 6.75 0.857178 7.75736 0.857178 9C0.857178 10.2426 1.86454 11.25 3.10718 11.25C4.34982 11.25 5.35718 10.2426 5.35718 9Z'
        stroke='#1DD3B0'
        stroke-width='1.5'
      />
      <path
        d='M15.8572 14.25C15.8572 13.0074 14.8498 12 13.6072 12C12.3645 12 11.3572 13.0074 11.3572 14.25C11.3572 15.4926 12.3645 16.5 13.6072 16.5C14.8498 16.5 15.8572 15.4926 15.8572 14.25Z'
        stroke='#1DD3B0'
        stroke-width='1.5'
      />
      <path d='M11.3572 4.125L4.98218 7.5' stroke='#1DD3B0' stroke-width='1.5' stroke-linecap='round' />
      <path d='M11.3572 13.875L4.98218 10.5' stroke='#1DD3B0' stroke-width='1.5' stroke-linecap='round' />
    </svg>
  )
}
export const IconCheckBoxChecked = ({ width, height, className }) => {
  return (
    <svg
      className={`${className}`}
      width={width || "14"}
      height={height || "15"}
      viewBox='0 0 11 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.125 10.625H2.875C1.77043 10.625 0.875 9.72957 0.875 8.625V3.375C0.875 2.27043 1.77043 1.375 2.875 1.375H8.125C9.22957 1.375 10.125 2.27043 10.125 3.375V8.625C10.125 9.72957 9.22957 10.625 8.125 10.625Z'
        stroke='#121219'
      />
      <path
        d='M3.25 5.75L4.20603 6.8426C4.61852 7.31402 5.35792 7.29447 5.74492 6.80192L7.75 4.25'
        stroke='#121219'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export const IconCheckBox = ({ width, height, className }) => {
  return (
    <svg
      className={`${className}`}
      width={width || "14"}
      height={height || "15"}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.75 21.25H5.25C4.14543 21.25 3.25 20.3546 3.25 19.25V4.75C3.25 3.64543 4.14543 2.75 5.25 2.75H19.75C20.8546 2.75 21.75 3.64543 21.75 4.75V19.25C21.75 20.3546 20.8546 21.25 19.75 21.25Z'
        stroke='#121219'
        stroke-width='2'
      />
    </svg>
  )
}

export const IconProperties = () => {
  return (
    <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.1072 5.8125H2.60718C2.29968 5.8125 2.04468 5.5575 2.04468 5.25C2.04468 4.9425 2.29968 4.6875 2.60718 4.6875H16.1072C16.4147 4.6875 16.6697 4.9425 16.6697 5.25C16.6697 5.5575 16.4147 5.8125 16.1072 5.8125Z'
        fill='#1DD3B0'
      />
      <path
        d='M16.1072 9.5625H2.60718C2.29968 9.5625 2.04468 9.3075 2.04468 9C2.04468 8.6925 2.29968 8.4375 2.60718 8.4375H16.1072C16.4147 8.4375 16.6697 8.6925 16.6697 9C16.6697 9.3075 16.4147 9.5625 16.1072 9.5625Z'
        fill='#1DD3B0'
      />
      <path
        d='M16.1072 13.3125H2.60718C2.29968 13.3125 2.04468 13.0575 2.04468 12.75C2.04468 12.4425 2.29968 12.1875 2.60718 12.1875H16.1072C16.4147 12.1875 16.6697 12.4425 16.6697 12.75C16.6697 13.0575 16.4147 13.3125 16.1072 13.3125Z'
        fill='#1DD3B0'
      />
    </svg>
  )
}
export const IconSearch = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_264_5529)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.663 15.663C15.8023 15.5235 15.9678 15.4129 16.1499 15.3374C16.332 15.2619 16.5272 15.2231 16.7243 15.2231C16.9214 15.2231 17.1166 15.2619 17.2987 15.3374C17.4808 15.4129 17.6462 15.5235 17.7855 15.663L23.5605 21.438C23.842 21.7193 24.0002 22.1008 24.0003 22.4987C24.0005 22.8966 23.8426 23.2783 23.5613 23.5598C23.28 23.8412 22.8985 23.9994 22.5006 23.9996C22.1027 23.9997 21.721 23.8418 21.4395 23.5605L15.6645 17.7855C15.5251 17.6462 15.4144 17.4808 15.339 17.2987C15.2635 17.1166 15.2246 16.9214 15.2246 16.7243C15.2246 16.5271 15.2635 16.332 15.339 16.1499C15.4144 15.9678 15.5251 15.8023 15.6645 15.663H15.663Z'
          fill='#1DD3B0'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.75 18C10.8334 18 11.9062 17.7866 12.9071 17.372C13.9081 16.9574 14.8175 16.3497 15.5836 15.5836C16.3497 14.8175 16.9574 13.9081 17.372 12.9071C17.7866 11.9062 18 10.8334 18 9.75C18 8.66659 17.7866 7.5938 17.372 6.59286C16.9574 5.59193 16.3497 4.68245 15.5836 3.91637C14.8175 3.15029 13.9081 2.5426 12.9071 2.12799C11.9062 1.71339 10.8334 1.5 9.75 1.5C7.56196 1.5 5.46354 2.36919 3.91637 3.91637C2.36919 5.46354 1.5 7.56196 1.5 9.75C1.5 11.938 2.36919 14.0365 3.91637 15.5836C5.46354 17.1308 7.56196 18 9.75 18ZM19.5 9.75C19.5 12.3359 18.4728 14.8158 16.6443 16.6443C14.8158 18.4728 12.3359 19.5 9.75 19.5C7.16414 19.5 4.68419 18.4728 2.85571 16.6443C1.02723 14.8158 0 12.3359 0 9.75C0 7.16414 1.02723 4.68419 2.85571 2.85571C4.68419 1.02723 7.16414 0 9.75 0C12.3359 0 14.8158 1.02723 16.6443 2.85571C18.4728 4.68419 19.5 7.16414 19.5 9.75Z'
          fill='#1DD3B0'
        />
      </g>
      <defs>
        <clipPath id='clip0_264_5529'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export const IconSearchTag = ({ white, height, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={"19" || white}
      height={"18" || height}
      viewBox='0 0 19 18'
      fill='none'>
      <path
        d='M15.3234 4.3125H10.8234C10.5159 4.3125 10.2609 4.0575 10.2609 3.75C10.2609 3.4425 10.5159 3.1875 10.8234 3.1875H15.3234C15.6309 3.1875 15.8859 3.4425 15.8859 3.75C15.8859 4.0575 15.6309 4.3125 15.3234 4.3125Z'
        fill={"#1DD3B0" || color}
      />
      <path
        d='M13.0734 6.5625H10.8234C10.5159 6.5625 10.2609 6.3075 10.2609 6C10.2609 5.6925 10.5159 5.4375 10.8234 5.4375H13.0734C13.3809 5.4375 13.6359 5.6925 13.6359 6C13.6359 6.3075 13.3809 6.5625 13.0734 6.5625Z'
        fill={"#1DD3B0" || color}
      />
      <path
        d='M8.94836 16.3125C4.71086 16.3125 1.26086 12.8625 1.26086 8.625C1.26086 4.3875 4.71086 0.9375 8.94836 0.9375C9.25586 0.9375 9.51086 1.1925 9.51086 1.5C9.51086 1.8075 9.25586 2.0625 8.94836 2.0625C5.32586 2.0625 2.38586 5.01 2.38586 8.625C2.38586 12.24 5.32586 15.1875 8.94836 15.1875C12.5709 15.1875 15.5109 12.24 15.5109 8.625C15.5109 8.3175 15.7659 8.0625 16.0734 8.0625C16.3809 8.0625 16.6359 8.3175 16.6359 8.625C16.6359 12.8625 13.1859 16.3125 8.94836 16.3125Z'
        fill={"#1DD3B0" || color}
      />
      <path
        d='M16.8234 17.0626C16.6809 17.0626 16.5384 17.0101 16.4259 16.8976L14.9259 15.3976C14.7084 15.1801 14.7084 14.8201 14.9259 14.6026C15.1434 14.3851 15.5034 14.3851 15.7209 14.6026L17.2209 16.1026C17.4384 16.3201 17.4384 16.6801 17.2209 16.8976C17.1084 17.0101 16.9659 17.0626 16.8234 17.0626Z'
        fill={"#1DD3B0" || color}
      />
    </svg>
  )
}
export const IconComments = () => {
  return (
    <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.3572 17.0625H2.60718C1.88718 17.0625 1.29468 16.47 1.29468 15.75V6C1.29468 2.685 3.04218 0.9375 6.35718 0.9375H12.3572C15.6722 0.9375 17.4197 2.685 17.4197 6V12C17.4197 15.315 15.6722 17.0625 12.3572 17.0625ZM6.35718 2.0625C3.67218 2.0625 2.41968 3.315 2.41968 6V15.75C2.41968 15.855 2.50218 15.9375 2.60718 15.9375H12.3572C15.0422 15.9375 16.2947 14.685 16.2947 12V6C16.2947 3.315 15.0422 2.0625 12.3572 2.0625H6.35718Z'
        fill='#1DD3B0'
      />
      <path
        d='M13.1072 7.6875H5.60718C5.29968 7.6875 5.04468 7.4325 5.04468 7.125C5.04468 6.8175 5.29968 6.5625 5.60718 6.5625H13.1072C13.4147 6.5625 13.6697 6.8175 13.6697 7.125C13.6697 7.4325 13.4147 7.6875 13.1072 7.6875Z'
        fill='#1DD3B0'
      />
      <path
        d='M10.8572 11.4375H5.60718C5.29968 11.4375 5.04468 11.1825 5.04468 10.875C5.04468 10.5675 5.29968 10.3125 5.60718 10.3125H10.8572C11.1647 10.3125 11.4197 10.5675 11.4197 10.875C11.4197 11.1825 11.1647 11.4375 10.8572 11.4375Z'
        fill='#1DD3B0'
      />
    </svg>
  )
}
export const IconCommentsOk = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' fill='none' viewBox='0 0 21 20'>
      <path
        fill='#1DD3B0'
        d='M11.56 18.946l-.108-.994-.53.058-.247.473.886.463h-.001zM5.029 17.09l.614.79 1.011-.787-1.008-.79-.617.787zm-2.226 1.731l-.614-.79.614.79zM18.572 10a8.001 8.001 0 01-7.12 7.952l.217 1.988c5.008-.547 8.903-4.788 8.903-9.94h-2zm-8-8a8 8 0 018 8h2c0-5.523-4.477-10-10-10v2zm-8 8a8 8 0 018-8V0c-5.523 0-10 4.477-10 10h2zm3.073 6.303A7.985 7.985 0 012.572 10h-2a9.98 9.98 0 003.84 7.877l1.233-1.574zM3.416 19.61l2.226-1.73-1.228-1.58-2.226 1.732 1.228 1.578zM2.864 18c.857 0 1.229 1.084.552 1.61l-1.228-1.578c-.826.643-.372 1.968.676 1.968v-2zm7.708 0H2.864v2h7.708v-2zm.9 0h-.9v2h.9v-2zm-.797.483a.9.9 0 01.797-.483v2a1.1 1.1 0 00.975-.59l-1.772-.927z'></path>
      <path fill='none' stroke='#FF0000' strokeWidth='1.5' d='M6.688 10.156l3.187 3'></path>
      <path fill='none' stroke='#FF0000' strokeWidth='1.5' d='M14.812 6.719l-5.937 6.5'></path>
    </svg>
  )
}
export const IconCommentsViewerOk = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' fill='none' viewBox='0 0 21 20'>
      <path
        fill='#FFF'
        d='M11.56 18.946l-.108-.994-.53.058-.247.473.886.463h-.001zM5.029 17.09l.614.79 1.011-.787-1.008-.79-.617.787zm-2.226 1.731l-.614-.79.614.79zM18.572 10a8.001 8.001 0 01-7.12 7.952l.217 1.988c5.008-.547 8.903-4.788 8.903-9.94h-2zm-8-8a8 8 0 018 8h2c0-5.523-4.477-10-10-10v2zm-8 8a8 8 0 018-8V0c-5.523 0-10 4.477-10 10h2zm3.073 6.303A7.985 7.985 0 012.572 10h-2a9.98 9.98 0 003.84 7.877l1.233-1.574zM3.416 19.61l2.226-1.73-1.228-1.58-2.226 1.732 1.228 1.578zM2.864 18c.857 0 1.229 1.084.552 1.61l-1.228-1.578c-.826.643-.372 1.968.676 1.968v-2zm7.708 0H2.864v2h7.708v-2zm.9 0h-.9v2h.9v-2zm-.797.483a.9.9 0 01.797-.483v2a1.1 1.1 0 00.975-.59l-1.772-.927z'></path>
      <path fill='none' stroke='#FF0000' strokeWidth='1.5' d='M6.688 10.156l3.187 3'></path>
      <path fill='none' stroke='#FF0000' strokeWidth='1.5' d='M14.812 6.719l-5.937 6.5'></path>
    </svg>
  )
}
export const IconFolderGray = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='17' fill='none' viewBox='0 0 21 17'>
      <path
        stroke='#1DD3B0'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M3.285 1h4l3 3h7a2 2 0 012 2v8a2 2 0 01-2 2h-14a2 2 0 01-2-2V3a2 2 0 012-2'></path>
    </svg>
  )
}
export const IconTrashGray = () => {
  return (
    <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.1072 5.04785C16.0922 5.04785 16.0697 5.04785 16.0472 5.04785C12.0797 4.65035 8.11968 4.50035 4.19718 4.89785L2.66718 5.04785C2.35218 5.07785 2.07468 4.85285 2.04468 4.53785C2.01468 4.22285 2.23968 3.95285 2.54718 3.92285L4.07718 3.77285C8.06718 3.36785 12.1097 3.52535 16.1597 3.92285C16.4672 3.95285 16.6922 4.23035 16.6622 4.53785C16.6397 4.83035 16.3922 5.04785 16.1072 5.04785Z'
        fill='#1DD3B0'
      />
      <path
        d='M6.73212 4.29C6.70212 4.29 6.67212 4.29 6.63462 4.2825C6.33462 4.23 6.12462 3.9375 6.17712 3.6375L6.34212 2.655C6.46212 1.935 6.62712 0.9375 8.37462 0.9375H10.3396C12.0946 0.9375 12.2596 1.9725 12.3721 2.6625L12.5371 3.6375C12.5896 3.945 12.3796 4.2375 12.0796 4.2825C11.7721 4.335 11.4796 4.125 11.4346 3.825L11.2696 2.85C11.1646 2.1975 11.1421 2.07 10.3471 2.07H8.38212C7.58712 2.07 7.57212 2.175 7.45962 2.8425L7.28712 3.8175C7.24212 4.095 7.00212 4.29 6.73212 4.29Z'
        fill='#1DD3B0'
      />
      <path
        d='M11.7647 17.0627H6.94972C4.33222 17.0627 4.22722 15.6152 4.14472 14.4452L3.65722 6.89268C3.63472 6.58518 3.87472 6.31518 4.18222 6.29268C4.49722 6.27768 4.75972 6.51018 4.78222 6.81768L5.26972 14.3702C5.35222 15.5102 5.38222 15.9377 6.94972 15.9377H11.7647C13.3397 15.9377 13.3697 15.5102 13.4447 14.3702L13.9322 6.81768C13.9547 6.51018 14.2247 6.27768 14.5322 6.29268C14.8397 6.31518 15.0797 6.57768 15.0572 6.89268L14.5697 14.4452C14.4872 15.6152 14.3822 17.0627 11.7647 17.0627Z'
        fill='#1DD3B0'
      />
      <path
        d='M10.6022 12.9375H8.10474C7.79724 12.9375 7.54224 12.6825 7.54224 12.375C7.54224 12.0675 7.79724 11.8125 8.10474 11.8125H10.6022C10.9097 11.8125 11.1647 12.0675 11.1647 12.375C11.1647 12.6825 10.9097 12.9375 10.6022 12.9375Z'
        fill='#1DD3B0'
      />
      <path
        d='M11.2322 9.9375H7.48218C7.17468 9.9375 6.91968 9.6825 6.91968 9.375C6.91968 9.0675 7.17468 8.8125 7.48218 8.8125H11.2322C11.5397 8.8125 11.7947 9.0675 11.7947 9.375C11.7947 9.6825 11.5397 9.9375 11.2322 9.9375Z'
        fill='#1DD3B0'
      />
    </svg>
  )
}
export const IconBadge = () => {
  return (
    <svg height='15' viewBox='0 0 13 19' fill='' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.50008 12.6667C3.01675 12.6667 0.166748 9.81667 0.166748 6.33333C0.166748 2.85 3.01675 0 6.50008 0C9.98342 0 12.8334 2.85 12.8334 6.33333C12.8334 9.81667 9.98342 12.6667 6.50008 12.6667ZM6.50008 1.58333C3.88758 1.58333 1.75008 3.72083 1.75008 6.33333C1.75008 8.94583 3.88758 11.0833 6.50008 11.0833C9.11258 11.0833 11.2501 8.94583 11.2501 6.33333C11.2501 3.72083 9.11258 1.58333 6.50008 1.58333Z'
        fill='#898989'
      />
      <path
        d='M9.66675 19C9.50841 19 9.42925 19 9.35008 18.9208L6.50008 17.4958L3.65008 18.9208C3.41258 19.0792 3.09591 19 2.85841 18.9208C2.70008 18.7625 2.54175 18.4458 2.54175 18.2083V11.0833C2.54175 10.6083 2.85841 10.2917 3.33341 10.2917C3.80841 10.2917 4.12508 10.6083 4.12508 11.0833V16.9417L6.18341 15.9125C6.42091 15.8333 6.65841 15.8333 6.89591 15.9125L8.95425 16.9417V11.0833C8.95425 10.6083 9.27091 10.2917 9.74591 10.2917C10.2209 10.2917 10.5376 10.6083 10.5376 11.0833V18.2083C10.5376 18.4458 10.3792 18.7625 10.1417 18.9208C9.98341 18.9208 9.82508 19 9.66675 19Z'
        fill='#898989'
      />
    </svg>
  )
}
export const IconStarredSec = () => {
  return (
    <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.4'
        d='M4.97494 12.4326C5.05744 12.0651 4.90744 11.5401 4.64494 11.2776L2.82244 9.45513C2.25244 8.88513 2.02744 8.27763 2.19244 7.75263C2.36494 7.22763 2.89744 6.86763 3.69244 6.73263L6.03244 6.34263C6.36994 6.28263 6.78244 5.98263 6.93994 5.67513L8.22994 3.08763C8.60494 2.34513 9.11494 1.93263 9.66994 1.93263C10.2249 1.93263 10.7349 2.34513 11.1099 3.08763L12.3999 5.67513C12.4974 5.87013 12.6999 6.05763 12.9174 6.18513L4.83994 14.2626C4.73494 14.3676 4.55494 14.2701 4.58494 14.1201L4.97494 12.4326Z'
        fill='#1DD3B0'
      />
      <path
        d='M14.695 11.2776C14.425 11.5476 14.275 12.0651 14.365 12.4326L14.8825 14.6901C15.1 15.6276 14.965 16.3326 14.5 16.6701C14.3125 16.8051 14.0875 16.8726 13.825 16.8726C13.4425 16.8726 12.9925 16.7301 12.4975 16.4376L10.3 15.1326C9.95502 14.9301 9.38502 14.9301 9.04002 15.1326L6.84252 16.4376C6.01002 16.9251 5.29752 17.0076 4.84002 16.6701C4.66752 16.5426 4.54002 16.3701 4.45752 16.1451L13.5775 7.02513C13.9225 6.68013 14.41 6.52263 14.8825 6.60513L15.64 6.73263C16.435 6.86763 16.9675 7.22763 17.14 7.75263C17.305 8.27763 17.08 8.88513 16.51 9.45513L14.695 11.2776Z'
        fill='#1DD3B0'
      />
    </svg>
  )
}
export const IconLoadingBlack = () => {
  return (
    <svg
      className='text-black animate-spin'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_801_5077)'>
        <path
          d='M16.8396 21.9227C17.072 22.3992 16.8753 22.9778 16.3817 23.1714C14.7785 23.8002 13.055 24.078 11.3281 23.9812C9.28099 23.8664 7.29743 23.229 5.56664 22.1298C3.83586 21.0305 2.41559 19.5062 1.44131 17.7021C0.467042 15.898 -0.0287318 13.8744 0.00128644 11.8243C0.0313048 9.77419 0.586114 7.76596 1.61279 5.99119C2.63946 4.21641 4.10376 2.7343 5.86598 1.68623C7.62821 0.638166 9.62958 0.0591049 11.6792 0.00429001C13.4082 -0.04195 15.1228 0.286166 16.7069 0.961651C17.1946 1.16961 17.3743 1.75374 17.1281 2.22326C16.8818 2.69279 16.3029 2.86912 15.812 2.66861C14.5211 2.14126 13.1314 1.88614 11.7305 1.9236C10.0088 1.96965 8.32769 2.45606 6.84742 3.33644C5.36715 4.21681 4.13715 5.46179 3.27474 6.9526C2.41234 8.44341 1.9463 10.1303 1.92108 11.8524C1.89587 13.5745 2.31232 15.2744 3.1307 16.7898C3.94909 18.3052 5.14212 19.5857 6.59598 20.509C8.04984 21.4323 9.71603 21.9678 11.4356 22.0642C12.8348 22.1427 14.2314 21.9283 15.5372 21.439C16.0337 21.2529 16.6072 21.4462 16.8396 21.9227Z'
          fill='currentColor'
        />
        <path
          d='M22.7657 14.4446C23.2827 14.562 23.8011 14.2382 23.8768 13.7134C24.1505 11.8158 23.9662 9.87512 23.3329 8.05557C22.6996 6.23601 21.6391 4.60027 20.2463 3.28267C19.8611 2.91831 19.2538 2.98635 18.9214 3.3994C18.5889 3.81244 18.6581 4.41368 19.0376 4.78386C20.1531 5.87179 21.0045 7.20688 21.5196 8.68669C22.0346 10.1665 22.1962 11.7417 21.9972 13.2871C21.9295 13.813 22.2486 14.3272 22.7657 14.4446Z'
          fill='currentColor'
          fillOpacity='0.3'
        />
        <path
          d='M18.0967 21.204C18.3895 21.646 18.9879 21.7699 19.4051 21.4428C20.7442 20.3927 21.8448 19.0696 22.6334 17.5617C22.8792 17.0919 22.6483 16.526 22.1604 16.3186C21.6724 16.1112 21.1127 16.3414 20.8599 16.8074C20.2275 17.9728 19.3713 19.0022 18.3405 19.8362C17.9283 20.1697 17.8039 20.762 18.0967 21.204Z'
          fill='currentColor'
          fillOpacity='0.3'
        />
      </g>
      <defs>
        <clipPath id='clip0_801_5077'>
          <rect width='24' height='24' fill='currentColor' />
        </clipPath>
      </defs>
    </svg>
  )
}
export const IconAnonomize2Black = ({ color, height, width }) => {
  return (
    <svg
      width={width || "30"}
      height={height || "24"}
      viewBox='0 0 30 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M22.3167 14.0762C19.9677 14.0762 17.993 15.7973 17.5531 18.073C16.9261 17.7957 16.1306 17.5758 15.1947 17.5758C14.2308 17.5662 13.4072 17.7957 12.7708 18.073C12.331 15.7973 10.3657 14.0762 8.00729 14.0762C5.33071 14.0762 3.15015 16.3041 3.15015 19.0387C3.15015 21.7734 5.33071 24.0013 8.00729 24.0013C10.5622 24.0013 12.6679 21.9742 12.8457 19.4021C13.3979 19.0961 14.1934 18.7997 15.1854 18.8092C16.1493 18.8188 16.9261 19.1057 17.4689 19.4021C17.6561 21.9646 19.7524 24.0013 22.3073 24.0013C24.9839 24.0013 27.1645 21.7734 27.1645 19.0387C27.1738 16.3041 24.9933 14.0762 22.3167 14.0762ZM8.01665 22.7678C6.00454 22.7678 4.36677 21.0945 4.36677 19.0387C4.36677 16.9829 6.00454 15.3096 8.01665 15.3096C10.0288 15.3096 11.6665 16.9829 11.6665 19.0387C11.6665 21.0945 10.0288 22.7678 8.01665 22.7678ZM22.3167 22.7678C20.3046 22.7678 18.6668 21.0945 18.6668 19.0387C18.6668 16.9829 20.3046 15.3096 22.3167 15.3096C24.3288 15.3096 25.9666 16.9829 25.9666 19.0387C25.9666 21.0945 24.3288 22.7678 22.3167 22.7678Z'
        fill={color || "#575757"}
      />
      <path
        d='M29.1391 11.3984H1.20353C0.866616 11.3984 0.595215 11.6757 0.595215 12.02C0.595215 12.3642 0.866616 12.6415 1.20353 12.6415H29.1391C29.476 12.6415 29.7474 12.3642 29.7474 12.02C29.7381 11.6757 29.4667 11.3984 29.1391 11.3984Z'
        fill={color || "#575757"}
      />
      <path
        d='M8.0166 9.94476H22.326C22.5132 9.94476 22.6816 9.85871 22.8033 9.71528C22.9156 9.57186 22.9624 9.37106 22.9156 9.18939L21.334 2.29536C21.1655 1.57823 20.7537 0.947154 20.1548 0.526437C19.5558 0.10572 18.8352 -0.0759534 18.1239 0.0292259C17.1506 0.16309 16.1493 0.230023 15.1666 0.230023C15.1573 0.230023 15.1479 0.230023 15.1385 0.230023C14.1652 0.230023 13.1732 0.16309 12.2093 0.0292259C11.4887 -0.0663917 10.768 0.10572 10.1784 0.526437C9.57949 0.947154 9.16771 1.56867 8.99926 2.29536L7.427 9.18939C7.38957 9.37106 7.427 9.56229 7.53931 9.71528C7.66097 9.85871 7.82942 9.94476 8.0166 9.94476ZM10.1784 2.57265C10.375 1.70253 11.1798 1.12883 12.0502 1.25313C13.0703 1.39656 14.1091 1.46349 15.1385 1.46349C15.1479 1.46349 15.1573 1.46349 15.1666 1.46349C16.2054 1.46349 17.2536 1.38699 18.2924 1.25313C19.1628 1.12883 19.9582 1.70253 20.1641 2.57265L21.5679 8.7113H8.77465L10.1784 2.57265Z'
        fill={color || "#575757"}
      />
    </svg>
  )
}
export const IconShareBlack = ({ width, height, color }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "22"}
      viewBox='0 0 23 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.5 4C21.5 2.34315 20.1569 1 18.5 1C16.8431 1 15.5 2.34315 15.5 4C15.5 5.65685 16.8431 7 18.5 7C20.1569 7 21.5 5.65685 21.5 4Z'
        stroke={color || "#575757"}
        stroke-width='1.5'
      />
      <path
        d='M7.5 11C7.5 9.34315 6.15685 8 4.5 8C2.84315 8 1.5 9.34315 1.5 11C1.5 12.6569 2.84315 14 4.5 14C6.15685 14 7.5 12.6569 7.5 11Z'
        stroke={color || "#575757"}
        stroke-width='1.5'
      />
      <path
        d='M21.5 18C21.5 16.3431 20.1569 15 18.5 15C16.8431 15 15.5 16.3431 15.5 18C15.5 19.6569 16.8431 21 18.5 21C20.1569 21 21.5 19.6569 21.5 18Z'
        stroke={color || "#575757"}
        stroke-width='1.5'
      />
      <path d='M15.5 4.5L7 9' stroke={color || "#575757"} stroke-width='1.5' stroke-linecap='round' />
      <path d='M15.5 17.5L7 13' stroke={color || "#575757"} stroke-width='1.5' stroke-linecap='round' />
    </svg>
  )
}
export const IconCommentsBlack = ({ color, width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "22"}
      viewBox='0 0 19 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.3572 17.0625H2.60718C1.88718 17.0625 1.29468 16.47 1.29468 15.75V6C1.29468 2.685 3.04218 0.9375 6.35718 0.9375H12.3572C15.6722 0.9375 17.4197 2.685 17.4197 6V12C17.4197 15.315 15.6722 17.0625 12.3572 17.0625ZM6.35718 2.0625C3.67218 2.0625 2.41968 3.315 2.41968 6V15.75C2.41968 15.855 2.50218 15.9375 2.60718 15.9375H12.3572C15.0422 15.9375 16.2947 14.685 16.2947 12V6C16.2947 3.315 15.0422 2.0625 12.3572 2.0625H6.35718Z'
        fill={color || "#575757"}
      />
      <path
        d='M13.1072 7.6875H5.60718C5.29968 7.6875 5.04468 7.4325 5.04468 7.125C5.04468 6.8175 5.29968 6.5625 5.60718 6.5625H13.1072C13.4147 6.5625 13.6697 6.8175 13.6697 7.125C13.6697 7.4325 13.4147 7.6875 13.1072 7.6875Z'
        fill={color || "#575757"}
      />
      <path
        d='M10.8572 11.4375H5.60718C5.29968 11.4375 5.04468 11.1825 5.04468 10.875C5.04468 10.5675 5.29968 10.3125 5.60718 10.3125H10.8572C11.1647 10.3125 11.4197 10.5675 11.4197 10.875C11.4197 11.1825 11.1647 11.4375 10.8572 11.4375Z'
        fill={color || "#575757"}
      />
    </svg>
  )
}
export const IconCommentsBlackOk = ({ color }) => {
  return (
    <svg width='23' height='22' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.3572 17.0625H2.60718C1.88718 17.0625 1.29468 16.47 1.29468 15.75V6C1.29468 2.685 3.04218 0.9375 6.35718 0.9375H12.3572C15.6722 0.9375 17.4197 2.685 17.4197 6V12C17.4197 15.315 15.6722 17.0625 12.3572 17.0625ZM6.35718 2.0625C3.67218 2.0625 2.41968 3.315 2.41968 6V15.75C2.41968 15.855 2.50218 15.9375 2.60718 15.9375H12.3572C15.0422 15.9375 16.2947 14.685 16.2947 12V6C16.2947 3.315 15.0422 2.0625 12.3572 2.0625H6.35718Z'
        fill={color || "#575757"}
      />
      <path
        d='M5 9.06738L6.91206 11.3017C7.73704 12.2657 9.21584 12.2257 9.98984 11.2185L14 6'
        stroke={color || "#575757"}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export const IconListFolderBlack = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='27' height='26' fill='none' viewBox='0 0 27 26'>
      <g clipPath='url(#clip0_940_25060)'>
        <path
          stroke='#000'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M5.419 4.333h4.333l3.25 3.25h7.583a2.167 2.167 0 012.167 2.167v8.667a2.167 2.167 0 01-2.167 2.166H5.42a2.167 2.167 0 01-2.167-2.166V6.5a2.167 2.167 0 012.167-2.167'></path>
      </g>
      <defs>
        <clipPath id='clip0_940_25060'>
          <path fill='#fff' d='M0 0H26V26H0z' transform='translate(.002)'></path>
        </clipPath>
      </defs>
    </svg>
  )
}
export const IconListTrashBlack = ({ color, width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "24"}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.5 6.73046C21.48 6.73046 21.45 6.73046 21.42 6.73046C16.13 6.20046 10.85 6.00046 5.62004 6.53046L3.58004 6.73046C3.16004 6.77046 2.79004 6.47046 2.75004 6.05046C2.71004 5.63046 3.01004 5.27046 3.42004 5.23046L5.46004 5.03046C10.78 4.49046 16.17 4.70046 21.57 5.23046C21.98 5.27046 22.28 5.64046 22.24 6.05046C22.21 6.44046 21.88 6.73046 21.5 6.73046Z'
        fill={color || "#575757"}
      />
      <path
        d='M9.00001 5.72C8.96001 5.72 8.92001 5.72 8.87001 5.71C8.47001 5.64 8.19001 5.25 8.26001 4.85L8.48001 3.54C8.64001 2.58 8.86001 1.25 11.19 1.25H13.81C16.15 1.25 16.37 2.63 16.52 3.55L16.74 4.85C16.81 5.26 16.53 5.65 16.13 5.71C15.72 5.78 15.33 5.5 15.27 5.1L15.05 3.8C14.91 2.93 14.88 2.76 13.82 2.76H11.2C10.14 2.76 10.12 2.9 9.97001 3.79L9.74001 5.09C9.68001 5.46 9.36001 5.72 9.00001 5.72Z'
        fill={color || "#575757"}
      />
      <path
        d='M15.71 22.7496H9.29002C5.80002 22.7496 5.66002 20.8196 5.55002 19.2596L4.90002 9.18959C4.87002 8.77959 5.19002 8.41959 5.60002 8.38959C6.02002 8.36959 6.37002 8.67959 6.40002 9.08959L7.05002 19.1596C7.16002 20.6796 7.20002 21.2496 9.29002 21.2496H15.71C17.81 21.2496 17.85 20.6796 17.95 19.1596L18.6 9.08959C18.63 8.67959 18.99 8.36959 19.4 8.38959C19.81 8.41959 20.13 8.76959 20.1 9.18959L19.45 19.2596C19.34 20.8196 19.2 22.7496 15.71 22.7496Z'
        fill={color || "#575757"}
      />
      <path
        d='M14.16 17.25H10.83C10.42 17.25 10.08 16.91 10.08 16.5C10.08 16.09 10.42 15.75 10.83 15.75H14.16C14.57 15.75 14.91 16.09 14.91 16.5C14.91 16.91 14.57 17.25 14.16 17.25Z'
        fill={color || "#575757"}
      />
      <path
        d='M15 13.25H10C9.59 13.25 9.25 12.91 9.25 12.5C9.25 12.09 9.59 11.75 10 11.75H15C15.41 11.75 15.75 12.09 15.75 12.5C15.75 12.91 15.41 13.25 15 13.25Z'
        fill={color || "#575757"}
      />
    </svg>
  )
}
export const IconPropertiesBlack = ({ color, width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "24"}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.8334 7.75H3.83337C3.42337 7.75 3.08337 7.41 3.08337 7C3.08337 6.59 3.42337 6.25 3.83337 6.25H21.8334C22.2434 6.25 22.5834 6.59 22.5834 7C22.5834 7.41 22.2434 7.75 21.8334 7.75Z'
        fill={color || "#575757"}
      />
      <path
        d='M21.8334 12.75H3.83337C3.42337 12.75 3.08337 12.41 3.08337 12C3.08337 11.59 3.42337 11.25 3.83337 11.25H21.8334C22.2434 11.25 22.5834 11.59 22.5834 12C22.5834 12.41 22.2434 12.75 21.8334 12.75Z'
        fill={color || "#575757"}
      />
      <path
        d='M21.8334 17.75H3.83337C3.42337 17.75 3.08337 17.41 3.08337 17C3.08337 16.59 3.42337 16.25 3.83337 16.25H21.8334C22.2434 16.25 22.5834 16.59 22.5834 17C22.5834 17.41 22.2434 17.75 21.8334 17.75Z'
        fill={color || "#575757"}
      />
    </svg>
  )
}
export const IconGrid = () => {
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.375 5.3125V2.375C1.375 1.54657 2.04657 0.875 2.875 0.875H5.8125C6.64093 0.875 7.3125 1.54657 7.3125 2.375V5.3125C7.3125 6.14093 6.64093 6.8125 5.8125 6.8125H2.875C2.04657 6.8125 1.375 6.14093 1.375 5.3125Z'
        stroke='#1DD3B0'
        stroke-width='1.5'
      />
      <path
        d='M1.375 13.625V10.6875C1.375 9.85907 2.04657 9.1875 2.875 9.1875H5.8125C6.64093 9.1875 7.3125 9.85907 7.3125 10.6875V13.625C7.3125 14.4534 6.64093 15.125 5.8125 15.125H2.875C2.04657 15.125 1.375 14.4534 1.375 13.625Z'
        stroke='#1DD3B0'
        stroke-width='1.5'
      />
      <path
        d='M9.6875 5.3125V2.375C9.6875 1.54657 10.3591 0.875 11.1875 0.875H14.125C14.9534 0.875 15.625 1.54657 15.625 2.375V5.3125C15.625 6.14093 14.9534 6.8125 14.125 6.8125H11.1875C10.3591 6.8125 9.6875 6.14093 9.6875 5.3125Z'
        stroke='#1DD3B0'
        stroke-width='1.5'
      />
      <path
        d='M9.6875 13.625V10.6875C9.6875 9.85907 10.3591 9.1875 11.1875 9.1875H14.125C14.9534 9.1875 15.625 9.85907 15.625 10.6875V13.625C15.625 14.4534 14.9534 15.125 14.125 15.125H11.1875C10.3591 15.125 9.6875 14.4534 9.6875 13.625Z'
        stroke='#1DD3B0'
        stroke-width='1.5'
      />
    </svg>
  )
}
export const IconDownload = ({ width, height, color }) => {
  return (
    <svg
      width={width || "19"}
      height={height || "18"}
      viewBox='0 0 19 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.10718 13.3125C7.03218 13.3125 6.96468 13.2975 6.88968 13.2675C6.67968 13.185 6.54468 12.975 6.54468 12.75V8.25C6.54468 7.9425 6.79968 7.6875 7.10718 7.6875C7.41468 7.6875 7.66968 7.9425 7.66968 8.25V11.3925L8.20968 10.8525C8.42718 10.635 8.78718 10.635 9.00468 10.8525C9.22218 11.07 9.22218 11.43 9.00468 11.6475L7.50468 13.1475C7.39968 13.2525 7.24968 13.3125 7.10718 13.3125Z'
        fill={color || "#1DD3B0"}
      />
      <path
        d='M7.10713 13.3126C6.96463 13.3126 6.82213 13.2601 6.70963 13.1476L5.20963 11.6476C4.99213 11.4301 4.99213 11.0701 5.20963 10.8526C5.42713 10.6351 5.78713 10.6351 6.00463 10.8526L7.50463 12.3526C7.72213 12.5701 7.72213 12.9301 7.50463 13.1476C7.39213 13.2601 7.24963 13.3126 7.10713 13.3126Z'
        fill={color || "#1DD3B0"}
      />
      <path
        d='M11.6072 17.0625H7.10718C3.03468 17.0625 1.29468 15.3225 1.29468 11.25V6.75C1.29468 2.6775 3.03468 0.9375 7.10718 0.9375H10.8572C11.1647 0.9375 11.4197 1.1925 11.4197 1.5C11.4197 1.8075 11.1647 2.0625 10.8572 2.0625H7.10718C3.64968 2.0625 2.41968 3.2925 2.41968 6.75V11.25C2.41968 14.7075 3.64968 15.9375 7.10718 15.9375H11.6072C15.0647 15.9375 16.2947 14.7075 16.2947 11.25V7.5C16.2947 7.1925 16.5497 6.9375 16.8572 6.9375C17.1647 6.9375 17.4197 7.1925 17.4197 7.5V11.25C17.4197 15.3225 15.6797 17.0625 11.6072 17.0625Z'
        fill={color || "#1DD3B0"}
      />
      <path
        d='M16.8572 8.06237H13.8572C11.2922 8.06237 10.2947 7.06487 10.2947 4.49987V1.49987C10.2947 1.27487 10.4297 1.06487 10.6397 0.982369C10.8497 0.892369 11.0897 0.94487 11.2547 1.10237L17.2547 7.10237C17.4122 7.25987 17.4647 7.50737 17.3747 7.71737C17.2847 7.92737 17.0822 8.06237 16.8572 8.06237ZM11.4197 2.85737V4.49987C11.4197 6.43487 11.9222 6.93737 13.8572 6.93737H15.4997L11.4197 2.85737Z'
        fill={color || "#1DD3B0"}
      />
    </svg>
  )
}
export const IconDownloadBlack = ({ width, height, color }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "24"}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.16663 17.75C9.06663 17.75 8.97663 17.73 8.87663 17.69C8.59663 17.58 8.41663 17.3 8.41663 17V11C8.41663 10.59 8.75663 10.25 9.16663 10.25C9.57663 10.25 9.91663 10.59 9.91663 11V15.19L10.6366 14.47C10.9266 14.18 11.4066 14.18 11.6966 14.47C11.9866 14.76 11.9866 15.24 11.6966 15.53L9.69663 17.53C9.55663 17.67 9.35663 17.75 9.16663 17.75Z'
        fill={color || "#575757"}
      />
      <path
        d='M9.16657 17.7495C8.97657 17.7495 8.78657 17.6795 8.63657 17.5295L6.63657 15.5295C6.34657 15.2395 6.34657 14.7595 6.63657 14.4695C6.92657 14.1795 7.40657 14.1795 7.69657 14.4695L9.69657 16.4695C9.98657 16.7595 9.98657 17.2395 9.69657 17.5295C9.54657 17.6795 9.35657 17.7495 9.16657 17.7495Z'
        fill={color || "#575757"}
      />
      <path
        d='M15.1666 22.75H9.16663C3.73663 22.75 1.41663 20.43 1.41663 15V9C1.41663 3.57 3.73663 1.25 9.16663 1.25H14.1666C14.5766 1.25 14.9166 1.59 14.9166 2C14.9166 2.41 14.5766 2.75 14.1666 2.75H9.16663C4.55663 2.75 2.91663 4.39 2.91663 9V15C2.91663 19.61 4.55663 21.25 9.16663 21.25H15.1666C19.7766 21.25 21.4166 19.61 21.4166 15V10C21.4166 9.59 21.7566 9.25 22.1666 9.25C22.5766 9.25 22.9166 9.59 22.9166 10V15C22.9166 20.43 20.5966 22.75 15.1666 22.75Z'
        fill={color || "#575757"}
      />
      <path
        d='M22.1666 10.7505H18.1666C14.7466 10.7505 13.4166 9.42048 13.4166 6.00048V2.00048C13.4166 1.70048 13.5966 1.42048 13.8766 1.31048C14.1566 1.19048 14.4766 1.26048 14.6966 1.47048L22.6966 9.47048C22.9066 9.68048 22.9766 10.0105 22.8566 10.2905C22.7366 10.5705 22.4666 10.7505 22.1666 10.7505ZM14.9166 3.81048V6.00048C14.9166 8.58048 15.5866 9.25048 18.1666 9.25048H20.3566L14.9166 3.81048Z'
        fill={color || "#575757"}
      />
    </svg>
  )
}
export const IconLoadingOnList = () => {
  return (
    <svg
      className='text-black animate-spin'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_801_5077)'>
        <path
          d='M16.8396 21.9227C17.072 22.3992 16.8753 22.9778 16.3817 23.1714C14.7785 23.8002 13.055 24.078 11.3281 23.9812C9.28099 23.8664 7.29743 23.229 5.56664 22.1298C3.83586 21.0305 2.41559 19.5062 1.44131 17.7021C0.467042 15.898 -0.0287318 13.8744 0.00128644 11.8243C0.0313048 9.77419 0.586114 7.76596 1.61279 5.99119C2.63946 4.21641 4.10376 2.7343 5.86598 1.68623C7.62821 0.638166 9.62958 0.0591049 11.6792 0.00429001C13.4082 -0.04195 15.1228 0.286166 16.7069 0.961651C17.1946 1.16961 17.3743 1.75374 17.1281 2.22326C16.8818 2.69279 16.3029 2.86912 15.812 2.66861C14.5211 2.14126 13.1314 1.88614 11.7305 1.9236C10.0088 1.96965 8.32769 2.45606 6.84742 3.33644C5.36715 4.21681 4.13715 5.46179 3.27474 6.9526C2.41234 8.44341 1.9463 10.1303 1.92108 11.8524C1.89587 13.5745 2.31232 15.2744 3.1307 16.7898C3.94909 18.3052 5.14212 19.5857 6.59598 20.509C8.04984 21.4323 9.71603 21.9678 11.4356 22.0642C12.8348 22.1427 14.2314 21.9283 15.5372 21.439C16.0337 21.2529 16.6072 21.4462 16.8396 21.9227Z'
          fill='currentColor'
        />
        <path
          d='M22.7657 14.4446C23.2827 14.562 23.8011 14.2382 23.8768 13.7134C24.1505 11.8158 23.9662 9.87512 23.3329 8.05557C22.6996 6.23601 21.6391 4.60027 20.2463 3.28267C19.8611 2.91831 19.2538 2.98635 18.9214 3.3994C18.5889 3.81244 18.6581 4.41368 19.0376 4.78386C20.1531 5.87179 21.0045 7.20688 21.5196 8.68669C22.0346 10.1665 22.1962 11.7417 21.9972 13.2871C21.9295 13.813 22.2486 14.3272 22.7657 14.4446Z'
          fill='currentColor'
          fillOpacity='0.3'
        />
        <path
          d='M18.0967 21.204C18.3895 21.646 18.9879 21.7699 19.4051 21.4428C20.7442 20.3927 21.8448 19.0696 22.6334 17.5617C22.8792 17.0919 22.6483 16.526 22.1604 16.3186C21.6724 16.1112 21.1127 16.3414 20.8599 16.8074C20.2275 17.9728 19.3713 19.0022 18.3405 19.8362C17.9283 20.1697 17.8039 20.762 18.0967 21.204Z'
          fill='currentColor'
          fillOpacity='0.3'
        />
      </g>
      <defs>
        <clipPath id='clip0_801_5077'>
          <rect width='24' height='24' fill='currentColor' />
        </clipPath>
      </defs>
    </svg>
  )
}
