import React from "react";

const PrivacyPolicy = () => {
	return (
		<>
			<h2>
				<strong>DICOMWallet Privacy Policy</strong>
			</h2>

			<p>Last Update: January 2023</p>

			<h2>Introduction</h2>

			<p>
				Thank you for choosing to be part of our community at Precious MD Ltd,
				doing business as DICOMWallet. We are committed to protecting your
				privacy and we have prepared this privacy policy (&ldquo;Privacy
				Policy&rdquo;) to explain how we collect, use and disclose any Personal
				Information (as further defined) that we collect. &ldquo;Personal
				Information&rdquo; is defined as any information that can be used to
				identify an individual.
			</p>

			<p>
				This Privacy Policy applies to the processing of your Personal
				Information when you visit our website at&nbsp;
				<a href="https://dicomwallet.com">www.DICOMWallet.com</a> (the
				&ldquo;Site&rdquo;), if you contact us by any means and if you make use
				of any of our servuces, subscription and support services, software or
				mobile applications.
				<br />
				&nbsp;
			</p>

			<p>
				This Privacy Policy is incorporated into and forms part of our{" "}
				<a href="term-of-service">Terms of Use</a>, which outline the terms and
				conditions you agree to when accessing and using the Site. By accessing
				and using the Site, you agree and consent to the collection, use and
				disclosure of your Personal Information as outlined in this Privacy
				Policy. If you do not agree with this Privacy Policy, please do not use
				the Site.
			</p>
			<p>
				Please be aware that by following links to third-party websites, you
				will be leaving the DicomWallet website and our policies will no longer
				apply. We encourage you to make yourself aware of the privacy policies
				and terms of use of any third-party websites you visit. DicomWallet is
				not responsible for the privacy practices or content of these websites,
				and we recommend that you review their policies carefully before
				providing any personal information.
			</p>

			<h2>The types of Personal Information DICOMWallet collects:</h2>

			<p>We currently collect and process the following information:</p>

			<ul>
				<li>
					Personal identifiers, contact details and characteristics (for
					example, name, profession group and e-mail address when you subscribe
					to DICOMWallet and when you contact our sales or marketing team)
				</li>
				<li>
					Our Service is designed as a simple and personalised way for you to
					store your DICOM images, documents, comments, messages and so on
					(‘Your Data’) collaborate with others and work across multiple devices
					and services. To make that possible, we store, process and transmit
					Your Data as well as information related to it. This related
					information includes your profile information, which makes it easier
					to collaborate and share Your Data with others, as well as things like
					the size of the file, the time it was uploaded, collaborators and
					usage activity
				</li>
				<li>
					Website user statistics (when you visit the Site certain data will be
					collected automatically. This may include (but is not limited to) some
					or all of the following: How you connect to the internet (including IP
					address), how you engage with the Site, screen resolution, information
					about the device software you are using such as internet browser and
					location data (city, region of the IP address you used when accessing
					the Site). We also collect information about which website you came to
					the Site from, the fact that you visited the Site before and how you
					interact with the Site and services. Having access to this information
					helps us better understand our customers and improve our marketing;
				</li>
				<li>
					Social Media Login Data, when you want to register with our website,
					we provide you with the option to register with us using your existing
					social media account details, like your Facebook, Twitter or other
					social media account). If you choose to register in this way, we will
					receive certain profile information about you from your social media
					provider. The profile information we receive may vary depending on the
					social media provider concerned, but will often include your name,
					email address, friends list, profile picture as well as other
					information you choose to make public on such social media platform.
					We will use the information we receive only for the purposes that are
					described in this privacy notice or that are otherwise made clear to
					you on the relevant website. Please note that we do not control, and
					are not responsible for, other uses of your personal information by
					your third-party social media provider;
				</li>
				<li>
					Content commenting when you comment on your own studies or the studies
					shared with you. We keep these comments for your future reference at
					&nbsp;<a href="https://dicomwallet.com/">www.DICOMWallet.com</a>. You
					are responsible for the content of their own comments and you are able
					to edit or delete your comments
				</li>
			</ul>

			<h2>
				How DICOMWallet gets your Personal Information and why we have it:
			</h2>

			<p>
				Most of the Personal Information we process is provided to us directly
				by you for one of the following reasons:
			</p>

			<ul>
				<li>To enable you to access the Site and use DICOMWallet services</li>
				<li>
					To enable DICOMWallet to deliver the services you have asked for and
					to give you the best possible experience when you engage with us (via
					the Site and apps or otherwise) and when you use our services;&nbsp;
				</li>
				<li>
					To let you know about significant changes to our services,{" "}
					<a href="term-of-service">Terms of Use</a> or this Privacy Policy;
				</li>
				<li>To provide and improve customer support</li>
				<li>To send marketing communications</li>
				<li>For internal training purposes</li>
				<li>To maintain our records and other administrative functions</li>
				<li>For complaint and dispute resolution</li>
				<li>To improve data accuracy and completeness.</li>
				<li>When you access our training materials</li>
				<li>When you ask to receive online information from us</li>
				<li>
					When you participate in a review, survey or similar type activity;
				</li>
				<li>
					When you otherwise participate in features of the Site that ask for
					Personal Information.
				</li>
				<li>By using technologies such as cookies</li>
			</ul>
			<p>
				By using our service, you are giving your informed consent to be
				contacted by DicomWallet for the purpose of marketing our products and
				services. You understand that you may be contacted by email that you can
				opt out of these communications at any time by contacting us or using
				the opt-out options provided in the communication.
			</p>

			<h2>Who has access to your Personal Information?</h2>

			<p>
				As a general rule, we&nbsp;will&nbsp;only share your Personal
				Information with those that need access to the information for us to
				achieve the purpose for which we have collected it, or to comply with an
				obligation imposed by law. Internally, we will only share your Personal
				Information on a &ldquo;need-to-know&rdquo; basis, i.e. with employees
				who need access to the Personal Information to perform a task on our
				behalf. We may need to process your data or share your personal
				information in the following scenarios:
			</p>

			<p>
				Vital Interests: We may disclose your information where we believe it is
				necessary to investigate, prevent, or take action regarding potential
				violations of our policies, suspected fraud, situations involving
				potential threats to the safety of any person and illegal activities, or
				as evidence in litigation in which we are involved.
			</p>

			<p>
				Business Transfers. We may share or transfer your information in
				connection with, or during negotiations of, any merger, sale of company
				assets, financing, or acquisition of all or a portion of our business to
				another company.
			</p>

			<h2>The lawful basis for processing your Personal Information</h2>

			<p>
				Under the General Data Protection Regulation (GDPR), the lawful bases we
				rely on for processing Personal Information are:
			</p>

			<p>&nbsp;</p>

			<ul>
				<li>
					Your consent. You are able to withdraw your consent at any time and/or
					&ldquo;unsubscribe&rdquo; from receiving e-mail or other
					communications at any time. You can do this by contacting DICOMWallet
					at the contact details as set out below.
				</li>
				<li>
					We have a legitimate interest, meaning that the processing is
					necessary for the purposes of the legitimate interests pursued by
					DICOMWallet, such as processing client data and administrative
					transfers internally.
				</li>
			</ul>

			<h2>How DICOMWallet stores your Personal Information</h2>

			<p>
				The security of your Personal Information is important to us. &nbsp;We
				use reasonable and appropriate physical, technical and administrative
				industry safeguards to protect information from unauthorized use,
				disclosure or access of the Personal Information we collect on the Site.
			</p>

			<p>
				We adhere to the principle of data minimization, which means that we
				only collect, use, and store the minimum amount of PII necessary to
				achieve our business purposes. 
			</p>

			<p>
				We will make reasonable efforts to protect Personal Information stored
				on the Site secure servers from unauthorized access using commercially
				available computer security products (for example, firewalls), as well
				as carefully developed security procedures and practices.
				&nbsp;Notwithstanding our security safeguards it is impossible to
				guarantee 100% security in all circumstances.
			</p>

			<p>
				We keep your Personal Information only for such period necessary to
				achieve our purposes for collecting the information and to meet any
				applicable legal obligations. Where your Personal Information is stored
				by DICOMWallet as part of its hosting services, we will store such data
				indefinitely, or until such time as you request your Personal
				Information be removed or transferred to another system. In all cases,
				our need to use your Personal Information will be reassessed on a
				regular basis, and information which is no longer required for any
				purposes will be disposed of securely.
			</p>

			<div>
				<h2>Data Destruction&nbsp;</h2>
			</div>

			<div>
				<div>
					<p>
						personally&nbsp;identifiable information will be destroyed in a
						secure manner when it is no longer needed for business purposes.
						This may include shredding paper documents, wiping electronic media,
						or using other secure methods of destruction. &nbsp;
					</p>
				</div>
				<div>
					<p>
						Data Confidentiality Breaches: A data confidentiality breach occurs
						when PII is accessed, used, disclosed, or destroyed without
						authorization. If a data&nbsp;confidentiality breach is suspected or
						confirmed, the following steps will be taken: &nbsp;
					</p>
				</div>
				<div>
					<ul>
						<li>
							<p>
								The breach will be immediately reported to
								the&nbsp;DicomWallet&nbsp;Data Protection Officer
								(DPO)&nbsp;&nbsp;
							</p>
						</li>
						<li>
							<p>
								The DPO will conduct an investigation to determine the scope and
								impact of the breach&nbsp;&nbsp;
							</p>
						</li>
					</ul>
				</div>
			</div>
			<div>
				<div>
					<ul>
						<li>
							<p>
								If the breach is confirmed and PII has been accessed, used,
								disclosed, or destroyed without authorization, the DPO will take
								appropriate action to secure the PII and prevent further
								unauthorized access, use, disclosure, or destruction&nbsp;&nbsp;
							</p>
						</li>
						<li>
							<p>
								If the breach is confirmed and it is determined that PII has
								been accessed, used, disclosed, or destroyed without
								authorization, the DPO will report the breach to relevant
								authorities, as required by law&nbsp; &nbsp;
							</p>
						</li>
						<li>
							<p>
								If the breach is confirmed and it is determined that PII has
								been accessed, used, disclosed, or destroyed without
								authorization, the DPO will inform affected users and provide
								them with information on how to protect themselves from
								potential harm resulting from the breach&nbsp;
							</p>
						</li>
					</ul>
				</div>
				<div>
					<h2>Data Collection from Children&nbsp;</h2>
				</div>
				<div>
					<p>
						We do not knowingly collect personal data from children under the
						age of 13 (or the age of consent in your jurisdiction) without
						parental consent. &nbsp;
					</p>
				</div>
			</div>
			<div>
				<p>
					If you have knowledge of a child accessing our app and providing
					personal data without parental consent, we encourage you to report
					this incident to us. You can contact us at&nbsp;
					<a
						href="mailto:privacy@dicomwallet.com"
						rel="noreferrer noopener"
						target="_blank"
					>
						privacy@DicomWallet.com
					</a>{" "}
					to report any incidents of children accessing the app and providing
					personal data without parental consent. &nbsp;
				</p>
			</div>
			<div>
				<h2>Transfer of Personal Information across borders: &nbsp;</h2>
			</div>

			<div>
				<div>
					<ul>
						<li>
							<p>
								Some or all of the Personal Information we collect may be stored
								on secure computers or Secure servers located outside of the
								country in which we obtain the Personal Information, including
								countries whose protection of data protection laws may differ
								from the jurisdiction in which you live. This is in order to
								allow for the performing of centralized functions for our users
								internationally. As a result, this information may be subject to
								access requests from governments, courts, law enforcement
								officials, and national security authorities in those
								jurisdictions according to the applicable laws in those
								jurisdictions. Subject to such applicable laws, we will use
								reasonable efforts to ensure the appropriate protections are in
								place to maintain such protections of the
								Personal&nbsp;Information that are equivalent to those that
								apply in the country in which we obtain the Personal
								Information&nbsp;
							</p>
						</li>
						<li>
							<p>
								Where we transfer Personal Information from the United Kingdom
								or anywhere in the European Union to a country that has data
								protection laws adequate to the GDPR, such transfer is made on
								the basis of an adequacy decision by the European Commission
								under Article 45 of the GDPR&nbsp;
							</p>
						</li>
						<li>
							<p>
								Where we transfer Personal Information from anywhere in the
								world to a country or territory which does not have an adequacy
								status as determined by the European Commission, we ensure that
								appropriate safeguards are put in place which provide sufficient
								guarantees for an adequate level of protection of the Personal
								Information as well as effective and enforceable rights for you
								to rely on. In other words, whenever we transfer your Personal
								Information cross border, it will receive a similar level of
								protection as described in this Privacy Policy. We do this by
								ensuring that the European Commission&rsquo;s Standard
								Contractual Clauses (&ldquo;SCC&rsquo;s&rdquo;) have been agreed
								to between&nbsp;PreciousMD&nbsp;ltd and its affiliates and that
								a copy of those SCC&rsquo;s can be obtained by
								contacting&nbsp;DicomWallet&nbsp;DPO at 
								<a
									href="mailto:privacy@Dicomwallet.com"
									rel="noreferrer noopener"
									target="_blank"
								>
									privacy@DicomWallet.com
								</a>
								&nbsp;
							</p>
						</li>
					</ul>
				</div>
				<div>
					<h2>Your rights with regards to your Personal Information &nbsp;</h2>
				</div>
			</div>
			<div>
				<div>
					<p>
						Under data protection law, you have the following rights: &nbsp;
					</p>
				</div>
				<div>
					<ul>
						<li>
							<p>
								You have the right to ask us for copies of your Personal
								Information we hold &nbsp;
							</p>
						</li>
						<li>
							<p>
								You have the right to ask us to rectify your Personal
								Information we hold which you think is inaccurate. You can
								either correct the information using
								the&nbsp;DicomWallet&nbsp;directly, or you can contact us, by
								e-mailing us&nbsp;.at 
								<a
									href="mailto:privacy@Dicomwallet.com"
									rel="noreferrer noopener"
									target="_blank"
								>
									privacy@DicomWallet.com
								</a>
								. You also have the right to ask us to complete your Personal
								Information we hold which you think is incomplete&nbsp;
							</p>
						</li>
						<li>
							<p>
								You have the right to ask us to erase your Personal Information
								we hold in certain circumstances. To do so, email us at 
								<a
									href="mailto:privacy@Dicomwallet.com"
									rel="noreferrer noopener"
									target="_blank"
								>
									privacy@DicomWallet.com
								</a>
								 and we will delete your information from our files. To do this,
								we will require you to provide us with your name, address, and
								telephone numbers so that we may handle your request
								accurately&nbsp;
							</p>
						</li>
					</ul>
				</div>
			</div>
			<div>
				<div>
					<ul>
						<li>
							<p>
								You have the right to withdraw your consent for us to process
								your Personal Information where we rely on such consent as the
								basis for the processing. To withdraw your consent, e-mail us
								at 
								<a
									href="mailto:privacy@Dicomwallet.com"
									rel="noreferrer noopener"
									target="_blank"
								>
									privacy@DicomWallet.com
								</a>
								&nbsp;
							</p>
						</li>
						<li>
							<p>
								You have the right to ask us to restrict the processing of your
								Personal Information in certain circumstances&nbsp;
							</p>
						</li>
						<li>
							<p>
								You have the right to object to our processing of your Personal
								Information in certain circumstances&nbsp;
							</p>
						</li>
						<li>
							<p>
								You have the right to ask that we transfer your Personal
								Information you gave us to another organisation, or to you, in
								certain circumstances&nbsp;
							</p>
						</li>
						<li>
							<p>
								You are not required to pay any charge for exercising your
								rights. If you make a request, we have one month to respond to
								you &nbsp;
							</p>
						</li>
					</ul>
				</div>
			</div>
			<div>
				<div>
					<ul>
						<li>
							<p>
								Please contact us at 
								<a
									href="mailto:privacy@Dicomwallet.com"
									rel="noreferrer noopener"
									target="_blank"
								>
									privacy@DicomWallet.com
								</a>
								 if you wish to make a request. &nbsp;
							</p>
						</li>
					</ul>
				</div>
				<div>
					<h2>Information Obtained Through Automated Means &nbsp;</h2>
				</div>
				<div>
					<p>
						We use cookies and similar technologies, such as web beacons used
						for web analytics, to enhance functionality and navigation for users
						of the Site. Information tracked through these mechanisms includes
						but is not limited to: &nbsp;
					</p>
				</div>
			</div>
			<div>
				<div>
					<ul>
						<li>
							Your IP&nbsp;address
						</li>
						<li>
							Your cookie ID
						</li>
						<li>
							Your web browser version
						</li>
						<li>
							Past visits to the Site
						</li>
					</ul>
				</div>
			</div>
			<div>
				<h2>Privacy Policy changes and questions &nbsp;</h2>
			</div>

			<div>
				<p>
					DicomWallet&nbsp;reserves the right, at its discretion, to change,
					modify, add, or remove portions of this Privacy Policy at any time. If
					a change is made, we will post the updated Privacy Policy on the Site
					and we encourage you to check our Privacy Policy periodically. By
					using the Site or any&nbsp;DicomWallet&nbsp;services, subscription and
					support services, or mobile applications following the posting of a
					change in the Privacy Policy, you consent to the updated Privacy
					Policy. &nbsp;
				</p>
			</div>
			<div>
				<p>
					If there is a change in the purpose of data collection, we will update
					our policy to reflect this change. In the event that the change in
					purpose requires re-obtaining user consent as the lawful basis for
					data collection, we will take steps to obtain this consent in a timely
					and appropriate manner.
				</p>
			</div>
			<div>
				<h2>Questions or complaints &nbsp;</h2>
			</div>

			<div>
				<p>
					If you have any concerns or questions about our use of your Personal
					Information, please let us know by contacting us in any of the
					following ways: &nbsp;
				</p>
			</div>

			<div>
				Precious MD Ltd&nbsp;
			</div>
			<div>
				20-22, Wenlock Road,&nbsp;
			</div>
			<div>
				London, England,&nbsp;
			</div>
			<div>
				N1 7GU
			</div>

			<div>
				<p>
					You can also contact our data owner and data protection officer
					Fatemeh Ameri at: 
					<a
						href="mailto:privacy@Dicomwallet.com"
						rel="noreferrer noopener"
						target="_blank"
					>
						privacy@DicomWallet.com
					</a>
					 &nbsp;
				</p>
			</div>
		</>
	);
};

export default PrivacyPolicy;
