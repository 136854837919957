import React from "react"
import StudyCard from "./studyCard"

const StudyCardContainer = ({
  studyList,
  handleSelectedStudies,
  selectedStudies,
  handleMoveAndCopy,
  isXlScreen,
  handleStudyClick
}) => {
  const handleCardClick = (study, event) => {
    const isCtrlOrCmdPressed = event.ctrlKey || event.metaKey
    const isCheckboxClicked = event.target.classList.contains("checkboxmeta")
    if (isXlScreen()) {
      handleSelectedStudies({ ...study, type: "study" }, isCtrlOrCmdPressed)
    } else {
      handleSelectedStudies({ ...study, type: "study" }, isCheckboxClicked)
    }
  }

  if (studyList !== undefined)
    return (
      <>
        {studyList.map((study) => {
          return (
            <StudyCard
              handleStudyClick={handleStudyClick}
              key={study.id}
              handleSelect={(study, event) => handleCardClick(study, event)}
              studyMeta={study}
              handleMoveAndCopy={handleMoveAndCopy}
              isSelected={selectedStudies.includes(`study-${study.id}`)}
              selectedCount={selectedStudies.length}
              isXlScreen={isXlScreen}
            />
          )
        })}
      </>
    )
}

export default StudyCardContainer
