import React from "react";
import { DicomButtonLink } from "../DesignSystem/buttons";

const TopHeader = () => {
  return (
    <>
      <div className="grid items-center grid-cols-6 lg:grid-cols-12 mt-5 lg:mt-0 gap-y-28 lg:gap-x-16">
        <div className="w-full col-span-6 order-1 lg:-order-1 lg:col-span-5 space-y-dicom-30">
          <h1 className="text-dicom-headline2 lg:text-dicom-headline1 font-bold">
            Your personal wallet for medical images
          </h1>
          <p className="text-dicom-body2 lg:text-dicom-body1 text-dicom-text-100">
            We made it super easy to store, view and share medical images
            compliantly.
          </p>

          <DicomButtonLink title={"Start now free"} url={"/auth/join-us"} />
        </div>
        <div className="w-full col-span-6 lg:col-span-7 flex items-center">
          <img
            src={"images/topheader-illustration.png"}
            className={"lg:ml-[92px]"}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default TopHeader;
