import React, { useEffect, useRef, useState } from "react"
import {
  IconBlackCopy,
  IconBlackCopyed,
  IconBlackCut,
  IconBlackDelete,
  IconBlackDownload,
  IconBlackRename,
  IconCheckBox,
  IconCheckBoxChecked,
  IconListTrashBlack,
  IconOptionsDotted,
  IconShareBlack
} from "../../DesignSystem/svgIcons"
import Modal from "../../DesignSystem/Modal"
import DeleteFolder from "./deleteFolder"
import EditFolder from "./editFolder"
import { FormatSizeUnitsSpace } from "../../../utils/SizeHandler"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { contextMenuAtom } from "../../../atom/contextMenu"
import { userSelectedData } from "../../../atom/userStudies"
import { useLocation } from "react-router-dom"
import { authAtom } from "../../../atom/auth"

export default function FolderSkelliton({
  folderMeta,
  onClick,
  handleSelectedFolder,
  isSelected,
  selectedCount,
  handleMoveAndCopy,
  handleFolderDownload,
  handleDragDropMove,
  isXlScreen
}) {
  const [isReadytoMove, setIsReadyToMove] = useState(false)
  const setSelectedData = useSetRecoilState(userSelectedData)
  const handleNavigate = (e) => {
    setSelectedData([])
    onClick(folderMeta.id)
  }

  const handleContextMenu = (e) => {
    if (selectedCount > 1) return
    e.preventDefault()
    document.getElementById(`folder-dropdown-${folderMeta.id}`).click()
  }

  const handleDoubleClickRename = (e) => {
    e.stopPropagation()
    document.getElementById(`folder-rename-${folderMeta?.id}`).checked = true
  }

  const handleClick = (folderMeta, event) => {
    const isCtrlOrCmdPressed = event.ctrlKey || event.metaKey
    const isCheckboxClicked = event.target.classList.contains("checkboxmeta")
    if (isXlScreen()) {
      handleSelectedFolder({ ...folderMeta, type: "folder" }, isCtrlOrCmdPressed)
    } else {
      handleSelectedFolder({ ...folderMeta, type: "folder" }, isCheckboxClicked)
    }
  }
  const handleDragOver = (e) => {
    e.preventDefault()
  }
  const handleDrop = (e) => {
    e.preventDefault()
    const data = JSON.parse(e.dataTransfer.getData("text/plain"))
    if (data.type == "folder" && data.id == folderMeta?.id) return
    handleDragDropMove(data.type, data.id, folderMeta.id)
  }

  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", JSON.stringify({ id: folderMeta?.id, type: "folder" }))
  }
  const highlightSelect = `bg-[#E7F9FF] !hover:bg-[#E7F9FF]`
  return (
    <>
      <Modal modalId={`folder-delete-${folderMeta.id}`} title={"Delete Folder"}>
        <DeleteFolder modalId={`folder-delete-${folderMeta.id}`} item={folderMeta} />
      </Modal>
      <Modal modalId={`folder-rename-${folderMeta.id}`} title={"Rename Folder"}>
        <EditFolder modalId={`folder-rename-${folderMeta.id}`} item={folderMeta} />
      </Modal>
      <div
        onClick={(event) => handleClick(folderMeta, event)}
        onContextMenu={handleContextMenu}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDoubleClick={handleNavigate}
        draggable={true}
        onDrop={handleDrop}
        id={`folder-${folderMeta.id}`}
        className={`${
          isSelected && highlightSelect
        } xl:w-[182px] dropdown w-[104px] folder min-h-[160px] xl:min-h-[245px] relative cursor-pointer p-[10px] border-[#F5F5F5] border rounded-md flex flex-col hover:bg-slate-50`}>
        <div className='flex items-center flex-initial self-stretch w-full'>
          <span
            onDoubleClick={handleDoubleClickRename}
            title={folderMeta.name}
            className={`${
              isReadytoMove && "opacity-50"
            }  font-semibold items-center flex gap-1 text-xs flex-1 w-[90%] xl:w-full`}>
            <span className='flex-initial'>
              {isSelected ? (
                <IconCheckBoxChecked className='checkboxmeta' />
              ) : !isXlScreen() ? (
                <IconCheckBox className='checkboxmeta' />
              ) : (
                ""
              )}
            </span>
            <span className='flex-1 line-clamp-1'>{folderMeta.name}</span>
          </span>
          <div className='flex-initial hidden cursor-pointer md:block  md:relative'>
            <FolderOptions
              setReadytoMove={setIsReadyToMove}
              id={folderMeta.id}
              handleMoveAndCopy={handleMoveAndCopy}
              handleDownload={handleFolderDownload}
            />
          </div>
        </div>

        <div className={`${isReadytoMove && "opacity-50"} flex justify-center items-center flex-1`}>
          <img
            draggable={false}
            className='w-[74px] xl:w-auto'
            src={`/images/dashboard/file_system/folder_icon.png`}
            alt={"folderIcon"}
          />
        </div>
        {folderMeta.size !== undefined && (
          <div className={`${isReadytoMove && "opacity-50"} text-xs flex-initial`}>
            size
            {FormatSizeUnitsSpace(+folderMeta.size)}
          </div>
        )}
      </div>
    </>
  )
}

export const FolderOptions = (params) => {
  const contextMenu = useRecoilValue(contextMenuAtom)

  const [selected, setSelected] = useState(false)
  const dropdownRef = useRef()
  const user = useRecoilValue(authAtom)

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown()
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)
    if (contextMenu !== null && contextMenu.action === "move") {
      if (contextMenu.type === "folder" && params.id === contextMenu.objectId) {
        params.setReadytoMove(true)
      } else params.setReadytoMove(false)
    } else params.setReadytoMove(false)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [contextMenu])

  const closeDropdown = () => {
    setSelected(false)
  }

  const location = useLocation()
  const checkFreeze = () => {
    if (parseInt(user.usedSpace) > parseInt(user.totalSpace) || user.freezeAccount) return true
    else return false
  }
  return (
    <div
      ref={dropdownRef}
      onClick={() => {
        setSelected(!selected)
      }}
      className={`relative z-1 cursor-pointer`}
      id={`folder-dropdown-${params?.id}`}>
      {!checkFreeze() && (
        <div className='flex space-x-2'>
          <IconOptionsDotted />
        </div>
      )}
      {selected && (
        <div className='absolute cursor-pointer -top-24 md:top-0 -right-3 md:right-0 z-[3] shadow bg-base-100 rounded-box w-28 xl:w-40  max-xl:text-xs '>
          <div className='overflow-hidden  bg-slate-50/50 rounded-t-lg '>
            <span
              onClick={() => {
                document.getElementById(`share-study-with-table`).checked = true
              }}
              className='flex anim hover:bg-slate-100 px-4 py-2 gap-2 items-center justify-start'>
              <IconShareBlack width={18} height={18} /> Share
            </span>
            <button
              disabled={location.pathname.split("/").includes("shared")}
              onClick={() => (document.getElementById("delete-studies").checked = true)}
              className='flex anim  hover:bg-slate-100 px-2 xl:px-4 w-full py-2 gap-2 items-center justify-start disabled:opacity-50 disabled:cursor-not-allowed'>
              <IconListTrashBlack color='black' width={18} height={18} />
              {location.pathname.split("/").includes("shared-with-you") ? "Remove" : "Delete"}
            </button>
          </div>
          <div className='border border-slate-100' />
          <div className=' overflow-hidden bg-slate-50/50 rounded-b-lg'>
            <span
              onClick={() => {
                params.handleMoveAndCopy("copy", params?.id, "folder")
              }}
              className='flex anim hover:bg-slate-100 px-4 py-2 gap-2 items-center justify-start'>
              <IconBlackCopy /> <span>Copy</span>
            </span>
            <span
              onClick={() => {
                params.handleMoveAndCopy("move", params?.id, "folder")
                params.setReadytoMove(true)
              }}
              className='flex anim hover:bg-slate-100 px-4 py-2 gap-2  items-center justify-start'>
              <IconBlackCut /> Cut
            </span>
            {/* <span
              htmlFor={`folder-rename-${params?.id}`}
              onClick={() => {
                document.getElementById(
                  `folder-rename-${params.id}`
                ).checked = true;
              }}
              className="flex anim hover:bg-slate-100 px-4 py-2 gap-2 items-center justify-start"
            >
              <IconBlackRename /> Rename
            </span> */}
            {/* <span
              htmlFor={`folder-rename-${params?.id}`}
              onClick={() => params.handleDownload(params?.id)}
              className="flex anim hover:bg-slate-100 px-4 py-2 gap-2 items-center justify-start"
            >
              <IconBlackDownload /> Download
            </span> */}
          </div>
        </div>
      )}
    </div>
  )
}
