import React from "react";
import Layout from "../containers/Layout";
import UseDocumentTitle from "../utils/useDocumentTitle";

const NotFound = () => {
  UseDocumentTitle("404 | Not Found Page", true);
  return (
    <>
      <Layout>
        <div className="flex items-center justify-center py-24">
          <h1 className="text-dicom-headline1 font-bold">404</h1>
        </div>
      </Layout>
    </>
  );
};

export default NotFound;
