import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import toast from "react-hot-toast"

import { DicomButton, DicomButtonOutline } from "../../../components/DesignSystem/buttons"
import { fetchData } from "../../../services/fetching"

const DeleteStudy = ({ modalId, id, item }) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const onSubmit = () => {
    setIsLoading(true)
    fetchData("DELETE", `/study/?codes[]=${id}`)
      .then((res) => {
        if (res.data.data) {
          navigate("/dashboard/my-dicom-wallet")
          document.getElementById(modalId).checked = false
          toast.success("The selected study/studies has been successfully deleted")
        }
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
      })
  }
  return (
    <>
      <div className='space-y-dicom-15'>
        <p>
          Are you sure you want to permanently delete the <span className='font-bold'>{item.title}</span>
          study from DicomWallet?
        </p>
        <div className='flex justify-center gap-5 w-full'>
          <DicomButtonOutline
            onClick={() => (document.getElementById(modalId).checked = false)}
            btnClass={"!border-dicom-secondary-100 !text-dicom-secondary-100 hover:!bg-dicom-secondary-100/5"}
            title={"Cancel"}
          />
          <DicomButton
            isLoading={isLoading}
            onClick={onSubmit}
            btnClass={"!border-dicom-secondary-100 !bg-dicom-secondary-100 !text-white"}
            title={"Delete"}
          />
        </div>
      </div>
    </>
  )
}

export default DeleteStudy
