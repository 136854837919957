import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

import {
  DicomButton,
  DicomButtonOutline,
} from "../../DesignSystem/buttons";
import { Input } from "../../DesignSystem/inputs";
import { fetchData } from "../../../services/fetching";
import { useLocation, useParams } from "react-router-dom";
import {useSetRecoilState,useRecoilValue} from "recoil"
import { PaginationMeta, UserStudies, UserStudiesURL } from "../../../atom/userStudies";

const RenameFolder = ({ modalId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const setStudies= useSetRecoilState(UserStudies)
  const studyUrl= useRecoilValue(UserStudiesURL)
  const params = useParams();
  const setPaginationMeta= useSetRecoilState(PaginationMeta)
  const location = useLocation();
  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Folder name is required")
      .max(50, "Folder name must be at most 50 characters"),
  });
  const onSubmit = () => {
    setIsLoading(true);
    let data = {
      ...formik.values,
    };
    if (location.pathname.split("/").includes("folders"))
      data.parentId = params.folderId;
      fetchData("POST", "/folder", data)
      .then(async (res) => {
        if (res.data.data.folder) {
          // window.location.reload()
          try{
            const study=await fetchData("GET",studyUrl)
            setStudies(study.data.data)
            setPaginationMeta((old)=>({
              ...old, total:study.data.data.folderCount +study.data.data.count,
              page:0
             }))
          }
          catch(err){
            console.log(err)
          }

          formik.resetForm();
          document.getElementById(modalId).checked = false;
          toast.success("Your folder has been created successfully");
        }

        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data.message);
        setIsLoading(false);
      });
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <>
      <div className="space-y-dicom-15">
        <Input label={"Folder name"} formik={formik} nameId={"name"} />
        <div className="flex justify-center gap-5 w-full">
          <DicomButtonOutline
            onClick={() => (document.getElementById(modalId).checked = false)}
            btnClass={
              "!border-dicom-secondary-100 !text-dicom-secondary-100 hover:!bg-dicom-secondary-100/5"
            }
            title={"Cancel"}
          />
          <DicomButton
            isLoading={isLoading}
            onClick={onSubmit}
            btnClass={
              "!border-dicom-secondary-100 !bg-dicom-secondary-100 !text-white"
            }
            title={"Create folder"}
          />
        </div>
      </div>
    </>
  );
};

export default RenameFolder;
