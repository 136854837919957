import React, { useEffect, useRef, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { latestStudyUploaded, PaginationMeta, UserStudies } from "../../../atom/userStudies"
import { AnonyomizeStatus, IsXlScreen, StarAction, StudyImagePreview } from "../ActionsItem"
import reactDom from "react-dom"
import { FolderOptionsListView } from "../folders/folderContextMenuListView"
import { FileOptionsListView } from "../study/studyContextMenuListView"
import moment from "moment"
import { FormatSizeUnitsSpace } from "../../../utils/SizeHandler"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { IconCheckBox, IconCheckBoxChecked } from "../../DesignSystem/svgIcons"
import { PaginationFooter } from "../PaginationFooter"
import { NoRowsOverlay } from "../../../pages/dashboard/all-studies/StudyList"
import EditFolder from "../folders/editFolder"
import { Modal } from "@mui/material"
import { GetLocalStorage, SetLocalStorage } from "../../../services/LocalStorage"
import DeleteFolder from "../folders/deleteFolder"
import { authAtom } from "../../../atom/auth"

export default function TableView({ setSelectedData, selectedData, handleMoveAndCopy }) {
  const Studies = useRecoilValue(UserStudies)
  const [Items, setItems] = useState([
    ...Studies.folders.map((val) => ({ ...val, type: "folder" })),
    ...Studies.studies.map((val) => ({ ...val, type: "studies" }))
  ])
  const [renameFolder, setRenameFolder] = useState(null)
  const [sortType, setSortType] = useState({ count: 0, type: "" })
  const LatestStudyUploaded = useRecoilValue(latestStudyUploaded)

  const SortIcon = ({ type }) => {
    const handleSort = () => {
      const sortedItems = [
        ...Studies.folders.map((val) => ({ ...val, type: "folder" })),
        ...Studies.studies.map((val) => ({ ...val, type: "studies" }))
      ]

      switch (type) {
        case "title":
          toggleSort("title", sortedItems)
          break
        case "owner":
          toggleSort("owner", sortedItems)
          break
        case "patientName":
          toggleSort("patientName", sortedItems)
          break
        case "uploadDate":
          toggleSort("uploadDate", sortedItems)
          break
        case "modality":
          toggleSort("modality", sortedItems)
          break
        case "instances":
          toggleSort("instances", sortedItems)
          break
        case "size":
          toggleSort("size", sortedItems)
          break
        default:
          setItems(sortedItems)
          setSortType({ count: 0, type: "" })
          break
      }
    }

    const toggleSort = (sortBy, sortedItems) => {
      const isToggleSort = sortType.type === sortBy

      // Toggle between ascending and descending
      const sortOrder = isToggleSort && sortType.count === 2 ? 0 : sortType.count + 1
      const sortOrderModifier = sortOrder === 2 ? -1 : 1
      if (sortOrder === 0) {
        setItems([
          ...Studies.folders.map((val) => ({ ...val, type: "folder" })),
          ...Studies.studies.map((val) => ({ ...val, type: "studies" }))
        ])
        return setSortType({ count: 0, type: "" })
      }
      sortedItems.sort((a, b) => {
        const valueA = getValueForSort(a, sortBy)
        const valueB = getValueForSort(b, sortBy)

        if (sortBy === "size") {
          return sortOrderModifier * (Number(valueA) - Number(valueB))
        }
        if (sortBy === "instances") {
          return sortOrderModifier * (Number(valueA) - Number(valueB))
        }
        return sortOrderModifier * valueA.localeCompare(valueB)
      })

      setItems(sortedItems)
      setSortType({ count: sortOrder, type: sortBy })
    }

    const getValueForSort = (item, sortBy) => {
      switch (sortBy) {
        case "title":
          return item.title || item.name
        case "owner":
          return `${item?.user?.firstName} ${item?.user?.lastName}` || ""
        case "patientName":
          return `${item?.patientName}` || ""
        case "uploadDate":
          return `${moment(item?.updateDate).format("MMM DD, YYYY HH:mm")}` || ""
        case "modality":
          return `${item?.modality}` || ""
        case "instances":
          return `${item?.frames}` || ""
        case "size":
          return +item.size
        default:
          return ""
      }
    }

    return (
      <svg
        onClick={handleSort}
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth='2.5'
        stroke='currentColor'
        className={`w-6 h-6 hover:bg-gray-200 p-1.5 ${sortType.count === 2 ? "rotate-180" : ""} rounded-full`}>
        <path strokeLinecap='round' strokeLinejoin='round' d='M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18' />
      </svg>
    )
  }
  const { sharedFolderId } = useParams()
  const location = useLocation()
  useEffect(() => {
    setItems([
      ...Studies.folders.map((val) => ({ ...val, type: "folder" })),
      ...Studies.studies.map((val) => ({ ...val, type: "studies" }))
    ])
  }, [Studies])
  const navigate = useNavigate()
  const renderContextMenu = (e, item) => {
    let { type } = item
    if (selectedData.length > 1) return
    e.preventDefault()
    setSelectedData([item])
    if (type === "studies") {
      document.getElementById(`study-dropdown-list-${item.id}`).click()
    } else {
      document.getElementById(`folder-dropdown-list-${item.id}`).click()
    }
  }
  const getCurrentPath = (myLocation) => {
    let locationPath = myLocation.pathname
    return locationPath.endsWith("/") ? locationPath.slice(0, -1) : locationPath
  }

  const handleFolderClick = (id) => {
    let crumbs = sessionStorage.getItem("breadcrumbs")
    if (crumbs !== null) crumbs = [...JSON.parse(crumbs)]
    else crumbs = []
    const folderName = Items?.find((meta) => meta.id === id && meta.type === "folder")?.name || `Folder ${id}`
    crumbs.push({ folderId: id, folderName: folderName })
    sessionStorage.setItem("breadcrumbs", JSON.stringify(crumbs))

    if (getCurrentPath(location) == "/dashboard/shared-with-you") {
      SetLocalStorage("p_id", id)
      const query = new URLSearchParams({
        p_id: id
      }).toString()
      return navigate(`/dashboard/shared/folder/${id}?${query}`)
    }

    if (getCurrentPath(location) == `/dashboard/shared/folder/${sharedFolderId}`) {
      const query = new URLSearchParams(location.search)
      return navigate(`/dashboard/shared/folder/${id}?p_id=${query.get("p_id") || parseInt(GetLocalStorage("p_id"))}`)
    }

    navigate(`/dashboard/folders/${id}`, { replace: true })
  }
  useEffect(() => {}, [renameFolder])

  const handleStudyClick = (studyMeta) => {
    if (getCurrentPath(location) == `/dashboard/shared/folder/${sharedFolderId}`) {
      const query = new URLSearchParams(location.search)
      window.location.href = `/dashboard/shared/study/${studyMeta?.id}?p_id=${
        query.get("p_id") || parseInt(GetLocalStorage("p_id"))
      }&code=${studyMeta.code}`
      return
    }

    window.location.href = studyMeta?.code
  }

  const handleSelectAll = () => {
    setSelectedData(Items)
  }
  const user = useRecoilValue(authAtom)

  const checkFreeze = () => {
    if (parseInt(user.usedSpace) > parseInt(user.totalSpace) || user.freezeAccount) return true
    else return false
  }
  return (
    <div className='relative overflow-x-auto bg-dicom-surface-100 min-h-[70vh]'>
      {/* <Modal modalId={`folder-rename-table`} title={"Rename Folder"}>
        <EditFolder modalId={`folder-rename-table`} item={renameFolder} />
      </Modal> */}

      {Items.length !== 0 ? (
        <>
          <table class='w-full  text-left rtl:text-right text-gray-500 '>
            <thead class='text-sm text-gray-700 capitalize bg-[#F5F5F5] '>
              <tr className='cursor-default'>
                <th scope='col' className='px-2 py-3 sr-only'>
                  <span onClick={handleSelectAll}>
                    <IconCheckBox width={20} height={20} />
                  </span>
                </th>
                <th scope='col' className=' px-6 py-3 '>
                  {""}
                </th>
                <th scope='col' className='group px-2 py-3'>
                  <div className='flex items-center '>
                    Name
                    <div
                      className={`${
                        sortType.type === "title" ? "" : "opacity-0 group-hover:opacity-100 transition-opacity"
                      }`}>
                      <SortIcon type='title' />
                    </div>
                  </div>
                </th>
                <th scope='col' className='px-2 group '>
                  <div className='flex items-center '>
                    Owner
                    <div
                      className={`${
                        sortType.type === "owner" ? "" : "opacity-0 group-hover:opacity-100 transition-opacity"
                      }`}>
                      <SortIcon type='owner' />
                    </div>
                  </div>
                </th>
                <th scope='col' className='px-2 group'>
                  <div className='flex items-center '>
                    Patient Name
                    <div
                      className={`${
                        sortType.type === "patientName" ? "" : "opacity-0 group-hover:opacity-100 transition-opacity"
                      }`}>
                      <SortIcon type='patientName' />
                    </div>
                  </div>
                </th>
                <th scope='col' className='px-2 group'>
                  <div className='flex items-center '>
                    Upload Date
                    <div
                      className={`${
                        sortType.type === "uploadDate" ? "" : "opacity-0 group-hover:opacity-100 transition-opacity"
                      }`}>
                      <SortIcon type='uploadDate' />
                    </div>
                  </div>
                </th>
                <th scope='col' className='px-2 group'>
                  <div className='flex items-center '>
                    Modality
                    <div
                      className={`${
                        sortType.type === "modality" ? "" : "opacity-0 group-hover:opacity-100 transition-opacity"
                      }`}>
                      <SortIcon type='modality' />
                    </div>
                  </div>
                </th>
                <th scope='col' className='px-2 group'>
                  <div className='flex items-center '>
                    Instances
                    <div
                      className={`${
                        sortType.type === "instances" ? "" : "opacity-0 group-hover:opacity-100 transition-opacity"
                      }`}>
                      <SortIcon type='instances' />
                    </div>
                  </div>
                </th>
                <th scope='col' className='group'>
                  <div className='flex items-center '>
                    Size
                    <div
                      className={`${
                        sortType.type === "size" ? "" : "opacity-0 group-hover:opacity-100 transition-opacity"
                      }`}>
                      <SortIcon type='size' />
                    </div>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody className='table-font'>
              {Items.map((item, key) => {
                return (
                  <tr
                    onDoubleClick={() => {
                      if (!checkFreeze()) {
                        if (item.type === "folder") {
                          handleFolderClick(item.id)
                        } else {
                          handleStudyClick(item)
                        }
                      }
                    }}
                    onContextMenu={(e) => renderContextMenu(e, item)}
                    className={`${LatestStudyUploaded === item.id ? "bg-[#e5f6fc]" : ""} border-b ${
                      selectedData.some((data) => data.id == item.id && data.type == item.type)
                        ? "hover:bg-[#e7f9ff]"
                        : "hover:bg-gray-50"
                    }  text-black folder        
                      
                    `}
                    id={`table-row-${item.id}-${item.type}`}
                    key={`listview-${key}`}>
                    <td className='px-2 cursor-pointer'>
                      <CustomCheckBox
                        item={item}
                        checkBoxId={`${item.type}-${item.id}`}
                        setSelectedData={setSelectedData}
                        selectedData={selectedData}
                      />
                    </td>
                    <td role='row' className='px cursor-pointer'>
                      <ActionItem
                        setRenameFolder={setRenameFolder}
                        checkBoxId={key}
                        handleAction={handleMoveAndCopy}
                        item={item}
                      />
                    </td>
                    <td className='font-normal cursor-pointer'>
                      <label
                        htmlFor={`checkbox-${item.type}-${item.id}`}
                        className='2xl:min-w-[200px] 2xl:max-w-[220px] capitalize items-baseline line-clamp-1 flex cursor-pointer'
                        title={item?.name || item?.title}>
                        {item?.name || item?.title}
                      </label>
                    </td>
                    <td className='font-normal px-2 cursor-pointer'>
                      <label
                        htmlFor={`checkbox-${item.type}-${item.id}`}
                        className={`capitalize font-normal line-clamp-1 2xl:min-w-[200px] 2xl:max-w-[220px] ${
                          item.type == "folder" ? "opacity-0" : ""
                        } cursor-pointer`}
                        title={`${item?.user?.firstName} ${item?.user?.lastName}`}>
                        {item?.user?.firstName} {item?.user?.lastName}
                        {item.type == "folder" && "owner"}
                      </label>
                    </td>
                    <td className='font-normal cursor-pointer'>
                      <label
                        htmlFor={`checkbox-${item.type}-${item.id}`}
                        className={`capitalize line-clamp-1 2xl:min-w-[200px] 2xl:max-w-[220px] ${
                          item.type == "folder" ? "opacity-0" : ""
                        } cursor-pointer`}
                        title={`${item?.patientName}`}>
                        {item?.patientName}
                        {item.type == "folder" && "patientName"}
                      </label>
                    </td>

                    <td className='font-normal cursor-pointer'>
                      <label
                        htmlFor={`checkbox-${item.type}-${item.id}`}
                        className='line-clamp-1 2xl:min-w-[140px] 2xl:max-w-[150px] cursor-pointer'
                        title={moment(item?.updateDate).format("MMM DD, YYYY HH:mm")}>
                        {moment(item?.updateDate).format("MMM DD, YYYY HH:mm")}
                      </label>
                    </td>
                    <td className='font-normal cursor-pointer'>
                      <label
                        htmlFor={`checkbox-${item.type}-${item.id}`}
                        className='line-clamp-1 xl:min-w-[80px] xl:max-w-[100px] cursor-pointer'
                        title={`${item?.modality}`}>
                        {" "}
                        {item?.modality}
                      </label>
                    </td>
                    <td className='font-normal cursor-pointer'>
                      <label
                        htmlFor={`checkbox-${item.type}-${item.id}`}
                        className='line-clamp-1 xl:min-w-[80px] xl:max-w-[100px] cursor-pointer'
                        title={`${item?.frames}`}>
                        {" "}
                        {item?.frames}
                      </label>
                    </td>
                    <td className='font-normal cursor-pointer'>
                      <label
                        htmlFor={`checkbox-${item.type}-${item.id}`}
                        className='line-clamp-1 min-w-[90px] max-w-[110px] cursor-pointer'
                        title={`${FormatSizeUnitsSpace(item?.size)}`}>
                        {" "}
                        {FormatSizeUnitsSpace(item?.size)}
                      </label>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <PaginationFooter />
        </>
      ) : (
        <NoRowsOverlay />
      )}
    </div>
  )
}

const CustomCheckBox = ({ checkBoxId, setSelectedData, selectedData, item }) => {
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    const isSelected = selectedData.some((val) => val.id === item.id && val.type === item.type)
    if (isSelected) {
      document.getElementById(`table-row-${item.id}-${item.type}`).classList.add("bg-[#e7f9ff]")
    } else {
      document.getElementById(`table-row-${item.id}-${item.type}`).classList.remove("bg-[#e7f9ff]")
    }
    setIsChecked(isSelected)
  }, [selectedData, item, isChecked])

  const handleSelection = () => {
    const checkbox = document.getElementById(`checkbox-${checkBoxId}`)
    if (checkbox.checked) {
      document.getElementById(`table-row-${item.id}-${item.type}`).classList.add("bg-[#e7f9ff]")
      setIsChecked(true)

      const isStudyAlreadySelected = selectedData.find((val) => val.id === item.id && val.type === item.type)
      if (!isStudyAlreadySelected) setSelectedData((prev) => [...prev, item])
    } else {
      setIsChecked(false)
      document.getElementById(`table-row-${item.id}-${item.type}`).classList.remove("bg-[#e7f9ff]")

      let itemList = selectedData.filter((val) => !(val.id === item.id && val.type === item.type))
      setSelectedData(itemList)
    }
  }

  return (
    <label onClick={handleSelection} htmlFor={`checkbox-${checkBoxId}`}>
      {isChecked ? <IconCheckBoxChecked height={20} width={20} /> : <IconCheckBox height={20} width={20} />}

      <input type='checkbox' className='hidden' id={`checkbox-${checkBoxId}`} />
    </label>
  )
}

const ActionItem = ({ item, handleAction, setRenameFolder, checkBoxId }) => {
  let { type } = item
  const user = useRecoilValue(authAtom)
  const checkFreeze = () => {
    if (parseInt(user.usedSpace) > parseInt(user.totalSpace) || user.freezeAccount) return true
    else return false
  }
  if (type === "folder")
    return (
      <>
        <div className='flex  items-center relative'>
          <StarAction study={item} />
          <AnonyomizeStatus study={item} />
          <div className='m-dicom-5'></div>
          <img
            src='\images\dashboard\file_system\folder_icon.png'
            className={`${IsXlScreen() ? "h-[36px]" : "h-[45px]"} `}
            alt='folderIcon'
          />
          {!checkFreeze() && (
            <FolderOptionsListView setRenameFolder={setRenameFolder} handleAction={handleAction} meta={item} />
          )}
        </div>
      </>
    )
  else
    return (
      <>
        <div className='flex folder items-center '>
          <StarAction study={item} />
          <AnonyomizeStatus study={item} />
          <div className='m-dicom-5'></div>
          <StudyImagePreview checkFreeze={checkFreeze()} study={item} />
          {!checkFreeze() && <FileOptionsListView handleMoveAndCopy={handleAction} study={item} />}
        </div>
      </>
    )
}
