import { useFormik } from "formik";
import React from "react";
import { AnonymizeRadioButton } from "../../../components/DesignSystem/inputs";
import AnonymizedWhenSharing from "./Anonymize/AnonymizedWhenSharing";
import FullyAnonymized from "./Anonymize/FullyAnonymized";
import Original from "./Anonymize/Original";
import { useRecoilState } from "recoil";
import { UserStudies } from "../../../atom/userStudies";

let AnonymizeData = [
  {
    id: 3,
    title: "Original",
    description:
      "This feature keeps your files in its original format in DICOMWallet’s secure servers. You can edit or anonymize any data later.",
  },
  {
    id: 1,
    title: "Anonymized when sharing",
    description:
      "This feature removes all information attached to DICOM images and allows the user to share images anonymously. However, DICOMWallet keeps a copy of patient information for you for your future reference. You can access the information in View Study or All Studies page.",
  },
  {
    id: 2,
    title: "Fully Anonymized",
    description:
      "This feature removes all patient information attached to DICOM images. Studies are assigned a randomly selected name and ID. This way, sensitive patient information is never stored on the DICOMWallet platform.",
  },
];

const AnonymizeStudy = ({ study,setStudy, modalId }) => {
  const [studies,setStudies]= useRecoilState(UserStudies)
  const initialValues = {
    anonymize: study.anonymization === 0 ? 3 : study.anonymization,
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
  });

//   const setStudy=(updatedStudy)=>{
// console.log(updatedStudy)
// console.log(userStudies.studies)
// const studyId= userStudies.studies.indexOf((item)=>item.id===updatedStudy.id)
// console.log(studyId)
//   }

  return (
    <>
      <AnonymizeRadioButton
        data={AnonymizeData}
        nameId={"anonymize"}
        formik={formik}
      />
      <div>
        {formik.values.anonymize ? (
          <>
            {parseInt(formik.values.anonymize) === 2 ? (
              <FullyAnonymized setUpdatedStudies={setStudies} originalStudies={studies} study={study} setStudyPage={setStudy} modalId={modalId} />
            ) : (
              ""
            )}
            {parseInt(formik.values.anonymize) === 1 ? (
              <AnonymizedWhenSharing
              setUpdatedStudies={setStudies} originalStudies={studies} 
                study={study} setStudyPage={setStudy}
                modalId={modalId}
              />
            ) : (
              ""
            )}
            {parseInt(formik.values.anonymize) === 3 ? (
              <Original  setUpdatedStudies={setStudies} originalStudies={studies}  study={study} setStudyPage={setStudy} modalId={modalId} />
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default AnonymizeStudy;
