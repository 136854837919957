import { atom } from "recoil";

export const StudyShareByLink = atom({
    key: "studyShareByLink",
    default: {
        studyCode:"",
        accessLevel:-1,
        linkId:"",
        viewId:""
    },
});
export const FolderShared= atom({
    key: "folderShare",
    default: {
        studyCode:"",
        accessLevel:-1,
    },
});