import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

import {
  DicomButton,
  DicomButtonOutline,
} from "../../../components/DesignSystem/buttons";
import { Input } from "../../../components/DesignSystem/inputs";
import { fetchData } from "../../../services/fetching";

const NewFolder = ({ modalId, setFoldersData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    name: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Folder name is required"),
  });
  const onSubmit = () => {
    setIsLoading(true);
    fetchData("POST", "/folder", formik.values)
      .then((res) => {
        if (res.data.data.folder) {
          fetchData("GET", "/folder").then((res) =>
            setFoldersData(res.data.data.folders)
          );
          formik.resetForm();
          document.getElementById(modalId).checked = false;
          toast.success("Your folder has been created successfully");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <>
      <div className="space-y-dicom-15">
        <Input label={"Folder name"} formik={formik} nameId={"name"} />
        <div className="flex justify-center gap-5 w-full">
          <DicomButtonOutline
            onClick={() => (document.getElementById(modalId).checked = false)}
            btnClass={
              "!border-dicom-secondary-100 !text-dicom-secondary-100 hover:!bg-dicom-secondary-100/5"
            }
            title={"Cancel"}
          />
          <DicomButton
            isLoading={isLoading}
            onClick={onSubmit}
            btnClass={
              "!border-dicom-secondary-100 !bg-dicom-secondary-100 !text-white"
            }
            title={"Create folder"}
          />
        </div>
      </div>
    </>
  );
};

export default NewFolder;
