import React from "react";

let data = [
  {
    id: 0,
    title: "Upload and storage",
    image: "/images/cards/UPLOAD-AND-STORE.png",
    description:
      "You can upload, organize and store your medical files easily and free of charge. ",
  },
  {
    id: 1,
    title: "Share",
    image: "/images/cards/SHARE.png",
    description:
      "With only one click, you can share your medical image/file format. Be it an MRI, CT scan, Xray or anything else. ",
  },
  {
    id: 2,
    title: "Anonymize",
    image: "/images/cards/ANONYMIZE.png",
    description:
      "You can anonymize patient data as you prefer before storing or when sharing it.",
  },
  {
    id: 3,
    title: "View",
    image: "/images/cards/VIEW.png",
    description:
      "You can view images using our DICOM Viewer equipped with a range of functionalities from image manipulation to advanced visualization ",
  },
];

const CardsSections = () => {
  return (
    <>
      <div className="grid grid-cols-6 lg:grid-cols-12 gap-dicom-30">
        {data.map((item) => (
          <div
            key={item.id}
            className="relative space-y-dicom-15 transition-all duration-300 ease-in-out flex flex-col items-center col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-3 xl:col-span-3 px-dicom-30 py-[60px] rounded-dicom-10 bg-[#485AFE] text-white"
          >
            <img className="w-[148px]" src={item.image} alt="" />
            <div className="flex items-center space-x-[5px] text-dicom-body1 font-bold">
              <p>{item.title}</p>
            </div>
            <div className="flex flex-col items-center">
              <p className="text-sm font-semibold text-white">
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CardsSections;
