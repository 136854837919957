import React from "react"
import OhifViewer from "../../pages/dashboard/viewer/DCMViewerPage"
import { SetLocalStorage } from "../../services/LocalStorage"
import { DicomButton } from "../DesignSystem/buttons"

const OhifViewerSection = ({ study, OpenViewer, setOpenViewer }) => {
  return (
    <>
      {OpenViewer ? (
        <div className='fixed z-30 left-0 lg:left-[74px] top-0 bottom-0 right-0 h-screen bg-white'>
          <div className='!relative w-full h-screen'>
            <OhifViewer study={study} setOpenViewer={setOpenViewer} code={study?.orthanc_viewer_id} />
          </div>
        </div>
      ) : (
        ""
      )}

      <DicomButton
        onClick={() => {
          setOpenViewer(true)
          SetLocalStorage("viIsOp", true)
        }}
        btnClass={"!w-full"}
        iconL={
          <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M12.5 9C10.8431 9 9.5 10.3431 9.5 12C9.5 13.6569 10.8431 15 12.5 15C14.1569 15 15.5 13.6569 15.5 12C15.5 10.3431 14.1569 9 12.5 9ZM11.5 12C11.5 11.4477 11.9477 11 12.5 11C13.0523 11 13.5 11.4477 13.5 12C13.5 12.5523 13.0523 13 12.5 13C11.9477 13 11.5 12.5523 11.5 12Z'
              fill='white'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M22.33 11.2807C20.042 7.15186 16.3122 5 12.5 5C8.68777 5 4.95796 7.15186 2.67003 11.2807C2.44637 11.6844 2.44361 12.1821 2.66029 12.5876C4.91183 16.8013 8.6628 19 12.5 19C16.3372 19 20.0882 16.8013 22.3397 12.5876C22.5564 12.1821 22.5536 11.6844 22.33 11.2807ZM12.5 17C9.56097 17 6.54052 15.3724 4.59173 11.9487C6.56862 8.59614 9.57319 7 12.5 7C15.4268 7 18.4314 8.59614 20.4083 11.9487C18.4595 15.3724 15.439 17 12.5 17Z'
              fill='white'
            />
          </svg>
        }
        title={"View in DICOM viewer"}
      />
    </>
  )
}

export default OhifViewerSection
