import React from 'react';
import { Link } from 'react-router-dom';

const DicomButtonN = ({ type, title, onClick, btnClass, isLoading, disabled, iconR, iconL }) => {
  return (
    <>
      <button
        type={type}
        onClick={onClick}
        className={`${btnClass} ${
          disabled ? 'opacity-50 btn-disabled' : ''
        } !capitalize flex text-white items-center btn !px-[12px] bg-transparent !border-0 hover:bg-dicom-on-surface-100/10 !rounded-dicom-10`}
      >
        {isLoading ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.5 14.25C3.90326 14.25 3.33097 14.0129 2.90901 13.591C2.48705 13.169 2.25 12.5967 2.25 12C2.25 11.4033 2.48705 10.831 2.90901 10.409C3.33097 9.98705 3.90326 9.75 4.5 9.75C5.09674 9.75 5.66903 9.98705 6.09099 10.409C6.51295 10.831 6.75 11.4033 6.75 12C6.75 12.5967 6.51295 13.169 6.09099 13.591C5.66903 14.0129 5.09674 14.25 4.5 14.25ZM12 14.25C11.4033 14.25 10.831 14.0129 10.409 13.591C9.98705 13.169 9.75 12.5967 9.75 12C9.75 11.4033 9.98705 10.831 10.409 10.409C10.831 9.98705 11.4033 9.75 12 9.75C12.5967 9.75 13.169 9.98705 13.591 10.409C14.0129 10.831 14.25 11.4033 14.25 12C14.25 12.5967 14.0129 13.169 13.591 13.591C13.169 14.0129 12.5967 14.25 12 14.25ZM19.5 14.25C18.9033 14.25 18.331 14.0129 17.909 13.591C17.4871 13.169 17.25 12.5967 17.25 12C17.25 11.4033 17.4871 10.831 17.909 10.409C18.331 9.98705 18.9033 9.75 19.5 9.75C20.0967 9.75 20.669 9.98705 21.091 10.409C21.5129 10.831 21.75 11.4033 21.75 12C21.75 12.5967 21.5129 13.169 21.091 13.591C20.669 14.0129 20.0967 14.25 19.5 14.25Z"
              fill="white"
            />
          </svg>
        ) : (
          <div className="flex !items-center !justify-center gap-dicom-10">
            {iconL ? iconL : ''}
            {title ? <span>{title}</span> : ''}
            {iconR ? iconR : ''}
          </div>
        )}
      </button>
    </>
  );
};

const DicomButtonLink2 = ({ title, url, btnClass, isLoading, disabled, iconR, iconL }) => {
  return (
    <>
      <a
        download
        href={url}
        className={`${btnClass} ${
          disabled ? 'opacity-50 btn-disabled' : ''
        } !capitalize flex text-white items-center btn !px-[12px] bg-transparent !border-0 hover:bg-dicom-on-surface-100/10 !rounded-dicom-10`}
      >
        {isLoading ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.5 14.25C3.90326 14.25 3.33097 14.0129 2.90901 13.591C2.48705 13.169 2.25 12.5967 2.25 12C2.25 11.4033 2.48705 10.831 2.90901 10.409C3.33097 9.98705 3.90326 9.75 4.5 9.75C5.09674 9.75 5.66903 9.98705 6.09099 10.409C6.51295 10.831 6.75 11.4033 6.75 12C6.75 12.5967 6.51295 13.169 6.09099 13.591C5.66903 14.0129 5.09674 14.25 4.5 14.25ZM12 14.25C11.4033 14.25 10.831 14.0129 10.409 13.591C9.98705 13.169 9.75 12.5967 9.75 12C9.75 11.4033 9.98705 10.831 10.409 10.409C10.831 9.98705 11.4033 9.75 12 9.75C12.5967 9.75 13.169 9.98705 13.591 10.409C14.0129 10.831 14.25 11.4033 14.25 12C14.25 12.5967 14.0129 13.169 13.591 13.591C13.169 14.0129 12.5967 14.25 12 14.25ZM19.5 14.25C18.9033 14.25 18.331 14.0129 17.909 13.591C17.4871 13.169 17.25 12.5967 17.25 12C17.25 11.4033 17.4871 10.831 17.909 10.409C18.331 9.98705 18.9033 9.75 19.5 9.75C20.0967 9.75 20.669 9.98705 21.091 10.409C21.5129 10.831 21.75 11.4033 21.75 12C21.75 12.5967 21.5129 13.169 21.091 13.591C20.669 14.0129 20.0967 14.25 19.5 14.25Z"
              fill="white"
            />
          </svg>
        ) : (
          <div className="flex !items-center !justify-center gap-dicom-10">
            {iconL ? iconL : ''}
            {title ? <span>{title}</span> : ''}
            {iconR ? iconR : ''}
          </div>
        )}
      </a>
    </>
  );
};

const DicomButtonNLabel = ({
  htmlFor,
  title,
  onClick,
  btnClass,
  isLoading,
  disabled,
  iconR,
  iconL
}) => {
  return (
    <>
      <label
        htmlFor={htmlFor}
        onClick={onClick}
        className={`${btnClass} ${
          disabled ? 'opacity-50 btn-disabled' : ''
        } !capitalize flex text-white items-center btn !px-[12px] bg-transparent !border-0 hover:bg-dicom-on-surface-100/10 !rounded-dicom-10`}
      >
        {isLoading ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.5 14.25C3.90326 14.25 3.33097 14.0129 2.90901 13.591C2.48705 13.169 2.25 12.5967 2.25 12C2.25 11.4033 2.48705 10.831 2.90901 10.409C3.33097 9.98705 3.90326 9.75 4.5 9.75C5.09674 9.75 5.66903 9.98705 6.09099 10.409C6.51295 10.831 6.75 11.4033 6.75 12C6.75 12.5967 6.51295 13.169 6.09099 13.591C5.66903 14.0129 5.09674 14.25 4.5 14.25ZM12 14.25C11.4033 14.25 10.831 14.0129 10.409 13.591C9.98705 13.169 9.75 12.5967 9.75 12C9.75 11.4033 9.98705 10.831 10.409 10.409C10.831 9.98705 11.4033 9.75 12 9.75C12.5967 9.75 13.169 9.98705 13.591 10.409C14.0129 10.831 14.25 11.4033 14.25 12C14.25 12.5967 14.0129 13.169 13.591 13.591C13.169 14.0129 12.5967 14.25 12 14.25ZM19.5 14.25C18.9033 14.25 18.331 14.0129 17.909 13.591C17.4871 13.169 17.25 12.5967 17.25 12C17.25 11.4033 17.4871 10.831 17.909 10.409C18.331 9.98705 18.9033 9.75 19.5 9.75C20.0967 9.75 20.669 9.98705 21.091 10.409C21.5129 10.831 21.75 11.4033 21.75 12C21.75 12.5967 21.5129 13.169 21.091 13.591C20.669 14.0129 20.0967 14.25 19.5 14.25Z"
              fill="white"
            />
          </svg>
        ) : (
          <div className="flex !items-center !justify-center gap-dicom-10">
            {iconL ? iconL : ''}
            {title ? <span>{title}</span> : ''}
            {iconR ? iconR : ''}
          </div>
        )}
      </label>
    </>
  );
};

const DicomIconBtn = ({ icon, onClick, tooltip, className }) => {
  return (
    <>
      <button className={`tooltip ${className}`} onClick={onClick} data-tip={tooltip}>
        {icon}
      </button>
    </>
  );
};

const DicomButton = ({ type, title, onClick, btnClass, isLoading, disabled, iconR, iconL }) => {
  return (
    <>
      <button
        type={type}
        onClick={onClick}
        className={`${btnClass} ${
          disabled ? 'opacity-50 btn-disabled' : ''
        } !capitalize flex text-white items-center btn px-6 !border-0 bg-dicom-primary-100 border-transparent hover:bg-dicom-primary-hover-100 hover:border-dicom-primary-hover-100 !rounded-dicom-10`}
      >
        {isLoading ? (
          <svg
            className="text-white animate-spin"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_801_5077)">
              <path
                d="M16.8396 21.9227C17.072 22.3992 16.8753 22.9778 16.3817 23.1714C14.7785 23.8002 13.055 24.078 11.3281 23.9812C9.28099 23.8664 7.29743 23.229 5.56664 22.1298C3.83586 21.0305 2.41559 19.5062 1.44131 17.7021C0.467042 15.898 -0.0287318 13.8744 0.00128644 11.8243C0.0313048 9.77419 0.586114 7.76596 1.61279 5.99119C2.63946 4.21641 4.10376 2.7343 5.86598 1.68623C7.62821 0.638166 9.62958 0.0591049 11.6792 0.00429001C13.4082 -0.04195 15.1228 0.286166 16.7069 0.961651C17.1946 1.16961 17.3743 1.75374 17.1281 2.22326C16.8818 2.69279 16.3029 2.86912 15.812 2.66861C14.5211 2.14126 13.1314 1.88614 11.7305 1.9236C10.0088 1.96965 8.32769 2.45606 6.84742 3.33644C5.36715 4.21681 4.13715 5.46179 3.27474 6.9526C2.41234 8.44341 1.9463 10.1303 1.92108 11.8524C1.89587 13.5745 2.31232 15.2744 3.1307 16.7898C3.94909 18.3052 5.14212 19.5857 6.59598 20.509C8.04984 21.4323 9.71603 21.9678 11.4356 22.0642C12.8348 22.1427 14.2314 21.9283 15.5372 21.439C16.0337 21.2529 16.6072 21.4462 16.8396 21.9227Z"
                fill="currentColor"
              />
              <path
                d="M22.7657 14.4446C23.2827 14.562 23.8011 14.2382 23.8768 13.7134C24.1505 11.8158 23.9662 9.87512 23.3329 8.05557C22.6996 6.23601 21.6391 4.60027 20.2463 3.28267C19.8611 2.91831 19.2538 2.98635 18.9214 3.3994C18.5889 3.81244 18.6581 4.41368 19.0376 4.78386C20.1531 5.87179 21.0045 7.20688 21.5196 8.68669C22.0346 10.1665 22.1962 11.7417 21.9972 13.2871C21.9295 13.813 22.2486 14.3272 22.7657 14.4446Z"
                fill="currentColor"
                fillOpacity="0.3"
              />
              <path
                d="M18.0967 21.204C18.3895 21.646 18.9879 21.7699 19.4051 21.4428C20.7442 20.3927 21.8448 19.0696 22.6334 17.5617C22.8792 17.0919 22.6483 16.526 22.1604 16.3186C21.6724 16.1112 21.1127 16.3414 20.8599 16.8074C20.2275 17.9728 19.3713 19.0022 18.3405 19.8362C17.9283 20.1697 17.8039 20.762 18.0967 21.204Z"
                fill="currentColor"
                fillOpacity="0.3"
              />
            </g>
            <defs>
              <clipPath id="clip0_801_5077">
                <rect width="24" height="24" fill="currentColor" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <div className="flex !items-center !justify-center gap-dicom-10">
            {iconL ? iconL : ''}
            {title ? <span>{title}</span> : ''}
            {iconR ? iconR : ''}
          </div>
        )}
      </button>
    </>
  );
};

const DicomButtonListAction = ({
  type,
  title,
  onClick,
  btnClass,
  isLoading,
  disabled,
  iconR,
  iconL,
  htmlFor,
  titleAttr,
  id
}) => {
  return (
    <>
      <label
      id={id}
        type={type}
        onClick={onClick}
        htmlFor={htmlFor}
        title={titleAttr}
        className={`${
          disabled ? 'opacity-40 btn-disabled' : ''
        } !capitalize flex text-dicom-secondary-100 grow  items-center btn px-0 xl:px-3 !border-0 bg-transparent border-transparent !rounded-dicom-10 !hover:bg-dicom-secondary2-100 ${btnClass}`}
      >
        {isLoading ? (
          <svg
            className="animate-spin"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_801_5077)">
              <path
                d="M16.8396 21.9227C17.072 22.3992 16.8753 22.9778 16.3817 23.1714C14.7785 23.8002 13.055 24.078 11.3281 23.9812C9.28099 23.8664 7.29743 23.229 5.56664 22.1298C3.83586 21.0305 2.41559 19.5062 1.44131 17.7021C0.467042 15.898 -0.0287318 13.8744 0.00128644 11.8243C0.0313048 9.77419 0.586114 7.76596 1.61279 5.99119C2.63946 4.21641 4.10376 2.7343 5.86598 1.68623C7.62821 0.638166 9.62958 0.0591049 11.6792 0.00429001C13.4082 -0.04195 15.1228 0.286166 16.7069 0.961651C17.1946 1.16961 17.3743 1.75374 17.1281 2.22326C16.8818 2.69279 16.3029 2.86912 15.812 2.66861C14.5211 2.14126 13.1314 1.88614 11.7305 1.9236C10.0088 1.96965 8.32769 2.45606 6.84742 3.33644C5.36715 4.21681 4.13715 5.46179 3.27474 6.9526C2.41234 8.44341 1.9463 10.1303 1.92108 11.8524C1.89587 13.5745 2.31232 15.2744 3.1307 16.7898C3.94909 18.3052 5.14212 19.5857 6.59598 20.509C8.04984 21.4323 9.71603 21.9678 11.4356 22.0642C12.8348 22.1427 14.2314 21.9283 15.5372 21.439C16.0337 21.2529 16.6072 21.4462 16.8396 21.9227Z"
                fill="currentColor"
              />
              <path
                d="M22.7657 14.4446C23.2827 14.562 23.8011 14.2382 23.8768 13.7134C24.1505 11.8158 23.9662 9.87512 23.3329 8.05557C22.6996 6.23601 21.6391 4.60027 20.2463 3.28267C19.8611 2.91831 19.2538 2.98635 18.9214 3.3994C18.5889 3.81244 18.6581 4.41368 19.0376 4.78386C20.1531 5.87179 21.0045 7.20688 21.5196 8.68669C22.0346 10.1665 22.1962 11.7417 21.9972 13.2871C21.9295 13.813 22.2486 14.3272 22.7657 14.4446Z"
                fill="currentColor"
                fillOpacity="0.3"
              />
              <path
                d="M18.0967 21.204C18.3895 21.646 18.9879 21.7699 19.4051 21.4428C20.7442 20.3927 21.8448 19.0696 22.6334 17.5617C22.8792 17.0919 22.6483 16.526 22.1604 16.3186C21.6724 16.1112 21.1127 16.3414 20.8599 16.8074C20.2275 17.9728 19.3713 19.0022 18.3405 19.8362C17.9283 20.1697 17.8039 20.762 18.0967 21.204Z"
                fill="currentColor"
                fillOpacity="0.3"
              />
            </g>
            <defs>
              <clipPath id="clip0_801_5077">
                <rect width="24" height="24" fill="currentColor" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <div className="flex !items-center !justify-center gap-dicom-10">
            {iconL ? iconL : ''}
            {title ? <span className="hidden xl:inline">{title}</span> : ''}
            {iconR ? iconR : ''}
          </div>
        )}
      </label>
    </>
  );
};

const DicomButtonOutline = ({ type, title, onClick, btnClass, isLoading, iconR, iconL }) => {
  return (
    <>
      <button
        type={type}
        onClick={onClick}
        className={`${btnClass} !capitalize btn btn-outline px-6 border-2 text-dicom-primary-100 border-transparent hover:bg-dicom-primary-100 hover:border-dicom-primary-100 !rounded-dicom-10`}
      >
        {isLoading ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.5 14.25C3.90326 14.25 3.33097 14.0129 2.90901 13.591C2.48705 13.169 2.25 12.5967 2.25 12C2.25 11.4033 2.48705 10.831 2.90901 10.409C3.33097 9.98705 3.90326 9.75 4.5 9.75C5.09674 9.75 5.66903 9.98705 6.09099 10.409C6.51295 10.831 6.75 11.4033 6.75 12C6.75 12.5967 6.51295 13.169 6.09099 13.591C5.66903 14.0129 5.09674 14.25 4.5 14.25ZM12 14.25C11.4033 14.25 10.831 14.0129 10.409 13.591C9.98705 13.169 9.75 12.5967 9.75 12C9.75 11.4033 9.98705 10.831 10.409 10.409C10.831 9.98705 11.4033 9.75 12 9.75C12.5967 9.75 13.169 9.98705 13.591 10.409C14.0129 10.831 14.25 11.4033 14.25 12C14.25 12.5967 14.0129 13.169 13.591 13.591C13.169 14.0129 12.5967 14.25 12 14.25ZM19.5 14.25C18.9033 14.25 18.331 14.0129 17.909 13.591C17.4871 13.169 17.25 12.5967 17.25 12C17.25 11.4033 17.4871 10.831 17.909 10.409C18.331 9.98705 18.9033 9.75 19.5 9.75C20.0967 9.75 20.669 9.98705 21.091 10.409C21.5129 10.831 21.75 11.4033 21.75 12C21.75 12.5967 21.5129 13.169 21.091 13.591C20.669 14.0129 20.0967 14.25 19.5 14.25Z"
              fill="white"
            />
          </svg>
        ) : (
          <div className="flex !items-center !justify-center gap-dicom-10">
            {iconL ? iconL : ''}
            {title ? <span>{title}</span> : ''}
            {iconR ? iconR : ''}
          </div>
        )}
      </button>
    </>
  );
};

const DicomButtonLabel = ({
  htmlFor,
  title,
  onClick,
  btnClass,
  isLoading,
  disabled,
  iconR,
  iconL,
  titleAttr
}) => {
  return (
    <>
      <label
        htmlFor={htmlFor}
        onClick={onClick}
        title={titleAttr}
        className={`${btnClass} ${
          disabled ? 'opacity-50 btn-disabled' : ''
        } !capitalize flex text-white items-center btn px-6 !border-0 bg-dicom-primary-100 border-transparent hover:bg-dicom-primary-hover-100 hover:border-dicom-primary-hover-100 !rounded-dicom-10`}
      >
        {isLoading ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.5 14.25C3.90326 14.25 3.33097 14.0129 2.90901 13.591C2.48705 13.169 2.25 12.5967 2.25 12C2.25 11.4033 2.48705 10.831 2.90901 10.409C3.33097 9.98705 3.90326 9.75 4.5 9.75C5.09674 9.75 5.66903 9.98705 6.09099 10.409C6.51295 10.831 6.75 11.4033 6.75 12C6.75 12.5967 6.51295 13.169 6.09099 13.591C5.66903 14.0129 5.09674 14.25 4.5 14.25ZM12 14.25C11.4033 14.25 10.831 14.0129 10.409 13.591C9.98705 13.169 9.75 12.5967 9.75 12C9.75 11.4033 9.98705 10.831 10.409 10.409C10.831 9.98705 11.4033 9.75 12 9.75C12.5967 9.75 13.169 9.98705 13.591 10.409C14.0129 10.831 14.25 11.4033 14.25 12C14.25 12.5967 14.0129 13.169 13.591 13.591C13.169 14.0129 12.5967 14.25 12 14.25ZM19.5 14.25C18.9033 14.25 18.331 14.0129 17.909 13.591C17.4871 13.169 17.25 12.5967 17.25 12C17.25 11.4033 17.4871 10.831 17.909 10.409C18.331 9.98705 18.9033 9.75 19.5 9.75C20.0967 9.75 20.669 9.98705 21.091 10.409C21.5129 10.831 21.75 11.4033 21.75 12C21.75 12.5967 21.5129 13.169 21.091 13.591C20.669 14.0129 20.0967 14.25 19.5 14.25Z"
              fill="white"
            />
          </svg>
        ) : (
          <div className="flex !items-center !justify-center gap-dicom-10">
            {iconL ? iconL : ''}
            {title ? <span>{title}</span> : ''}
            {iconR ? iconR : ''}
          </div>
        )}
      </label>
    </>
  );
};

const DicomButtonOutlineLabel = ({
  type,
  title,
  onClick,
  htmlFor,
  btnClass,
  isLoading,
  iconR,
  iconL,
  titleAttr
}) => {
  return (
    <>
      <label
        onClick={onClick}
        type={type}
        title={titleAttr}
        htmlFor={htmlFor}
        className={`${btnClass} !capitalize btn btn-outline px-6 border-2 text-dicom-primary-100 border-transparent hover:bg-dicom-primary-100 hover:border-dicom-primary-100 !rounded-dicom-10`}
      >
        {isLoading ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.5 14.25C3.90326 14.25 3.33097 14.0129 2.90901 13.591C2.48705 13.169 2.25 12.5967 2.25 12C2.25 11.4033 2.48705 10.831 2.90901 10.409C3.33097 9.98705 3.90326 9.75 4.5 9.75C5.09674 9.75 5.66903 9.98705 6.09099 10.409C6.51295 10.831 6.75 11.4033 6.75 12C6.75 12.5967 6.51295 13.169 6.09099 13.591C5.66903 14.0129 5.09674 14.25 4.5 14.25ZM12 14.25C11.4033 14.25 10.831 14.0129 10.409 13.591C9.98705 13.169 9.75 12.5967 9.75 12C9.75 11.4033 9.98705 10.831 10.409 10.409C10.831 9.98705 11.4033 9.75 12 9.75C12.5967 9.75 13.169 9.98705 13.591 10.409C14.0129 10.831 14.25 11.4033 14.25 12C14.25 12.5967 14.0129 13.169 13.591 13.591C13.169 14.0129 12.5967 14.25 12 14.25ZM19.5 14.25C18.9033 14.25 18.331 14.0129 17.909 13.591C17.4871 13.169 17.25 12.5967 17.25 12C17.25 11.4033 17.4871 10.831 17.909 10.409C18.331 9.98705 18.9033 9.75 19.5 9.75C20.0967 9.75 20.669 9.98705 21.091 10.409C21.5129 10.831 21.75 11.4033 21.75 12C21.75 12.5967 21.5129 13.169 21.091 13.591C20.669 14.0129 20.0967 14.25 19.5 14.25Z"
              fill="white"
            />
          </svg>
        ) : (
          <div className="flex !items-center !justify-center gap-dicom-10">
            {iconL ? iconL : ''}
            {title ? <span>{title}</span> : ''}
            {iconR ? iconR : ''}
          </div>
        )}
      </label>
    </>
  );
};

const DicomButtonLink = ({ title, btnClass, url, iconR, iconL }) => {
  return (
    <>
      <Link
        to={url}
        className={`${btnClass} !flex !capitalize w-full lg:w-fit text-white space-x-dicom-10 items-center btn px-6 !border-0 bg-dicom-primary-100 border-dicom-primary-100 hover:bg-dicom-primary-hover-100 hover:border-dicom-primary-hover-100 !rounded-dicom-10`}
      >
        {iconL ? iconL : ''}
        {title ? <span>{title}</span> : ''}
        {iconR ? iconR : ''}
      </Link>
    </>
  );
};

const DicomButtonOutlineLink = ({ title, url, btnClass, isLoading, iconR, iconL }) => {
  return (
    <>
      <Link
        to={url}
        className={`${btnClass} !capitalize btn btn-outline px-6 border-2 text-dicom-primary-100 border-transparent hover:bg-dicom-primary-100 hover:border-dicom-primary-100 !rounded-dicom-10`}
      >
        {isLoading ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.5 14.25C3.90326 14.25 3.33097 14.0129 2.90901 13.591C2.48705 13.169 2.25 12.5967 2.25 12C2.25 11.4033 2.48705 10.831 2.90901 10.409C3.33097 9.98705 3.90326 9.75 4.5 9.75C5.09674 9.75 5.66903 9.98705 6.09099 10.409C6.51295 10.831 6.75 11.4033 6.75 12C6.75 12.5967 6.51295 13.169 6.09099 13.591C5.66903 14.0129 5.09674 14.25 4.5 14.25ZM12 14.25C11.4033 14.25 10.831 14.0129 10.409 13.591C9.98705 13.169 9.75 12.5967 9.75 12C9.75 11.4033 9.98705 10.831 10.409 10.409C10.831 9.98705 11.4033 9.75 12 9.75C12.5967 9.75 13.169 9.98705 13.591 10.409C14.0129 10.831 14.25 11.4033 14.25 12C14.25 12.5967 14.0129 13.169 13.591 13.591C13.169 14.0129 12.5967 14.25 12 14.25ZM19.5 14.25C18.9033 14.25 18.331 14.0129 17.909 13.591C17.4871 13.169 17.25 12.5967 17.25 12C17.25 11.4033 17.4871 10.831 17.909 10.409C18.331 9.98705 18.9033 9.75 19.5 9.75C20.0967 9.75 20.669 9.98705 21.091 10.409C21.5129 10.831 21.75 11.4033 21.75 12C21.75 12.5967 21.5129 13.169 21.091 13.591C20.669 14.0129 20.0967 14.25 19.5 14.25Z"
              fill="white"
            />
          </svg>
        ) : (
          <div className="flex !items-center !justify-center gap-dicom-10">
            {iconL ? iconL : ''}
            {title ? <span>{title}</span> : ''}
            {iconR ? iconR : ''}
          </div>
        )}
      </Link>
    </>
  );
};

const DicomButtonOutlineLinka = ({ title, url, btnClass, isLoading, iconR, iconL }) => {
  return (
    <>
      <a
        href={url}
        className={`${btnClass} !capitalize btn btn-outline px-6 border-2 text-dicom-primary-100 border-transparent hover:bg-dicom-primary-100 hover:border-dicom-primary-100 !rounded-dicom-10`}
      >
        {isLoading ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.5 14.25C3.90326 14.25 3.33097 14.0129 2.90901 13.591C2.48705 13.169 2.25 12.5967 2.25 12C2.25 11.4033 2.48705 10.831 2.90901 10.409C3.33097 9.98705 3.90326 9.75 4.5 9.75C5.09674 9.75 5.66903 9.98705 6.09099 10.409C6.51295 10.831 6.75 11.4033 6.75 12C6.75 12.5967 6.51295 13.169 6.09099 13.591C5.66903 14.0129 5.09674 14.25 4.5 14.25ZM12 14.25C11.4033 14.25 10.831 14.0129 10.409 13.591C9.98705 13.169 9.75 12.5967 9.75 12C9.75 11.4033 9.98705 10.831 10.409 10.409C10.831 9.98705 11.4033 9.75 12 9.75C12.5967 9.75 13.169 9.98705 13.591 10.409C14.0129 10.831 14.25 11.4033 14.25 12C14.25 12.5967 14.0129 13.169 13.591 13.591C13.169 14.0129 12.5967 14.25 12 14.25ZM19.5 14.25C18.9033 14.25 18.331 14.0129 17.909 13.591C17.4871 13.169 17.25 12.5967 17.25 12C17.25 11.4033 17.4871 10.831 17.909 10.409C18.331 9.98705 18.9033 9.75 19.5 9.75C20.0967 9.75 20.669 9.98705 21.091 10.409C21.5129 10.831 21.75 11.4033 21.75 12C21.75 12.5967 21.5129 13.169 21.091 13.591C20.669 14.0129 20.0967 14.25 19.5 14.25Z"
              fill="white"
            />
          </svg>
        ) : (
          <div className="flex !items-center !justify-center gap-dicom-10">
            {iconL ? iconL : ''}
            {title ? <span>{title}</span> : ''}
            {iconR ? iconR : ''}
          </div>
        )}
      </a>
    </>
  );
};

export {
  DicomButton,
  DicomIconBtn,
  DicomButtonN,
  DicomButtonNLabel,
  DicomButtonOutline,
  DicomButtonLink,
  DicomButtonLink2,
  DicomButtonOutlineLink,
  DicomButtonOutlineLinka,
  DicomButtonLabel,
  DicomButtonOutlineLabel,
  DicomButtonListAction
};
