import { useState } from "react";
import { NavLink } from "react-router-dom";
import { checkImageLoadingError } from "../../utils/functions";


const UserSection = ({ user, onClick, isMobile }) => {
 const [imgLoaderror,setImageLoadErr]=useState(false)
  return (
    <>
      <div
        className={`dropdown dropdown-end z-10 cursor-pointer ${
          isMobile ? "dropdown-top border p-4 rounded-dicom-10" : ""
        }`}
      >
        <div tabIndex="0" className="flex space-x-2">
          <img
            src={user?.image ? imgLoaderror?"/images/avatar-default.gif": user?.image : "/images/avatar-default.gif"}
            crossOrigin="anonymous"
            className="rounded-full !h-10 !w-10"
            alt=""
            onError={()=>checkImageLoadingError(setImageLoadErr)}
          />
          <div className="flex space-x-2 w-full items-center justify-between">
            <div className="flex flex-col">
              <h1 className="font-medium">{user.firstName}</h1>
              <p className="font-normal text-xs text-slate-500">{user.email}</p>
            </div>
            <svg
              className={`${isMobile ? "rotate-180" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 256 256"
            >
              <rect width="256" height="256" fill="none"></rect>
              <polyline
                points="208 96 128 176 48 96"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="16"
              ></polyline>
            </svg>
          </div>
        </div>
        <div
          tabIndex="0"
          className="space-y-4 dropdown-content menu p-4 bg-white border border-slate-200 rounded-xl w-72"
        >
          <div className="flex space-x-2">
            <img
            src={user?.image ? imgLoaderror?"/images/avatar-default.gif": user?.image : "/images/avatar-default.gif"}
            crossOrigin="anonymous"
              className="rounded-full !h-10 !w-10"
              alt=""
            />
            <div className="flex w-full items-center justify-between space-x-2">
              <div className="flex flex-col">
                <p className="!text-sm font-medium">{user.firstName}</p>
                <p className="font-normal text-xs text-slate-500">
                  {user.email}
                </p>
              </div>
            </div>
          </div>
          <div className="divide-y overflow-hidden bg-slate-50/50 rounded-lg divide-slate-100">
            <NavLink
              to={"/dashboard"}
              className="flex anim hover:bg-slate-100 p-4 items-center justify-between"
            >
              <div className=" flex items-center space-x-3">
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5 11C17.5 10.4477 17.0523 10 16.5 10C15.9477 10 15.5 10.4477 15.5 11H17.5ZM15.5 16C15.5 16.5523 15.9477 17 16.5 17C17.0523 17 17.5 16.5523 17.5 16H15.5ZM9.5 11C9.5 10.4477 9.05228 10 8.5 10C7.94772 10 7.5 10.4477 7.5 11H9.5ZM7.5 16C7.5 16.5523 7.94772 17 8.5 17C9.05228 17 9.5 16.5523 9.5 16H7.5ZM13.5 8C13.5 7.44772 13.0523 7 12.5 7C11.9477 7 11.5 7.44772 11.5 8H13.5ZM11.5 16C11.5 16.5523 11.9477 17 12.5 17C13.0523 17 13.5 16.5523 13.5 16H11.5ZM7.5 4H17.5V2H7.5V4ZM20.5 7V17H22.5V7H20.5ZM17.5 20H7.5V22H17.5V20ZM4.5 17V7H2.5V17H4.5ZM7.5 20C5.84315 20 4.5 18.6569 4.5 17H2.5C2.5 19.7614 4.73858 22 7.5 22V20ZM20.5 17C20.5 18.6569 19.1569 20 17.5 20V22C20.2614 22 22.5 19.7614 22.5 17H20.5ZM17.5 4C19.1569 4 20.5 5.34315 20.5 7H22.5C22.5 4.23858 20.2614 2 17.5 2V4ZM7.5 2C4.73858 2 2.5 4.23858 2.5 7H4.5C4.5 5.34315 5.84315 4 7.5 4V2ZM15.5 11V16H17.5V11H15.5ZM7.5 11V16H9.5V11H7.5ZM11.5 8V16H13.5V8H11.5Z"
                    fill="currentColor"
                  />
                </svg>

                <span className="text-sm">Dashboard</span>
              </div>
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.90991 19.92L15.4299 13.4C16.1999 12.63 16.1999 11.37 15.4299 10.6L8.90991 4.07999"
                  stroke="#24282D"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </NavLink>

            <button
              onClick={onClick}
              className="relative w-full flex hover:bg-slate-100 p-4 items-center justify-between"
            >
              <div className=" !text-red-500 flex items-center space-x-3">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.4399 14.62L19.9999 12.06L17.4399 9.5"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M9.76001 12.06H19.93"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M11.76 20C7.34001 20 3.76001 17 3.76001 12C3.76001 7 7.34001 4 11.76 4"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <span className="text-sm">Log Out</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const UserSectionMobile = ({ user, onClick, isMobile }) => {
  const [imgLoaderror,setImageLoadErr]=useState(false)

  return (
    <>
      <div
        className={`dropdown cursor-pointer ${
          isMobile ? "dropdown-top border p-4 rounded-dicom-10" : ""
        }`}
      >
        <div tabIndex="0" className="flex space-x-2">
          <img
            src={user?.image ? imgLoaderror?"/images/avatar-default.gif": user?.image : "/images/avatar-default.gif"}
            crossOrigin="anonymous"
            className="rounded-full !h-10 !w-10"
            alt=""
            onError={()=>checkImageLoadingError(setImageLoadErr)}

          />
          <div className="hidden space-x-2 w-full items-center justify-between">
            <div className="flex flex-col">
              <h1 className="font-medium">{user.firstName}</h1>
              <p className="font-normal text-xs text-slate-500">{user.email}</p>
            </div>
            <svg
              className={`${isMobile ? "rotate-180" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 256 256"
            >
              <rect width="256" height="256" fill="none"></rect>
              <polyline
                points="208 96 128 176 48 96"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="16"
              ></polyline>
            </svg>
          </div>
        </div>
        <div
          tabIndex="0"
          className="space-y-4 dropdown-content menu p-4 bg-white border border-slate-200 rounded-xl w-72"
        >
          <div className="flex space-x-2">
            <img
            src={user?.image ? imgLoaderror?"/images/avatar-default.gif": user?.image : "/images/avatar-default.gif"}
            crossOrigin="anonymous"
              className="rounded-full !h-10 !w-10"
              alt=""
            />
            <div className="flex w-full items-center justify-between space-x-2">
              <div className="flex flex-col">
                <h1 className="font-medium">{user.firstName}</h1>
                <p className="font-normal text-xs text-slate-500">
                  {user.email}
                </p>
              </div>
            </div>
          </div>
          <div className="divide-y overflow-hidden bg-slate-50/50 rounded-lg divide-slate-100">
            <NavLink
              to={"/dashboard"}
              className="flex anim hover:bg-slate-100 p-4 items-center justify-between"
            >
              <div className=" flex items-center space-x-3">
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5 11C17.5 10.4477 17.0523 10 16.5 10C15.9477 10 15.5 10.4477 15.5 11H17.5ZM15.5 16C15.5 16.5523 15.9477 17 16.5 17C17.0523 17 17.5 16.5523 17.5 16H15.5ZM9.5 11C9.5 10.4477 9.05228 10 8.5 10C7.94772 10 7.5 10.4477 7.5 11H9.5ZM7.5 16C7.5 16.5523 7.94772 17 8.5 17C9.05228 17 9.5 16.5523 9.5 16H7.5ZM13.5 8C13.5 7.44772 13.0523 7 12.5 7C11.9477 7 11.5 7.44772 11.5 8H13.5ZM11.5 16C11.5 16.5523 11.9477 17 12.5 17C13.0523 17 13.5 16.5523 13.5 16H11.5ZM7.5 4H17.5V2H7.5V4ZM20.5 7V17H22.5V7H20.5ZM17.5 20H7.5V22H17.5V20ZM4.5 17V7H2.5V17H4.5ZM7.5 20C5.84315 20 4.5 18.6569 4.5 17H2.5C2.5 19.7614 4.73858 22 7.5 22V20ZM20.5 17C20.5 18.6569 19.1569 20 17.5 20V22C20.2614 22 22.5 19.7614 22.5 17H20.5ZM17.5 4C19.1569 4 20.5 5.34315 20.5 7H22.5C22.5 4.23858 20.2614 2 17.5 2V4ZM7.5 2C4.73858 2 2.5 4.23858 2.5 7H4.5C4.5 5.34315 5.84315 4 7.5 4V2ZM15.5 11V16H17.5V11H15.5ZM7.5 11V16H9.5V11H7.5ZM11.5 8V16H13.5V8H11.5Z"
                    fill="currentColor"
                  />
                </svg>
                <span className="text-sm">Dashboard</span>
              </div>
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.90991 19.92L15.4299 13.4C16.1999 12.63 16.1999 11.37 15.4299 10.6L8.90991 4.07999"
                  stroke="#24282D"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </NavLink>

            <button
              onClick={onClick}
              className="relative w-full flex hover:bg-slate-100 p-4 items-center justify-between"
            >
              <div className=" !text-red-500 flex items-center space-x-3">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.4399 14.62L19.9999 12.06L17.4399 9.5"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M9.76001 12.06H19.93"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M11.76 20C7.34001 20 3.76001 17 3.76001 12C3.76001 7 7.34001 4 11.76 4"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <span className="text-sm">Log Out</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { UserSection, UserSectionMobile };
