import React, { useState } from "react"
import toast from "react-hot-toast"

import { DicomButton, DicomButtonOutline } from "../../../components/DesignSystem/buttons"
import { fetchData } from "../../../services/fetching"

const DeleteFolder = ({ modalId, item, setFoldersData }) => {
  const [isLoading, setIsLoading] = useState(false)
  const onSubmit = () => {
    setIsLoading(true)
    fetchData("DELETE", `/folder/${item?.id}`)
      .then((res) => {
        if (res.data.data.folder) {
          fetchData("GET", "/folder").then((res) => setFoldersData(res.data.data.folders))
          document.getElementById(modalId).checked = false
          toast.success("The selected folder has been successfully deleted")
        }
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
      })
  }
  return (
    <>
      <div className='space-y-dicom-15'>
        <p>
          Do you want to delete the <span className='font-bold'>{item.name}</span> folder?
        </p>
        <p className='text-dicom-caption-100'>Note: By deleting the folder, all its contents will be deleted!</p>
        <div className='flex justify-center gap-5 w-full'>
          <DicomButtonOutline
            onClick={() => (document.getElementById(modalId).checked = false)}
            btnClass={"!border-dicom-secondary-100 !text-dicom-secondary-100 hover:!bg-dicom-secondary-100/5"}
            title={"Cancel"}
          />
          <DicomButton
            isLoading={isLoading}
            onClick={onSubmit}
            btnClass={"!border-dicom-secondary-100 !bg-dicom-secondary-100 !text-white"}
            title={"Delete"}
          />
        </div>
      </div>
    </>
  )
}

export default DeleteFolder
