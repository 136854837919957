import React, { useState } from 'react';

export const Context = React.createContext({
  modalState: false,
  setModalState: () => {},
  shareInputValue: [],
  setShareInputValue: () => {}
});

export const ContextProvider = ({ children }) => {
  const [modalState, setModalState] = useState(false);
  const [shareInputValue, setShareInputValue] = useState([]);

  return (
    <Context.Provider value={{ modalState, setModalState, shareInputValue, setShareInputValue }}>
      {children}
    </Context.Provider>
  );
};
