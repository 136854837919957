import React from "react"
import { NavLink } from "react-router-dom"
import UseDocumentTitle from "../../utils/useDocumentTitle"
import { GetLocalStorage, SetLocalStorage } from "../../services/LocalStorage"

const SidebarItem = ({ data, url, Collaps, menu }) => {
  let filtredUrl = url.find((url) => url === data.url)
  if (url.includes("folders") || url.includes("folder")) {
    const index = GetLocalStorage("IndexRoute") || "my-dicom-wallet"
    if (index !== null && index.replaceAll("-", " ") === data.title.toLowerCase()) {
      filtredUrl = GetLocalStorage("IndexRoute")
    }
  }
  if (data.url === filtredUrl) {
    UseDocumentTitle(`Dashboard | ${data.title}`, true)
  }

  return (
    <>
      <NavLink
        id={data.url}
        to={`/dashboard/${data.url}`}
        title={data.titleAttr}
        onClick={() => {
          SetLocalStorage("IndexRoute", data.url)
        }}
        className={`flex items-center gap-dicom-15 px-dicom-15 py-dicom-10 rounded-dicom-10 text-white hover:bg-white/10 cursor-pointer
        ${filtredUrl ? "bg-dicom-secondary-100 hover:bg-dicom-secondary-hover-100 font-bold" : ""}
        `}>
        {data.icons}
        {menu ? (
          <span className='text-dicom-body2'>{data.title}</span>
        ) : Collaps ? (
          ""
        ) : (
          <span className='text-dicom-body2'>{data.title}</span>
        )}
      </NavLink>
    </>
  )
}

export default SidebarItem
