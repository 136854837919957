import React from "react";

const FolderCard = ({
  className,
  folderSelected,
  folder,
  onChange,
}) => {

  const svgIconPath = folder.id ? '/images/Folders.svg' : '/images/nofolder.svg';
  return (
    <>
      <input
        className={"sr-only peer"}
        type={"radio"}
        id={folder.id}
        name={"folderSelected"}
        value={folder.id}
        onChange={onChange}
        checked={folderSelected === folder.id}
      />
      <label
        htmlFor={folder.id}
        className={`${className} ${folderSelected === folder.id
            ? "!border-dicom-secondary-100 text-slate-900 bg-dicom-secondary-100/5"
            : ""
          } p-4 lg:px-4 lg:py-1   bg-white border-2 border-slate-200 rounded-dicom-10 cursor-pointer focus:outline-none hover:bg-gray-50`}
      >
        <img className="!w-24 !h-[102px]" src={svgIconPath} alt="" />
        <div className="flex items-center justify-between">
          <p className="!truncate text-[12px]">{folder.name}</p>
        </div>
        {folder?.studiesCount ? (
          <div className="flex items-center w-full justify-between">
            <p className="text-[12px] truncate">Study</p>
            <p className="text-[12px] truncate" title="Number of studies in the current folder">{folder?.studiesCount}</p>
          </div>
        ) : ""}
      </label>
    </>
  );
};

export default FolderCard;
