import React, { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { fetchData } from "../../services/fetching"
import { GetLocalStorage, RemoveLocalStorage, SetLocalStorage } from "../../services/LocalStorage"

// Recoil
import { useSetRecoilState } from "recoil"
import { authAtom } from "../../atom/auth"

const SocialLoginSuccess = () => {
  const [token, setToken] = useState(window.location.search.split("=")[1])
  const navigate = useNavigate()
  const setUser = useSetRecoilState(authAtom)

  useEffect(() => {
    setToken(window.location.search.split("=")[1])
    SetLocalStorage("login_key", token)
    fetchData("GET", "/user/profile")
      .then((res) => {
        setUser(res?.data?.data?.user)

        SetLocalStorage("login_user", res?.data?.data?.user)
        let loginRoute = GetLocalStorage("after_Route")
          ? GetLocalStorage("after_Route")
          : res?.data?.data?.user?.role === "Admin"
          ? "/dashboard/reports"
          : "/dashboard"
        RemoveLocalStorage("after_Route")
        navigate(loginRoute, { replace: true })
      })
      .catch((err) => {
        if (err) {
          toast.error(err?.response?.data?.message)
          RemoveLocalStorage("login_key")
          RemoveLocalStorage("login_user")
          navigate("/", { replace: true })
        }
        if (err.response.status === 500) {
          toast.error("Internal Server Error")
        }
      })
  }, [token, navigate, setUser])

  return (
    <>
      <div className='flex h-screen items-center justify-center'>
        <p className='text-dicom-headline2 text-dicom-primary-100'>please wait...</p>
        <svg
          className='animate-spin h-5 w-5 text-white'
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'>
          <circle class='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
          <path
            class='opacity-75'
            fill='currentColor'
            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
        </svg>
      </div>
    </>
  )
}

export default SocialLoginSuccess
