import React, { useEffect, useState } from "react"

import { DataGrid } from "@mui/x-data-grid"

import { DicomButtonLabel, DicomButtonLink } from "../../../components/DesignSystem/buttons"

// components
import AdminLayout from "../../../containers/AdminLayout"
import { fetchData } from "../../../services/fetching"
import { FormatSizeUnits } from "../../../utils/SizeHandler"
import toast from "react-hot-toast"
import Modal from "../../../components/DesignSystem/Modal"
import SetLimit from "./setLimit"
import SetLimitForAll from "./setLimitForAll"
import SetContactsEmails from "./setContactsEmails"
import DownloadNetworkExcel from "./downloadNetworkExcel"
import moment from "moment/moment"
import { BaseInput } from "../../../components/DesignSystem/inputs"
import DownloadUsersExcel from "./downloadUsersExcel"

const Users = () => {
  const [users, setUsers] = useState({})
  const [searchValue, setSearchValue] = useState("")
  const [pageSize, setPageSize] = useState(20)

  const ActivateChangeHandler = (id, isActive) => {
    fetchData("PATCH", `/admin/user/manage`, {
      userId: id,
      isActive: isActive ? false : true
    }).then((res) => {
      if (res) {
        toast.success("User status changed successfully")
        fetchData("GET", "/admin/user").then((res) => setUsers(res?.data?.data?.users))
      }
    })
  }

  const RoleChangeHandler = (id, role) => {
    fetchData("PATCH", `/admin/user/manage`, {
      userId: id,
      role: role === "User" ? "Admin" : "User"
    }).then((res) => {
      if (res) {
        toast.success("User role changed successfully")
        fetchData("GET", "/admin/user").then((res) => setUsers(res?.data?.data?.users))
      }
    })
  }

  useEffect(() => {
    if (searchValue) {
      fetchData("GET", `/admin/user?query=${searchValue}`).then((res) => setUsers(res?.data?.data?.users))
    } else {
      fetchData("GET", "/admin/user").then((res) => setUsers(res?.data?.data?.users))
    }
  }, [searchValue])

  const columns = [
    {
      field: "image",
      headerName: "",
      renderCell: (params) =>
        params?.value ? (
          <img className='rounded-full h-[32px]' src={params?.value} alt='' crossOrigin='anonymous' />
        ) : (
          <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g class='currentLayer'>
              <circle cx='16' cy='16' r='16' fill='#F5F5F5' id='svg_1' />
              <path
                d='M18.857142448425293,9.63265323638916 C18.857142448425293,11.28955323638916 17.514042448425293,12.63265323638916 15.857142448425293,12.63265323638916 C14.200242448425293,12.63265323638916 12.857142448425293,11.28955323638916 12.857142448425293,9.63265323638916 C12.857142448425293,7.97575323638916 14.200242448425293,6.63265323638916 15.857142448425293,6.63265323638916 C17.514042448425293,6.63265323638916 18.857142448425293,7.97575323638916 18.857142448425293,9.63265323638916 z'
                stroke='white'
                strokeWidth='2'
                id='svg_2'
                class=''
              />
              <path
                d='M18.69387722015381,9.714285850524902 C18.69387722015381,11.371185850524903 17.35077722015381,12.714285850524902 15.693877220153809,12.714285850524902 C14.036977220153808,12.714285850524902 12.693877220153809,11.371185850524903 12.693877220153809,9.714285850524902 C12.693877220153809,8.057385850524902 14.036977220153808,6.714285850524902 15.693877220153809,6.714285850524902 C17.35077722015381,6.714285850524902 18.69387722015381,8.057385850524902 18.69387722015381,9.714285850524902 z'
                stroke='white'
                strokeWidth='2'
                class=''
                id='svg_4'
              />
              <path
                d='M8.857142448425293,22.13265323638916 C8.857142448425293,18.54275323638916 11.767242448425293,15.63265323638916 15.357142448425293,15.63265323638916 H16.357142448425293 C19.947042448425293,15.63265323638916 22.857142448425293,18.54275323638916 22.857142448425293,22.13265323638916 V22.63265323638916 C22.857142448425293,23.184953236389163 22.409442448425292,23.63265323638916 21.857142448425293,23.63265323638916 H9.857142448425293 C9.304842448425294,23.63265323638916 8.857142448425293,23.184953236389163 8.857142448425293,22.63265323638916 V22.13265323638916 z'
                stroke='white'
                strokeWidth='2'
                id='svg_3'
                class=''
              />
              <path
                d='M8.693877220153809,22.214285850524902 C8.693877220153809,18.624385850524902 11.603977220153809,15.714285850524902 15.193877220153809,15.714285850524902 H16.19387722015381 C19.78377722015381,15.714285850524902 22.69387722015381,18.624385850524902 22.69387722015381,22.214285850524902 V22.714285850524902 C22.69387722015381,23.266585850524905 22.246177220153807,23.714285850524902 21.69387722015381,23.714285850524902 H9.693877220153809 C9.14157722015381,23.714285850524902 8.693877220153809,23.266585850524905 8.693877220153809,22.714285850524902 V22.214285850524902 z'
                stroke='white'
                strokeWidth='2'
                class=''
                id='svg_5'
              />
            </g>
          </svg>
        ),
      width: 32
    },
    { field: "email", headerName: "Email address", width: 250 },
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => `${params?.row?.firstName} ${params?.row?.lastName}`,
      width: 170
    },
    {
      field: "professionalGroup",
      width: 170,
      headerName: "Profession Group"
    },
    {
      field: "country",
      headerName: "Country",
      renderCell: (params) => params?.value?.name,
      width: 150
    },
    { field: "loginsCount", headerName: "Login count", width: 100 },
    {
      field: "lastLoginDate",
      headerName: "Last login time",
      renderCell: (params) =>
        params?.row?.lastLoginDate ? moment(params?.row?.lastLoginDate).format("MMM DD, YYYY HH:mm") : "-",
      width: 165
    },
    { field: "studiesCount", headerName: "Study Count", width: 100 },
    {
      field: "totalShared",
      headerName: "Total Shared",
      renderCell: (params) => params?.row?.sharedStats?.totalShared,
      width: 110
    },
    {
      field: "publicShare",
      headerName: "Public Share",
      renderCell: (params) => params?.row?.sharedStats?.public,
      width: 110
    },
    {
      field: "privateShare",
      headerName: "Private Share",
      renderCell: (params) => params?.row?.sharedStats?.private,
      width: 110
    },
    {
      field: "privateToOne",
      headerName: "Unique Share",
      renderCell: (params) => params?.row?.sharedStats?.privateToOne,
      width: 110
    },
    // { field: "sharedWith", headerName: "Shared with", width: 120 },
    {
      field: "userStudies",
      headerName: "User studies",
      renderCell: (params) =>
        params?.row?.studiesCount ? (
          <DicomButtonLink
            url={`/dashboard/my-dicom-wallet?userId=${params?.row?.id}`}
            btnClass={"!capitalize !bg-dicom-secondary-110 rounded-sm p-0"}
            title={"User studies"}
          />
        ) : (
          ""
        ),
      width: 150
    },
    {
      field: "storageUsed",
      headerName: "Storage used",
      renderCell: (params) => (
        <div className='text-dicom-secondary-100 font-bold'>
          {FormatSizeUnits(params?.row?.usedSpace)} - {FormatSizeUnits(params?.row?.totalSpace)}
        </div>
      ),

      width: 150
    },
    {
      field: "storageLimit",
      headerName: "Storage limit",
      renderCell: (params) => {
        return (
          <>
            <DicomButtonLabel btnClass={"!capitalize"} title={"Set limit"} htmlFor={`set-limit-${params?.row?.id}`} />
          </>
        )
      },
      width: 170
    },
    {
      field: "isVerified",
      headerName: "Email verified",
      renderCell: (params) =>
        params?.row?.isVerified === true ? (
          <span className='px-[8px] py-[4px] text-white bg-dicom-secondary-100 rounded-full'>Verified</span>
        ) : (
          <span className='px-[8px] py-[4px] text-white bg-slate-400 rounded-full'>Not Verified</span>
        ),
      width: 170
    },
    {
      field: "field",
      headerName: "",
      renderCell: (params) => (
        <div className='flex items-center gap-dicom-10'>
          <div className='relative'>
            <div id='user-switch' className='flex w-[160px] items-center space-x-dicom-10'>
              <p>deactivate</p>
              <label htmlFor='checkbox-user' className='switch'>
                <input
                  type='checkbox'
                  id='checkbox-user'
                  checked={params?.row?.isActive || ""}
                  onChange={ActivateChangeHandler}
                />
                <span className='slider round'></span>
              </label>
              <p>Active</p>
            </div>
            <label
              className='absolute left-0 top-0 bottom-0 right-0 cursor-pointer'
              htmlFor='user-switch'
              onClick={() => ActivateChangeHandler(params?.row?.id, params?.row?.isActive)}></label>
          </div>
          <div className='relative'>
            <div id='user-switch' className='flex w-[160px] items-center space-x-dicom-10'>
              <p>User</p>
              <label htmlFor='checkbox-user' className='switch'>
                <input
                  type='checkbox'
                  id='checkbox-user'
                  checked={params?.row?.role === "Admin" || ""}
                  onChange={RoleChangeHandler}
                />
                <span className='slider round'></span>
              </label>
              <p>Admin</p>
            </div>
            <label
              className='absolute left-0 top-0 bottom-0 right-0 cursor-pointer'
              htmlFor='user-switch'
              onClick={() => RoleChangeHandler(params?.row?.id, params?.row?.role)}></label>
          </div>
        </div>
      ),
      width: 350
    }
  ]

  return (
    <>
      <AdminLayout>
        <div className='space-y-dicom-30'>
          <div className='grid grid-cols-6 md:grid-cols-12 gap-6 bg-white p-dicom-15 rounded-dicom-10'>
            <BaseInput
              classInput={"!bg-white !border-dicom-secondary-100"}
              InputClassName={"col-span-4 md:col-span-4"}
              value={searchValue}
              placeholder={"Search"}
              onChange={(e) => setSearchValue(e.target.value)}
              badgeTitle={
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <g clipPath='url(#clip0_264_5529)'>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M15.663 15.663C15.8023 15.5235 15.9678 15.4129 16.1499 15.3374C16.332 15.2619 16.5272 15.2231 16.7243 15.2231C16.9214 15.2231 17.1166 15.2619 17.2987 15.3374C17.4808 15.4129 17.6462 15.5235 17.7855 15.663L23.5605 21.438C23.842 21.7193 24.0002 22.1008 24.0003 22.4987C24.0005 22.8966 23.8426 23.2783 23.5613 23.5598C23.28 23.8412 22.8985 23.9994 22.5006 23.9996C22.1027 23.9997 21.721 23.8418 21.4395 23.5605L15.6645 17.7855C15.5251 17.6462 15.4144 17.4808 15.339 17.2987C15.2635 17.1166 15.2246 16.9214 15.2246 16.7243C15.2246 16.5271 15.2635 16.332 15.339 16.1499C15.4144 15.9678 15.5251 15.8023 15.6645 15.663H15.663Z'
                      fill='#1DD3B0'
                    />
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M9.75 18C10.8334 18 11.9062 17.7866 12.9071 17.372C13.9081 16.9574 14.8175 16.3497 15.5836 15.5836C16.3497 14.8175 16.9574 13.9081 17.372 12.9071C17.7866 11.9062 18 10.8334 18 9.75C18 8.66659 17.7866 7.5938 17.372 6.59286C16.9574 5.59193 16.3497 4.68245 15.5836 3.91637C14.8175 3.15029 13.9081 2.5426 12.9071 2.12799C11.9062 1.71339 10.8334 1.5 9.75 1.5C7.56196 1.5 5.46354 2.36919 3.91637 3.91637C2.36919 5.46354 1.5 7.56196 1.5 9.75C1.5 11.938 2.36919 14.0365 3.91637 15.5836C5.46354 17.1308 7.56196 18 9.75 18ZM19.5 9.75C19.5 12.3359 18.4728 14.8158 16.6443 16.6443C14.8158 18.4728 12.3359 19.5 9.75 19.5C7.16414 19.5 4.68419 18.4728 2.85571 16.6443C1.02723 14.8158 0 12.3359 0 9.75C0 7.16414 1.02723 4.68419 2.85571 2.85571C4.68419 1.02723 7.16414 0 9.75 0C12.3359 0 14.8158 1.02723 16.6443 2.85571C18.4728 4.68419 19.5 7.16414 19.5 9.75Z'
                      fill='#1DD3B0'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_264_5529'>
                      <rect width='24' height='24' fill='white' />
                    </clipPath>
                  </defs>
                </svg>
              }
            />

            <Modal modalId={"set-limit-for-all"} title={"Set limit storage for all users"}>
              <SetLimitForAll modalId={"set-limit-for-all"} />
            </Modal>
            <Modal modalId={"set-contacts-emails"} title={"Set contact email(s):"}>
              <SetContactsEmails modalId={"set-contacts-emails"} />
            </Modal>
            <div className='col-span-8'>
              <div className='flex items-start w-full gap-dicom-10'>
                <DicomButtonLabel
                  htmlFor={"set-limit-for-all"}
                  btnClass={
                    "!bg-dicom-secondary-100 !px-[10px] !border-dicom-secondary-100/0 hover:!bg-dicom-secondary-hover-100"
                  }
                  title={"Limit storage"}
                  iconL={
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M10.5213 3.62368C11.3147 2.75255 12.6853 2.75255 13.4787 3.62368L14.2142 4.43128C14.6151 4.87154 15.1914 5.11025 15.7862 5.08245L16.8774 5.03146C18.0543 4.97645 19.0236 5.94568 18.9685 7.12264L18.9176 8.21377C18.8898 8.80859 19.1285 9.38487 19.5687 9.78582L20.3763 10.5213C21.2475 11.3147 21.2475 12.6853 20.3763 13.4787L19.5687 14.2142C19.1285 14.6151 18.8898 15.1914 18.9176 15.7862L18.9685 16.8774C19.0236 18.0543 18.0543 19.0236 16.8774 18.9685L15.7862 18.9176C15.1914 18.8898 14.6151 19.1285 14.2142 19.5687L13.4787 20.3763C12.6853 21.2475 11.3147 21.2475 10.5213 20.3763L9.78582 19.5687C9.38487 19.1285 8.80859 18.8898 8.21376 18.9176L7.12264 18.9685C5.94568 19.0236 4.97645 18.0543 5.03146 16.8774L5.08245 15.7862C5.11025 15.1914 4.87154 14.6151 4.43128 14.2142L3.62368 13.4787C2.75255 12.6853 2.75255 11.3147 3.62368 10.5213L4.43128 9.78582C4.87154 9.38487 5.11025 8.80859 5.08245 8.21376L5.03146 7.12264C4.97645 5.94568 5.94568 4.97645 7.12264 5.03146L8.21376 5.08245C8.80859 5.11025 9.38487 4.87154 9.78583 4.43128L10.5213 3.62368Z'
                        stroke='white'
                        strokeWidth='2'
                      />
                      <circle cx='12' cy='12' r='3' stroke='white' strokeWidth='2' />
                    </svg>
                  }
                />
                <DicomButtonLabel
                  htmlFor={"set-contacts-emails"}
                  btnClass={
                    "!bg-dicom-secondary-100 !px-[10px] !border-dicom-secondary-100/0 hover:!bg-dicom-secondary-hover-100"
                  }
                  title={"Set contact email"}
                  iconL={
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M7 7C6.44772 7 6 7.44772 6 8C6 8.55228 6.44772 9 7 9H13C13.5523 9 14 8.55228 14 8C14 7.44772 13.5523 7 13 7H7Z'
                        fill='white'
                      />
                      <path
                        d='M6 12C6 11.4477 6.44772 11 7 11H13C13.5523 11 14 11.4477 14 12C14 12.5523 13.5523 13 13 13H7C6.44772 13 6 12.5523 6 12Z'
                        fill='white'
                      />
                      <path
                        d='M7 15C6.44772 15 6 15.4477 6 16C6 16.5523 6.44772 17 7 17H13C13.5523 17 14 16.5523 14 16C14 15.4477 13.5523 15 13 15H7Z'
                        fill='white'
                      />
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M5.625 3H7.03369C7.71014 2.37135 8.6473 2 9.62497 2H18.375C20.263 2 22 3.38491 22 5.33333V14.6667C22 16.6151 20.263 18 18.375 18H17.94C17.631 19.697 16.1637 21 14.375 21H5.625C3.60818 21 2 19.3435 2 17.3333V6.66667C2 4.65653 3.60818 3 5.625 3ZM18.375 16H18V6.66667C18 5.62093 17.5648 4.67089 16.8632 4H18.375C19.3864 4 20 4.70443 20 5.33333V14.6667C20 15.2956 19.3864 16 18.375 16ZM5.625 5C4.74233 5 4 5.73129 4 6.66667V17.3333C4 18.2687 4.74233 19 5.625 19H14.375C15.2577 19 16 18.2687 16 17.3333V6.66667C16 5.73129 15.2577 5 14.375 5H5.625Z'
                        fill='white'
                      />
                    </svg>
                  }
                />
                <DownloadNetworkExcel />
                <DownloadUsersExcel />
              </div>
            </div>
          </div>
          <div className='bg-white rounded-dicom-10 border-0'>
            <div style={{ height: 550 }}>
              {users?.users ? (
                <DataGrid
                  // autoHeight
                  rowHeight={40}
                  rows={users?.users}
                  columns={columns}
                  pageSize={pageSize}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  checkboxSelection={false}
                  disableColumnMenu
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              ) : (
                ""
              )}
            </div>
          </div>

          {users?.users
            ? users?.users.map((oneUser) => (
                <Modal modalId={`set-limit-${oneUser.id}`} title={"Set limit storage for user"}>
                  <SetLimit data={oneUser} setUsers={setUsers} modalId={`set-limit-${oneUser.id}`} />
                </Modal>
              ))
            : ""}
        </div>
        {/* <TableContainer
          className={`${
            GetLocalStorage("Collaps") && GetLocalStorage("Collaps") === true
              ? "!w-[100%] lg:!w-[890px] xl:!w-[1300px] 2xl:!w-[1470px]"
              : "!w-[100%] lg:!w-[780px] xl:!w-[1190px] 2xl:!w-[1250px]"
          } max-h-[600px] w-[100%]`}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ width: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer> */}
      </AdminLayout>
    </>
  )
}

export default Users
