import React, { useEffect, useState } from "react";
import ACTIVITY_AVERAGE from "../../../components/dashboard/reports/ACTIVITY_AVERAGE";
import AnalyticsSection from "../../../components/dashboard/reports/Analytics/AnalyticsSection";
import AnonymizationStatus from "../../../components/dashboard/reports/AnonymizationStatus";
import AverageStorage from "../../../components/dashboard/reports/AverageStorage";
import AverageStorageModality from "../../../components/dashboard/reports/AverageStorage(Modality)";
import AverageTime from "../../../components/dashboard/reports/AverageTime";
import Country from "../../../components/dashboard/reports/Country";
import ModalityActivity from "../../../components/dashboard/reports/ModalityActivity";
// import ProfessionGroup from "../../../components/dashboard/reports/ProfessionGroup";
import ShareStudy from "../../../components/dashboard/reports/ShareStudy";
import ChartStudies from "../../../components/dashboard/reports/Studies";

// components
import AdminLayout from "../../../containers/AdminLayout";
import { fetchData } from "../../../services/fetching";

const Reports = () => {
  const [ReportUsersData, setReportUsersData] = useState({});
  const [ReportStudyData, setReportStudyData] = useState({});
  useEffect(() => {
    fetchData("GET", "/admin/user-report").then((res) =>
      setReportUsersData(res.data.data.result)
    );
    fetchData("GET", "/admin/study-report").then((res) =>
      setReportStudyData(res.data.data.result)
    );
  }, []);
  return (
    <>
      <AdminLayout>
        <div className="space-y-dicom-30">
          <AnalyticsSection
            data={ReportUsersData}
            studyData={ReportStudyData}
          />
          <div className="grid grid-cols-12 gap-dicom-30">
            <ChartStudies
              studyData={ReportStudyData}
              className={"col-span-12 lg:col-span-6"}
              title={"Studies"}
            />
            <ShareStudy
              studyData={ReportStudyData}
              className={"col-span-12 lg:col-span-6"}
              title={"Share Study"}
            />
            {/* <ProfessionGroup
              studyData={ReportStudyData}
              className={"col-span-12"}
              title={"Profession Group chart"}
            /> */}
            <Country
              userData={ReportUsersData}
              className={"col-span-12"}
              title={"Country"}
            />
            {/*  */}
            <ACTIVITY_AVERAGE
              studyData={ReportStudyData}
              className={"col-span-12 lg:col-span-8"}
              title={"ACTIVITY (AVERAGE) / PERFESSION"}
            />
            <AverageStorage
              studyData={ReportStudyData}
              className={"col-span-12 lg:col-span-4"}
              title={"Average Storage (MB)"}
            />

            {/*  */}
            <AverageStorageModality
              studyData={ReportStudyData}
              className={"col-span-12 lg:col-span-4"}
              title={"Average Storage / Modality"}
            />
            <AverageTime
              className={"col-span-12 lg:col-span-8"}
              title={"Average time ( Minutes )"}
            />

            {/*  */}
            <ModalityActivity
              studyData={ReportStudyData}
              className={"col-span-12 lg:col-span-8"}
              title={"Modality / Activity"}
            />
            <AnonymizationStatus
              studyData={ReportStudyData}
              className={"col-span-12 lg:col-span-4"}
              title={"Anonymization Status"}
            />
          </div>
        </div>
      </AdminLayout>
    </>
  );
};

export default Reports;
