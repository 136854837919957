import React from "react";

const HowToDicom = () => {
  return (
    <>
      <div className="grid items-center grid-cols-3 lg:grid-cols-6 xl:grid-cols-12 gap-x-28 space-y-dicom-30">
        <div className="col-span-3 lg:col-span-3 xl:col-span-6">
          <video width="720" height="480" controls>
            <source
              src={"/images/DicomWallet-project.mp4"}
              type="video/mp4"
            />
          </video>
        </div>
        <div className="space-y-dicom-30 col-span-3 lg:col-span-3 xl:col-span-6">
          <div className="space-y-dicom-15">
            <p className="text-dicom-body2 text-dicom-primary-100">
              Ease of use
            </p>
            <p className="text-dicom-headline2 font-bold">
              DICOMWallet is smart and convenient
            </p>
            {/* <p className="text-dicom-body2 text-dicom-text-100 lg:w-2/3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus
              non sollicitudin enim, nisi.
            </p> */}
            <svg
              width="147"
              height="2"
              viewBox="0 0 147 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="147" height="2" fill="#021BFA" />
            </svg>
          </div>
          <p className="text-base text-dicom-text-100">
            DICOMWallet is your cloud service that connects you to all your
            medical documents and images. It lets you store and protect your
            files, share them with others, create teaching and social media
            content and get to them from anywhere on all your devices. You can
            view your files using the integrated Dicom viewer, makes ideal
            platform for education, research and second opinion purposes.
            DICOMWallet lets you share medical files publicly or privately. You
            can anonymize the files or anonymize the files to share but keep the
            patient information for follow up.
          </p>
        </div>
      </div>
    </>
  );
};

export default HowToDicom;
