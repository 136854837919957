import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  indexAxis: "y",
  plugins: {
    legend: {
      display: false,
      position: "bottom",
    },
    title: {
      display: false,
    },
  },
};

const ShareStudy = ({ studyData, title, className }) => {
  let filtredStudy = studyData?.studiesSharing?.filter(
    (x) => x.value !== "shared" && x.value !== "unique_shared"
  );
  const data = {
    labels: filtredStudy?.map((x) => x.label),
    datasets: [
      {
        data: filtredStudy?.map((x) => x.data),
        backgroundColor: ["rgba(2, 27, 250, 1)"],
      },
    ],
  };
  return (
    <>
      <div
        className={`${className} space-y-dicom-30 rounded-dicom-10 bg-white p-dicom-30`}
        title="The number of users shared based on the type of private or public sharing"
      >
        <div className="text-dicom-headline4 text-dicom-secondary-100 font-semibold">
          {title}
        </div>
        <Bar options={options} data={data} />
      </div>
    </>
  );
};

export default ShareStudy;
