import React, { useState } from "react";
import {
  DicomButton,
  DicomButtonOutlineLabel,
} from "../../../components/DesignSystem/buttons";
import { fetchData } from "../../../services/fetching";
import { FormatSizeUnitsSpaceSetLimit } from "../../../utils/SizeHandler";

const SetLimit = ({ data, setUsers, modalId }) => {
  const [space, setSpace] = useState(data?.totalSpace / 1024 / 1024);
  const [isLoading, setIsLoading] = useState(false);

  const LimitChangeHandler = (e) => {
    setSpace(parseInt(e.target.value));
  };

  const changeSpaceLimitHandler = () => {
    setIsLoading(true);
    fetchData("PUT", "/admin/settings/space", {
      space: parseInt(space * 1024 * 1024),
      userIds: [data.id],
    }).then((res) => {
      if (res.data.data) {
        setIsLoading(false);
        fetchData("GET", "/admin/user").then((res) =>
          setUsers(res?.data?.data?.users)
        );
        document.getElementById(modalId).checked = false;
      }
    });
  };

  return (
    <>
      <p>Specify the amount of volume you want to allocate</p>
      <div className="flex items-center gap-x-dicom-15 text-dicom-body2">
        <span>user:</span>
        <span className="font-bold">{data?.email}</span>
      </div>
      <div className="input-group">
        <input
          type="number"
          value={space}
          onChange={LimitChangeHandler}
          placeholder="Enter the space limit..."
          className="input input-bordered !appearance-none input-md w-full !outline-none"
        />

        <span className="w-[200px] text-lg font-bold items-center justify-center border rounded-dicom-10 border-dicom-caption-100">
          {FormatSizeUnitsSpaceSetLimit(space, "GB")}
        </span>
      </div>

      {/* <Limitslider setSpace={setSpace} space={space} /> */}

      <div className="flex items-center justify-center gap-x-[20px]">
        <DicomButtonOutlineLabel
          onClick={() => {
            document.getElementById(modalId).checked = false;
            setSpace(data?.totalSpace / 1024 / 1024);
          }}
          btnClass={
            "!border-dicom-secondary-100 !text-dicom-secondary-100 hover:!bg-dicom-secondary-100/5"
          }
          title={"Cancel"}
        />
        <DicomButton
          isLoading={isLoading}
          onClick={changeSpaceLimitHandler}
          btnClass={"!bg-dicom-secondary-100 hover:!bg-dicom-secondary-100"}
          title={"Done"}
        />
      </div>
    </>
  );
};

export default SetLimit;
