import React, { useState } from "react"
import { Link } from "react-router-dom"

import { useUserActions } from "../../services/AuthActions"

import Drawer from "./Drawer"
import { UserSection, UserSectionMobile } from "./UserSection"

const LogoHeader = () => {
  return <img className='lg:py-dicom-25' src='/images/Logo.svg' alt='' />
}

const MobileNav = ({ user, onClick, data, isLoginPage, isRegisterPage }) => {
  const [showHeader, setshowHeader] = useState(false)
  const Draweronclick = () => {
    setshowHeader((prevState) => !prevState)
  }

  if (showHeader) {
    document.body.setAttribute("style", "position: fixed !important; width: 100%;")
  } else if (showHeader === false) {
    document.body.setAttribute("style", "position: relative")
  }

  return (
    <>
      <div className='flex !w-full items-center justify-between px-4 lg:px-8 xl:px-0 py-6'>
        {user ? <UserSectionMobile onClick={onClick} user={user} /> : ""}
        <Link to={user ? "/dashboard" : "/"}>
          <LogoHeader />
        </Link>
        <button className='transition-all duration-300 ease-in-out' onClick={Draweronclick}>
          {showHeader ? (
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M16 8L8 16M8 8L16 16' stroke='black' strokeWidth='2' strokeLinecap='round' />
            </svg>
          ) : (
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M4 18L20 18' stroke='black' strokeWidth='2' strokeLinecap='round' />
              <path d='M4 12L20 12' stroke='black' strokeWidth='2' strokeLinecap='round' />
              <path d='M4 6L20 6' stroke='black' strokeWidth='2' strokeLinecap='round' />
            </svg>
          )}
        </button>
        {isRegisterPage ? (
          <Link
            to={"/auth/login"}
            className='btn btn-outline px-6 border-2 text-dicom-primary-100 border-dicom-primary-100 hover:bg-dicom-primary-100 hover:border-dicom-primary-100 !rounded-dicom-10'>
            login
          </Link>
        ) : (
          ""
        )}
        {isLoginPage ? (
          <Link
            to={"/auth/join-us"}
            className='flex text-white space-x-dicom-10 items-center btn px-6 border-2 bg-dicom-primary-100 border-dicom-primary-100 hover:bg-dicom-primary-hover-100 hover:border-dicom-primary-hover-100 !rounded-dicom-10'>
            <span>JOIN US</span>
            <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M16.2071 7.29289C15.8166 6.90237 15.1834 6.90237 14.7929 7.29289C14.4024 7.68342 14.4024 8.31658 14.7929 8.70711L16.2071 7.29289ZM19.5 12L20.2071 12.7071L20.9142 12L20.2071 11.2929L19.5 12ZM14.7929 15.2929C14.4024 15.6834 14.4024 16.3166 14.7929 16.7071C15.1834 17.0976 15.8166 17.0976 16.2071 16.7071L14.7929 15.2929ZM5.5 11C4.94772 11 4.5 11.4477 4.5 12C4.5 12.5523 4.94772 13 5.5 13V11ZM14.7929 8.70711L18.7929 12.7071L20.2071 11.2929L16.2071 7.29289L14.7929 8.70711ZM18.7929 11.2929L14.7929 15.2929L16.2071 16.7071L20.2071 12.7071L18.7929 11.2929ZM19.5 11H5.5V13H19.5V11Z'
                fill='white'
              />
            </svg>
          </Link>
        ) : (
          ""
        )}

        <div
          className={`!z-20 transition-all w-full duration-300 ease-in-out fixed px-4 pt-0 pb-dicom-30 bg-white ${
            showHeader ? "right-0" : "-right-[1500px]"
          }  top-[78px] bottom-0`}>
          <Drawer data={data} />
        </div>
      </div>
    </>
  )
}

const Index = ({ user, data, isAuthPage, isLoginPage, isRegisterPage }) => {
  const userActions = useUserActions()

  const LogOutClickHandler = () => {
    userActions.UserLogOut()
  }

  return (
    <>
      <div className={isAuthPage ? "relative z-20" : "fixed z-30 top-0 right-0 left-0"}>
        <div className={`hidden lg:flex ${isAuthPage ? "" : "bg-[#f5f5f5] "}`}>
          <div className='flex items-center justify-between lg:container !max-w-7xl mx-auto px-4 lg:px-8 xl:px-0'>
            <div className='flex items-center space-x-dicom-30'>
              <Link to={user ? "/dashboard" : "/"}>
                <LogoHeader />
              </Link>
              <nav>
                <ul className='flex items-center text-dicom-caption space-x-dicom-30'>
                  {data.map((nav) => (
                    <li key={nav.url}>
                      <Link
                        className='transition-all duration-300 ease-in-out hover:text-dicom-primary-100'
                        to={nav.url}
                        target={nav.target}>
                        {nav.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
            {user ? (
              <UserSection user={user} onClick={LogOutClickHandler} />
            ) : (
              <div className='flex space-x-dicom-10'>
                {!isRegisterPage && !isLoginPage ? (
                  <div className='flex space-x-dicom-10'>
                    <Link
                      to={"/auth/login"}
                      className='btn btn-outline px-6 border-2 text-dicom-primary-100 border-dicom-primary-100 hover:bg-dicom-primary-100 hover:border-dicom-primary-100 !rounded-dicom-10'>
                      login
                    </Link>
                    <Link
                      to={"/auth/join-us"}
                      className='flex text-white space-x-dicom-10 items-center btn px-6 border-2 bg-dicom-primary-100 border-dicom-primary-100 hover:bg-dicom-primary-hover-100 hover:border-dicom-primary-hover-100 !rounded-dicom-10'>
                      <span>JOIN US</span>
                      <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M16.2071 7.29289C15.8166 6.90237 15.1834 6.90237 14.7929 7.29289C14.4024 7.68342 14.4024 8.31658 14.7929 8.70711L16.2071 7.29289ZM19.5 12L20.2071 12.7071L20.9142 12L20.2071 11.2929L19.5 12ZM14.7929 15.2929C14.4024 15.6834 14.4024 16.3166 14.7929 16.7071C15.1834 17.0976 15.8166 17.0976 16.2071 16.7071L14.7929 15.2929ZM5.5 11C4.94772 11 4.5 11.4477 4.5 12C4.5 12.5523 4.94772 13 5.5 13V11ZM14.7929 8.70711L18.7929 12.7071L20.2071 11.2929L16.2071 7.29289L14.7929 8.70711ZM18.7929 11.2929L14.7929 15.2929L16.2071 16.7071L20.2071 12.7071L18.7929 11.2929ZM19.5 11H5.5V13H19.5V11Z'
                          fill='white'
                        />
                      </svg>
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                {isRegisterPage ? (
                  <Link
                    to={"/auth/login"}
                    className='btn btn-outline px-6 border-2 text-dicom-primary-100 border-dicom-primary-100 hover:bg-dicom-primary-100 hover:border-dicom-primary-100 !rounded-dicom-10'>
                    login
                  </Link>
                ) : (
                  ""
                )}
                {isLoginPage ? (
                  <Link
                    to={"/auth/join-us"}
                    className='flex text-white space-x-dicom-10 items-center btn px-6 border-2 bg-dicom-primary-100 border-dicom-primary-100 hover:bg-dicom-primary-hover-100 hover:border-dicom-primary-hover-100 !rounded-dicom-10'>
                    <span>JOIN US</span>
                    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M16.2071 7.29289C15.8166 6.90237 15.1834 6.90237 14.7929 7.29289C14.4024 7.68342 14.4024 8.31658 14.7929 8.70711L16.2071 7.29289ZM19.5 12L20.2071 12.7071L20.9142 12L20.2071 11.2929L19.5 12ZM14.7929 15.2929C14.4024 15.6834 14.4024 16.3166 14.7929 16.7071C15.1834 17.0976 15.8166 17.0976 16.2071 16.7071L14.7929 15.2929ZM5.5 11C4.94772 11 4.5 11.4477 4.5 12C4.5 12.5523 4.94772 13 5.5 13V11ZM14.7929 8.70711L18.7929 12.7071L20.2071 11.2929L16.2071 7.29289L14.7929 8.70711ZM18.7929 11.2929L14.7929 15.2929L16.2071 16.7071L20.2071 12.7071L18.7929 11.2929ZM19.5 11H5.5V13H19.5V11Z'
                        fill='white'
                      />
                    </svg>
                  </Link>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>

        <div className='flex lg:hidden bg-dicom-surface-100 shadow-dicom-shadow'>
          <MobileNav
            onClick={LogOutClickHandler}
            data={data}
            isLoginPage={isLoginPage}
            isRegisterPage={isRegisterPage}
            user={user}
          />
        </div>
      </div>
    </>
  )
}

export default Index
