import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

import {
  DicomButton,
  DicomButtonOutline,
} from "../../../components/DesignSystem/buttons";
import { Input } from "../../../components/DesignSystem/inputs";
import { fetchData } from "../../../services/fetching";

const EditFolder = ({ modalId, item, setFoldersData }) => {
  const [EditFolderData, setEditFolderData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = Yup.object({
    name: Yup.string().required("Folder name is required"),
  });
  const onSubmit = () => {
    setIsLoading(true);
    fetchData("PATCH", `/folder/${item.id}`, {
      name: formik.values.name,
    })
      .then((res) => {
        if (res.data.data.folder) {
          fetchData("GET", "/folder").then((res) =>
            setFoldersData(res.data.data.folders)
          );
          formik.resetForm();
          document.getElementById(modalId).checked = false;
          toast.success("The desired folder has been successfully updated");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const formik = useFormik({
    initialValues: EditFolderData,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  useEffect(() => {
    fetchData("GET", `/folder/${item.id}`).then((res) =>
      setEditFolderData(res.data.data)
    );
  }, [item]);

  return (
    <>
      <div className="space-y-dicom-15">
        <Input label={"Folder name"} formik={formik} nameId={"name"} />
        <div className="flex justify-center gap-5 w-full">
          <DicomButtonOutline
            onClick={() => (document.getElementById(modalId).checked = false)}
            btnClass={
              "!border-dicom-secondary-100 !text-dicom-secondary-100 hover:!bg-dicom-secondary-100/5"
            }
            title={"Cancel"}
          />
          <DicomButton
            isLoading={isLoading}
            onClick={onSubmit}
            btnClass={
              "!border-dicom-secondary-100 !bg-dicom-secondary-100 !text-white"
            }
            title={"Edit folder"}
          />
        </div>
      </div>
    </>
  );
};

export default EditFolder;
