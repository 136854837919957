import React, { useState } from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";
import {
  DicomButton,
  DicomButtonOutlineLabel,
} from "../../../components/DesignSystem/buttons";
import { fetchData } from "../../../services/fetching";
import { FormatSizeUnitsSpaceSetLimit } from "../../../utils/SizeHandler";

const SetLimit = ({ modalId }) => {
  const [space, setSpace] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const LimitChangeHandler = (e) => {
    setSpace(parseInt(e.target.value));
  };

  const changeSpaceLimitHandler = () => {
    setIsLoading(true);
    fetchData("PUT", "/admin/settings/overall", {
      space: parseInt(space * 1024 * 1024)
    }).then((res) => {
      if (res.data.data) {
        toast.success("storage for all users changed to " + (space) + "MB]");
        // fetchData("GET", "/admin/user").then((res) =>
        //   setUsers(res?.data?.data?.users)
        // );
        document.getElementById(modalId).checked = false;
      }
    }).catch(err => {
      console.log(err);
      toast.error("request error[" + err.response.status + "]:\n " + err.message);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchData(
      "GET",
      `/admin/settings/`
    ).then((res) => {
      if (res.data) {
        setSpace(parseInt(res?.data?.data?.settings?.defaultUserSpace) / 1024 / 1024);
      }
    })
  }, []);

  return (
    <>
      <p>Specify the amount of volume you want to allocate to all users:</p>
      <div className="input-group">
        <input
          type="number"
          value={space}
          onChange={LimitChangeHandler}
          placeholder="Enter the space limit..."
          className="input input-bordered !appearance-none input-md w-full !outline-none"
        />

        <span className="w-[200px] text-lg font-bold items-center justify-center border rounded-dicom-10 border-dicom-caption-100">
          {FormatSizeUnitsSpaceSetLimit(space, "GB")}
        </span>
      </div>

      <div className="flex items-center justify-center gap-x-[20px]">
        <DicomButtonOutlineLabel
          onClick={() => {
            document.getElementById(modalId).checked = false;
            // setSpace(data?.totalSpace / 1024 / 1024);
          }}
          btnClass={
            "!border-dicom-secondary-100 !text-dicom-secondary-100 hover:!bg-dicom-secondary-100/5"
          }
          title={"Cancel"}
        />
        <DicomButton
          isLoading={isLoading}
          onClick={changeSpaceLimitHandler}
          btnClass={"!bg-dicom-secondary-100 hover:!bg-dicom-secondary-100"}
          title={"Set limit"}
        />
      </div>
    </>
  );
};

export default SetLimit;
