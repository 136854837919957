import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Draggable from "react-draggable";
import { DownloadStudyOnViewerPage } from "../../../components/dashboard/studyDownload/DownloadStudy";
import {
  IconCommentsBlack,
  IconCommentsViewerOk,
} from "../../../components/DesignSystem/svgIcons";
import { fetchData } from "../../../services/fetching";
import {
  GetLocalStorage,
  SetLocalStorage,
} from "../../../services/LocalStorage";
import { UserMePesrmissionShow } from "../../../utils/Permissions";
import Comments from "../all-studies/comments";
import { FolderShared, StudyShareByLink } from "../../../atom/studyShare";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { authAtom } from "../../../atom/auth";

const DragableElement = ({ study, setOpenViewer }) => {
  const [StudyComments, setStudyComments] = useState([]);
  const [OpenComments, setOpenComments] = useState(false);
  const [CommentCount, setCommentCount] = useState(0);
  const DragableElementRef = useRef(null);
  const user = useRecoilValue(authAtom);

  const studyShareByLink = useRecoilValue(StudyShareByLink);
  const folderShared = useRecoilValue(FolderShared);
  const navigate = useNavigate();
  const BackHandler = () => {
    SetLocalStorage("viIsOp", false);
    setOpenViewer(false);
  };
  const CommentHandler = () => {
    setOpenComments(!OpenComments);
  };

  useEffect(() => {
    if (study.code === undefined) {
      return;
    }
    fetchData(
      "GET",
      `/comment/?studyCode=${study.code}`,
      {},
      { "x-share-id": studyShareByLink.linkId }
    ).then((res) => {
      setStudyComments(res.data.data.comments);
      if (
        res.data.data.comments != undefined &&
        res.data.data.comments.length != 0
      ) {
        setCommentCount(res.data.data.comments.length);
      }
    });
  }, [study.code]);

  return (
    <>
      <div className="dicomV absolute !z-50 right-0 top-0 bottom-0 ">
        <Draggable
          axis="y"
          handle="span"
          defaultPosition={{ x: 0, y: 0 }}
          position={null}
          grid={[40, 40]}
          scale={1}
          bounds={"parent"}
        >
          <div
            ref={DragableElementRef}
            className="box flex flex-col !absolute !z-50  md:divide-y-2 top-1/3 rounded-l-dicom-10 right-0  bg-[#16322c] md:px-dicom-10 md:py-dicom-15"
          >
            <span
              className="flex flex-col items-center justify-center px-2 md:px-[18px] py-dicom-10 text-white"
              title="Drag and move this box"
            >
              <svg
                viewBox="0 0 25 25"
                fill="none"
                className="w-[25px] h-[25px]"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.85116 5.81946L12.8512 2.81946L15.8512 5.81946"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.8512 22.3195V2.81946"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.8512 19.3195L12.8512 22.3195L9.85116 19.3195"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="hidden md:block text-[10px]">Move</p>
            </span>

            <span
              onClick={() => navigate("/dashboard/my-dicom-wallet")}
              className=" flex md:hidden flex-col items-center justify-center px-2 md:px-[18px] py-dicom-10 text-white"
              title="Drag and move this box"
            >
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 12C8.5 11.4477 8.94772 11 9.5 11H15.5C16.0523 11 16.5 11.4477 16.5 12C16.5 12.5523 16.0523 13 15.5 13H9.5C8.94772 13 8.5 12.5523 8.5 12Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.5 3C3.39543 3 2.5 3.89543 2.5 5V7C2.5 8.10457 3.39543 9 4.5 9L4.5 18C4.5 19.6569 5.84315 21 7.5 21H17.5C19.1569 21 20.5 19.6569 20.5 18V9C21.6046 9 22.5 8.10457 22.5 7V5C22.5 3.89543 21.6046 3 20.5 3H4.5ZM18.5 9H6.5V18C6.5 18.5523 6.94772 19 7.5 19H17.5C18.0523 19 18.5 18.5523 18.5 18V9ZM20.5 7V5H4.5V7H20.5Z"
                  fill="white"
                />
              </svg>
              <p className="hidden md:block text-[10px]">Home</p>
            </span>

            <UserMePesrmissionShow
              user={GetLocalStorage("login_user")}
              userId={study.userId}
            >
              <button
                onClick={BackHandler}
                className="flex flex-col items-center justify-center md:px-[18px] py-dicom-10 text-white relative"
                title="Click and go to properties"
              >
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.29289 7.29289C8.68342 6.90237 9.31658 6.90237 9.70711 7.29289C10.0976 7.68342 10.0976 8.31658 9.70711 8.70711L8.29289 7.29289ZM5 12L4.29289 12.7071L3.58579 12L4.29289 11.2929L5 12ZM9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071C9.31658 17.0976 8.68342 17.0976 8.29289 16.7071L9.70711 15.2929ZM19 11C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13V11ZM9.70711 8.70711L5.70711 12.7071L4.29289 11.2929L8.29289 7.29289L9.70711 8.70711ZM5.70711 11.2929L9.70711 15.2929L8.29289 16.7071L4.29289 12.7071L5.70711 11.2929ZM5 11H19V13H5V11Z"
                    fill="white"
                  />
                </svg>
                <p class=" hidden md:block text-[10px]">
                  Properties<div></div>
                </p>
              </button>
            </UserMePesrmissionShow>

          
        
            {user.id!==null && (study?.subscribers?.filter(item=>item.userId == user.id)[0]?.access || studyShareByLink.accessLevel == 2 || folderShared.accessLevel==2)  && (
              <button
                onClick={BackHandler}
                className="flex  flex-col items-center justify-center md:px-[18px] py-dicom-10 text-white relative"
                title="Click and go to properties"
              >
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.29289 7.29289C8.68342 6.90237 9.31658 6.90237 9.70711 7.29289C10.0976 7.68342 10.0976 8.31658 9.70711 8.70711L8.29289 7.29289ZM5 12L4.29289 12.7071L3.58579 12L4.29289 11.2929L5 12ZM9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071C9.31658 17.0976 8.68342 17.0976 8.29289 16.7071L9.70711 15.2929ZM19 11C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13V11ZM9.70711 8.70711L5.70711 12.7071L4.29289 11.2929L8.29289 7.29289L9.70711 8.70711ZM5.70711 11.2929L9.70711 15.2929L8.29289 16.7071L4.29289 12.7071L5.70711 11.2929ZM5 11H19V13H5V11Z"
                    fill="white"
                  />
                </svg>
                <p class="hidden md:block text-[10px]">
                  Properties<div></div>
                </p>
              </button>
            )}

            <>
              {OpenComments ? (
                <div className="absolute right-[50px] md:right-[120px] h-[450px] w-80 top-0 bg-white p-dicom-15 rounded-dicom-10">
                  <button
                    onClick={() => {
                      // if(DragableElementRef.current!=null){
                      //   DragableElementRef.current.classList.toggle("bg-[#16322c]")
                      //    }
                      setOpenComments(!OpenComments);
                    }}
                    className="transition-all duration-300 ease-in-out active:scale-95 p-0 float-right"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 8L8 16M8 8L16 16"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>
                  <Comments
                    className={"!h-[200px]"}
                    studyCode={study.code}
                    StudyComments={StudyComments}
                    setStudyComments={setStudyComments}
                  />
                </div>
              ) : (
                ""
              )}
              <button
                onClick={CommentHandler}
                className="flex flex-col text-center items-center justify-center md:px-[18px] py-dicom-10 text-white relative"
                title="Add comment/document for this study"
              >
                <IconCommentsBlack color="white" />
                {CommentCount > 0 && (
                  <span className="text-sm absolute top-0 right-1 md:right-4">
                    <span className="w-[5px] h-[5px] mr-[2px] inline-block bg-red-600 rounded-full"></span>
                    {CommentCount}
                  </span>
                )}
                <p className="text-[10px] hidden md:block text-center">
                  Comments and documents
                </p>
              </button>
            </>
            <DownloadStudyOnViewerPage study={study} />
          </div>
        </Draggable>
      </div>
    </>
  );
};

export default DragableElement;
