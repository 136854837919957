import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

// components
import Layout from "../../containers/Layout";
import useDocumentTitle from "../../utils/useDocumentTitle";
import { Input } from "../../components/DesignSystem/inputs";
import { DicomButton } from "../../components/DesignSystem/buttons";
import Socialbtns from "../../components/socialbtns";
import { fetchData } from "../../services/fetching";

const ForgetPassword = () => {
  useDocumentTitle("Forget Password", true);

  const [isLoading, setisLoading] = useState(false);

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("The Email Address is required")
      .email("Email is invalid."),
  });
  const onSubmit = () => {
    setisLoading(true);
    fetchData("POST", "/user/forget-password", formik.values)
      .then((res) => {
        if (res.data.data.hash) {
          setisLoading(false);
          toast.success(
            "A password recovery email has been sent to you, please check"
          );
        }
      })
      .catch((err) => {
        setisLoading(false);
        toast.error("not found");
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnMount: true,
  });

  return (
    <>
      <Layout isAuthPage={true} isLoginPage={true}>
        <div className="py-16 grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-12 gap-dicom-30">
          <div className="order-1 col-span-3 md:col-span-3 lg:col-span-5 xl:col-span-9 grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-12">
            <div className="relative space-y-dicom-30 col-span-3 md:col-span-12 lg:col-span-5 xl:col-span-5 pt-dicom-30">
              <div className="absolute !z-0 -left-44 -top-36">
                <svg
                  className="!w-[100%]"
                  width="547"
                  height="560"
                  viewBox="0 0 547 560"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.2" filter="url(#filter0_f_56_1543)">
                    <circle cx="267" cy="280" r="174" fill="#1DD3B0" />
                  </g>
                  <defs>
                    <filter
                      id="filter0_f_56_1543"
                      x="-13"
                      y="0"
                      width="560"
                      height="560"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feGaussianBlur
                        stdDeviation="53"
                        result="effect1_foregroundBlur_56_1543"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
              <div className="relative !z-10 space-y-dicom-30">
                <h1 className="text-dicom-headline1 md:text-dicom-headline1 lg:text-[40px] xl:text-dicom-headline1 font-bold text-dicom-primary-200">
                  Have you forgotten your password?
                </h1>
              </div>
            </div>
            <div className="col-span-12 md:col-span-12 lg:col-span-7 xl:col-span-7">
              <img className="w-[450px]" src="/images/auth.png" alt="" />
            </div>
          </div>
          <div className="relative -order-1 md:order-1 col-span-3 md:col-span-3 lg:col-span-3 xl:col-span-3">
            <div className="space-y-dicom-15">
              <Input
                nameId={"email"}
                placeholder={"Enter Email address"}
                formik={formik}
              />

              <DicomButton
                disabled={!formik.isValid ? true : false || isLoading}
                isLoading={isLoading}
                type={"submit"}
                onClick={onSubmit}
                title={"Change password"}
                btnClass={"w-full"}
              />
              <Socialbtns title={"Or continue with"} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ForgetPassword;
