import React from "react";
import { Toaster } from "react-hot-toast";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import { RecoilRoot } from "recoil";
import "react-tooltip/dist/react-tooltip.css";
//
import App from "./App";
import "./styles/index.scss";
import { ContextProvider } from "./Context";
// use env setting
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <RecoilRoot>
      <BrowserRouter>
        <Toaster
          toastOptions={{
            duration: 8000,
          }}
        />
        <ContextProvider>
          <App />
        </ContextProvider>
      </BrowserRouter>
    </RecoilRoot>
  </>
);
