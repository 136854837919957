import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
    },
    title: {
      display: false,
    },
  },
};

let ApiData = [
  {
    name: "Doctor",
    On_Diocom_wallet: 7,
    On_Viewer: 10,
  },
  {
    name: "Radiologist",
    On_Diocom_wallet: 32,
    On_Viewer: 7,
  },
  {
    name: "Medical Students",
    On_Diocom_wallet: 10,
    On_Viewer: 32,
  },
];

export const data = {
  labels: ApiData?.map((x) => x.name),
  datasets: [
    {
      label: "On Diocom wallet",
      data: ApiData?.map((x) => x.On_Diocom_wallet),
      backgroundColor: "#021BFA",
    },
    {
      label: "On Viewer",
      data: ApiData?.map((x) => x.On_Viewer),
      backgroundColor: "#FD6D2F",
    },
  ],
};

const AverageTime = ({ title, className }) => {
  return (
    <>
      <div
        className={`${className} space-y-dicom-30 rounded-dicom-10 bg-white p-dicom-30`}
        title="The  Avarege time users that stay our panel"
      >
        <div className="text-dicom-headline4 text-dicom-secondary-100 font-semibold">
          {title}
        </div>
        <Bar options={options} data={data} />
      </div>
    </>
  );
};

export default AverageTime;
