import React from "react"
import Invlices from "../../../components/payments/invlices"
import AdminLayout from "../../../containers/AdminLayout"

export default function Billings() {
  return (
    <AdminLayout>
      <div className='bg-white min-h-screen py-5 px-10'>
        <Invlices />
      </div>
    </AdminLayout>
  )
}
