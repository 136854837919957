import { atom } from "recoil";

const authAtom = atom({
    key: "auth",
    default: null,
});

const authLoadingAtom = atom({
	key: "authLoading",
	default: true,
});

export { authAtom, authLoadingAtom };