import { atom } from "recoil";
const getDefaultFromSessionStorage = () => {
    const sessionValue = sessionStorage.getItem("user_action");
    return sessionValue ? JSON.parse(sessionValue) : [];
  };
  
export const contextMenuAtom = atom({
    key: "contextMenu",
    default: getDefaultFromSessionStorage(),
});
export const  ContextMenuPosition = atom({
    key: "contextMenuPosition",
    default: { top: 0,
      left: 0,}
});
export const  ContextMenuVisible = atom({
    key: "contextMenuVisible",
    default: false
});


