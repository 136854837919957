export const SetLocalStorage = (key, value) => {
  if (key === "login_key") {
    SetLocalStorage("showBetaText", true);
  }
  return localStorage.setItem(key, JSON.stringify(value));
};

export const GetLocalStorage = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (err) {}
  return null;
};

export const RemoveLocalStorage = (key) => {
  return localStorage.removeItem(key);
};
