import fileDownload from "js-file-download";
import React, { useState } from "react";
import { fetchDownloadFile } from "../../../services/fetching";
import { DicomButton } from "../../../components/DesignSystem/buttons";

const DownloadUsersExcel = () => {
  const [downloadIsLoading, setdownloadIsLoading] = useState(false);
  return (
    <>
      <DicomButton
        onClick={() => {
          setdownloadIsLoading(true);
          fetchDownloadFile("GET", `/admin/user-excel`).then(
            (response) => {
              setdownloadIsLoading(false);
              fileDownload(response.data, `users-excel.xlsx`);
            }
          );
        }}
        btnClass={"!bg-dicom-secondary-100 !px-[10px] !border-dicom-secondary-100/0 hover:!bg-dicom-secondary-hover-100"}
        iconL={
          downloadIsLoading ? (
            <svg
              className="text-black animate-spin"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_801_5077)">
                <path
                  d="M16.8396 21.9227C17.072 22.3992 16.8753 22.9778 16.3817 23.1714C14.7785 23.8002 13.055 24.078 11.3281 23.9812C9.28099 23.8664 7.29743 23.229 5.56664 22.1298C3.83586 21.0305 2.41559 19.5062 1.44131 17.7021C0.467042 15.898 -0.0287318 13.8744 0.00128644 11.8243C0.0313048 9.77419 0.586114 7.76596 1.61279 5.99119C2.63946 4.21641 4.10376 2.7343 5.86598 1.68623C7.62821 0.638166 9.62958 0.0591049 11.6792 0.00429001C13.4082 -0.04195 15.1228 0.286166 16.7069 0.961651C17.1946 1.16961 17.3743 1.75374 17.1281 2.22326C16.8818 2.69279 16.3029 2.86912 15.812 2.66861C14.5211 2.14126 13.1314 1.88614 11.7305 1.9236C10.0088 1.96965 8.32769 2.45606 6.84742 3.33644C5.36715 4.21681 4.13715 5.46179 3.27474 6.9526C2.41234 8.44341 1.9463 10.1303 1.92108 11.8524C1.89587 13.5745 2.31232 15.2744 3.1307 16.7898C3.94909 18.3052 5.14212 19.5857 6.59598 20.509C8.04984 21.4323 9.71603 21.9678 11.4356 22.0642C12.8348 22.1427 14.2314 21.9283 15.5372 21.439C16.0337 21.2529 16.6072 21.4462 16.8396 21.9227Z"
                  fill="currentColor"
                />
                <path
                  d="M22.7657 14.4446C23.2827 14.562 23.8011 14.2382 23.8768 13.7134C24.1505 11.8158 23.9662 9.87512 23.3329 8.05557C22.6996 6.23601 21.6391 4.60027 20.2463 3.28267C19.8611 2.91831 19.2538 2.98635 18.9214 3.3994C18.5889 3.81244 18.6581 4.41368 19.0376 4.78386C20.1531 5.87179 21.0045 7.20688 21.5196 8.68669C22.0346 10.1665 22.1962 11.7417 21.9972 13.2871C21.9295 13.813 22.2486 14.3272 22.7657 14.4446Z"
                  fill="currentColor"
                  fillOpacity="0.3"
                />
                <path
                  d="M18.0967 21.204C18.3895 21.646 18.9879 21.7699 19.4051 21.4428C20.7442 20.3927 21.8448 19.0696 22.6334 17.5617C22.8792 17.0919 22.6483 16.526 22.1604 16.3186C21.6724 16.1112 21.1127 16.3414 20.8599 16.8074C20.2275 17.9728 19.3713 19.0022 18.3405 19.8362C17.9283 20.1697 17.8039 20.762 18.0967 21.204Z"
                  fill="currentColor"
                  fillOpacity="0.3"
                />
              </g>
              <defs>
                <clipPath id="clip0_801_5077">
                  <rect width="24" height="24" fill="currentColor" />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.5 7C15.5 8.65685 14.1569 10 12.5 10C10.8431 10 9.5 8.65685 9.5 7C9.5 5.34315 10.8431 4 12.5 4C14.1569 4 15.5 5.34315 15.5 7Z" stroke="white" strokeWidth="2"></path>
              <path d="M5.5 19.5C5.5 15.9101 8.41015 13 12 13H13C16.5899 13 19.5 15.9101 19.5 19.5V20C19.5 20.5523 19.0523 21 18.5 21H6.5C5.94772 21 5.5 20.5523 5.5 20V19.5Z" stroke="white" strokeWidth="2">
              </path>
            </svg>
          )
        }
        title={"Users excel"}
      />
    </>
  );
};

export default DownloadUsersExcel;
