import { useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { IconCheckMark, IconEditOnly, IconFullAccess, IconViewOnly } from "../../../../components/DesignSystem/svgIcons"

export const SVGICon = [
  <IconFullAccess width={14} height={14} />,
  <IconEditOnly width={14} height={14} />,
  <IconViewOnly width={14} height={14} />
]

export const CheckMark = () => {
  return <IconCheckMark />
}

export const AccessDropdown = ({
  handleAccessChange,
  initialAccessLevel,
  className,
  innerClassName,
  removeAccess,
  user,
  handleDeleteAccess
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)
  const [selected, setSelected] = useState({
    icon: "",
    lable: "",
    value: 0
  })
  const [dropdownTop, setDropdownTop] = useState(false)
  const labelRef = useRef(null)
  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }
  useEffect(() => {
    switch (initialAccessLevel) {
      case 0:
        setSelected({
          icon: SVGICon[2],
          lable: "View Only",
          value: 0
        })
        break
      case 1:
        setSelected({
          icon: SVGICon[1],
          lable: "Edit Only",
          value: 1
        })
        break
      case 2:
        setSelected({
          icon: SVGICon[0],
          lable: "Full Access",
          value: 2
        })
        break
      default:
        break
    }
  }, [initialAccessLevel])
  const handleAccess = (meta) => {
    setSelected(meta)
    setIsOpen(false)
    handleAccessChange(meta, user)
  }

  const handleClickOutside = (event) => {
    let target = event.target
    let isMenuItem = false

    while (target) {
      if (target.classList && target.classList.contains("menu-item")) {
        isMenuItem = true
        break
      }
      target = target.parentElement
    }
    if (!isMenuItem && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    const calculateDropdownPosition = () => {
      if (!isOpen) return
      const parentElement = document.getElementById("people-with-access")
      const parentTop = parentElement.getBoundingClientRect().top
      const labelTop = labelRef.current.getBoundingClientRect().bottom
      const distanceFromParentTop = labelTop - parentTop
      const dropdownHeight = document.getElementById("access-dropdown").clientHeight

      if (
        parentElement.clientHeight > dropdownHeight &&
        parentElement.clientHeight - distanceFromParentTop >= dropdownHeight
      ) {
        setDropdownTop(true)
      } else {
        setDropdownTop(false)
      }
    }
    calculateDropdownPosition()
  }, [isOpen])

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (isOpen) {
        toggleDropdown()
      }
    }

    const parentElement = document.getElementById("people-with-access")
    parentElement.addEventListener("scroll", handleScroll)

    return () => {
      parentElement.removeEventListener("scroll", handleScroll)
    }
  }, [isOpen])

  return (
    <>
      <div
        onClick={toggleDropdown}
        ref={dropdownRef}
        className={`relative min-w-[80px] ${className}  rounded-md text-dicom-text-100 px-1 py-2 flex items-center justify-between cursor-pointer`}>
        <span className='flex items-center text-xs font-semibold' ref={labelRef}>
          {selected.lable}
        </span>
        <span>
          <svg xmlns='http://www.w3.org/2000/svg' width={11} height={11} viewBox='0 0 18 18' fill='none'>
            <path
              d='M9.00002 12.6005C8.47502 12.6005 7.95001 12.398 7.55251 12.0005L2.66251 7.11047C2.44501 6.89297 2.44501 6.53297 2.66251 6.31547C2.88001 6.09797 3.24001 6.09797 3.45751 6.31547L8.34751 11.2055C8.70751 11.5655 9.29251 11.5655 9.65251 11.2055L14.5425 6.31547C14.76 6.09797 15.12 6.09797 15.3375 6.31547C15.555 6.53297 15.555 6.89297 15.3375 7.11047L10.4475 12.0005C10.05 12.398 9.52502 12.6005 9.00002 12.6005Z'
              fill='#1A2128'
            />
          </svg>
        </span>
        {isOpen && (
          <div
            id='access-dropdown'
            className={`bg-white menu-item ${
              !dropdownTop ? "bottom-6" : "top-6"
            } absolute w-36 z-[11] ${innerClassName}  drop-shadow-sm border border-gray-200 rounded-md mt-1 px-3 `}>
            <div
              onClick={() => {
                handleAccess({
                  icon: SVGICon[2],
                  lable: "View Only",
                  value: 0
                })
              }}
              className='py-1  flex gap-2 items-center  border-gray-100 cursor-pointer'>
              <span className={`${selected.value == 0 ? "opacity-1" : "opacity-0"}`}>
                <CheckMark />
              </span>

              <div className='flex gap-3 items-center justify-center'>
                {SVGICon[2]}

                <div className='flex flex-col'>
                  <span className='font-semibold text-sm'>View Only</span>
                </div>
              </div>
            </div>

            <div
              onClick={() => {
                handleAccess({
                  icon: SVGICon[1],
                  lable: "Edit Only",
                  value: 1
                })
              }}
              className='py-1 flex gap-2 items-center  border-gray-100 cursor-pointer'>
              <span className={`${selected.value == 1 ? "opacity-1" : "opacity-0"}`}>
                <CheckMark />
              </span>

              <div className='flex gap-3 items-center justify-center'>
                {SVGICon[1]}

                <div className='flex flex-col'>
                  <span className='font-semibold text-sm'>Edit Only</span>
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                handleAccess({
                  icon: SVGICon[0],
                  lable: "Full Access",
                  value: 2
                })
              }}
              className='py-1 flex gap-2 group items-center  cursor-pointer'>
              <span aria-label='check' className={`${selected.value == 2 ? "opacity-1" : "opacity-0"}`}>
                <CheckMark />
              </span>

              <div className='flex gap-3 items-center justify-center'>
                {SVGICon[0]}
                <div className='flex flex-col'>
                  <span className='font-semibold text-sm'>Full Access</span>
                </div>
              </div>
            </div>
            {removeAccess && (
              <div
                onClick={() => {
                  handleDeleteAccess(user.email)
                }}
                className='py-1 flex gap-2 group items-center  cursor-pointer'>
                <span aria-label='check' className={`${selected.value == -1 ? "opacity-1" : "opacity-0"}`}>
                  <CheckMark />
                </span>

                <div className='flex gap-3 items-center justify-center'>
                  <svg
                    width={12}
                    height={14}
                    viewBox='0 0 10 10'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    classname='inline'>
                    <path
                      d='M8 4.01245L4 8.01245M4 4.01245L8 8.01245'
                      stroke='red'
                      strokewidth={3}
                      strokelinecap='round'
                    />
                  </svg>

                  <div className='flex flex-col'>
                    <span className='font-semibold text-sm'>Remove</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}
