import React, { useEffect, useState } from "react"
import AdminLayout from "../../../containers/AdminLayout"
import Stripe from "../../../components/stripe"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { IconRightChevron } from "../../../components/DesignSystem/svgIcons"
import { formatCurrency } from "../../../utils/functions"
import { fetchData } from "../../../services/fetching"
import toast from "react-hot-toast"
import { useRecoilValue } from "recoil"
import { authAtom } from "../../../atom/auth"
import { DicomButtonLabel } from "../../../components/DesignSystem/buttons"
import { GetLocalStorage, RemoveLocalStorage, SetLocalStorage } from "../../../services/LocalStorage"
export default function Payments() {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const [price, setprice] = useState()
  const userAuth = useRecoilValue(authAtom)
  const navigate = useNavigate()

  useEffect(() => {
    return navigate("/dashboard") // remove this for enabling payments

    if (userAuth?.currentSubscrionId == null && query.get("product") != null) getProductDetails()
  }, [])

  const getProductDetails = () => {
    fetchData("GET", `/payments/subscriptions/${query.get("product")}`)
      .then((res) => {
        setprice(res.data.data.price)
      })
      .catch((err) => {
        console.error(err)
        toast.error("Problem getting Price details")
      })
  }

  return (
    <AdminLayout>
      <div className='px-10 py-5 bg-white min-h-screen'>
        <BreadCrums />
        {userAuth?.currentSubscrionId == null && query.get("product") != null ? (
          <div className='grid grid-cols-2 gap-8 mt-5'>
            <>
              <PriceCard title='Price details' className='h-fit'>
                <PriceTotal price={price} />
              </PriceCard>
              <PriceCard title='Pay'>
                <Stripe price={price} />
              </PriceCard>
            </>
          </div>
        ) : (
          <ExistingSubscription />
        )}
      </div>
    </AdminLayout>
  )
}

const ExistingSubscription = () => {
  const currentUser = useRecoilValue(authAtom)

  const handleUnsubscribe = () => {
    toast
      .promise(fetchData("POST", "/payments/cancel-subscription"), {
        loading: "Processing",
        error: "Problem cancelling you're subscription",
        success: "You're Subscription has been canceled"
      })
      .then(() => {
        window.location.reload()
      })
  }
  const navigate = useNavigate()
  return (
    <div className='max-w-xl mx-auto'>
      <dialog id='cancelModel' className='modal'>
        <div className='modal-box'>
          <form method='dialog'>
            {/* if there is a button in form, it will close the modal */}
            <button className='btn btn-sm btn-circle btn-ghost absolute right-2 top-2'>✕</button>
          </form>
          <h3 className='font-bold text-lg'>Warning!</h3>
          <p className='py-4'>You're subscription will end immediacy, are you sure?</p>
          <DicomButtonLabel onClick={handleUnsubscribe} title='Yes cancel' btnClass='mt-2' />
        </div>
      </dialog>

      <PriceCard title='Subscription' className='h-fit mt-5 '>
        <div className='bg-[#f7f8fc] flex items-center justify-center py-5'>
          <div>
            <span className='text-dicom-text-100 font-semibold block capitalize text-center'>
              {currentUser.currentPlan}
            </span>
            <span className=' font-bold block text-2xl text-black text-center'>
              {currentUser.currentPlan === "premium"
                ? formatCurrency(20)
                : currentUser.currentPlan === "professional"
                ? formatCurrency(50)
                : ""}
            </span>
            {currentUser.currentPlan !== "free" && (
              <span className='font-semibold'>
                Per Month <span className='text-xs'>+ VAT</span>
              </span>
            )}
            {currentUser.currentPlan === "free" && (
              <>
                <span span className='text-gray-700'>
                  Create a subscription now! Check our latest subscription plans today
                </span>
                <DicomButtonLabel title='Plans' onClick={() => navigate("/pricing")} btnClass='mt-2' />
              </>
            )}
          </div>
        </div>
        <div className='flex gap-3 items-center justify-center'>
          {currentUser.currentPlan !== "free" && (
            <DicomButtonLabel
              onClick={() => document.getElementById("cancelModel").showModal()}
              title='Cancel Subscription'
              btnClass='mt-2'
            />
          )}
        </div>
      </PriceCard>
    </div>
  )
}
const BreadCrums = () => {
  const items = ["My DICOMWallet", "Payment"]

  return (
    <div className='flex items-center gap-2'>
      {items.map((item, index) => (
        <span className='flex items-center text-gray-400 font-semibold gap-2 cursor-default' key={index}>
          {index === 0 ? (
            <Link className='text-gray-500' to={`/dashboard/${item.toLowerCase().replaceAll(" ", "-")}`}>
              {item}
            </Link>
          ) : (
            <>
              <span className=''>
                {" "}
                <IconRightChevron />{" "}
              </span>
              {item}
            </>
          )}
        </span>
      ))}
    </div>
  )
}

const PriceTotal = ({ price }) => {
  return (
    <>
      <div className='space-y-3'>
        {price !== undefined ? (
          <div className='flex items-center justify-between text-gray-600'>
            <span className=''>Plan title</span>
            <span>{formatCurrency(price)}</span>
          </div>
        ) : (
          <div className='animate-pulse h-[34px] bg-gray-200 rounded-lg'></div>
        )}
        {price !== undefined ? (
          <div className='flex items-center justify-between text-gray-600'>
            <span>Taxes</span>
            <span>{formatCurrency(price)}</span>
          </div>
        ) : (
          <div className='animate-pulse h-[34px] bg-gray-200 rounded-lg'></div>
        )}
      </div>
      <div className='divider my-1'></div>
      {price !== undefined ? (
        <div className='flex items-center justify-between text-gray-800 font-semibold'>
          <span>Total</span>
          <span>{formatCurrency(price)}</span>
        </div>
      ) : (
        <div className='animate-pulse h-[34px] bg-gray-200 rounded-lg'></div>
      )}
    </>
  )
}
const PriceCard = ({ children, className, title }) => {
  return (
    <div className={`rounded-lg px-5 py-3 border border-gray-200 ${className}`}>
      <h3 className='text-lg text-dicom-secondary-100 font-semibold'>{title}</h3>
      <div className='divider my-1'></div>
      {children}
    </div>
  )
}
