import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import {
  DicomButton,
  DicomButtonOutlineLabel,
} from "../../../components/DesignSystem/buttons";
import { fetchData } from "../../../services/fetching";

const SetEmail = ({ modalId }) => {
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formHandler = () => {
    setIsLoading(true);
    fetchData("PUT", "/admin/settings/overall", {
      emails: emails.map(e => e.trim())
    }).then((res) => {
      if (res.data.data) {
        toast.success("Email(s) set for contactus");
        document.getElementById(modalId).checked = false;
      }
    }).catch(err => {
      console.log(err);
      toast.error("request error[" + err.response.status + "]:\n " + err.message);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleChange = (e) => {
    setEmails(e.target.value.split(';'));
  };

  useEffect(() => {
    fetchData(
      "GET",
      `/admin/settings/`
    ).then((res) => {
      if (res.data) {
        setEmails(res?.data?.data?.settings?.contactUsEmails);
      }
    })
  }, []);

  return (
    <>
      <p>Enter email for send contact us form to it. You can enter multiple emails seprating by ";" semicolon:</p>
      <input
        type="text"
        defaultValue={emails.join(";")}
        onChange={handleChange}
        placeholder="Enter the emails..."
        className="input input-bordered !appearance-none input-md w-full !outline-none"
      />

      <div className="flex items-center justify-center gap-x-[20px]">
        <DicomButtonOutlineLabel
          onClick={() => {
            document.getElementById(modalId).checked = false;
          }}
          btnClass={
            "!border-dicom-secondary-100 !text-dicom-secondary-100 hover:!bg-dicom-secondary-100/5"
          }
          title={"Cancel"}
        />
        <DicomButton
          isLoading={isLoading}
          onClick={formHandler}
          btnClass={"!bg-dicom-secondary-100 hover:!bg-dicom-secondary-100"}
          title={"Set emails"}
        />
      </div>
    </>
  );
};

export default SetEmail;
