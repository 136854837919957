import { IconButton } from "@mui/material"
import { useEffect, useState } from "react"
import { fetchData } from "../../services/fetching"
import toast from "react-hot-toast"
import {
  IconAnonomize0,
  IconAnonomize1,
  IconAnonomize2,
  IconStarEmpty,
  IconStarFilled,
  IconStarLoading
} from "../DesignSystem/svgIcons"
import useWindowDimensions from "../../utils/windowDimensions"
import { useNavigate } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { UserStudies, UserStudiesURL } from "../../atom/userStudies"

export const IsXlScreen = () => {
  const { width } = useWindowDimensions()
  return width >= 1280
}

export const StarAction = ({ study }) => {
  const [disableStarObject, setDisableStarObject] = useState(false)
  const [studies, setStudeis] = useRecoilState(UserStudies)
  const studyUrl = useRecoilValue(UserStudiesURL)
  return (
    <>
      <IconButton
        disabled={study.type === "folder" ? true : disableStarObject || false}
        onClick={() => {
          // set loading
          setDisableStarObject(true)

          //call request

          fetchData("PATCH", "/study/bulk-toggle-starred", {
            codes: [study.code]
          })
            .then((res) => {
              if (res.data.data.result) {
                toast.success("Star status of study changed")
              }

              let newStudies = studies.studies
              newStudies = newStudies.map((item) =>
                item.code === study.code ? { ...item, starred: !study.starred } : item
              )

              setStudeis((prev) => ({ ...prev, studies: newStudies }))
            })
            .finally(() => {
              setDisableStarObject(false)
            })
            .catch((err) => {
              if (err?.response.status == 401) {
                toast.error("Session expired!!")
                window.location.replace("/auth/login")
              }
            })
        }}>
        {disableStarObject ? <IconStarLoading /> : <>{study.starred ? <IconStarFilled /> : <IconStarEmpty />}</>}
      </IconButton>
    </>
  )
}

export const AnonyomizeStatus = ({ study }) => {
  return (
    <>
      {study.anonymization === 2 ? (
        <div className='flex items-center text-[#1DD3B0]' title='Anonymity status: Fully anonymized'>
          <IconAnonomize2 />
        </div>
      ) : study.anonymization === 1 ? (
        <div className='flex items-center text-[#FFB341]' title='Anonymity status: Anonymized when sharing'>
          <IconAnonomize1 />
        </div>
      ) : (
        <div className='flex items-center text-[#FF4141]' title='Anonymity status: Original'>
          <IconAnonomize0 />
        </div>
      )}
    </>
  )
}

export const StudyImagePreview = ({ study, checkFreeze }) => {
  const [hasError, setHasError] = useState(false)
  const navigate = useNavigate()
  const handleError = (e) => {
    console.log(e)
    setHasError(true)
  }

  if (hasError)
    return (
      <img
        className={`${
          IsXlScreen() ? "h-[36px] w-[36px] bg-gray-200" : "h-[45px] w-[45px] bg-gray-200"
        } text-center flex justify-center items-center rounded-dicom-10`}
        onClick={() => {
          if (!checkFreeze) navigate(`${study.code}`)
        }}
        alt={study?.title.slice(0, 2) || "SP"}
      />
    )

  return (
    <img
      className={`${IsXlScreen() ? "h-[36px]" : "h-[45px]"} rounded-dicom-10`}
      onClick={() => {
        if (!checkFreeze) navigate(`${study.code}`)
      }}
      src={`${process.env.REACT_APP_API_URL}/study/${study.code}/preview`}
      alt='Study Preview'
      onError={handleError}
    />
  )
}
