import React from "react";
import Layout from "../../containers/Layout";
import { NavLink, useLocation } from "react-router-dom";
import TermOfService from "./TermOfService";
import AcceptableUse from "./AcceptableUse";
import PrivacyPolicy from "./PrivacyPolicy";
import CookiePolicy from "./CookiePolicy";
import RecommendationForUserCompliance from "./RecommendationForUserCompliance";
import OpenSource from "./OpenSource";

let LegalandComplanceData = [
  {
    id: 1,
    title: "Terms of Service",
    url: "term-of-service",
    data: {
      title: "",
      text: <TermOfService />,
    },
  },
  {
    id: 2,
    title: "Acceptable Use",
    url: "acceptable-use",
    data: {
      title: "",
      text: <AcceptableUse />,
    },
  },
  {
    id: 3,
    title: "Privacy Policy",
    url: "privacy-policy",
    data: {
      title: "",
      text: <PrivacyPolicy />,
    },
  },
  {
    id: 4,
    title: "Cookie Policy",
    url: "cookie-policy",
    data: {
      title: "",
      text: <CookiePolicy />,
    },
  },
  {
    id: 5,
    title: "User Compliance",
    url: "user-compliance",
    data: {
      title: "",
      text: <RecommendationForUserCompliance />,
    },
  },
  {
    id: 6,
    title: "Open Source",
    url: "open-source",
    data: {
      title: "",
      text: <OpenSource />,
    },
  },
];

const LegalandComplance = () => {
  const location = useLocation();
  const currentLocation = location.pathname.split("/");
  return (
    <>
      <Layout>
        <div className="mt-[56px] lg:mt-0 space-y-[38px]">
          <ul className="flex justify-between overflow-x-scroll gap-4 lg:overflow-hidden whitespace-nowrap">
            {LegalandComplanceData.map((item) => (
              <NavLink key={item.id} to={item.url}>
                <div
                  className={`py-dicom-30 ${
                    currentLocation.includes(item.url)
                      ? "border-b-2 border-b-dicom-primary-100"
                      : ""
                  }`}
                >
                  {item.title}
                </div>
              </NavLink>
            ))}
          </ul>
          {LegalandComplanceData.map((item) =>
            currentLocation.includes(item.url) ? (
              <div key={item.id} className="space-y-dicom-15">
                <p className="text-dicom-body1 text-dicom-text-100">
                  {item.data.title ? item.data.title : ""}
                </p>
                <div className="prose-xl prose-li:list-disc prose-a:text-dicom-primary-100 text-dicom-body2 text-dicom-text-100">
                  {item.data.text}
                </div>
              </div>
            ) : (
              ""
            )
          )}
        </div>
      </Layout>
    </>
  );
};

export default LegalandComplance;
