import { useRecoilState } from "recoil";
import { PaginationMeta } from "../../atom/userStudies";
import { useEffect } from "react";

export const PaginationFooter = () => {
  const [paginationMeta, setPaginationMeta] = useRecoilState(PaginationMeta);

  const handleSelection = (e) => {
    setPaginationMeta((old) => ({
      ...old,
      pageSize: parseInt(e.target.value),
    }));
  };
  const handlePageClick = (type) => {
    const { page, pageSize, total } = paginationMeta;

    if (type === "forward") {
      const newPage = page + pageSize;

      if (newPage < total) {
        setPaginationMeta((old) => ({ ...old, page: newPage }));
      }
    } else if (type === "backward") {
      const newPage = page - pageSize;
      if (newPage >= 0) {
        setPaginationMeta((old) => ({ ...old, page: newPage }));
      }
    }
  };
  return (
    <div className="border-t border-gray-50  py-5">
      <nav
        className="flex items-center flex-column flex-wrap md:flex-row justify-between"
        aria-label="Table navigation"
      >
        <span className="text-sm font-normal text-gray-500  mb-4 md:mb-0 block w-full md:inline md:w-auto">
          <div>
            Showing{" "}
            <span className="font-semibold text-gray-900 ">
              {paginationMeta.page + 1}-
              {Math.min(
                paginationMeta.page + paginationMeta.pageSize,
                paginationMeta.total
              )}
            </span>{" "}
            of{" "}
            <span className="font-semibold text-gray-900 ">
              {paginationMeta.total}
            </span>
          </div>
        </span>
        <div className="gap-2 flex">
          <label htmlFor="selctionRow" className="text-sm xl:text-base">
            Rows per page:
            <select
              id="selctionRow"
              onChange={handleSelection}
              className="bg-gray-100  ml-1 border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500  px-2.5 py-1"
              defaultValue={paginationMeta.pageSize}
            >
              <option>5</option>
              <option>10</option>
              <option>20</option>
              <option>50</option>
              <option>100</option>
            </select>
          </label>
          <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
            <li>
              <button
                onClick={() => handlePageClick("backward")}
                className={`flex items-center justify-center px-3 xl:h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg ${
                  paginationMeta.page === 0
                    ? "disabled:bg-gray-100 cursor-not-allowed"
                    : ""
                }`}
                disabled={paginationMeta.page === 0}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5 8.25 12l7.5-7.5"
                  />
                </svg>
              </button>
            </li>

            <li>
              <button
                onClick={() => handlePageClick("forward")}
                className={`flex items-center justify-center px-3 xl:h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg ${
                  paginationMeta.page + paginationMeta.pageSize >=
                  paginationMeta.total
                    ? "disabled:bg-gray-100 cursor-not-allowed"
                    : ""
                }`}
                disabled={
                  paginationMeta.page + paginationMeta.pageSize >=
                  paginationMeta.total
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};
