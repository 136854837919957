import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";

import { DicomButton } from "../../components/DesignSystem/buttons";
import { Input } from "../../components/DesignSystem/inputs";
import AdminLayout from "../../containers/AdminLayout";
import UseDocumentTitle from "../../utils/useDocumentTitle";
import { fetchData } from "../../services/fetching";

const ChangePassword = () => {
  UseDocumentTitle("dashboard | Change Password", true);
  const [isLoading, setisLoading] = useState(false);
  const [UserProfile, setUserProfile] = useState("");

  let validationObject = {
    newPassword: Yup.string()
      .min(8, "password length must be at least 8 characters long")
      .required("The New Password is required"),
    confirmPassword: Yup.string()
      .required("The Confirm Password is required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  };
  if (UserProfile?.user?.hasPassword) {
    validationObject.oldPassword = Yup.string()
      .min(8, "old password length must be at least 8 characters long")
      .required("The Old Password is required");
  }

  const validationSchema = Yup.object(validationObject);

  const onSubmit = () => {
    setisLoading(true);
    const { oldPassword, newPassword } = formik.values;
    fetchData("POST", "/user/change-password", {
      oldPassword,
      newPassword,
    })
      .then((res) => {
        if (res?.data?.data?.user) {
          setisLoading(false);
          formik.resetForm();
          toast.success("Your password has been successfully changed");
        }
      })
      .catch((error) => {
        if (error?.response?.data) {
          setisLoading(false);
          toast.error(error?.response?.data?.message);
        }
      });
  };

  const formik = useFormik({
    initialValues: UserProfile?.user,
    validationSchema,
    onSubmit,
    validateOnMount: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    fetchData("GET", "/user/profile").then((res) =>
      setUserProfile(res.data.data)
    );
  }, []);

  return (
    <>
      <AdminLayout>
        <div className="flex flex-col bg-dicom-surface-100 rounded-dicom-10 items-center justify-center py-24">
          <div className="!space-y-dicom-30 w-4/5 lg:w-1/3">
            {UserProfile?.user?.hasPassword ? (
              <Input
                label={"Old password"}
                classInput={"!bg-transparent border-dicom-primary-100"}
                nameId={"oldPassword"}
                placeholder={"Old password"}
                formik={formik}
              />
            ) : (
              ""
            )}

            <Input
              label={"New password"}
              classInput={"!bg-transparent border-dicom-primary-100"}
              nameId={"newPassword"}
              placeholder={"New password"}
              formik={formik}
            />

            <Input
              label={"Confirm Password"}
              classInput={"!bg-transparent border-dicom-primary-100"}
              nameId={"confirmPassword"}
              placeholder={"Confirm Password"}
              formik={formik}
            />

            <DicomButton
              isLoading={isLoading}
              type={"submit"}
              onClick={onSubmit}
              title={"Save"}
              btnClass={"w-full"}
            />
          </div>
        </div>
      </AdminLayout>
    </>
  );
};

export default ChangePassword;
