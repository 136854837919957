import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Breadcrumbs"
import { DownloadStudy } from "../../../components/dashboard/studyDownload/DownloadStudy"
import OhifViewerSection from "../../../components/dashboard/viewerSection"
import {
  DicomButtonLabel,
  DicomButtonN,
  DicomButtonNLabel,
  DicomButtonOutlineLabel
} from "../../../components/DesignSystem/buttons"
import Modal from "../../../components/DesignSystem/Modal"
import { IconRecycleBinLightGray } from "../../../components/DesignSystem/svgIcons"

import AdminLayout from "../../../containers/AdminLayout"
import { fetchData } from "../../../services/fetching"
import { GetLocalStorage, RemoveLocalStorage, SetLocalStorage } from "../../../services/LocalStorage"
import { UserMePesrmissionShow } from "../../../utils/Permissions"
import AddToFolderSingle from "../folders/AddToFolderSingle"
import AnonymizeStudy from "./AnonymizeStudy"
import Comments from "./comments"
import DeleteStudy from "./deleteStudy"
import EditStudy from "./editStudy"
import ShareStudy from "./ShareStudy/ShareStudy"
import Breadcrumb from "../../../components/dashboard/folders/breadCrumb"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { FolderShared, StudyShareByLink } from "../../../atom/studyShare"
import { authAtom } from "../../../atom/auth"

const StudyPage = () => {
  const { id } = useParams()
  const [OpenViewer, setOpenViewer] = useState(true)
  const [titleText, setTitleText] = useState("Medical Viewer")
  const location = useLocation()
  const navigation = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const [studyShare, setStudyShare] = useRecoilState(StudyShareByLink)
  const [folderShare, setFolderShare] = useRecoilState(FolderShared)
  const user = useRecoilValue(authAtom)
  // check if share link id is present for guest access

  const getCurrentPath = (myLocation) => {
    let locationPath = myLocation.pathname
    return locationPath.endsWith("/") ? locationPath.slice(0, -1) : locationPath
  }
  useEffect(() => {
    if (
      queryParams.get("shareId") != null &&
      !(location.pathname.split("/").includes("shared") && location.pathname.split("/").includes("study"))
    ) {
      fetchData("GET", `/study/share/shareLinkByLinkId?linkId=${queryParams.get("shareId")}`)
        .catch((err) => {
          navigation("/auth/login", { replace: true })
        })
        .then((res) => {
          if (id !== res.data.data.studyCode) {
            return navigation("/auth/login", { replace: false })
          }
          if (res.data.data.accessLevel > 0 && user == null) {
            toast.error("Login to your account to edit this study")
            return navigation("/auth/login")
          }
          setStudyShare({
            studyCode: id,
            accessLevel: res.data.data.accessLevel,
            linkId: res.data.data.linkId,
            viewId: res.data.data.viewId
          })
        })
    }
  }, [])

  if (GetLocalStorage("viIsOp") === true) {
    setTitleText("Medical Viewer")
    setOpenViewer(true)
  } else if (GetLocalStorage("viIsOp") === false) {
    setTitleText("Study Properties")
    setOpenViewer(false)
  }
  RemoveLocalStorage("viIsOp")
  const [study, setStudy] = useState({})
  const [StudyComments, setStudyComments] = useState([])

  // useEffect
  useEffect(() => {
    const path = getCurrentPath(location)
    const queryParam = new URLSearchParams(location.search)
    const isShared = path == `/dashboard/shared/study/${id}`
    const StudyURL = isShared
      ? `/folder/share/study?parentFolderId=${queryParam.get("p_id")}&studyId=${id}&shareId=${queryParam.get(
          "shareId"
        )}`
      : `/study/${id}`

    fetchData("GET", StudyURL, {}, { "x-share-id": queryParams.get("shareId") }).then((res) => {
      if (res.data.data.access) {
        if (res.data.data.access.access > 0 && !user) {
          toast.error("Login to your account to edit this study")
          return navigation("/auth/login")
        }
        setFolderShare({
          accessLevel: res.data.data.access.access,
          studyCode: res.data.data.study.code
        })
      }

      setStudy(res.data.data.study)
    })
    const commentUrl = isShared ? `/comment/?studyCode=${queryParams.get("code")}` : `/comment/?studyCode=${id}`
    fetchData("GET", commentUrl, {}, { "x-share-id": queryParams.get("shareId") }).then((res) =>
      setStudyComments(res.data.data.comments)
    )

    document.title = titleText
  }, [id, study?.userId, titleText])

  const StaredHandler = () => {
    let newStarStatus = study?.starred ? false : true
    fetchData("PATCH", "/study/bulk-toggle-starred", {
      codes: [id],
      starred: newStarStatus
    }).then((res) => {
      if (newStarStatus) {
        toast.success("This study has been added to favorites")
      } else {
        toast.success("This study was removed from favorites")
      }
      let newStudy = { ...study }
      newStudy.starred = newStarStatus

      setStudy(newStudy)
    })
  }

  return (
    <>
      <AdminLayout OpenViewer={OpenViewer}>
        <UserMePesrmissionShow user={GetLocalStorage("login_user")} userId={study.userId}>
          <Modal title={"Delete Study"} modalId={"delete-study"}>
            <DeleteStudy modalId={"delete-study"} id={id} item={study} />
          </Modal>
        </UserMePesrmissionShow>
        <UserMePesrmissionShow user={GetLocalStorage("login_user")} userId={study.userId}>
          <Modal title={"Edit Study Title"} modalId={"edit-study"}>
            <EditStudy setStudy={setStudy} modalId={"edit-study"} id={id} item={study} />
          </Modal>
        </UserMePesrmissionShow>
        <UserMePesrmissionShow user={GetLocalStorage("login_user")} userId={study.userId}>
          <Modal className={"max-w-3xl"} title={"Anonymity Status"} modalId={"anonymize-add-modal"}>
            <AnonymizeStudy study={study} setStudy={setStudy} modalId={"anonymize-add-modal"} id={id} item={study} />
          </Modal>
        </UserMePesrmissionShow>
        {study ? (
          <div className='max-w-7xl mx-auto space-y-dicom-15'>
            <div className='flex overflow-x-scroll whitespace-nowrap gap-2 mt-5'>
              {/* <Breadcrumbs title={study.title} /> */}
              <Breadcrumb title={study.title} />
            </div>
            <div className='grid grid-cols-6 lg:grid-cols-12 gap-dicom-30'>
              <div className='relative col-span-6 lg:col-span-8 flex flex-col space-y-dicom-15 bg-white rounded-dicom-10 p-dicom-30'>
                <div className='flex flex-wrap w-full justify-center items-center gap-dicom-10 xl:justify-between'>
                  <div className='flex items-center justify-center text-center xl:justify-start'>
                    <p className='text-dicom-headline4 text-dicom-secondary-100 font-bold'>{study.title}</p>
                    <UserMePesrmissionShow user={GetLocalStorage("login_user")} userId={study.userId}>
                      <label className='cursor-pointer' htmlFor='edit-study'>
                        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M14.2322 5.76777C15.2085 4.79146 16.7915 4.79146 17.7678 5.76777L18.4749 6.47487C19.4512 7.45118 19.4512 9.0341 18.4749 10.0104L10.3431 18.1421L7.10051 18.1421C6.54822 18.1421 6.1005 17.6944 6.10051 17.1421L6.10051 13.8995L14.2322 5.76777ZM16.3536 7.18198L17.0607 7.88909C17.2559 8.08435 17.2559 8.40093 17.0607 8.59619L16 9.65685L14.5858 8.24264L15.6464 7.18198C15.8417 6.98672 16.1583 6.98672 16.3536 7.18198ZM14.5858 11.0711L9.51472 16.1421L8.10051 16.1421L8.10051 14.7279L13.1716 9.65685L14.5858 11.0711Z'
                            fill='#575757'
                          />
                        </svg>
                      </label>
                    </UserMePesrmissionShow>
                  </div>

                  <div className='flex flex-wrap gap-dicom-15'>
                    {GetLocalStorage("login_user") != null && (
                      <>
                        <UserMePesrmissionShow user={GetLocalStorage("login_user")} userId={study.userId}>
                          <DicomButtonN
                            onClick={StaredHandler}
                            btnClass={`${
                              study.starred
                                ? "!border-2 !border-dicom-secondary-100 !text-white !bg-dicom-secondary-100"
                                : "!border-2 !text-dicom-secondary-100 !border-dicom-secondary-100"
                            }`}
                            iconL={
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                  d='M11.0748 3.25583C11.4141 2.42845 12.5859 2.42845 12.9252 3.25583L14.6493 7.45955C14.793 7.80979 15.1221 8.04889 15.4995 8.07727L20.0303 8.41798C20.922 8.48504 21.2841 9.59942 20.6021 10.1778L17.1369 13.1166C16.8482 13.3614 16.7225 13.7483 16.8122 14.1161L17.8882 18.5304C18.1 19.3992 17.152 20.0879 16.3912 19.618L12.5255 17.2305C12.2034 17.0316 11.7966 17.0316 11.4745 17.2305L7.60881 19.618C6.84796 20.0879 5.90001 19.3992 6.1118 18.5304L7.18785 14.1161C7.2775 13.7483 7.1518 13.3614 6.86309 13.1166L3.3979 10.1778C2.71588 9.59942 3.07796 8.48504 3.96971 8.41798L8.50046 8.07727C8.87794 8.04889 9.20704 7.80979 9.35068 7.45955L11.0748 3.25583Z'
                                  stroke='currentColor'
                                  strokeWidth='2'
                                />
                              </svg>
                            }
                          />
                        </UserMePesrmissionShow>

                        <UserMePesrmissionShow user={GetLocalStorage("login_user")} userId={study.userId}>
                          <DicomButtonNLabel
                            htmlFor={"delete-study"}
                            btnClass={"!border-2 !border-dicom-secondary-100"}
                            iconL={<IconRecycleBinLightGray />}
                          />
                        </UserMePesrmissionShow>
                      </>
                    )}
                  </div>
                </div>
                <div className='h-[2px] w-full bg-[#EFF2FF]'></div>
                <div>
                  <div className='flex flex-wrap items-center gap-dicom-15'>
                    Anonymity Status :
                    {study.anonymization === 2 ? (
                      <div className='flex  items-center gap-x-dicom-15 text-[#1DD3B0]'>
                        <svg width='30' height='26' viewBox='0 0 30 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <g clipPath='url(#clip0_469_11716)'>
                            <path
                              d='M22.3601 15.1359C19.942 15.1359 17.9093 16.9077 17.4565 19.2503C16.811 18.9649 15.9921 18.7385 15.0287 18.7385C14.0364 18.7286 13.1887 18.9649 12.5335 19.2503C12.0808 16.9077 10.0576 15.1359 7.62988 15.1359C4.87458 15.1359 2.62988 17.4294 2.62988 20.2444C2.62988 23.0595 4.87458 25.353 7.62988 25.353C10.2599 25.353 12.4276 23.2662 12.6106 20.6185C13.179 20.3035 13.9979 19.9984 15.0191 20.0082C16.0114 20.0181 16.811 20.3133 17.3698 20.6185C17.5624 23.2564 19.7204 25.353 22.3505 25.353C25.1058 25.353 27.3505 23.0595 27.3505 20.2444C27.3601 17.4294 25.1154 15.1359 22.3601 15.1359ZM7.63952 24.0832C5.56823 24.0832 3.88229 22.3607 3.88229 20.2444C3.88229 18.1282 5.56823 16.4057 7.63952 16.4057C9.71081 16.4057 11.3967 18.1282 11.3967 20.2444C11.3967 22.3607 9.71081 24.0832 7.63952 24.0832ZM22.3601 24.0832C20.2888 24.0832 18.6029 22.3607 18.6029 20.2444C18.6029 18.1282 20.2888 16.4057 22.3601 16.4057C24.4314 16.4057 26.1174 18.1282 26.1174 20.2444C26.1174 22.3607 24.4314 24.0832 22.3601 24.0832Z'
                              fill='#1DD3B0'
                            />
                            <path
                              d='M29.3834 12.3799H0.626204C0.279383 12.3799 0 12.6653 0 13.0197C0 13.374 0.279383 13.6595 0.626204 13.6595H29.3834C29.7303 13.6595 30.0096 13.374 30.0096 13.0197C30 12.6653 29.7206 12.3799 29.3834 12.3799Z'
                              fill='#1DD3B0'
                            />
                            <path
                              d='M7.63945 10.8838H22.3697C22.5624 10.8838 22.7358 10.7952 22.861 10.6475C22.9766 10.4999 23.0248 10.2932 22.9766 10.1062L21.3485 3.00939C21.1751 2.27116 20.7512 1.62153 20.1346 1.18844C19.5181 0.755344 18.7763 0.568328 18.0441 0.6766C17.0422 0.814402 16.0113 0.883303 14.9998 0.883303C14.9901 0.883303 14.9805 0.883303 14.9709 0.883303C13.9689 0.883303 12.9477 0.814402 11.9554 0.6766C11.2136 0.578171 10.4718 0.755344 9.86489 1.18844C9.24832 1.62153 8.82443 2.26132 8.65101 3.00939L7.03252 10.1062C6.99398 10.2932 7.03252 10.4901 7.14812 10.6475C7.27336 10.7952 7.44678 10.8838 7.63945 10.8838ZM9.86489 3.29483C10.0672 2.39912 10.8957 1.80854 11.7917 1.9365C12.8418 2.08415 13.9111 2.15305 14.9709 2.15305C14.9805 2.15305 14.9901 2.15305 14.9998 2.15305C16.0691 2.15305 17.1481 2.0743 18.2175 1.9365C19.1134 1.80854 19.9323 2.39912 20.1443 3.29483L21.5894 9.61403H8.4198L9.86489 3.29483Z'
                              fill='#1DD3B0'
                            />
                          </g>
                          <defs>
                            <clipPath id='clip0_469_11716'>
                              <rect width='30' height='24.7059' fill='white' transform='translate(0 0.647064)' />
                            </clipPath>
                          </defs>
                        </svg>
                        <span>Fully Anonymized</span>
                      </div>
                    ) : study.anonymization === 1 ? (
                      <div className='flex  items-center gap-x-dicom-15 text-[#FFB341]'>
                        <svg width='30' height='26' viewBox='0 0 30 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <g clipPath='url(#clip0_469_11720)'>
                            <path
                              d='M22.3601 15.1359C19.942 15.1359 17.9093 16.9077 17.4565 19.2503C16.811 18.9649 15.9921 18.7385 15.0287 18.7385C14.0364 18.7286 13.1887 18.9649 12.5335 19.2503C12.0808 16.9077 10.0576 15.1359 7.62988 15.1359C4.87458 15.1359 2.62988 17.4294 2.62988 20.2444C2.62988 23.0595 4.87458 25.353 7.62988 25.353C10.2599 25.353 12.4276 23.2662 12.6106 20.6185C13.179 20.3035 13.9979 19.9984 15.0191 20.0082C16.0114 20.0181 16.811 20.3133 17.3698 20.6185C17.5624 23.2564 19.7204 25.353 22.3505 25.353C25.1058 25.353 27.3505 23.0595 27.3505 20.2444C27.3601 17.4294 25.1154 15.1359 22.3601 15.1359ZM7.63952 24.0832C5.56823 24.0832 3.88229 22.3607 3.88229 20.2444C3.88229 18.1282 5.56823 16.4057 7.63952 16.4057C9.71081 16.4057 11.3967 18.1282 11.3967 20.2444C11.3967 22.3607 9.71081 24.0832 7.63952 24.0832ZM22.3601 24.0832C20.2888 24.0832 18.6029 22.3607 18.6029 20.2444C18.6029 18.1282 20.2888 16.4057 22.3601 16.4057C24.4314 16.4057 26.1174 18.1282 26.1174 20.2444C26.1174 22.3607 24.4314 24.0832 22.3601 24.0832Z'
                              fill='#FFB341'
                            />
                            <path
                              d='M29.3834 12.3799H0.626204C0.279383 12.3799 0 12.6653 0 13.0197C0 13.374 0.279383 13.6595 0.626204 13.6595H29.3834C29.7303 13.6595 30.0096 13.374 30.0096 13.0197C30 12.6653 29.7206 12.3799 29.3834 12.3799Z'
                              fill='#FFB341'
                            />
                            <path
                              d='M7.63945 10.8838H22.3697C22.5624 10.8838 22.7358 10.7952 22.861 10.6475C22.9766 10.4999 23.0248 10.2932 22.9766 10.1062L21.3485 3.00939C21.1751 2.27116 20.7512 1.62153 20.1346 1.18844C19.5181 0.755344 18.7763 0.568328 18.0441 0.6766C17.0422 0.814402 16.0113 0.883303 14.9998 0.883303C14.9901 0.883303 14.9805 0.883303 14.9709 0.883303C13.9689 0.883303 12.9477 0.814402 11.9554 0.6766C11.2136 0.578171 10.4718 0.755344 9.86489 1.18844C9.24832 1.62153 8.82443 2.26132 8.65101 3.00939L7.03252 10.1062C6.99398 10.2932 7.03252 10.4901 7.14812 10.6475C7.27336 10.7952 7.44678 10.8838 7.63945 10.8838ZM9.86489 3.29483C10.0672 2.39912 10.8957 1.80854 11.7917 1.9365C12.8418 2.08415 13.9111 2.15305 14.9709 2.15305C14.9805 2.15305 14.9901 2.15305 14.9998 2.15305C16.0691 2.15305 17.1481 2.0743 18.2175 1.9365C19.1134 1.80854 19.9323 2.39912 20.1443 3.29483L21.5894 9.61403H8.4198L9.86489 3.29483Z'
                              fill='#FFB341'
                            />
                          </g>
                          <defs>
                            <clipPath id='clip0_469_11720'>
                              <rect width='30' height='24.7059' fill='white' transform='translate(0 0.647064)' />
                            </clipPath>
                          </defs>
                        </svg>
                        <span>Anonymized when sharing</span>
                      </div>
                    ) : (
                      <div className='flex  items-center gap-x-dicom-15 text-[#FF4141]'>
                        <svg width='30' height='26' viewBox='0 0 30 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <g clipPath='url(#clip0_759_10905)'>
                            <path
                              d='M22.3611 15.1359C19.943 15.1359 17.9102 16.9077 17.4574 19.2503C16.812 18.9648 15.9931 18.7385 15.0297 18.7385C14.0374 18.7286 13.1896 18.9648 12.5345 19.2503C12.0817 16.9077 10.0586 15.1359 7.63086 15.1359C4.87556 15.1359 2.63086 17.4293 2.63086 20.2444C2.63086 23.0595 4.87556 25.3529 7.63086 25.3529C10.2609 25.3529 12.4285 23.2662 12.6116 20.6185C13.18 20.3035 13.9989 19.9984 15.0201 20.0082C16.0124 20.018 16.812 20.3133 17.3707 20.6185C17.5634 23.2564 19.7214 25.3529 22.3515 25.3529C25.1068 25.3529 27.3515 23.0595 27.3515 20.2444C27.3611 17.4293 25.1164 15.1359 22.3611 15.1359ZM7.64049 24.0832C5.5692 24.0832 3.88327 22.3607 3.88327 20.2444C3.88327 18.1282 5.5692 16.4057 7.64049 16.4057C9.71178 16.4057 11.3977 18.1282 11.3977 20.2444C11.3977 22.3607 9.71178 24.0832 7.64049 24.0832ZM22.3611 24.0832C20.2898 24.0832 18.6039 22.3607 18.6039 20.2444C18.6039 18.1282 20.2898 16.4057 22.3611 16.4057C24.4324 16.4057 26.1183 18.1282 26.1183 20.2444C26.1183 22.3607 24.4324 24.0832 22.3611 24.0832Z'
                              fill='#FF4141'
                            />
                            <path
                              d='M29.3834 12.3799H0.626204C0.279383 12.3799 0 12.6653 0 13.0197C0 13.374 0.279383 13.6595 0.626204 13.6595H29.3834C29.7303 13.6595 30.0096 13.374 30.0096 13.0197C30 12.6653 29.7206 12.3799 29.3834 12.3799Z'
                              fill='#FF4141'
                            />
                            <path
                              d='M7.63897 10.8838H22.3692C22.5619 10.8838 22.7353 10.7952 22.8605 10.6475C22.9762 10.4999 23.0243 10.2932 22.9762 10.1062L21.348 3.00937C21.1746 2.27115 20.7507 1.62151 20.1341 1.18842C19.5176 0.755329 18.7758 0.568313 18.0436 0.676586C17.0417 0.814387 16.0108 0.883288 14.9993 0.883288C14.9896 0.883288 14.98 0.883288 14.9704 0.883288C13.9684 0.883288 12.9473 0.814387 11.955 0.676586C11.2131 0.578156 10.4713 0.755329 9.8644 1.18842C9.24783 1.62151 8.82394 2.26131 8.65053 3.00937L7.03203 10.1062C6.99349 10.2932 7.03203 10.49 7.14764 10.6475C7.27288 10.7952 7.44629 10.8838 7.63897 10.8838ZM9.8644 3.29482C10.0667 2.39911 10.8952 1.80853 11.7912 1.93649C12.8413 2.08413 13.9106 2.15303 14.9704 2.15303C14.98 2.15303 14.9896 2.15303 14.9993 2.15303C16.0686 2.15303 17.1476 2.07429 18.217 1.93649C19.113 1.80853 19.9318 2.39911 20.1438 3.29482L21.5889 9.61401H8.41931L9.8644 3.29482Z'
                              fill='#FF4141'
                            />
                          </g>
                          <defs>
                            <clipPath id='clip0_759_10905'>
                              <rect width='30' height='24.7059' fill='white' transform='translate(0 0.647057)' />
                            </clipPath>
                          </defs>
                        </svg>
                        <span>Original</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='!relative grid grid-cols-2 w-full gap-dicom-15 xl:flex flex-wrap xl:flex-nowrap'>
                  <UserMePesrmissionShow user={GetLocalStorage("login_user")} userId={study.userId}>
                    <DicomButtonNLabel
                      htmlFor={"anonymize-add-modal"}
                      btnClass={
                        "!bg-dicom-secondary-100 hover:!bg-dicom-secondary-hover-100 basis-full sm:basis-1/2 xl:basis-auto"
                      }
                      iconL={
                        <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M13.4766 14C13.4766 13.4477 13.0288 13 12.4766 13C11.9243 13 11.4766 13.4477 11.4766 14V16C11.4766 16.5523 11.9243 17 12.4766 17C13.0288 17 13.4766 16.5523 13.4766 16V14Z'
                            fill='white'
                          />
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M7.47656 8.12037C5.79267 8.53217 4.47656 9.95979 4.47656 11.7692V17.3077C4.47656 19.973 6.79201 22 9.47656 22H15.4766C18.1611 22 20.4766 19.973 20.4766 17.3077V11.7692C20.4766 9.95979 19.1605 8.53217 17.4766 8.12037V7C17.4766 4.23858 15.238 2 12.4766 2C9.71514 2 7.47656 4.23858 7.47656 7V8.12037ZM15.4766 7V8H9.47656V7C9.47656 6.64936 9.53672 6.31278 9.64727 6C10.0591 4.83481 11.1703 4 12.4766 4C13.7828 4 14.894 4.83481 15.3059 6C15.4164 6.31278 15.4766 6.64936 15.4766 7ZM6.47656 11.7692C6.47656 10.866 7.29512 10 8.47656 10H16.4766C17.658 10 18.4766 10.866 18.4766 11.7692V17.3077C18.4766 18.7208 17.2103 20 15.4766 20H9.47656C7.74284 20 6.47656 18.7208 6.47656 17.3077V11.7692Z'
                            fill='white'
                          />
                        </svg>
                      }
                      title={"Anonymize"}
                    />
                  </UserMePesrmissionShow>

                  <UserMePesrmissionShow user={GetLocalStorage("login_user")} userId={study.userId}>
                    <Modal title={"Share Study"} modalId={"share-study-modal"}>
                      <ShareStudy setStudy={setStudy} study={study} modalId={"share-study-modal"} />
                    </Modal>
                    <DicomButtonLabel
                      htmlFor={"share-study-modal"}
                      btnClass={
                        "!bg-dicom-secondary-100 hover:!bg-dicom-secondary-hover-100 basis-full sm:basis-1/2 xl:basis-auto"
                      }
                      iconL={
                        <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M20.5 5.5C20.5 7.433 18.933 9 17 9C16.098 9 15.2757 8.6588 14.6551 8.09843L9.38613 11.6111C9.46044 11.8949 9.5 12.1929 9.5 12.5C9.5 12.8071 9.46044 13.1051 9.38613 13.3889L14.1039 16.5341C14.7336 15.6081 15.7958 15 17 15C18.933 15 20.5 16.567 20.5 18.5C20.5 20.433 18.933 22 17 22C15.0788 22 13.5191 20.452 13.5002 18.5353L8.34486 15.0984C7.72429 15.6588 6.90199 16 6 16C4.067 16 2.5 14.433 2.5 12.5C2.5 10.567 4.067 9 6 9C6.90199 9 7.72429 9.3412 8.34486 9.90157L13.6139 6.3889C13.5396 6.10505 13.5 5.80715 13.5 5.5C13.5 3.567 15.067 2 17 2C18.933 2 20.5 3.567 20.5 5.5ZM17 7C17.8284 7 18.5 6.32843 18.5 5.5C18.5 4.67157 17.8284 4 17 4C16.1716 4 15.5 4.67157 15.5 5.5C15.5 6.32843 16.1716 7 17 7ZM6 14C6.82843 14 7.5 13.3284 7.5 12.5C7.5 11.6716 6.82843 11 6 11C5.17157 11 4.5 11.6716 4.5 12.5C4.5 13.3284 5.17157 14 6 14ZM18.5 18.5C18.5 19.3284 17.8284 20 17 20C16.1716 20 15.5 19.3284 15.5 18.5C15.5 17.6716 16.1716 17 17 17C17.8284 17 18.5 17.6716 18.5 18.5Z'
                            fill='white'
                          />
                        </svg>
                      }
                      title={"Share"}
                    />
                  </UserMePesrmissionShow>
                  {(user?.id === study?.userId || studyShare.accessLevel == 2 || folderShare.accessLevel == 2) && (
                    <DownloadStudy btnClass={"basis-full sm:basis-1/2 xl:basis-auto"} study={study} />
                  )}
                  <div className='col-span-2 xl:w-full'>
                    <OhifViewerSection study={study} OpenViewer={OpenViewer} setOpenViewer={setOpenViewer} />
                  </div>
                </div>
                <div className='grid grid-cols-8'>
                  <div className='col-span-8 xl:col-span-5 text-dicom-body2'>
                    <div className='grid grid-cols-4 items-center py-[9px]'>
                      <p className='col-span-2'>Patient ID</p>
                      <p className='col-span-2'>{study?.patientTags?.PatientID}</p>
                    </div>
                    <div className='grid grid-cols-4 items-center py-[9px]'>
                      <p className='col-span-2'>Patient Name</p>
                      <p className='col-span-2'>{study?.patientTags?.PatientName}</p>
                    </div>
                    <div className='grid grid-cols-4 items-center py-[9px]'>
                      <p className='col-span-2'>Modality</p>
                      <p className='col-span-2'>{study?.modality}</p>
                    </div>
                    <div className='grid grid-cols-4 items-center py-[9px]'>
                      <p className='col-span-2'>Age</p>
                      <p className='col-span-2'>{study?.patientTags?.PatientAge}</p>
                    </div>
                    <div className='grid grid-cols-4 items-center py-[9px]'>
                      <p className='col-span-2'>Sex</p>
                      <p className='col-span-2'>{study?.patientTags?.PatientSex}</p>
                    </div>
                    <div className='grid grid-cols-4 items-center py-[9px]'>
                      <p className='col-span-2'>Study Date</p>
                      <p className='col-span-2'>{study?.mainTags?.StudyDate}</p>
                    </div>
                    <div className='grid grid-cols-4 items-center py-[9px]'>
                      <p className='col-span-2'>Study Description</p>
                      <p className='col-span-2'>{study?.mainTags?.StudyDescription}</p>
                    </div>
                  </div>
                  <div className='col-span-8 xl:col-span-3 flex flex-col !space-y-dicom-10'>
                    <img
                      className='h-[276px] w-full object-cover rounded-dicom-10'
                      src={`${process.env.REACT_APP_API_URL}/study/${study?.code}/preview`}
                      alt=''
                    />
                    <div className='flex justify-between'>
                      <span>
                        images <span>{study?.frames}</span>
                      </span>
                      <span>
                        series <span>{study?.series?.length}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-span-6 lg:col-span-4 space-y-dicom-15 bg-white rounded-dicom-10 p-dicom-30'>
                <Comments studyCode={id} StudyComments={StudyComments} setStudyComments={setStudyComments} />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </AdminLayout>
    </>
  )
}

export default StudyPage
