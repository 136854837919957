import React, { useState } from "react"
import { Link, NavLink } from "react-router-dom"

import SidebarItem from "./sidebarItem"
import { sidebarData } from "../../utils/sidebarData"
import { FormatSizeUnits } from "../../utils/SizeHandler"
import { GetLocalStorage } from "../../services/LocalStorage"
import { DropDown } from "../DesignSystem/DropDown"

// Recoil
import { useUserActions } from "../../services/AuthActions"
import { checkImageLoadingError } from "../../utils/functions"

let DashboardProfileList = [
  {
    id: 0,
    title: "Profile",
    icon: (
      <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15 7.0162C15 8.67306 13.6569 10.0162 12 10.0162C10.3431 10.0162 9 8.67306 9 7.0162C9 5.35935 10.3431 4.0162 12 4.0162C13.6569 4.0162 15 5.35935 15 7.0162Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M5 19.5162C5 15.9264 7.91015 13.0162 11.5 13.0162H12.5C16.0899 13.0162 19 15.9264 19 19.5162V20.0162C19 20.5685 18.5523 21.0162 18 21.0162H6C5.44772 21.0162 5 20.5685 5 20.0162V19.5162Z'
          stroke='white'
          strokeWidth='2'
        />
      </svg>
    ),
    url: "profile"
  },
  {
    id: 1,
    title: "Change Password",
    icon: (
      <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M13 14.0162C13 13.4639 12.5523 13.0162 12 13.0162C11.4477 13.0162 11 13.4639 11 14.0162V16.0162C11 16.5685 11.4477 17.0162 12 17.0162C12.5523 17.0162 13 16.5685 13 16.0162V14.0162Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7 8.13657C5.3161 8.54837 4 9.97599 4 11.7854V17.3239C4 19.9892 6.31545 22.0162 9 22.0162H15C17.6846 22.0162 20 19.9892 20 17.3239V11.7854C20 9.97599 18.6839 8.54837 17 8.13657V7.0162C17 4.25478 14.7614 2.0162 12 2.0162C9.23858 2.0162 7 4.25478 7 7.0162V8.13657ZM15 7.0162V8.0162H9V7.0162C9 6.66557 9.06015 6.32898 9.17071 6.0162C9.58254 4.85101 10.6938 4.0162 12 4.0162C13.3062 4.0162 14.4175 4.85101 14.8293 6.0162C14.9398 6.32898 15 6.66557 15 7.0162ZM6 11.7854C6 10.8822 6.81856 10.0162 8 10.0162H16C17.1814 10.0162 18 10.8822 18 11.7854V17.3239C18 18.737 16.7337 20.0162 15 20.0162H9C7.26627 20.0162 6 18.737 6 17.3239V11.7854Z'
          fill='white'
        />
      </svg>
    ),
    url: "change-password"
  }
]

const Sidebar = ({ CollapsHandler, Collaps, User, menu }) => {
  const userActions = useUserActions()
  const [imgLoaderror, setImageLoadErr] = useState(false)

  const currentUrl = window.location.pathname.split("/").filter((item) => item !== "")
  const LogOutClickHandler = () => {
    userActions.UserLogOut()
  }

  return (
    <>
      <div className='relative overflow-auto bg-dicom-background2-100 p-dicom-10 min-h-screen h-full space-y-dicom-10'>
        <div className='hidden space-y-dicom-10 lg:flex lg:flex-col lg:items-center lg:justify-center'>
          {Collaps ? (
            <NavLink to={"/dashboard"} className='mt-4 text-dicom-headline2 text-white font-bold'>
              <img className='flex items-center justify-center h-11 w-11' src='/logo192.png' alt='' />
            </NavLink>
          ) : (
            <NavLink to={"/dashboard"} className='mt-4 text-dicom-headline2 text-white font-bold'>
              <img src='/images/dashboard/logo.svg' alt='' />
            </NavLink>
          )}

          {Collaps ? (
            <button
              onClick={CollapsHandler}
              className='!mt-dicom-30 flex text-white w-full hover:bg-white/10 py-dicom-10 items-center justify-center px-dicom-10 rounded-dicom-10'>
              <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M4 18.5H10' stroke='white' strokeWidth='2' strokeLinecap='round' />
                <path d='M4 12.5L16 12.5' stroke='white' strokeWidth='2' strokeLinecap='round' />
                <path d='M4 6.5L20 6.5' stroke='white' strokeWidth='2' strokeLinecap='round' />
              </svg>
            </button>
          ) : (
            <button
              onClick={CollapsHandler}
              className='!mt-dicom-30 flex text-white w-full hover:bg-white/10 py-dicom-10 items-center justify-between px-dicom-10 rounded-dicom-10'>
              <div className='flex items-center justify-center gap-dicom-10'>
                <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M4 18.5H10' stroke='white' strokeWidth='2' strokeLinecap='round' />
                  <path d='M4 12.5L16 12.5' stroke='white' strokeWidth='2' strokeLinecap='round' />
                  <path d='M4 6.5L20 6.5' stroke='white' strokeWidth='2' strokeLinecap='round' />
                </svg>
                <span>Menu</span>
              </div>

              <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M8.29289 7.79289C8.68342 7.40237 9.31658 7.40237 9.70711 7.79289C10.0976 8.18342 10.0976 8.81658 9.70711 9.20711L8.29289 7.79289ZM5 12.5L4.29289 13.2071L3.58579 12.5L4.29289 11.7929L5 12.5ZM9.70711 15.7929C10.0976 16.1834 10.0976 16.8166 9.70711 17.2071C9.31658 17.5976 8.68342 17.5976 8.29289 17.2071L9.70711 15.7929ZM19 11.5C19.5523 11.5 20 11.9477 20 12.5C20 13.0523 19.5523 13.5 19 13.5V11.5ZM9.70711 9.20711L5.70711 13.2071L4.29289 11.7929L8.29289 7.79289L9.70711 9.20711ZM5.70711 11.7929L9.70711 15.7929L8.29289 17.2071L4.29289 13.2071L5.70711 11.7929ZM5 11.5H19V13.5H5V11.5Z'
                  fill='white'
                />
              </svg>
            </button>
          )}
        </div>

        <div className='flex justify-end !w-full lg:hidden'>
          <DropDown
            tabIndex={"0"}
            ActionBtn={
              <label
                tabIndex={"0"}
                className={`flex !capitalize !px-[10px] text-white items-center btn !border-0 bg-dicom-primary-100 border-transparent hover:bg-dicom-primary-hover-100 hover:border-dicom-primary-hover-100 !rounded-dicom-10`}>
                <div className='flex !items-center !justify-center gap-dicom-10'>
                  <img
                    src={
                      User?.image
                        ? imgLoaderror
                          ? "/images/avatar-default.gif"
                          : User?.image
                        : "/images/avatar-default.gif"
                    }
                    crossOrigin='anonymous'
                    className='rounded-full !h-[34px] !w-[34px]'
                    alt=''
                    onError={() => checkImageLoadingError(setImageLoadErr)}
                  />

                  <span>{User.firstName + " " + User.lastName}</span>
                  <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M18.7593 9.15889C19.1187 8.73957 19.0701 8.10827 18.6508 7.74885C18.2315 7.38942 17.6002 7.43799 17.2407 7.85731L18.7593 9.15889ZM6.75926 7.85731C6.39983 7.43799 5.76853 7.38942 5.34921 7.74885C4.92988 8.10827 4.88132 8.73957 5.24074 9.15889L6.75926 7.85731ZM12.2278 15.2424L11.4685 14.5916L12.2278 15.2424ZM11.7722 15.2424L11.013 15.8932L11.7722 15.2424ZM17.2407 7.85731L11.4685 14.5916L12.987 15.8932L18.7593 9.15889L17.2407 7.85731ZM12.5315 14.5916L6.75926 7.85731L5.24074 9.15889L11.013 15.8932L12.5315 14.5916ZM11.4685 14.5916C11.7479 14.2656 12.2521 14.2656 12.5315 14.5916L11.013 15.8932C11.5318 16.4984 12.4682 16.4984 12.987 15.8932L11.4685 14.5916Z'
                      fill='white'
                    />
                  </svg>
                </div>
              </label>
            }>
            <div className='flex flex-col !p-dicom-15 !gap-y-dicom-15'>
              <div className='flex flex-col gap-dicom-10'>
                <div className='flex items-center gap-dicom-10'>
                  <img
                    src={
                      User?.image
                        ? imgLoaderror
                          ? "/images/avatar-default.gif"
                          : User?.image
                        : "/images/avatar-default.gif"
                    }
                    className='rounded-full !h-[34px] !w-[34px]'
                    alt=''
                    crossOrigin='anonymous'
                  />
                  <span className='text-dicom-body2 font-medium'>{User.firstName + " " + User.lastName}</span>
                </div>
                <span className='text-dicom-body2 font-medium'>{User.email}</span>
              </div>
              {DashboardProfileList.map((dash, index) => (
                <NavLink key={index} to={`/dashboard/${dash.url}`}>
                  <div className='flex items-center gap-x-dicom-10'>
                    {dash.icon}
                    <span className='text-dicom-body2 font-medium'>{dash.title}</span>
                  </div>
                </NavLink>
              ))}

              <button onClick={LogOutClickHandler} className='flex items-center gap-x-dicom-10'>
                <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M13.0002 2.0162C10.2387 2.0162 8.00015 4.25478 8.00015 7.0162C8.00015 7.56849 8.44787 8.0162 9.00015 8.0162C9.55244 8.0162 10.0002 7.56849 10.0002 7.0162C10.0002 5.35935 11.3433 4.0162 13.0002 4.0162H17.0002C18.657 4.0162 20.0002 5.35935 20.0002 7.0162V17.0162C20.0002 18.6731 18.657 20.0162 17.0002 20.0162H13.0002C11.3433 20.0162 10.0002 18.6731 10.0002 17.0162C10.0002 16.4639 9.55244 16.0162 9.00015 16.0162C8.44787 16.0162 8.00015 16.4639 8.00015 17.0162C8.00015 19.7776 10.2387 22.0162 13.0002 22.0162H17.0002C19.7616 22.0162 22.0002 19.7776 22.0002 17.0162V7.0162C22.0002 4.25478 19.7616 2.0162 17.0002 2.0162H13.0002Z'
                    fill='white'
                  />
                  <path
                    d='M14.0002 11.0162C14.5524 11.0162 15.0002 11.4639 15.0002 12.0162C15.0002 12.5685 14.5524 13.0162 14.0002 13.0162V11.0162Z'
                    fill='white'
                  />
                  <path
                    d='M5.71807 11.0162C5.80709 10.9064 5.89239 10.7999 5.97307 10.6982C6.21855 10.3886 6.42639 10.1166 6.57315 9.9217C6.6466 9.82415 6.70492 9.74566 6.7452 9.69113L6.79176 9.62783L6.80425 9.61075L6.80866 9.60469C6.8087 9.60463 6.80917 9.60399 6.00015 9.0162L6.80866 9.60469C7.13329 9.15788 7.03474 8.53181 6.58793 8.20719C6.14115 7.88258 5.51582 7.9816 5.19118 8.42835L5.18836 8.43222L5.17813 8.44622L5.13637 8.50299C5.09942 8.55302 5.0448 8.62653 4.9754 8.71871C4.83647 8.90323 4.63898 9.16162 4.40591 9.45557C3.93467 10.0499 3.33783 10.7643 2.79304 11.3091L2.08594 12.0162L2.79304 12.7233C3.33783 13.2681 3.93467 13.9825 4.40591 14.5768C4.63898 14.8708 4.83647 15.1292 4.9754 15.3137C5.0448 15.4059 5.09942 15.4794 5.13637 15.5294L5.17813 15.5862L5.18836 15.6002L5.1907 15.6034C5.51534 16.0501 6.14115 16.1498 6.58793 15.8252C7.03474 15.5006 7.13379 14.8752 6.80917 14.4284L6.00015 15.0162C6.80917 14.4284 6.80921 14.4285 6.80917 14.4284L6.80425 14.4217L6.79176 14.4046L6.7452 14.3413C6.70492 14.2867 6.6466 14.2083 6.57315 14.1107C6.42639 13.9158 6.21855 13.6439 5.97307 13.3343C5.89239 13.2325 5.80709 13.126 5.71807 13.0162H14.0002V11.0162H5.71807Z'
                    fill='white'
                  />
                </svg>
                <span className='text-dicom-body2 font-medium'>Log Out</span>
              </button>
            </div>
          </DropDown>
        </div>

        <div className='space-y-dicom-10 border-t-2 border-gray-100/50 py-2 border-b-2 '>
          {sidebarData
            .filter((filt) =>
              GetLocalStorage("login_user")?.role === "Admin"
                ? filt.url !== "shared-with-you" && filt.url !== "shared-by-you"
                : filt.url !== "users" && filt.title !== "Reports"
            )
            .map((item, index) => {
              return <SidebarItem menu={menu} Collaps={Collaps} key={index} data={item} url={currentUrl} />
            })}
        </div>

        <div className='relative'>
          {/* <div className='flex items-center gap-x-dicom-15 px-dicom-15 py-dicom-10'>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <g clipPath='url(#clip0_809_14058)'>
                <path
                  d='M6.41619 18.6207C5.12529 18.6207 3.88725 18.1301 2.97445 17.257C2.06164 16.3839 1.54883 15.1997 1.54883 13.9649C1.54883 12.7301 2.06164 11.5459 2.97445 10.6728C3.88725 9.7997 5.12529 9.30918 6.41619 9.30918C6.72108 7.95093 7.61299 6.7573 8.89572 5.9909C9.53087 5.61141 10.2428 5.34823 10.991 5.21639C11.7392 5.08454 12.5088 5.08661 13.2561 5.22248C14.0034 5.35835 14.7136 5.62535 15.3462 6.00824C15.9788 6.39114 16.5214 6.88243 16.9431 7.45406C17.3647 8.02569 17.6572 8.66646 17.8037 9.33981C17.9502 10.0131 17.9479 10.7059 17.7969 11.3784H18.8315C19.7919 11.3784 20.7129 11.7599 21.392 12.439C22.0711 13.1181 22.4526 14.0391 22.4526 14.9995C22.4526 15.9599 22.0711 16.881 21.392 17.5601C20.7129 18.2392 19.7919 18.6207 18.8315 18.6207H6.41619Z'
                  stroke='white'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </g>
              <defs>
                <clipPath id='clip0_809_14058'>
                  <rect width='24' height='24' fill='white' />
                </clipPath>
              </defs>
            </svg>
            <p className={`${Collaps ? "hidden" : ""} text-dicom-body2 text-dicom-surface-100`}>Storage</p>
          </div> */}
          {Object.keys(User).length !== 0 && (
            <div className={`${Collaps ? "hidden" : ""}`}>
              <div className='flex items-center gap-x-dicom-15 px-dicom-15 '>
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <g clipPath='url(#clip0_809_14058)'>
                    <path
                      d='M6.41619 18.6207C5.12529 18.6207 3.88725 18.1301 2.97445 17.257C2.06164 16.3839 1.54883 15.1997 1.54883 13.9649C1.54883 12.7301 2.06164 11.5459 2.97445 10.6728C3.88725 9.7997 5.12529 9.30918 6.41619 9.30918C6.72108 7.95093 7.61299 6.7573 8.89572 5.9909C9.53087 5.61141 10.2428 5.34823 10.991 5.21639C11.7392 5.08454 12.5088 5.08661 13.2561 5.22248C14.0034 5.35835 14.7136 5.62535 15.3462 6.00824C15.9788 6.39114 16.5214 6.88243 16.9431 7.45406C17.3647 8.02569 17.6572 8.66646 17.8037 9.33981C17.9502 10.0131 17.9479 10.7059 17.7969 11.3784H18.8315C19.7919 11.3784 20.7129 11.7599 21.392 12.439C22.0711 13.1181 22.4526 14.0391 22.4526 14.9995C22.4526 15.9599 22.0711 16.881 21.392 17.5601C20.7129 18.2392 19.7919 18.6207 18.8315 18.6207H6.41619Z'
                      stroke='white'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_809_14058'>
                      <rect width='24' height='24' fill='white' />
                    </clipPath>
                  </defs>
                </svg>
                <p className={`${Collaps ? "hidden" : ""}capitalize text-dicom-body2 text-dicom-surface-100`}>
                  {User?.currentPlan}
                </p>
              </div>
              <progress
                className='progress progress-accent bg-dicom-surface-100 h-[5px]'
                value={User?.usedSpace}
                max={User?.totalSpace}></progress>
              <div className='flex items-center justify-center'>
                <p className='text-dicom-body2 text-dicom-surface-100'>
                  <span className='font-bold'>{FormatSizeUnits(User?.usedSpace)}</span> of{" "}
                  <span className='font-bold'>{FormatSizeUnits(User?.totalSpace)}</span> used
                </p>
              </div>
              <div className='flex justify-center'>
                <Link to={"/pricing"}>
                  <button
                    className='mt-5 border-dicom-secondary-100 rounded-lg text-dicom-secondary-100 border px-7 py-3                  
                  bg-transparent hover:bg-transparent hover:border-dicom-secondary-hover-100 flex capitalize  items-center btn }
                  '>
                    Get more storage
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Sidebar
