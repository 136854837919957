import React from "react";

import { DicomButtonOutlineLinka } from "./DesignSystem/buttons";

const Socialbtns = ({ title }) => {
  // const redirectToLinkedinSSO = async () => {
  //   const LinkedinLoginURL = ${process.env.LINKEDIN_AUTH_URL};
  //   window.open(LinkedinLoginURL, "", "height=700,width=500,scrollbars=no");
  // };
  return (
    <>
      <div className="divider">{title}</div>
      <div className="flex w-full justify-between">
        <DicomButtonOutlineLinka
          url={process.env.REACT_APP_GOOGLE_AUTH_URL}
          // onClick={() => {
          //   window.open(
          //     ${process.env.GOOGLE_AUTH_URL},
          //     "",
          //     "height=1000,width=500,scrollbars=no"
          //   );
          // }
          btnClass={"!border-dicom-text-100 hover:!bg-dicom-text-100/10"}
          iconR={
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_216_1474)">
                <path
                  d="M24.1042 12.2719C24.1042 18.9047 19.5621 23.625 12.8542 23.625C6.423 23.625 1.22925 18.4312 1.22925 12C1.22925 5.56875 6.423 0.375 12.8542 0.375C15.9855 0.375 18.6199 1.52344 20.6496 3.41719L17.4855 6.45938C13.3464 2.46563 5.64956 5.46563 5.64956 12C5.64956 16.0547 8.88862 19.3406 12.8542 19.3406C17.4574 19.3406 19.1824 16.0406 19.4542 14.3297H12.8542V10.3312H23.9214C24.0292 10.9266 24.1042 11.4984 24.1042 12.2719Z"
                  fill="#737373"
                />
              </g>
              <defs>
                <clipPath id="clip0_216_1474">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0.666748)"
                  />
                </clipPath>
              </defs>
            </svg>
          }
        />
        <DicomButtonOutlineLinka
          url={process.env.REACT_APP_LINKEDIN_AUTH_URL}
          btnClass={"!border-dicom-text-100 hover:!bg-dicom-text-100/10"}
          iconR={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.20062 21H1.84688V6.97971H6.20062V21ZM4.02141 5.06721C2.62922 5.06721 1.5 3.91408 1.5 2.52189C1.5 1.85318 1.76565 1.21185 2.2385 0.738991C2.71136 0.266136 3.35269 0.000488281 4.02141 0.000488281C4.69012 0.000488281 5.33145 0.266136 5.80431 0.738991C6.27716 1.21185 6.54281 1.85318 6.54281 2.52189C6.54281 3.91408 5.41313 5.06721 4.02141 5.06721ZM22.4953 21H18.1509V14.175C18.1509 12.5485 18.1181 10.4625 15.8873 10.4625C13.6237 10.4625 13.2769 12.2297 13.2769 14.0578V21H8.92781V6.97971H13.1034V8.89221H13.1644C13.7456 7.79065 15.1655 6.62815 17.2838 6.62815C21.69 6.62815 22.5 9.52971 22.5 13.2985V21H22.4953Z"
                fill="#737373"
              />
            </svg>
          }
        />
        <DicomButtonOutlineLinka
          url={process.env.REACT_APP_YAHOO_AUTH_URL}
          btnClass={"!border-dicom-text-100 hover:!bg-dicom-text-100/10"}
          iconR={
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.3335 6H8.3335"
                stroke="#737373"
                strokeWidth="2.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.3335 18H14.3335"
                stroke="#737373"
                strokeWidth="2.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.8335 6L10.3335 13V18"
                stroke="#737373"
                strokeWidth="2.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.3335 13L16.3335 8"
                stroke="#737373"
                strokeWidth="2.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.8335 8H17.8335"
                stroke="#737373"
                strokeWidth="2.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.3335 11V15"
                stroke="#737373"
                strokeWidth="2.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.3335 18V18.0105"
                stroke="#737373"
                strokeWidth="2.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
        />
      </div>
    </>
  );
};

export default Socialbtns;
