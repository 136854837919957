import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

import {
  DicomButton,
  DicomButtonOutline,
} from "../../DesignSystem/buttons";
import { Input } from "../../DesignSystem/inputs";
import { fetchData } from "../../../services/fetching";
import { UserStudies, UserStudiesURL } from "../../../atom/userStudies";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useLocation, useParams } from "react-router-dom";

const EditFolder = ({ modalId, item }) => {
  const [EditFolderData, setEditFolderData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const setStudies= useSetRecoilState(UserStudies)
  const studyUrl= useRecoilValue(UserStudiesURL)
  const pathName=useLocation().pathname
  const params=useParams()
  const validationSchema = Yup.object({
    name: Yup.string().required("Folder name is required"),
  });
  const onSubmit = () => {
    setIsLoading(true);
    let parentFolderId=null
    if(pathName.split("/").includes("folders")) parentFolderId=params.folderId
    fetchData("PATCH", `/folder/${item.id}`, {
      name: formik.values.name,
      parentFolderId
    })
      .then((res) => {
        if (res.data.data.folder) {
          fetchData("GET",studyUrl).then(res=>{
            setStudies(res.data.data)
          })
          formik.resetForm();
          document.getElementById(modalId).checked = false;
          toast.success("The desired folder has been successfully updated");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if(err?.response.data?.message){
            toast.error(err?.response.data?.message)
        }
        setIsLoading(false);
      });
  };
  const formik = useFormik({
    initialValues: EditFolderData,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  useEffect(() => {
    fetchData("GET", `/folder/${item.id}`).then((res) =>
      setEditFolderData(res.data.data)
    );
  }, [item]);

  return (
    <>
      <div className="space-y-dicom-15">
        <Input label={"Folder name"} formik={formik} nameId={"name"} />
        <div className="flex justify-center gap-5 w-full">
          <DicomButtonOutline
            onClick={() => (document.getElementById(modalId).checked = false)}
            btnClass={
              "!border-dicom-secondary-100 !text-dicom-secondary-100 hover:!bg-dicom-secondary-100/5"
            }
            title={"Cancel"}
          />
          <DicomButton
            isLoading={isLoading}
            onClick={onSubmit}
            btnClass={
              "!border-dicom-secondary-100 !bg-dicom-secondary-100 !text-white"
            }
            title={"Edit folder"}
          />
        </div>
      </div>
    </>
  );
};

export default EditFolder;
