import React from "react"

export default function Skeleton({ children, title, subtitle, innerDivClass, FeatureComponent, featured, className }) {
  return (
    <div className={`bg-white relative  py-8 min-w-[520px] max-w-[640px] ${className} rounded-xl`}>
      {featured && (
        <div className='bg-[#FFCC02] absolute top-0 left-0 w-full rounded-t-xl text-center font-semibold text-sm p-1'>
          Recommended for clinical trial management{" "}
        </div>
      )}
      <div className={`${innerDivClass}`}>
        <h2 className='text-[32px] font-bold'>{title}</h2>
        <p className='leading-tight text-dicom-text-100 font-semibold -mt-1'>{subtitle}</p>
        <div className='mt-1 grid grid-cols-2  justify-between '>{FeatureComponent}</div>
      </div>
      {children}
    </div>
  )
}
