import React, { useEffect, useState } from "react"
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js"
import { fetchData } from "../../services/fetching"
import toast from "react-hot-toast"
import { useLocation, useNavigate } from "react-router-dom"

export default function CheckoutForm() {
  const stripe = useStripe()
  const elements = useElements()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const [checkStripe, setCheckStripe] = useState(true)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleError = (error) => {
    setLoading(false)
    toast.error(error.message || "An error occurred")
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!query.get("product")) {
      return toast.error("Missing package details")
    }
    if (!stripe || !elements) {
      return
    }

    setLoading(true)

    const { error: submitError } = await elements.submit()
    if (submitError) {
      handleError(submitError)
      return
    }

    try {
      const res = await fetchData("POST", "/payments/subscribe", {
        product: query.get("product")
      })
      const { type, clientSecret } = res.data.data
      const confirmIntent = type === "setup" ? stripe.confirmSetup : stripe.confirmPayment
      const { error } = await confirmIntent({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${process.env.REACT_APP_DICOMWALLET_URL}/dashboard/my-dicom-wallet/payments/success`
        }
      })

      if (error) {
        handleError(error)
      } else {
        setLoading(false)
        toast.success("Subscription activated")
        navigate("/success")
      }
    } catch (err) {
      setLoading(false)
      if (err.response && err.response.data) {
        toast.error(err.response.data.message || "An error occurred")
      } else {
        toast.error("Subscription already exists")
      }
    }
  }

  useEffect(() => {
    if (stripe && elements) {
      setCheckStripe(false)
      sessionStorage.removeItem("hasReloaded")
    } else {
      const timeout = setTimeout(() => {
        if (!loading) {
          const hasReloaded = sessionStorage.getItem("hasReloaded")
          if (!hasReloaded) {
            sessionStorage.setItem("hasReloaded", "true")
            window.location.reload()
          }
        }
      }, 100)

      return () => clearTimeout(timeout)
    }
  }, [stripe, elements, loading])

  return (
    <form onSubmit={handleSubmit}>
      {!checkStripe && (
        <>
          <PaymentElement />
          <div className='flex items-center justify-end gap-5'>
            <button
              onClick={() => {
                navigate(-1)
              }}
              type='button'
              className='border-dicom-primary-100 px-8 py-2 rounded-xl border'>
              Back
            </button>

            <button
              className='bg-dicom-primary-100 px-8 py-2 rounded-xl text-white disabled:opacity-50 disabled:cursor-not-allowed'
              type='submit'
              disabled={!stripe || loading}>
              {loading ? "Processing..." : "Pay"}
            </button>
          </div>
        </>
      )}
    </form>
  )
}
