import React from "react";
import { Link } from "react-router-dom";

// Recoil
import { useRecoilValue } from "recoil";
import { authAtom } from "../../atom/auth";
import { UserSection } from "./UserSection";

const Drawer = ({ data }) => {
  const user = useRecoilValue(authAtom);

  const LogOutClickHandler = () => {
    localStorage.removeItem("login_key");
    localStorage.removeItem("login_user");
    window.location = "/";
  };

  return (
    <>
      <div className="flex flex-col justify-between w-full h-full overflow-y-scroll border-t">
        <div>
          <ul className="flex flex-col items-start text-dicom-caption">
            {data.map((nav) => (
              <li className="flex p-2 items-start w-full" key={nav.url}>
                <Link
                  className="w-full hover:text-dicom-primary-100"
                  to={nav.url}
                >
                  {nav.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {user ? (
          <UserSection isMobile user={user} onClick={LogOutClickHandler} />
        ) : (
          <div className="flex flex-col space-y-dicom-10">
            <Link
              to={"/auth/login"}
              className="w-full btn btn-outline px-6 border-2 hover:text-white active:text-white text-dicom-primary-100 border-dicom-primary-100 hover:bg-dicom-primary-100 hover:border-dicom-primary-100 !rounded-dicom-10"
            >
              login
            </Link>
            <Link
              to={"/auth/join-us"}
              className="w-full text-white flex space-x-dicom-10 items-center btn px-6 border-2 bg-dicom-primary-100 border-dicom-primary-100 hover:bg-dicom-primary-hover-100 hover:border-dicom-primary-hover-100 !rounded-dicom-10"
            >
              <span>JOIN US</span>
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.2071 7.29289C15.8166 6.90237 15.1834 6.90237 14.7929 7.29289C14.4024 7.68342 14.4024 8.31658 14.7929 8.70711L16.2071 7.29289ZM19.5 12L20.2071 12.7071L20.9142 12L20.2071 11.2929L19.5 12ZM14.7929 15.2929C14.4024 15.6834 14.4024 16.3166 14.7929 16.7071C15.1834 17.0976 15.8166 17.0976 16.2071 16.7071L14.7929 15.2929ZM5.5 11C4.94772 11 4.5 11.4477 4.5 12C4.5 12.5523 4.94772 13 5.5 13V11ZM14.7929 8.70711L18.7929 12.7071L20.2071 11.2929L16.2071 7.29289L14.7929 8.70711ZM18.7929 11.2929L14.7929 15.2929L16.2071 16.7071L20.2071 12.7071L18.7929 11.2929ZM19.5 11H5.5V13H19.5V11Z"
                  fill="white"
                />
              </svg>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default Drawer;
