let data = [
  {
    id: 0,
    name: "John",
    position: "Patient",
    description:
      "Dicomwallet has made it so easy to store and share my medical images. The interface is user-friendly and the encryption feature gives me peace of mind. I highly recommend it!",
  },
  {
    id: 1,
    name: "Dr. Michael",
    position: "Clinician",
    description:
      "As a doctor, having a secure platform to share patient's imaging studies is a game changer. Dicomwallet has made it easy to collaborate with my colleagues",
  },
  {
    id: 2,
    name: "Kristen",
    position: "Assistant Professor of Radiology",
    description:
      "One of the things that sets DICOM Wallet apart is its strong emphasis on data privacy and security. As a researcher, I am often required to work with sensitive medical data, and the ability to easily anonymize and de-identify my images has been critical for compliance with privacy regulations",
  },
  {
    id: 3,
    name: "Dr. Alex",
    position: "Professor from radiology department",
    description:
      "Dicomwallet is the perfect solution for any healthcare provider looking for an easy and secure way to store and share medical imaging files",
  },
  {
    id: 4,
    name: "Daniel",
    position: "Medical student",
    description:
      "Dicomwallet has been a lifesaver for me, it has made it easy for me to store and share my medical images and to collaborate with my healthcare team",
  },
  {
    id: 5,
    name: "Oliver",
    position: "Clinical associate professor",
    description:
      "I have been using DICOM Wallet for all of my medical imaging needs and I couldn't be happier with the service. The user interface is intuitive and easy to use, and the ability to securely store and share my medical images with healthcare providers has been a life saver",
  },
  {
    id: 6,
    name: "Liyanu",
    position: "Consultant radiologist",
    description:
      "As a researcher in the medical field, I have found DICOM Wallet to be an invaluable tool for organizing and accessing my study data. The ability to securely store and share medical images with my colleagues has greatly streamlined our workflow and allowed us to collaborate more efficiently.",
  },
  {
    id: 7,
    name: "Sarah",
    position: "Radiologyst from NHS",
    description:
      "I had a hard time keeping track of my medical imaging, but with Dicomwallet, I can easily access and share my files with my healthcare team",
  },
];

const Testimonial = () => {
  return (
    <>
      <div className="space-y-dicom-15">
        <p className="text-dicom-headline2 font-bold">
          Testimonials
        </p>
        <svg
          width="147"
          height="2"
          viewBox="0 0 147 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="147" height="2" fill="#021BFA" />
        </svg>
      </div>
      <div className="grid grid-cols-6 lg:grid-cols-12 gap-dicom-30 !mt-10 items-stretch">
        {data.map((item) => (
          <div
            key={item.id}
            className="relative space-y-dicom-15 transition-all duration-300 ease-in-out flex flex-col items-stretch items-center col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-3 xl:col-span-3 px-dicom-30 py-[60px] rounded-dicom-10 bg-[#86d1d7] text-white"
          >
            <div className="grow flex flex-col items-center items-stretch text-sm font-semibold text-[#726e6c] bg-white py-5 px-3 rounded-dicom-10">
                {item.description}
            </div>
            <div className="flex items-center space-x-[5px] text-dicom-body2 font-bold text-[#596563]">
              <p>
                {item.name} 
                <div><small>({item.position})</small></div>
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Testimonial;
