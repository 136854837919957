import { useNavigate } from "react-router-dom";
import { GetLocalStorage } from "../services/LocalStorage";

const isUserAdmin = () => {
  let user = GetLocalStorage("login_user");
  let role = "Admin";
  if (user?.role === role) return true;
  return false;
};

const UserAdminPesrmission = ({ user, role = "Admin", children }) => {
  if (user?.role === role) return children;
};

const UserAdminPesrmissionShow = ({ user, userId, children }) => {
  if (user?.id === userId || user?.role === "Admin") return children;
};

const UserAdminAndCreatorPesrmissionShow = ({ user, userId, children }) => {
  const navigate = useNavigate();
  if (user?.id === userId || user?.role === "Admin") {
    return children;
  } else {
    navigate("/dashboard", { replace: true });
  }
};

const UserMePesrmissionShow = ({ user, userId, children }) => {
  if (user?.id === userId) return children;
};

export {
  isUserAdmin,
  UserAdminPesrmission,
  UserMePesrmissionShow,
  UserAdminPesrmissionShow,
  UserAdminAndCreatorPesrmissionShow,
};
