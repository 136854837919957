import React from "react";
import Layout from "../containers/Layout";
import UseDocumentTitle from "../utils/useDocumentTitle";

const AboutUs = () => {
	UseDocumentTitle("About Us", true);
	return (
		<>
			<Layout>
				<div className="flex flex-col items-center justify-center py-dicom-30 space-y-[64px] lg:px-24 xl:px-64">
					<div className="flex flex-col items-center text-center space-y-dicom-15">
						<h1 className="text-dicom-on-surface-100 text-dicom-headline1 font-bold">
							About Us
						</h1>
						<p className="text-dicom-body1">
							Leading Platform for storing/sharing medical images
						</p>
					</div>
					{/*  */}
					<div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-12 p-4 lg:p-dicom-10 bg-white rounded-dicom-10 shadow-dicom-shadow">
						<div className="col-span-3 md:col-span-3 lg:col-span-5 xl:col-span-8 p-4 lg:p-dicom-30">
							<h3
								className={"text-dicom-headline2 text-dicom-text-100 font-bold"}
							>
								About us
							</h3>
							<div className="space-y-dicom-15">
								<h3
									className={
										"text-dicom-headline4 text-dicom-text-100 font-bold"
									}
								>
									A Solution for Clinicians and Medical Instructors
								</h3>
								<p className={"text-sm text-dicom-text-100"}>
									At DICOMWallet, our mission is to help clinicians and medical
									instructors store and share medical data in a way that is
									easy, compliant, and responsive to their needs. We understand
									that managing medical images can be a challenge, especially
									when it comes to maintaining the quality, confidentiality, and
									compliance of the data. That's why we created DICOMWallet - a
									tool that was developed specifically to address these issues
									and make it easier for healthcare professionals to share and
									manage DICOM images
								</p>
								<p className={"text-sm text-dicom-text-100"}>
									Our solution was inspired by the needs of radiologists at the
									National Health Service (NHS) in the UK, who approached us
									with the problem of getting reliable second opinions from
									their colleagues while maintaining the quality of DICOM images
									and the privacy of patient information. Many of these
									professionals were relying on apps like Whatsapp to share
									DICOM images, but these apps often compromised the quality and
									confidentiality of the data. In response to this need, we
									developed DICOMWallet as a more secure and compliant
									alternative.
								</p>
								<p className={"text-sm text-dicom-text-100"}>
									We are proud to support the vital work of healthcare
									professionals and believe that DICOMWallet will make a
									positive difference in their practices. Whether for teaching,
									research, second opinions, or other purposes, DICOMWallet is
									here to help you take control of your medical images with
									confidence.
								</p>
								<h3
									className={
										"text-dicom-headline4 text-dicom-text-100 font-bold"
									}
								>
									The Benefits of Using DICOMWallet
								</h3>
								<p className={"text-sm text-dicom-text-100"}>
									DICOMWallet is a valuable tool for clinicians, medical
									instructors, patients, and anyone else who needs to manage and
									share medical data. If you are a clinician, you may have a
									personal DICOM library stored on your local drive that you've
									accumulated over the years. DICOMWallet makes it easy to
									access and share this valuable resource, and helps you keep it
									safe and organized even when you switch positions or
									institutions.
								</p>
								<p className={"text-sm text-dicom-text-100"}>
									If you are a medical instructor, you can use DICOMWallet as a
									teaching tool to create, view, and share radiology cases with
									your trainees in real-time online collaboration. The
									integrated DICOM viewer makes it easy to work together and
									learn from each other.
								</p>
								<p className={"text-sm text-dicom-text-100"}>
									For patients, caregivers, and clinicians who need a second
									opinion, DICOMWallet provides a convenient and secure way to
									share patient information without the hassle of using apps
									like Whatsapp or sending CDs/DVDs through the mail.
									DICOMWallet offers three levels of anonymization -
									fully-anonymized, anonymized when sharing, and original - so
									you can choose the level of privacy that is appropriate for
									your needs.
								</p>
								<p className={"text-sm text-dicom-text-100"}>
									If you are involved in law cases related to medical
									malpractice or negligence, DICOMWallet can help you securely
									store and share client cases with relevant parties. In any
									situation where you need to manage and share medical data
									responsibly, DICOMWallet is here to help.
								</p>
							</div>
						</div>
						<div
							className={"col-span-3 md:col-span-3 lg:col-span-3 xl:col-span-4"}
						>
							<div
								className={
									"bg-dicom-primary-hover-100/10 rounded-dicom-10 h-full w-full flex items-center justify-center py-24 px-dicom-30"
								}
							>
								<img
									className="w-[16.625rem]"
									src="/images/About-us.png"
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-col items-center justify-center lg:px-24 xl:px-64 !mt-0">
					<div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-12 p-4 lg:p-dicom-10 bg-white rounded-dicom-10 shadow-dicom-shadow">
						<div className="col-span-3 md:col-span-3 lg:col-span-5 xl:col-span-8 p-4 lg:p-dicom-30">
							<h3
								className={"text-dicom-headline2 text-dicom-text-100 font-bold"}
							>
								Our team
							</h3>

							<div className="text-sm text-dicom-text-100">
								<p className="mt-3">
									<span>
										<span>
											<strong>Dr</strong>
											<strong>. </strong>
											<strong>Fatemeh Ameri</strong>&nbsp;
										</span>
									</span>
								</p>

								<p>
									<span>
										<span>
											<span>
												Fatemeh, the founder of DicomWallet, began her career as
												a lecturer in Marketing at Alliance Manchester Business
												School. Her research centered on the commercialization
												of digital health technology, the analysis of online
												health service providers, and innovation in the field.
											</span>
											&nbsp;
										</span>
									</span>
								</p>

								<p className="mt-3">
									<span>
										<span>
											<strong>Marziyeh Rajabpour</strong>&nbsp;
										</span>
									</span>
								</p>

								<p>
									<span>
										<span>
											<span>
												Marziyeh is the Product Manager and Business Analyst at
												DicomWallet, where she manages all features and ensures
												efficient workflow and progression.
											</span>
											&nbsp;
										</span>
									</span>
								</p>

								<p className="mt-3">
									<span>
										<span>
											<strong>Dr.Lovena C.Pillay</strong>&nbsp;
										</span>
									</span>
								</p>

								<p>
									<span>
										<span>
											<span>
												Lovena is a highly experienced expert in computational
												biology and AI/ML software development. She plays a key
												role in developing the AI capabilities and transforming
												them into world-class technology.
											</span>
											&nbsp;
										</span>
									</span>
								</p>

								<p className="mt-3">
									<span>
										<span>
											<strong>Dr</strong>
											<strong>. </strong>
											<strong>Shayan Motamedi</strong>&nbsp;
										</span>
									</span>
								</p>

								<p>
									<span>
										<span>
											<span>
												Shayan is a highly experienced Senior Data Scientist
												with over 11 years of experience in the medical device
												industry. He has a strong background in signal
												processing, statistics, mathematics, and respiratory
												function, making him well-suited for his role.
											</span>
											&nbsp;
										</span>
									</span>
								</p>

								<p className="mt-3">
									<span>
										<span>
											<strong>Seyed A.Mousavi</strong>&nbsp;
										</span>
									</span>
								</p>

								<p>
									<span>
										<span>
											<span>
												Seyed is a qualified Investment Banker with a track
												record of advising on business growth. As a Financial
												Advisor, he is responsible for providing strategic and
												financial planning to clients.
											</span>
											&nbsp;
										</span>
									</span>
								</p>

								<p className="mt-3">
									<span>
										<span>
											<strong>Dr</strong>
											<strong>. </strong>
											<strong>Ali Maraci</strong>&nbsp;
										</span>
									</span>
								</p>

								<p>
									<span>
										<span>
											<span>
												Ali is a senior data scientist who specializes in
												AI-enabled vision systems. He has a passion for
												developing analytics and machine learning tools to
												address data challenges in resource-constrained
												environments. He holds a PhD from Oxford University,
												where he focused on medical data analysis, machine
												learning, and healthcare innovation during his doctoral
												research.
											</span>
											&nbsp;
										</span>
									</span>
								</p>

								<p className="mt-3">
									<span>
										<span>
											<strong>Dr</strong>
											<strong>. </strong>
											<strong>Shahram Rafieian</strong>&nbsp;
										</span>
									</span>
								</p>

								<p>
									<span>
										<span>
											<span>
												He is a medical doctor and a graduate of the University
												of Medical Sciences. He is a specialist working at
												Northeast London Foundation Trust in London, United
												Kingdom.
											</span>
											&nbsp;
										</span>
									</span>
								</p>
							</div>
						</div>
						<div
							className={"col-span-3 md:col-span-3 lg:col-span-3 xl:col-span-4"}
						>
							<div
								className={
									"bg-dicom-primary-hover-100/10 rounded-dicom-10 h-full w-full flex items-center justify-center py-24 px-dicom-30"
								}
							>
								<img
									className="w-[16.625rem]"
									src="/images/About-us.png"
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		</>
	);
};

export default AboutUs;
