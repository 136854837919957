import axios from "axios"
import React, { useEffect, useState, useContext } from "react"
import toast from "react-hot-toast"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { ErrAlert } from "../../../components/DesignSystem/alert"
import { DicomButton, DicomButtonLabel, DicomButtonOutlineLabel } from "../../../components/DesignSystem/buttons"
import { fetchData } from "../../../services/fetching"
import { GetLocalStorage } from "../../../services/LocalStorage"
import { FormatSizeUnits } from "../../../utils/SizeHandler"
import { Context } from "../../../Context"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { latestStudyUploaded, PaginationMeta, UserStudies, UserStudiesURL } from "../../../atom/userStudies"

const UploadStudy = ({ modalId }) => {
  return (
    <>
      <UploadSection modalId={modalId} />
    </>
  )
}

const UploadSection = ({ modalId }) => {
  const navigate = useNavigate()
  const [Files, setFiles] = useState([])
  const [totalSize, setTotalSize] = useState(0)
  const [user, setUser] = useState({})
  const [IsShowSpace, setIsShowSpace] = useState(false)
  const [Uploaded, setUploaded] = useState(null)
  const [processingPercent, setProcessingPercent] = useState(0)
  const [StatusText, setStatusText] = useState(null)
  const setStudies = useSetRecoilState(UserStudies)
  const studyUrl = useRecoilValue(UserStudiesURL)
  const setPaginationMeta = useSetRecoilState(PaginationMeta)
  const pathName = useLocation().pathname
  const setLatestStudy = useSetRecoilState(latestStudyUploaded)
  // Safeguard the context value
  const contextValue = useContext(Context)
  const modalState = contextValue?.modalState
  const params = useParams()
  const handleUploadChange = (e) => {
    let newFiles = []
    for (let i = newFiles.length; i < e.target.files.length; i++) {
      // if (/\.(DCM)$/i.test(e.target.files[i].name) === false) {
      //   toast.error("not allowed format file");
      // } else {
      // }
      newFiles.push(e.target.files[i])
      setFiles([...Files, ...newFiles])
    }
  }

  // handle files and folders dropping
  const dropHandler = async (ev) => {
    ev.preventDefault()
    const items = ev.dataTransfer.items

    for (let i = 0; i < items.length; i++) {
      const item = items[i]

      // First, check if the item has the webkitGetAsEntry method
      if (item.webkitGetAsEntry) {
        const entry = item.webkitGetAsEntry()

        if (entry) {
          if (entry.isFile) {
            entry.file((file) => {
              processFile(file)
            })
          } else if (entry.isDirectory) {
            processDirectory(entry)
          }
        }
      } else {
        // If webkitGetAsEntry isn't available, fall back to the item.kind method
        if (item.kind === "file") {
          const file = item.getAsFile()
          if (file) {
            processFile(file)
          }
        }
      }
    }
  }

  const processDirectory = (directory) => {
    const dirReader = directory.createReader()
    dirReader.readEntries(async (entries) => {
      for (let i = 0; i < entries.length; i++) {
        if (entries[i].isFile) {
          entries[i].file((file) => {
            processFile(file)
          })
        } else if (entries[i].isDirectory) {
          processDirectory(entries[i])
        }
      }
    })
  }

  const processFile = (file) => {
    setFiles((prevFiles) => [...prevFiles, file])
  }

  const UploadClickHandler = ({ anonymized }) => {
    if (+user?.usedSpace && +totalSize && +user?.totalSpace) {
      if (+user?.usedSpace + +totalSize > +user?.totalSpace) {
        toast.error("The selected files exceed your available storage space. To increase storage, please contact us")
        return false
      }
    }

    const formData = new FormData()
    Files.forEach((item) => {
      formData.append("files", item)
    })

    if (pathName.split("/").includes("folders")) formData.append("folderId", params?.folderId || null)
    formData.append("anonymization", anonymized ? 1 : 0)

    setUploaded(0)
    setProcessingPercent(0)

    axios({
      method: "POST",
      // url: "/study/upload",
      url: process.env.REACT_APP_UPLOAD_ENDPOINT_URL,
      data: formData,
      headers: {
        Authorization: `Bearer ${GetLocalStorage("login_key")}`
      },
      onUploadProgress: (progressEvent) => {
        let percentCompleted = (progressEvent.loaded * 100) / progressEvent.total
        setUploaded(percentCompleted)
      }
    })
      .then(async (res) => {
        if (res.data.data.studies) {
          // console.log(res.data.data.studies);
          if (res.data.data.studies.length === 1) {
            // navigate('/dashboard');
            // navigate(0);
            try {
              let study = await fetchData("GET", studyUrl)
              setStudies(study.data.data)
              const latestItem = res.data.data.studies.reduce((prev, current) =>
                prev.createDate > current.createDate ? prev : current
              )

              sessionStorage.setItem("latestItemId", latestItem.id)
              setLatestStudy(latestItem.id)

              setPaginationMeta((old) => ({
                ...old,
                total: study.data.data.folderCount + study.data.data.count,
                page: 0
              }))
              setFiles([])
              setUploaded(null)
              document.getElementById(modalId).checked = false
            } catch (err) {
              console.log(err)
            }
          } else {
            navigate(0)
          }
          toast.success("The study/studies has been successfully uploaded")
        }
      })
      .catch((err) => {
        console.log(err)
        toast.error(err?.response?.data?.message || err?.message)
        setFiles([])
        setUploaded(null)
      })
  }

  useEffect(() => {
    if (!modalState) {
      setFiles([])
      setUploaded(null)
    }
  }, [modalState])

  useEffect(() => {
    if (Uploaded < 0.01) {
      setStatusText("Initializing...")
    } else if (Uploaded < 99.99) {
      setStatusText("Uploading...")
    } else {
      let current_progress = 0,
        progress = 0,
        defaultstep = 2,
        devide = 4,
        interval,
        step

      let totalSizeinMB = totalSize / 1024 / 1024
      if (totalSizeinMB < 5) {
        defaultstep = 10
      } else if (totalSizeinMB < 30) {
        defaultstep = 3.3
      } else if (totalSizeinMB < 60) {
        defaultstep = 2.5
      } else if (totalSizeinMB < 100) {
        defaultstep = 2
      } else if (totalSizeinMB < 500) {
        defaultstep = 1
      } else if (totalSizeinMB < 1000) {
        defaultstep = 0.4
      } else {
        defaultstep = 0.1
      }

      interval = setInterval(function () {
        if (progress >= 100) {
          clearInterval(interval)
        } else if (progress >= 90) {
          step = defaultstep / (devide * 9)
        } else if (progress >= 80) {
          step = defaultstep / (devide * 8)
        } else if (progress >= 70) {
          step = defaultstep / (devide * 7)
        } else if (progress >= 60) {
          step = defaultstep / (devide * 6)
        } else if (progress >= 50) {
          step = defaultstep / (devide * 5)
        } else if (progress >= 40) {
          step = defaultstep / (devide * 4)
        } else if (progress >= 30) {
          step = defaultstep / (devide * 3)
        } else if (progress >= 20) {
          step = defaultstep / (devide * 2)
        } else if (progress >= 10) {
          step = defaultstep / (devide * 1)
        } else {
          step = defaultstep
        }

        current_progress += step
        progress = Math.round((Math.atan(current_progress) / (Math.PI / 2)) * 100 * 1000) / 1000

        setProcessingPercent(progress)

        let processingText = `Processing...`
        setStatusText(processingText)
      }, 100)
    }
  }, [Uploaded, totalSize])

  useEffect(() => {
    fetchData("GET", "/user/profile").then((res) => {
      setUser(res.data.data.user)
      if (res.data.data.user) {
        setIsShowSpace(parseInt(res.data.data.user?.usedSpace) > parseInt(res.data.data.user?.totalSpace))
      }
    })
  }, [user?.usedSpace, user?.totalSpace])

  useEffect(() => {
    let myTotalSize = 0
    for (let i = 0; i < Files.length; i++) {
      myTotalSize += Files[i].size
    }
    setTotalSize(myTotalSize)
  }, [Files])

  return (
    <>
      {IsShowSpace ? (
        <ErrAlert isShow={IsShowSpace} message={"Your storage is full. please delete some studies to add new one!"} />
      ) : Uploaded !== null ? (
        <div className='flex flex-col items-center space-y-dicom-15'>
          <p className='text-dicom-body1 text-dicom-secondary-100'>{StatusText}</p>
          <div
            className='radial-progress text-dicom-secondary-100'
            style={{
              "--value": Uploaded < 100 ? Uploaded : processingPercent,
              "--size": "14.25rem",
              "--thickness": "8px"
            }}>
            <img className='h-[102px]' src='/images/Upload-study.svg' alt='' />
          </div>
          <p className='text-dicom-body1 text-[#C4C4C4]'>Uploading: {Uploaded.toFixed(0)}%</p>
          <p className='text-dicom-body1 text-[#C4C4C4] !mt-0'>Processing: {processingPercent.toFixed()}%</p>
          <DicomButtonOutlineLabel
            onClick={() => navigate(0)}
            htmlFor={modalId}
            btnClass={"!border-dicom-secondary-100 !text-dicom-secondary-100 hover:!bg-dicom-secondary-100/5"}
            title={"Cancel"}
          />
        </div>
      ) : Files.length ? (
        <div className='space-y-dicom-15'>
          <div className='flex items-center justify-between'>
            <input
              webkitdirectory={"true"}
              mozdirectory={"true"}
              directory={"true"}
              className='hidden'
              type='file'
              id='Folders'
              multiple
              name='files'
              onChange={handleUploadChange}
            />
            <input className='hidden' type='file' id='files' multiple name='files' onChange={handleUploadChange} />
            <div className='flex w-full flex-col lg:flex-row gap-dicom-15'>
              <DicomButtonLabel
                htmlFor={"files"}
                btnClass={"!border-dicom-secondary-100 !bg-dicom-secondary-100 hover:!bg-dicom-secondary-hover-100"}
                iconL={
                  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H13V7C13 6.44771 12.5523 6 12 6C11.4477 6 11 6.44771 11 7V11H7C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H11V17Z'
                      fill='white'
                    />
                  </svg>
                }
                title={"Add file"}
              />
              <DicomButtonLabel
                htmlFor={"Folders"}
                btnClass={"!border-dicom-secondary-100 !bg-dicom-secondary-100 hover:!bg-dicom-secondary-hover-100"}
                iconL={
                  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H13V7C13 6.44771 12.5523 6 12 6C11.4477 6 11 6.44771 11 7V11H7C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H11V17Z'
                      fill='white'
                    />
                  </svg>
                }
                title={"Add folder"}
              />
            </div>
          </div>
          <div className='flex items-center border-b-2 border-b-[#FAFAFA] px-dicom-30 justify-between text-dicom-text-100 text-base font-bold'>
            <span className=''>No</span>
            <span className=''>File name</span>
            <span className=''>Size</span>
            <span className=''>Operation</span>
          </div>
          <div className='max-h-64 overflow-y-scroll bg-dicom-background-100'>
            {Files?.map((file, index) => (
              <div
                key={index}
                className='flex items-center border-b-2 border-b-[#FAFAFA] px-dicom-30 justify-between text-dicom-text-100 text-base'>
                <span className=''>{index + 1}</span>
                <span className=''>{file.name}</span>
                <span className=''>{FormatSizeUnits(file.size)}</span>
                <span className=''>
                  <button
                    onClick={() => {
                      let filteredFiles = Files.filter((findItem) => findItem.name !== file.name)
                      setFiles(filteredFiles)
                    }}
                    className='p-dicom-10'>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M5.16565 10.1534C5.07629 8.99181 5.99473 8 7.15975 8H16.8402C18.0053 8 18.9237 8.9918 18.8344 10.1534L18.142 19.1534C18.0619 20.1954 17.193 21 16.1479 21H7.85206C6.80699 21 5.93811 20.1954 5.85795 19.1534L5.16565 10.1534Z'
                        stroke='#575757'
                        strokeWidth='2'
                      />
                      <path d='M19.5 5H4.5' stroke='#575757' strokeWidth='2' strokeLinecap='round' />
                      <path
                        d='M10 3C10 2.44772 10.4477 2 11 2H13C13.5523 2 14 2.44772 14 3V5H10V3Z'
                        stroke='#575757'
                        strokeWidth='2'
                      />
                    </svg>
                  </button>
                </span>
              </div>
            ))}
          </div>
          <div className='flex w-full justify-center gap-x-dicom-10 mt-0'>
            <div className='flex w-full justify-left gap-x-dicom-10 text-dicom-secondary-100 mt-3'>
              Total: {Files.length} files, {FormatSizeUnits(totalSize)}
            </div>
            <input
              webkitdirectory={"true"}
              mozdirectory={"true"}
              directory={"true"}
              accept='.zip'
              className='hidden'
              type='file'
              id='files'
              multiple
              name='files'
              onChange={handleUploadChange}
            />
          </div>
          <div className='flex justify-between'>
            <DicomButton
              onClick={() => UploadClickHandler({ anonymized: true })}
              btnClass={"!border-dicom-secondary-100 !bg-dicom-secondary-100 hover:!bg-dicom-secondary-hover-100"}
              title={"Anonymized upload"}
            />
            <div className='flex gap-2'>
              <DicomButtonOutlineLabel
                htmlFor={modalId}
                btnClass={"!border-dicom-secondary-100 !text-dicom-secondary-100 hover:!bg-dicom-secondary-100/5"}
                title={"Cancel"}
              />
              <DicomButton
                onClick={UploadClickHandler}
                btnClass={"!border-dicom-secondary-100 !bg-dicom-secondary-100 hover:!bg-dicom-secondary-hover-100"}
                title={"Upload"}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className='flex flex-col space-y-dicom-15 w-full items-center justify-center'>
          {/* Dropzone Area */}

          <div
            onDrop={dropHandler}
            onDragOver={(e) => e.preventDefault()}
            className='flex flex-col space-y-dicom-15 border-2 border-dicom-secondary-100 border-dashed px-[30px] py-[15px] rounded-dicom-10'>
            <img src='/images/Upload-study.svg' className='h-[144px]' alt='' />
            <p className='text-dicom-background2-100 text-sm text-start font-medium'>
              You are uploading files to this website. This action will upload all files to "My DicomWallet" folder.
              <br />
              You also have the option to anonymize all studies in the selected files/folders before uploading. To do
              so, simply click on the "Anonymize Upload" button.
            </p>
          </div>
          <div className='flex lg:items-center lg:justify-center w-full flex-col lg:flex-row gap-dicom-15'>
            <input
              webkitdirectory={"true"}
              mozdirectory={"true"}
              directory={"true"}
              className='hidden'
              type='file'
              id='Folders'
              multiple
              name='files'
              onChange={handleUploadChange}
            />
            <input className='hidden' type='file' id='files' multiple name='files' onChange={handleUploadChange} />

            <div className='flex justify-between max-w-[250px] w-full gap-3'>
              <DicomButtonLabel
                htmlFor={"files"}
                btnClass={
                  "!border-dicom-secondary-100 !bg-dicom-secondary-100 hover:!bg-dicom-secondary-hover-100 flex-1 font-normal"
                }
                title={"Add file"}
              />
              <DicomButtonLabel
                htmlFor={"Folders"}
                btnClass={
                  "!border-dicom-secondary-100 !bg-dicom-secondary-100 hover:!bg-dicom-secondary-hover-100 flex-1 font-normal"
                }
                title={"Add folder"}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default UploadStudy
