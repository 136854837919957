import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import toast from "react-hot-toast"

// Components
import { DicomButton } from "../components/DesignSystem/buttons"
import { Input, TeaxtArea } from "../components/DesignSystem/inputs"
import Layout from "../containers/Layout"
import UseDocumentTitle from "../utils/useDocumentTitle"

const ContactUs = () => {
  UseDocumentTitle("Contact Us", true)
  const [ErrorMessage, setErrorMessage] = useState({})

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    message: ""
  }

  const validationSchema = Yup.object({
    firstName: Yup.string().required("The First Name is required"),
    lastName: Yup.string().required("The Last Name is required"),
    email: Yup.string().required("The Email Address is required").email("Email is invalid."),
    // phone: Yup.string().required("The Phone Number is required"),
    message: Yup.string().required("The Message is required")
  })

  const onSubmit = () => {
    axios({
      method: "POST",
      url: "/contactus",
      data: formik.values
    })
      .then((res) => {
        if (res?.data) {
          toast.success("Submitted successfully")
          formik.resetForm()
        }
      })
      .catch((err) => {
        if (err?.response?.data) {
          setErrorMessage(err?.response?.data?.data)
        }
      })
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnMount: true
  })

  const ContactData = [
    // {
    //   id: 1,
    //   icon: (
    //     <svg
    //       width="24"
    //       height="24"
    //       viewBox="0 0 24 24"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         d="M3.44297 5.59453L5.93906 3.10078C6.24375 2.79609 6.65156 2.625 7.08281 2.625C7.51406 2.625 7.92188 2.79375 8.22656 3.10078L10.9148 5.78437C11.2195 6.08906 11.3906 6.49922 11.3906 6.93047C11.3906 7.36406 11.2219 7.76953 10.9148 8.07656L8.81484 10.1789C9.29324 11.2936 9.98061 12.3063 10.8398 13.1625C11.7 14.0273 12.7055 14.7094 13.8211 15.1922L15.9211 13.0898C16.2258 12.7852 16.6336 12.6141 17.0648 12.6141C17.2776 12.6133 17.4883 12.655 17.6847 12.7366C17.8811 12.8183 18.0592 12.9384 18.2086 13.0898L20.8992 15.7734C21.2039 16.0781 21.375 16.4883 21.375 16.9195C21.375 17.3531 21.2062 17.7586 20.8992 18.0656L18.4055 20.5594C17.8852 21.0797 17.168 21.3773 16.432 21.3773C16.2797 21.3773 16.132 21.3656 15.982 21.3398C12.8789 20.8289 9.79922 19.1766 7.3125 16.6922C4.82812 14.2031 3.17812 11.1234 2.6625 8.01797C2.51484 7.13672 2.81016 6.22969 3.44297 5.59453ZM4.32422 7.73906C4.78125 10.5023 6.26719 13.2586 8.50547 15.4969C10.7438 17.7352 13.4977 19.2211 16.2609 19.6781C16.6078 19.7367 16.9641 19.6195 17.2172 19.3687L19.6664 16.9195L17.0695 14.3203L14.2617 17.1328L14.2406 17.1539L13.7344 16.9664C12.1994 16.402 10.8055 15.5107 9.64924 14.354C8.49301 13.1974 7.60211 11.8032 7.03828 10.268L6.85078 9.76172L9.68203 6.93281L7.08516 4.33359L4.63594 6.78281C4.38281 7.03594 4.26562 7.39219 4.32422 7.73906Z"
    //         fill="currentColor"
    //       />
    //     </svg>
    //   ),
    //   text: "+1 25358849866",
    // },
    {
      id: 1,
      url: "mailto:support@dicomwallet.com",
      icon: (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M7.62469 8.21913C7.19343 7.87412 6.56414 7.94404 6.21913 8.37531C5.87412 8.80657 5.94404 9.43586 6.37531 9.78087L10.7506 13.2811C11.481 13.8655 12.519 13.8655 13.2494 13.2811L17.6247 9.78087C18.056 9.43586 18.1259 8.80657 17.7809 8.37531C17.4359 7.94404 16.8066 7.87412 16.3753 8.21913L12 11.7194L7.62469 8.21913Z'
            fill='currentColor'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7 3C4.23858 3 2 5.23858 2 8V16C2 18.7614 4.23858 21 7 21H17C19.7614 21 22 18.7614 22 16V8C22 5.23858 19.7614 3 17 3H7ZM4 8C4 6.34315 5.34315 5 7 5H17C18.6569 5 20 6.34315 20 8V16C20 17.6569 18.6569 19 17 19H7C5.34315 19 4 17.6569 4 16V8Z'
            fill='currentColor'
          />
        </svg>
      ),
      text: "Support@dicomwallet.com"
    },
    {
      id: 2,
      url: "mailto:Business@dicomwallet.com",
      icon: (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M7.62469 8.21913C7.19343 7.87412 6.56414 7.94404 6.21913 8.37531C5.87412 8.80657 5.94404 9.43586 6.37531 9.78087L10.7506 13.2811C11.481 13.8655 12.519 13.8655 13.2494 13.2811L17.6247 9.78087C18.056 9.43586 18.1259 8.80657 17.7809 8.37531C17.4359 7.94404 16.8066 7.87412 16.3753 8.21913L12 11.7194L7.62469 8.21913Z'
            fill='currentColor'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7 3C4.23858 3 2 5.23858 2 8V16C2 18.7614 4.23858 21 7 21H17C19.7614 21 22 18.7614 22 16V8C22 5.23858 19.7614 3 17 3H7ZM4 8C4 6.34315 5.34315 5 7 5H17C18.6569 5 20 6.34315 20 8V16C20 17.6569 18.6569 19 17 19H7C5.34315 19 4 17.6569 4 16V8Z'
            fill='currentColor'
          />
        </svg>
      ),
      text: "Business@dicomwallet.com"
    }
    // {
    //   id: 3,
    //   icon: (
    //     <svg
    //       width="24"
    //       height="24"
    //       viewBox="0 0 24 24"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         fillRule="evenodd"
    //         clipRule="evenodd"
    //         d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13ZM11 10C11 9.44772 11.4477 9 12 9C12.5523 9 13 9.44772 13 10C13 10.5523 12.5523 11 12 11C11.4477 11 11 10.5523 11 10Z"
    //         fill="currentColor"
    //       />
    //       <path
    //         fillRule="evenodd"
    //         clipRule="evenodd"
    //         d="M13.4507 21.4216L18.3266 16.7933C21.8911 13.4099 21.8911 7.87894 18.3266 4.49553C14.8212 1.16816 9.1788 1.16816 5.67336 4.49553C2.10888 7.87894 2.10888 13.4099 5.67336 16.7933L10.5493 21.4216C11.3618 22.1928 12.6382 22.1928 13.4507 21.4216ZM7.05025 15.3427C4.31658 12.7479 4.31658 8.54091 7.05025 5.9461C9.78392 3.3513 14.2161 3.3513 16.9497 5.9461C19.6834 8.54091 19.6834 12.7479 16.9497 15.3427L12.0738 19.971C12.033 20.0097 11.967 20.0097 11.9262 19.971L7.05025 15.3427Z"
    //         fill="currentColor"
    //       />
    //     </svg>
    //   ),
    //   text: "Address",
    // },
  ]

  return (
    <>
      <Layout>
        <div className='flex flex-col items-center justify-center py-dicom-30 space-y-[64px] lg:px-24 xl:px-36'>
          <div className='flex flex-col items-center text-center space-y-dicom-15'>
            <h1 className='text-dicom-on-surface-100 text-dicom-headline1 font-bold'>Contact Us</h1>
            <p className='text-dicom-body1'>Any question or remarks! Just write us a message!</p>
          </div>

          <div className='grid w-full grid-cols-3 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-12 p-dicom-10 bg-white rounded-dicom-10 gap-dicom-10 shadow-dicom-shadow'>
            <div className='space-y-[40px] col-span-3 md:col-span-3 lg:col-span-5 xl:col-span-8 p-dicom-30 !text-dicom-text-100 bg-white rounded-dicom-10'>
              <div>
                <h3 className={"text-dicom-headline2 font-bold"}>Contact information</h3>
                <p className={"text-dicom-body2"}>
                  Please write to us if you have any problem, comments, a feature to suggest, potential collaborations,
                  or you want to know more about us. Please fill in the form with your enquiry or contact us via{" "}
                  <a className='!text-dicom-primary-100' href='mailto:support@dicomwallet.com'>
                    support@dicomwallet.com
                  </a>
                </p>
                <p className={"text-dicom-body2"}>
                  If you need DICOMWallet services for business and institutional use, please contact us via.{" "}
                  <a className='!text-dicom-primary-100' href='mailto:Business@dicomwallet.com'>
                    Business@dicomwallet.com
                  </a>
                </p>
                <p className={"text-sm text-dicom-text-100"}>
                  We aim to respond to all emails and queries as soon as possible.
                </p>
              </div>
              <div className='space-y-dicom-15'>
                {ContactData.map((item) => (
                  <div key={item.id} className='flex items-center space-x-dicom-10'>
                    {item.icon}
                    <a href={item.url} className='text-dicom-body2'>
                      {item.text}
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div className={"col-span-3 md:col-span-3 lg:col-span-3 xl:col-span-4"}>
              <div
                className={"flex flex-col space-y-dicom-15 rounded-dicom-10 h-full w-full items-center justify-center"}>
                <Input
                  error={ErrorMessage?.firstName}
                  nameId={"firstName"}
                  placeholder={"First name"}
                  formik={formik}
                />
                <Input error={ErrorMessage?.lastName} nameId={"lastName"} placeholder={"Last name"} formik={formik} />
                <Input error={ErrorMessage?.email} nameId={"email"} placeholder={"Email address"} formik={formik} />
                {/* 
                <Input
                  error={ErrorMessage?.phone}
                  nameId={"phone"}
                  placeholder={"Phone number"}
                  formik={formik}
                />
                */}
                <TeaxtArea error={ErrorMessage?.message} nameId={"message"} placeholder={"Message"} formik={formik} />
                <DicomButton
                  type={"submit"}
                  onClick={onSubmit}
                  title={"Send message"}
                  btnClass={"w-full"}
                  disabled={!formik.isValid ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ContactUs
