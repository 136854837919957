import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

const footerData = {
  desctption: "",
  social: [
    {
      id: 1,
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.4375 12.2719C23.4375 18.9047 18.8953 23.625 12.1875 23.625C5.75625 23.625 0.5625 18.4312 0.5625 12C0.5625 5.56875 5.75625 0.375 12.1875 0.375C15.3188 0.375 17.9531 1.52344 19.9828 3.41719L16.8187 6.45938C12.6797 2.46563 4.98281 5.46563 4.98281 12C4.98281 16.0547 8.22188 19.3406 12.1875 19.3406C16.7906 19.3406 18.5156 16.0406 18.7875 14.3297H12.1875V10.3312H23.2547C23.3625 10.9266 23.4375 11.4984 23.4375 12.2719Z"
            fill="currentColor"
          />
        </svg>
      ),
      url: "",
    },
    {
      id: 2,
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.0047 6.60928C9.02341 6.60928 6.61873 9.01396 6.61873 11.9952C6.61873 14.9765 9.02341 17.3811 12.0047 17.3811C14.9859 17.3811 17.3906 14.9765 17.3906 11.9952C17.3906 9.01396 14.9859 6.60928 12.0047 6.60928ZM12.0047 15.4968C10.0781 15.4968 8.5031 13.9265 8.5031 11.9952C8.5031 10.064 10.0734 8.49365 12.0047 8.49365C13.9359 8.49365 15.5062 10.064 15.5062 11.9952C15.5062 13.9265 13.9312 15.4968 12.0047 15.4968V15.4968ZM18.8672 6.38896C18.8672 7.0874 18.3047 7.64521 17.6109 7.64521C16.9125 7.64521 16.3547 7.08271 16.3547 6.38896C16.3547 5.69521 16.9172 5.13271 17.6109 5.13271C18.3047 5.13271 18.8672 5.69521 18.8672 6.38896ZM22.4344 7.66396C22.3547 5.98115 21.9703 4.49053 20.7375 3.2624C19.5094 2.03428 18.0187 1.6499 16.3359 1.56553C14.6015 1.46709 9.4031 1.46709 7.66873 1.56553C5.9906 1.64521 4.49998 2.02959 3.26716 3.25771C2.03435 4.48584 1.65466 5.97646 1.57029 7.65928C1.47185 9.39365 1.47185 14.5921 1.57029 16.3265C1.64998 18.0093 2.03435 19.4999 3.26716 20.728C4.49998 21.9562 5.98591 22.3405 7.66873 22.4249C9.4031 22.5233 14.6015 22.5233 16.3359 22.4249C18.0187 22.3452 19.5094 21.9608 20.7375 20.728C21.9656 19.4999 22.35 18.0093 22.4344 16.3265C22.5328 14.5921 22.5328 9.39834 22.4344 7.66396V7.66396ZM20.1937 18.1874C19.8281 19.1062 19.1203 19.814 18.1969 20.1843C16.814 20.7327 13.5328 20.6062 12.0047 20.6062C10.4765 20.6062 7.1906 20.728 5.81248 20.1843C4.89373 19.8187 4.18591 19.1108 3.8156 18.1874C3.26716 16.8046 3.39373 13.5233 3.39373 11.9952C3.39373 10.4671 3.27185 7.18115 3.8156 5.80303C4.18123 4.88428 4.88904 4.17646 5.81248 3.80615C7.19529 3.25771 10.4765 3.38428 12.0047 3.38428C13.5328 3.38428 16.8187 3.2624 18.1969 3.80615C19.1156 4.17178 19.8234 4.87959 20.1937 5.80303C20.7422 7.18584 20.6156 10.4671 20.6156 11.9952C20.6156 13.5233 20.7422 16.8093 20.1937 18.1874Z"
            fill="currentColor"
          />
        </svg>
      ),
      url: "",
    },
    {
      id: 3,
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_218_1841)">
            <path
              d="M22.3125 0.15022L0.585893 12.6846C-0.262544 13.1721 -0.154731 14.3533 0.689018 14.7096L5.67183 16.8002L19.139 4.93147C19.3968 4.70178 19.7625 5.05335 19.5421 5.32053L8.24996 19.0783V22.8518C8.24996 23.958 9.58589 24.394 10.2421 23.5924L13.2187 19.969L19.0593 22.4158C19.725 22.6971 20.4843 22.2799 20.6062 21.5627L23.9812 1.31272C24.1406 0.365845 23.1234 -0.31853 22.3125 0.15022Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_218_1841">
              <rect width="24" height="24" fill="currentColor" />
            </clipPath>
          </defs>
        </svg>
      ),
      url: "",
    },
    {
      id: 4,
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.20062 21H1.84688V6.97971H6.20062V21ZM4.02141 5.06721C2.62922 5.06721 1.5 3.91408 1.5 2.52189C1.5 1.85318 1.76565 1.21185 2.2385 0.738991C2.71136 0.266136 3.35269 0.000488281 4.02141 0.000488281C4.69012 0.000488281 5.33145 0.266136 5.80431 0.738991C6.27716 1.21185 6.54281 1.85318 6.54281 2.52189C6.54281 3.91408 5.41313 5.06721 4.02141 5.06721ZM22.4953 21H18.1509V14.175C18.1509 12.5485 18.1181 10.4625 15.8873 10.4625C13.6237 10.4625 13.2769 12.2297 13.2769 14.0578V21H8.92781V6.97971H13.1034V8.89221H13.1644C13.7456 7.79065 15.1655 6.62815 17.2838 6.62815C21.69 6.62815 22.5 9.52971 22.5 13.2985V21H22.4953Z"
            fill="currentColor"
          />
        </svg>
      ),
      url: "",
    },
    {
      id: 5,
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.625 12C23.625 5.57813 18.4219 0.375 12 0.375C5.57812 0.375 0.375 5.57813 0.375 12C0.375 17.8022 4.62609 22.6116 10.1836 23.4844V15.3605H7.23047V12H10.1836V9.43875C10.1836 6.52547 11.918 4.91625 14.5744 4.91625C15.8466 4.91625 17.1769 5.14313 17.1769 5.14313V8.0025H15.7106C14.2669 8.0025 13.8164 8.89875 13.8164 9.81797V12H17.0405L16.5248 15.3605H13.8164V23.4844C19.3739 22.6116 23.625 17.8022 23.625 12Z"
            fill="currentColor"
          />
        </svg>
      ),
      url: "",
    },
  ],
  footerData: [
    {
      id: 1,
      title: "Quick links",
      data: [
        { id: 1, title: "About Us", url: "/about-us" },
        { id: 2, title: "Contact us", url: "/contact-us" },
        { id: 3, title: "Login", url: "/auth/login" },
        { id: 4, title: "Sign up", url: "/auth/join-us" },
        { id: 5, title: "Blog", url: "https://blog.dicomwallet.com/" },
      ],
    },
    {
      id: 2,
      title: "Company",
      data: [
        {
          id: 1,
          title: "Terms of Service",
          url: "/legal/term-of-service",
        },
        {
          id: 2,
          title: "Acceptable use",
          url: "/legal/acceptable-use",
        },
        {
          id: 3,
          title: "Privacy Policy",
          url: "/legal/privacy-policy",
        },
        {
          id: 4,
          title: "Cookie Policy ",
          url: "/legal/cookie-policy",
        },
        {
          id: 5,
          title: "Open source ",
          url: "/legal/open-source",
        },
        {
          id: 6,
          title: "User compliance",
          url: "/legal/user-compliance",
        },
      ],
    },
  ],
};

const Footer = () => {
	const navigate = useNavigate()
  return (
    <>
      <div className="bg-[#F2F2FF]">
        <div className="lg:container !max-w-7xl mx-auto px-4 lg:px-8 xl:px-0 pt-14 space-y-[68px]">
          <div className="grid grid-cols-3 lg:grid-cols-6 xl:grid-cols-12 gap-6 md:gap-10 lg:gap-16 xl:gap-24">
            <div className="col-span-6 xl:col-span-4">
              <div className="space-y-dicom-15">
                <p className="text-dicom-headline2 font-bold">DICOMWallet</p>
                <svg
                  width="147"
                  height="2"
                  viewBox="0 0 147 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="147" height="2" fill="#021BFA" />
                </svg>
                <p className="text-base text-dicom-on-surface-100">
                  Cloud-based medical storage service with integrated Dicom
                  viewer. It enables users to store, access and share medical
                  images online while safeguard the confidentiality of patient
                  information.
                </p>
                <div className="flex gap-dicom-15">
                  {footerData.social.map((social) => (
                    <a
                      key={social.id}
                      href={social.url}
                      target={"_blank"}
                      rel="noreferrer"
                      className="transition-all duration-300 ease-in-out cursor-pointer hover:!text-dicom-primary-100 hover:-translate-y-1"
                    >
                      {social.icon}
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-span-6 xl:col-span-8 grid grid-cols-3 md:grid-cols-6 lg:grid-cols-6 xl:grid-cols-12 gap-6 md:gap-10 lg:gap-16 xl:gap-24">
              {footerData.footerData.map((item) => (
                <div
                  key={item.id}
                  className="col-span-3 md:col-span-2 lg:col-span-2 xl:col-span-3 space-y-dicom-15 text-dicom-on-surface-100"
                >
                  <p className="text-dicom-body1 font-bold">{item.title}</p>
                  <div className="space-y-dicom-15">
                    {item.data.map((nav) => (
                      <div key={nav.id}>
                        {nav.url.indexOf("http") === 0 ? (
                          <a
                            className="transition-all duration-300 ease-in-out hover:text-dicom-primary-100"
                            href={nav.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {nav.title}
                          </a>
                        ) : (
                          <NavLink
                            className="transition-all duration-300 ease-in-out hover:text-dicom-primary-100"
                            to={nav.url}
                          >
                            {nav.title}
                          </NavLink>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              <div className="col-span-3 md:col-span-2 lg:col-span-2 xl:col-span-3 space-y-dicom-15 text-dicom-on-surface-100">
                <Link target="_blank"  to="https://developer.orchahealth.com/app/?appId=17014&platformId=3">
                  <img
				 
                    src="/images/orchaLogo.png"
                    className="w-[153px] h-[77px]"
                    alt="orcha Logo"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="flex text-center items-center justify-center py-12 border-t border-t-[#D9D9D9]">
            <p>
              COPYRIGHT © {new Date().getFullYear()} DICOMWALLET ALL RIGHTS
              RESERVED.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
