import React, { useState } from "react";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { DicomButton, DicomButtonOutline } from "./DesignSystem/buttons";

const DatePickerComponent = ({ DateRange, setDateRange, titleAttr }) => {
  const [GetData, setGetData] = useState({});
  const onclickDoneHandler = () => {
    let Sdate = [];
    for (let key in GetData) {
      if (GetData[key]) {
        Sdate.push(`${[key]}=${GetData[key]}`);
      }
    }
    setDateRange(Sdate.join("&"));
  };

  const clearHandler = () => {
    setGetData(undefined);
    setDateRange("");
  };
  return (
    <>
      <DatePicker
        shadow={false}
        range
        plugins={[<DatePanel />]}
        render={(value, openCalendar) => {
          return (
            <button title={titleAttr} onClick={openCalendar}>
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.3242 3C17.3242 2.44772 16.8765 2 16.3242 2C15.7719 2 15.3242 2.44772 15.3242 3H17.3242ZM15.3242 6C15.3242 6.55228 15.7719 7 16.3242 7C16.8765 7 17.3242 6.55228 17.3242 6H15.3242ZM9.32422 3C9.32422 2.44772 8.8765 2 8.32422 2C7.77193 2 7.32422 2.44772 7.32422 3H9.32422ZM7.32422 6C7.32422 6.55228 7.77193 7 8.32422 7C8.8765 7 9.32422 6.55228 9.32422 6H7.32422ZM7.32422 6H17.3242V4H7.32422V6ZM20.3242 9V17H22.3242V9H20.3242ZM17.3242 20H7.32422V22H17.3242V20ZM4.32422 17V9H2.32422V17H4.32422ZM7.32422 20C5.66736 20 4.32422 18.6569 4.32422 17H2.32422C2.32422 19.7614 4.56279 22 7.32422 22V20ZM20.3242 17C20.3242 18.6569 18.9811 20 17.3242 20V22C20.0856 22 22.3242 19.7614 22.3242 17H20.3242ZM17.3242 6C18.9811 6 20.3242 7.34315 20.3242 9H22.3242C22.3242 6.23858 20.0856 4 17.3242 4V6ZM7.32422 4C4.5628 4 2.32422 6.23858 2.32422 9H4.32422C4.32422 7.34315 5.66736 6 7.32422 6V4ZM15.3242 3V6H17.3242V3H15.3242ZM7.32422 3V6H9.32422V3H7.32422Z"
                  fill="#1DD3B0"
                />
                <rect
                  x="8.32422"
                  y="13"
                  width="8"
                  height="3"
                  rx="1.5"
                  stroke="#1DD3B0"
                  strokeWidth="2"
                />
              </svg>
            </button>
          );
        }}
        value={DateRange}
        format="MMM DD, YYYY"
        onChange={(data) => {
          setGetData({
            dateFrom: new Date(
              `${data[0]?.year}/${data[0]?.month?.number}/${data[0]?.day}`
            ).getTime(),
            dateTo: new Date(
              `${data[1]?.year}/${data[1]?.month?.number}/${data[1]?.day}`
            ).getTime(),
          });
        }}
      >
        <div className="flex px-4 pb-4 w-full gap-2">
          <DicomButton onClick={onclickDoneHandler} title={"Done"} />
          <DicomButtonOutline
            btnClass={
              "!border-2 !border-dicom-primary-100 hover:!bg-dicom-primary-100/10 hover:!text-dicom-primary-100"
            }
            onClick={clearHandler}
            title={"Clear"}
          />
        </div>
      </DatePicker>
    </>
  );
};

export default DatePickerComponent;
