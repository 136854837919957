import React from "react";

// Pages
import CardsSections from "../components/LandingSections/CardsSections";
import ChooseUs from "../components/LandingSections/ChooseUs";
import HowToDicom from "../components/LandingSections/HowToDicom";
import Security from "../components/LandingSections/Security";
import Testimonial from "../components/LandingSections/Testimonial";
import TopHeader from "../components/LandingSections/TopHeader";
import Layout from "../containers/Layout";
import UseDocumentTitle from "../utils/useDocumentTitle";

const Landing = () => {
  UseDocumentTitle("DICOM Wallet", true);

  return (
    <>
      <Layout>
        <TopHeader />
        <ChooseUs />
        <HowToDicom />
        <CardsSections />
        <Security />
        <Testimonial />
      </Layout>
    </>
  );
};

export default Landing;
