import axios from "axios";

import { GetLocalStorage } from "./LocalStorage";

const fetchData = async (method, url, data, headers, onUploadProgress) => {
 
  return axios({
    method,
    url,
    data,
    headers: {
      ...headers,
      Authorization: `Bearer ${GetLocalStorage("login_key")}`,
    },
    onUploadProgress,
  });
};

const fetchDownloadFile = async (method, url,headers) => {
  return axios({
    method,
    url,
    responseType: "blob",
    headers: {
      ...headers,
      Authorization: `Bearer ${GetLocalStorage("login_key")}`,
    },
  });
};

export { fetchData, fetchDownloadFile };
