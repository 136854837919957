import { Autocomplete } from "@mui/material";
import React, { useContext, useState } from "react";
import { Context } from "../../Context";

import { ReactMultiEmail } from "react-multi-email";

const BaseInput = ({
  onBlurHandler,
  nameId,
  type,
  isPassword,
  placeholder,
  label,
  classInput,
  onChange,
  onKeyDown,
  InputClassName,
  badgeTitle,
  description,
  disable,
  value,
  titleAttr,
}) => {
  const [passwordShow, setPasswordShow] = useState(false);
  return (
    <>
      <div className={`${InputClassName} flex flex-col w-full`}>
        {label ? (
          <div className="relative flex items-center w-full justify-between">
            <label className="text-sm mb-1 text-slate-600" htmlFor={nameId}>
              {label}
            </label>
          </div>
        ) : (
          ""
        )}
        <div className="relative w-full">
          <div className="relative w-full">
            <input
              name={nameId}
              onChange={onChange}
              onBlur={onBlurHandler}
              onKeyDown={onKeyDown}
              value={value}
              title={titleAttr}
              placeholder={placeholder ? placeholder : ""}
              disabled={disable}
              id={nameId}
              className={`input ${classInput} bg-dicom-secondary2-100 !ring-0 !outline-none border-2 rounded-dicom-10 focus-within:border-dicom-primary-100 w-full 
                `}
              type={
                isPassword
                  ? passwordShow
                    ? "text"
                    : "password"
                  : type
                  ? type
                  : "text"
              }
            />
            <PassowrdAction
              isPassword={isPassword}
              passwordShow={passwordShow}
              setPasswordShow={setPasswordShow}
            />
            <BadgeTitleSection badge={badgeTitle} />
          </div>
          {description ? (
            <label className="px-4 text-xs text-dicom-text-100">
              {description}
            </label>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

const AutoCompleteInput = ({
  onBlurHandler,
  nameId,
  type,
  isPassword,
  placeholder,
  label,
  classInput,
  onChange,
  onKeyDown,
  InputClassName,
  badgeTitle,
  description,
  disable,
  value,
  setValue,
  options,
}) => {
  const { shareInputValue, setShareInputValue } = useContext(Context);

  return (
    <>
      <div className={`${InputClassName} flex flex-col w-full`}>
        {label ? (
          <div className="relative flex items-center w-full justify-between">
            <label className="text-sm mb-1 text-slate-600" htmlFor={nameId}>
              {label}
            </label>
          </div>
        ) : (
          ""
        )}
        <div className="relative w-full">
          <div className="relative w-full">
            <Autocomplete
              // inputValue={shareInputValue}
              options={options}
              freeSolo
              // selectOnFocus
              clearOnBlur
              // handleHomeEndKeys
              id={`auto-${nameId}`}
              onChange={(event, newValue) => {
                setShareInputValue(newValue);
              }}
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <input
                    {...params.inputProps}
                    // onChange={(event, newValue) => {
                    //   setShareInputValue(newValue);
                    // }}
                    value={shareInputValue}
                    onBlur={onBlurHandler}
                    onKeyDown={onKeyDown}
                    placeholder={placeholder ? placeholder : ""}
                    name={nameId}
                    id={nameId}
                    className={`input ${classInput} bg-dicom-secondary2-100 !ring-0 !outline-none border-2 rounded-dicom-10 focus-within:border-dicom-primary-100 w-full 
                `}
                    type={type ? type : "text"}
                  />
                </div>
              )}
            />
            <BadgeTitleSection badge={badgeTitle} />
          </div>
          {description ? (
            <label className="px-4 text-xs text-dicom-text-100">
              {description}
            </label>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

const AutoCompleteMultiInput = ({
  onBlurHandler,
  nameId,
  type,
  isPassword,
  placeholder,
  label,
  classInput,
  onChange,
  onKeyDown,
  InputClassName,
  badgeTitle,
  description,
  disable,
  value,
  setValue,
  options,
  getLabel
}) => {
  const { shareInputValue, setShareInputValue } = useContext(Context);
  const styles = `
.react-multi-email {
margin: 0;
max-width: 100%;
-webkit-box-flex: 1;
-ms-flex: 1 0 auto;
flex: 1 0 auto;
outline: 0;
-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
text-align: left;
line-height: 1.21428571em;
padding: 0.4em 0.5em;
background: #fff;

color: rgba(0, 0, 0, 0.87);
-webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
transition: box-shadow 0.1s ease, border-color 0.1s ease;
position: relative;
display: flex;
flex-wrap: wrap;
align-items: center;
align-content: flex-start;
}
.react-multi-email > span[data-placeholder] {
display: none;
position: absolute;
left: 0.5em;
top: 0.4em;
padding: 0.4em;
line-height: 1.21428571em;
}
.react-multi-email.focused {
border:none;
background: #fff;
}

.react-multi-email.empty > span[data-placeholder] {
display: inline;
color: #ccc;
}
.react-multi-email.focused > span[data-placeholder] {
display: none;
}

.react-multi-email > input {
flex: 1;
width: auto !important;
outline: none !important;
border: 0 none !important;
display: inline-block !important;
line-height: 1;
vertical-align: baseline !important;
padding: 0.4em 0.1em !important;
}

.react-multi-email [data-tag] {
line-height: 1;
vertical-align: baseline;
margin: 0.14285714em;
background-color: #f3f3f3;
background-image: none;
padding: 0.4em 0.8em;
color: rgba(0, 0, 0, 0.6);
text-transform: none;
font-weight: 600;
border: 0 solid transparent;
border-radius: 0.28571429rem;
-webkit-transition: background 0.1s ease;
-o-transition: background 0.1s ease;
transition: background 0.1s ease;
font-size: 0.8rem;
display: flex;
align-items: center;
justify-content: flex-start;
max-width: 100%;
}

.react-multi-email [data-tag] [data-tag-item] {
max-width: 100%;
overflow: hidden;
}
.react-multi-email [data-tag]:first-child {
margin-left: 0;
}
.react-multi-email [data-tag] [data-tag-handle] {
margin-left: 0.833em;
cursor: pointer;
}
  `;

  return (
    <>
      <div className={`${InputClassName} flex flex-col w-full`}>
        <style>{styles}</style>
        {label ? (
          <div className="relative flex items-center w-full justify-between">
            <label className="text-sm mb-1 text-slate-600" htmlFor={nameId}>
              {label}
            </label>
          </div>
        ) : (
          ""
        )}
        <div className="relative w-full">
          <div className="relative w-full">
            <ReactMultiEmail
              id={nameId}
              emails={shareInputValue}
              placeholder={placeholder ? placeholder : ""}
              className={`input ${classInput} bg-dicom-[#EAF0F7] h-auto !ring-0 !outline-none border-2 rounded-dicom-10 focus-within:border-dicom-primary-100 w-full`}
              onChange={(_emails) => {
                setShareInputValue(_emails);
              }}
              getLabel={getLabel}
            />
            <BadgeTitleSection badge={badgeTitle} />
          </div>
          {description ? (
            <label className="px-4 text-xs text-dicom-text-100">
              {description}
            </label>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

const Input = ({
  nameId,
  type,
  isPassword,
  placeholder,
  label,
  classInput,
  formik,
  error,
  InputClassName,
  badgeTitle,
  description,
  disable,
}) => {
  const [passwordShow, setPasswordShow] = useState(false);
  return (
    <>
      <div className={`${InputClassName} flex flex-col w-full`}>
        <div className="relative flex items-center w-full justify-between">
          <label className="text-sm mb-1 text-slate-600" htmlFor={nameId}>
            {label}
          </label>
          <ErrorLable formik={formik} nameId={nameId} error={error} />
        </div>
        <div className="relative w-full">
          <div className="relative w-full">
            <input
              name={nameId}
              {...formik.getFieldProps(nameId)}
              value={formik.values?.[nameId] || ""}
              placeholder={placeholder ? placeholder : ""}
              disabled={disable}
              id={nameId}
              className={`input bg-dicom-secondary2-100 !ring-0 !outline-none border-2 rounded-dicom-10 focus-within:border-dicom-primary-100 w-full ${classInput} ${
                formik.touched[nameId] && formik.errors[nameId]
                  ? "border-red-500"
                  : ""
              }`}
              type={
                isPassword
                  ? passwordShow
                    ? "text"
                    : "password"
                  : type
                  ? type
                  : "text"
              }
            />
            <PassowrdAction
              isPassword={isPassword}
              passwordShow={passwordShow}
              setPasswordShow={setPasswordShow}
            />
            <BadgeTitleSection badge={badgeTitle} />
          </div>
          {description ? (
            <label className="px-4 text-xs text-dicom-text-100">
              {description}
            </label>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

const TeaxtArea = ({
  nameId,
  type,
  placeholder,
  label,
  classInput,
  formik,
  error,
  InputClassName,
  description,
  disable,
}) => {
  return (
    <>
      <div className={`${InputClassName} flex flex-col w-full`}>
        <div className="relative flex items-center w-full justify-between">
          <label className="text-sm mb-1 text-slate-600" htmlFor={nameId}>
            {label}
          </label>
          <ErrorLable formik={formik} nameId={nameId} error={error} />
        </div>
        <div className="relative w-full">
          <textarea
            disabled={disable}
            rows={"3"}
            id={nameId}
            className={`textarea bg-dicom-secondary2-100 !ring-0 !outline-none border-2 rounded-dicom-10 focus-within:border-dicom-primary-100 w-full ${classInput} ${
              formik.touched[nameId] && formik.errors[nameId]
                ? "border-red-500"
                : ""
            }`}
            type={"text"}
            name={nameId}
            {...formik.getFieldProps(nameId)}
            placeholder={placeholder ? placeholder : ""}
          />
          {description ? (
            <label className="text-xs text-dicom-text-100">{description}</label>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

const TeaxtAreaComments = ({
  nameId,
  rows = "3",
  placeholder,
  label,
  classInput,
  formik,
  error,
  InputClassName,
  description,
  disable,
}) => {
  return (
    <>
      <div className={`${InputClassName} flex flex-col w-full`}>
        <div className="relative flex items-center w-full justify-between">
          <label className="text-sm mb-1 text-slate-600" htmlFor={nameId}>
            {label}
          </label>
          <ErrorLable formik={formik} nameId={nameId} error={error} />
        </div>
        <div className="relative w-full">
          <textarea
            disabled={disable}
            rows={rows}
            id={nameId}
            className={`textarea bg-dicom-secondary2-100 !ring-0 !outline-none border-2 rounded-dicom-10 focus-within:border-dicom-primary-100 w-full ${classInput} ${
              formik.touched[nameId] && formik.errors[nameId]
                ? "border-red-500"
                : ""
            }`}
            type={"text"}
            name={nameId}
            {...formik.getFieldProps(nameId)}
            placeholder={placeholder ? placeholder : ""}
          />
          {description ? (
            <label className="text-xs text-dicom-text-100">{description}</label>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

const CheckBox = ({
  nameId,
  label,
  classInput,
  formik,
  error,
  data,
  inputClassName,
  description,
}) => {
  return (
    <>
      <div className={`${inputClassName} flex flex-col`}>
        <div className="relative flex items-center w-full justify-between">
          <label className="text-sm mb-1 text-slate-600" htmlFor={nameId}>
            {label}
          </label>
          <ErrorLable formik={formik} nameId={nameId} error={error} />
        </div>
        <div dir="ltr" className="relative flex justify-end gap-x-8 w-full">
          {data.map((checkbox, index) => (
            <label key={index} className="label gap-x-2 cursor-pointer">
              <span className="label-text">{checkbox.title}</span>
              <input
                name={nameId}
                onChange={formik.handleChange}
                value={checkbox.value}
                checked={formik.values[nameId].includes(checkbox.value)}
                className={`w-full !border !border-slate-300 ${classInput} ${
                  formik.touched[nameId] && formik.errors[nameId]
                    ? "border-red-500"
                    : ""
                }`}
                type="checkbox"
              />
            </label>
          ))}
        </div>
        {description ? <label className="text-xs">{description}</label> : ""}
      </div>
    </>
  );
};

const CheckBox2 = ({
  nameId,
  label,
  classInput,
  formik,
  error,
  title,
  inputClassName,
  description,
}) => {
  return (
    <>
      <div className={`${inputClassName} flex flex-col`}>
        <div className="relative flex items-center w-full justify-between">
          <label className="text-sm mb-1 text-slate-600" htmlFor={nameId}>
            {label}
          </label>
          <ErrorLable formik={formik} nameId={nameId} error={error} />
        </div>
        <div className="relative flex gap-x-8 w-full">
          <label className="label gap-x-2 items-start cursor-pointer">
            <input
              name={nameId}
              onChange={formik.handleChange}
              value={formik.values[nameId]}
              checked={formik.values[nameId]}
              className={`checkbox mt-1 checkbox-primary !border !border-slate-300 ${classInput} ${
                formik.touched[nameId] && formik.errors[nameId]
                  ? "border-red-500"
                  : ""
              }`}
              type="checkbox"
            />
            {title ? title : ""}
          </label>
        </div>
        {description ? <label className="text-xs">{description}</label> : ""}
      </div>
    </>
  );
};

const Select = ({
  nameId,
  placeholder,
  label,
  classInput,
  formik,
  error,
  data,
  inputClassName,
  description,
}) => {
  return (
    <>
      <div className={`${inputClassName} flex flex-col`}>
        <div className="relative flex items-center w-full justify-between">
          <label className="text-sm mb-1 text-slate-600" htmlFor={nameId}>
            {label}
          </label>
          <ErrorLable formik={formik} nameId={nameId} error={error} />
        </div>
        <div className="relative w-full">
          <div className="relative flex items-center">
            <svg
              className="absolute right-4"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.7593 9.49639C19.1187 9.07706 19.0701 8.44576 18.6508 8.08634C18.2315 7.72692 17.6002 7.77548 17.2407 8.1948L18.7593 9.49639ZM6.75926 8.1948C6.39983 7.77548 5.76853 7.72692 5.34921 8.08634C4.92988 8.44576 4.88132 9.07706 5.24074 9.49639L6.75926 8.1948ZM12.2278 15.5799L11.4685 14.9291L12.2278 15.5799ZM11.7722 15.5799L11.013 16.2306L11.7722 15.5799ZM17.2407 8.1948L11.4685 14.9291L12.987 16.2306L18.7593 9.49639L17.2407 8.1948ZM12.5315 14.9291L6.75926 8.1948L5.24074 9.49639L11.013 16.2306L12.5315 14.9291ZM11.4685 14.9291C11.7479 14.6031 12.2521 14.6031 12.5315 14.9291L11.013 16.2306C11.5318 16.8359 12.4682 16.8359 12.987 16.2306L11.4685 14.9291Z"
                fill="#575757"
              />
            </svg>
            <select
              placeholder={placeholder ? label : ""}
              className={`input bg-dicom-secondary2-100 !ring-0 !outline-none border-2 rounded-dicom-10 focus-within:border-dicom-primary-100 w-full ${classInput} ${
                formik.touched[nameId] && formik.errors[nameId]
                  ? "border-red-500"
                  : ""
              }`}
              name={nameId}
              id={nameId}
              {...formik.getFieldProps(nameId)}
            >
              <option value={""}>{placeholder}</option>
              {data.map((select, index) => (
                <option key={index} value={select}>
                  {select}
                </option>
              ))}
            </select>
          </div>
          {description ? <label className="text-xs">{description}</label> : ""}
        </div>
      </div>
    </>
  );
};

const SelectCountry = ({
  nameId,
  placeholder,
  label,
  classInput,
  formik,
  error,
  data,
  inputClassName,
  description,
}) => {
  return (
    <>
      <div className={`${inputClassName} flex flex-col`}>
        <div className="relative flex items-center w-full justify-between">
          <label className="text-sm mb-1 text-slate-600" htmlFor={nameId}>
            {label}
          </label>
          <ErrorLable formik={formik} nameId={nameId} error={error} />
        </div>
        <div className="relative w-full">
          <div className="relative flex items-center">
            <svg
              className="absolute right-4"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.7593 9.49639C19.1187 9.07706 19.0701 8.44576 18.6508 8.08634C18.2315 7.72692 17.6002 7.77548 17.2407 8.1948L18.7593 9.49639ZM6.75926 8.1948C6.39983 7.77548 5.76853 7.72692 5.34921 8.08634C4.92988 8.44576 4.88132 9.07706 5.24074 9.49639L6.75926 8.1948ZM12.2278 15.5799L11.4685 14.9291L12.2278 15.5799ZM11.7722 15.5799L11.013 16.2306L11.7722 15.5799ZM17.2407 8.1948L11.4685 14.9291L12.987 16.2306L18.7593 9.49639L17.2407 8.1948ZM12.5315 14.9291L6.75926 8.1948L5.24074 9.49639L11.013 16.2306L12.5315 14.9291ZM11.4685 14.9291C11.7479 14.6031 12.2521 14.6031 12.5315 14.9291L11.013 16.2306C11.5318 16.8359 12.4682 16.8359 12.987 16.2306L11.4685 14.9291Z"
                fill="#575757"
              />
            </svg>
            <select
              placeholder={placeholder ? label : ""}
              className={`input bg-dicom-secondary2-100 !ring-0 !outline-none border-2 rounded-dicom-10 focus-within:border-dicom-primary-100 w-full ${classInput} ${
                formik.touched[nameId] && formik.errors[nameId]
                  ? "border-red-500"
                  : ""
              }`}
              name={nameId}
              id={nameId}
              {...formik.getFieldProps(nameId)}
            >
              <option value={""}>{placeholder}</option>
              {data.map((select, index) => (
                <option key={index} value={select.id}>
                  {select.name}
                </option>
              ))}
            </select>
          </div>
          {description ? <label className="text-xs">{description}</label> : ""}
        </div>
      </div>
    </>
  );
};

const InputDropDown = ({
  title,
  classInput,
  children,
  tabIndex,
  selectData,
  label,
}) => {
  return (
    <>
      <div className="dropdown dropdown-end !bg-white w-full z-10">
        {label ? (
          <div className="relative flex items-center w-full justify-between">
            <label className="text-sm mb-1 text-slate-600">{label}</label>
          </div>
        ) : (
          ""
        )}
        <div
          tabIndex={tabIndex}
          className={`flex w-full items-center justify-between !bg-dicom-secondary2-100 px-4 text-[14px] !text-dicom-text-100/60 py-[10.5px] !ring-0 !outline-none border-2 border-transparent rounded-dicom-10 focus-within:border-dicom-primary-100  ${classInput}`}
        >
          <div className="flex w-full flex-wrap gap-1">
            {selectData?.length ? (
              <label
                className={`text-sm mb-1 !bg-dicom-primary-100/5 py-1 px-2 rounded-md text-dicom-primary-100`}
              >
                {selectData}
              </label>
            ) : (
              <span>{title}</span>
            )}
          </div>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.7593 9.49639C19.1187 9.07706 19.0701 8.44576 18.6508 8.08634C18.2315 7.72692 17.6002 7.77548 17.2407 8.1948L18.7593 9.49639ZM6.75926 8.1948C6.39983 7.77548 5.76853 7.72692 5.34921 8.08634C4.92988 8.44576 4.88132 9.07706 5.24074 9.49639L6.75926 8.1948ZM12.2278 15.5799L11.4685 14.9291L12.2278 15.5799ZM11.7722 15.5799L11.013 16.2306L11.7722 15.5799ZM17.2407 8.1948L11.4685 14.9291L12.987 16.2306L18.7593 9.49639L17.2407 8.1948ZM12.5315 14.9291L6.75926 8.1948L5.24074 9.49639L11.013 16.2306L12.5315 14.9291ZM11.4685 14.9291C11.7479 14.6031 12.2521 14.6031 12.5315 14.9291L11.013 16.2306C11.5318 16.8359 12.4682 16.8359 12.987 16.2306L11.4685 14.9291Z"
              fill="#575757"
            />
          </svg>
        </div>
        <ul
          tabIndex={tabIndex}
          className="dropdown-content !bg-white menu p-2 shadow rounded-box"
        >
          {children}
        </ul>
      </div>
    </>
  );
};

const RadioButton = ({
  nameId,
  label,
  classInput,
  formik,
  error,
  data,
  inputClassName,
  description,
}) => {
  return (
    <>
      <div className={`${inputClassName} flex flex-col`}>
        <div className="relative flex items-center w-full justify-between">
          <label className="text-sm mb-1 text-slate-600" htmlFor={nameId}>
            {label}
          </label>
          <ErrorLable formik={formik} nameId={nameId} error={error} />
        </div>
        <div className="relative flex gap-2 w-full overflow-visible flex-wrap">
          {data.map((radio, index) => (
            <div key={index}>
              <input
                className="sr-only peer"
                type="checkbox"
                value={radio.title}
                name={nameId}
                id={radio.id}
                onChange={formik.handleChange}
                checked={formik.values[nameId] === radio.title}
              />
              <label
                className="flex theme-radio py-2 px-4 md:py-4 md:px-6 bg-white border-2 border-slate-200 rounded-full cursor-pointer focus:outline-none hover:bg-gray-50"
                htmlFor={radio.id}
              >
                {radio.title}
              </label>
            </div>
          ))}
        </div>
        {description ? <label className="text-xs">{description}</label> : ""}
      </div>
    </>
  );
};

const MultiCheckBox = ({
  nameId,
  label,
  classInput,
  formik,
  error,
  data,
  inputClassName,
  description,
  selectData,
}) => {
  return (
    <>
      <div className={`${inputClassName} flex flex-col`}>
        <div className="relative flex items-center w-full justify-between">
          <label className="text-sm mb-1 text-slate-600" htmlFor={nameId}>
            {label}
          </label>

          <ErrorLable formik={formik} nameId={nameId} error={error} />
        </div>
        <div className="relative flex gap-2 w-full overflow-visible flex-wrap">
          {data.map((radio, index) => (
            <div key={index}>
              <input
                className="sr-only peer"
                type="checkbox"
                value={radio.title}
                name={nameId}
                id={radio.title}
                onChange={formik.handleChange}
                checked={formik.values[nameId].includes(radio.title)}
                // checked={formik.values[nameId] === radio.title}
              />
              <label
                className="flex peer-checked:text-slate-900  peer-checked:!border-dicom-primary-100 peer-checked:bg-dicom-primary-100/5 py-2 px-4 md:py-2 md:px-4 bg-white border-2 border-slate-200 rounded-dicom-10 cursor-pointer focus:outline-none hover:bg-gray-50"
                htmlFor={radio.title}
              >
                {radio.title}
              </label>
            </div>
          ))}
        </div>
        {description ? <label className="text-xs">{description}</label> : ""}
      </div>
    </>
  );
};

const MultiRadioButton = ({
  nameId,
  label,
  classInput,
  formik,
  error,
  data,
  inputClassName,
  description,
}) => {
  return (
    <>
      <div className={`${inputClassName} flex flex-col`}>
        <div className="relative flex items-center w-full justify-between">
          <label className="text-sm mb-1 text-slate-600" htmlFor={nameId}>
            {label}
          </label>

          <ErrorLable formik={formik} nameId={nameId} error={error} />
        </div>
        <div className="relative flex gap-2 w-full overflow-visible flex-wrap">
          {data?.map((radio, index) => (
            <div key={index}>
              <input
                className={`sr-only peer`}
                type="radio"
                value={radio?.title || ""}
                name={nameId}
                id={radio.title}
                onChange={formik.handleChange}
                checked={
                  (formik?.values?.[nameId] &&
                    formik?.values?.[nameId] === radio.title) ||
                  ""
                }
              />
              <label
                className={`flex peer-checked:text-slate-900  peer-checked:!border-dicom-primary-100 peer-checked:bg-dicom-primary-100/5 py-2 px-4 md:py-2 md:px-4 bg-white border-2 border-slate-200 rounded-dicom-10 cursor-pointer focus:outline-none hover:bg-gray-50 ${classInput}`}
                htmlFor={radio.title}
              >
                {radio.title}
              </label>
            </div>
          ))}
        </div>
        {description ? <label className="text-xs">{description}</label> : ""}
      </div>
    </>
  );
};

const AnonymizeRadioButton = ({
  nameId,
  label,
  classInput,
  formik,
  error,
  data,
  ClassName,
}) => {
  return (
    <>
      <div className={`${ClassName} flex flex-col`}>
        <div className="relative flex items-center w-full justify-between">
          <label className="text-sm mb-1 text-slate-600" htmlFor={nameId}>
            {label}
          </label>

          <ErrorLable formik={formik} nameId={nameId} error={error} />
        </div>
        <div className="relative flex flex-col gap-2 w-full overflow-visible flex-wrap">
          {data?.map((radio, index) => (
            <div key={index}>
              <input
                className={`sr-only peer`}
                type="radio"
                value={radio?.id || ""}
                name={nameId}
                id={radio.id}
                onChange={formik.handleChange}
                checked={
                  (formik?.values?.[nameId] &&
                    parseInt(formik?.values?.[nameId]) ===
                      parseInt(radio.id)) ||
                  ""
                }
              />
              <label
                className={`flex flex-col peer-checked:text-slate-900 peer-checked:!border-dicom-secondary-100 peer-checked:bg-dicom-secondary-100/5 py-2 px-4 md:py-2 md:px-4 bg-white border-2 border-slate-200 rounded-dicom-10 cursor-pointer focus:outline-none hover:bg-gray-50 ${classInput}`}
                htmlFor={radio.id}
              >
                <div className="flex items-center gap-x-2">
                  <label
                    className={`h-4 w-4 border rounded-full flex items-center justify-center ${
                      parseInt(formik?.values?.[nameId]) === parseInt(radio.id)
                        ? "!border-dicom-secondary-100 !bg-dicom-secondary-100"
                        : ""
                    }`}
                  >
                    {parseInt(formik?.values?.[nameId]) ===
                    parseInt(radio.id) ? (
                      <div className="w-2 h-2 bg-white rounded-full"></div>
                    ) : (
                      ""
                    )}
                  </label>
                  <p className="text-dicom-body2 text-dicom-text-100">
                    {radio.title}
                  </p>
                </div>
                {radio.description ? (
                  <p className="text-sm font-light text-dicom-text-100">
                    {radio.description}
                  </p>
                ) : (
                  ""
                )}
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

//
//
//
//
//
//
//
//
//
//
//
const ErrorLable = ({ formik, nameId, error }) => {
  return (formik.touched[nameId] && formik.errors[nameId]) || error ? (
    <label
      className="text-red-500 bg-red-50 px-2 py-1 rounded-tr-md rounded-tl-md text-xs"
      htmlFor={nameId}
    >
      {formik.errors[nameId] || error}
    </label>
  ) : (
    ""
  );
};

const PassowrdAction = ({ setPasswordShow, isPassword, passwordShow }) => {
  return isPassword ? (
    <div className="absolute flex right-4 top-0 bottom-0 items-center justify-center">
      <button type="button" onClick={() => setPasswordShow(!passwordShow)}>
        {passwordShow ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9ZM11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.83 11.2807C19.542 7.15186 15.8122 5 12 5C8.18777 5 4.45796 7.15186 2.17003 11.2807C1.94637 11.6844 1.94361 12.1821 2.16029 12.5876C4.41183 16.8013 8.1628 19 12 19C15.8372 19 19.5882 16.8013 21.8397 12.5876C22.0564 12.1821 22.0536 11.6844 21.83 11.2807ZM12 17C9.06097 17 6.04052 15.3724 4.09173 11.9487C6.06862 8.59614 9.07319 7 12 7C14.9268 7 17.9314 8.59614 19.9083 11.9487C17.9595 15.3724 14.939 17 12 17Z"
              fill="black"
            />
          </svg>
        ) : (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.70711 19.7071L19.7071 5.70711C20.0976 5.31658 20.0976 4.68342 19.7071 4.29289C19.3166 3.90237 18.6834 3.90237 18.2929 4.29289L4.29289 18.2929C3.90237 18.6834 3.90237 19.3166 4.29289 19.7071C4.68342 20.0976 5.31658 20.0976 5.70711 19.7071Z"
              fill="black"
            />
            <path
              d="M12 5C13.2011 5 14.394 5.21361 15.5362 5.63535L13.9368 7.23482C13.2953 7.0777 12.6458 7 12 7C9.07319 7 6.06862 8.59614 4.09173 11.9487C4.74631 13.0987 5.52178 14.046 6.37447 14.7971L4.95845 16.2131C3.88666 15.248 2.93477 14.037 2.16029 12.5876C1.94361 12.1821 1.94637 11.6844 2.17003 11.2807C4.45796 7.15186 8.18777 5 12 5Z"
              fill="black"
            />
            <path
              d="M12 9C12.056 9 12.1117 9.00154 12.167 9.00457L9.00457 12.167C9.00154 12.1117 9 12.056 9 12C9 10.3431 10.3431 9 12 9Z"
              fill="black"
            />
            <path
              d="M14.9954 11.833L11.833 14.9954C11.8883 14.9985 11.944 15 12 15C13.6569 15 15 13.6569 15 12C15 11.944 14.9985 11.8883 14.9954 11.833Z"
              fill="black"
            />
            <path
              d="M12 17C11.355 17 10.7061 16.9216 10.0654 16.763L8.46807 18.3604C9.60812 18.7849 10.7998 19 12 19C15.8372 19 19.5882 16.8013 21.8397 12.5876C22.0564 12.1821 22.0536 11.6844 21.83 11.2807C21.0543 9.88089 20.1128 8.7083 19.0587 7.76977L17.6421 9.18635C18.4837 9.91776 19.2525 10.8366 19.9083 11.9487C17.9595 15.3724 14.939 17 12 17Z"
              fill="black"
            />
          </svg>
        )}
      </button>
    </div>
  ) : (
    ""
  );
};

const BadgeTitleSection = ({ badge }) => {
  return badge ? (
    <div className="absolute text-sm flex right-4 top-0 bottom-0 items-center justify-center">
      <label className="text-sm font-bold">{badge}</label>
    </div>
  ) : (
    ""
  );
};

export {
  BaseInput,
  AutoCompleteInput,
  Input,
  InputDropDown,
  RadioButton,
  TeaxtArea,
  TeaxtAreaComments,
  CheckBox,
  CheckBox2,
  MultiCheckBox,
  MultiRadioButton,
  Select,
  SelectCountry,
  AnonymizeRadioButton,
  AutoCompleteMultiInput,
};
