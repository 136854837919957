import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import toast from "react-hot-toast";

// components
import Layout from "../../containers/Layout";
import useDocumentTitle from "../../utils/useDocumentTitle";
import {
  CheckBox2,
  Input,
  Select,
  SelectCountry,
} from "../../components/DesignSystem/inputs";
import { DicomButton } from "../../components/DesignSystem/buttons";
import Socialbtns from "../../components/socialbtns";
import { fetchData } from "../../services/fetching";

const JoinUs = () => {
  const [CountryLists, setCountryLists] = useState([]);
  const history = useNavigate();
  useDocumentTitle("Join Us", true);
  const [isLoading, setisLoading] = useState(false);

  let Professingroup = [
    "Clinicians",
    "Academic Instructors",
    "Medical students/ Trainees",
    "Clinical Researchers",
    "Dentists",
    "Veterinarians",
    "Law Firms",
    "MedTech Companies",
    "Patients",
    "Others",
  ];

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    professionalGroup: "",
    countryId: "",
    password: "",
    rules: false,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("The Email Address is required")
      .email("Email is invalid."),
    firstName: Yup.string().required("The First Name is required"),
    lastName: Yup.string().required("The Last Name is required"),
    countryId: Yup.string().required("The Country is required"),
    professionalGroup: Yup.string().required("The Professional group is required"),
    password: Yup.string()
      .min(6, "password length must be at least 6 characters long")
      .required("The Password is required"),
    rules: Yup.boolean().oneOf(
      [true],
      "You must accept the terms and conditions"
    ),
  });

  const onSubmit = () => {
    setisLoading(true);
    const {
      firstName,
      lastName,
      email,
      countryId,
      password,
      professionalGroup,
    } = formik.values;
    axios({
      method: "POST",
      url: "/auth/signup",
      data: {
        firstName,
        lastName,
        email,
        countryId,
        password,
        professionalGroup: professionalGroup,
      },
    })
      .then((res) => {
        if (res?.data?.data) {
          setisLoading(false);
          formik.resetForm();
          history("/auth/verify", { replace: true });
          toast.success("Your registration was successful");
        }
      })
      .catch((err) => {
        if (err?.response?.data) {
          setisLoading(false);
          toast.error(err?.response?.data?.message);
        }
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnMount: true,
  });

  useEffect(() => {
    fetchData("GET", "/country").then((res) => setCountryLists(res));
  }, []);

  return (
    <>
      <Layout isAuthPage={true} isRegisterPage={true}>
        <div className="py-16 grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-12 gap-dicom-30">
          <div className="order-1 col-span-3 md:col-span-3 lg:col-span-5 xl:col-span-9 grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-12">
            <div className="relative space-y-dicom-30 col-span-12 md:col-span-12 lg:col-span-5 xl:col-span-5 pt-dicom-30">
              <div className="absolute !z-0 -top-36 -left-44">
                <svg
                  className="!w-[100%]"
                  width="547"
                  height="560"
                  viewBox="0 0 547 560"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.2" filter="url(#filter0_f_56_1543)">
                    <circle cx="267" cy="280" r="174" fill="#1DD3B0" />
                  </g>
                  <defs>
                    <filter
                      id="filter0_f_56_1543"
                      x="-13"
                      y="0"
                      width="560"
                      height="560"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feGaussianBlur
                        stdDeviation="53"
                        result="effect1_foregroundBlur_56_1543"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
              <div className="relative !z-10 space-y-dicom-30">
                <h1 className="text-dicom-headline1 md:text-dicom-headline1 lg:text-[40px] xl:text-dicom-headline1 font-bold text-dicom-primary-200">
                  Have an account?
                </h1>
                <p className="md:w-4/6 lg:w-4/6 xl:w-3/6">
                  If you have an account you can{" "}
                  <NavLink
                    to={"/auth/login"}
                    className={
                      "text-dicom-primary-100 text-dicom-body1 font-medium"
                    }
                  >
                    Login here.
                  </NavLink>
                </p>
              </div>
            </div>
            <div className="col-span-3 md:col-span-12 lg:col-span-7 xl:col-span-7">
              <img className="w-[450px]" src="/images/auth.png" alt="" />
            </div>
          </div>
          <div className="relative -order-1 md:order-1 col-span-3 md:col-span-3 lg:col-span-3 xl:col-span-3">
            <div className="space-y-dicom-15">
              <Input
                nameId={"firstName"}
                placeholder={"First name"}
                formik={formik}
              />
              <Input
                nameId={"lastName"}
                placeholder={"Last name"}
                formik={formik}
              />
              <Input
                nameId={"email"}
                placeholder={"Enter Email address"}
                formik={formik}
              />

              <Select
                nameId={"professionalGroup"}
                data={Professingroup}
                placeholder={"--Select professional group:"}
                formik={formik}
              />

              <SelectCountry
                nameId={"countryId"}
                data={
                  CountryLists?.data?.data
                    ? CountryLists?.data?.data?.countries
                    : []
                }
                placeholder={"--Select country:"}
                formik={formik}
              />

              <Input
                nameId={"password"}
                placeholder={"Password"}
                description={"At least 8 characters long, Number and Symbol"}
                formik={formik}
                isPassword
              />

              <CheckBox2
                formik={formik}
                nameId={"rules"}
                title={
                  <>
                    <span className="text-sm font-light">
                      I have read and I agree to DICOM Wallet's{" "}
                      <NavLink
                        target={"_blank"}
                        to={"/legal/term-of-service"}
                        className={"text-dicom-primary-100"}
                      >
                        Terms of Service
                      </NavLink>
                      ,{" "}
                      <NavLink
                        target={"_blank"}
                        to={"/legal/privacy-policy"}
                        className={"text-dicom-primary-100"}
                      >
                        Privacy Policy
                      </NavLink>{" "}
                      and{" "}
                      <NavLink
                        target={"_blank"}
                        to={"/legal/cookie-policy"}
                        className={"text-dicom-primary-100"}
                      >
                        Cookies Policy
                      </NavLink>{" "}
                      and{" "}
                      <NavLink
                        target={"_blank"}
                        to={"/legal/acceptable-use"}
                        className={"text-dicom-primary-100"}
                      >
                        Acceptable use
                      </NavLink>
                    </span>
                  </>
                }
              />

              <DicomButton
                disabled={!formik.isValid ? true : false || isLoading}
                isLoading={isLoading}
                type={"submit"}
                onClick={onSubmit}
                title={"Create an account"}
                btnClass={"w-full !bg-dicom-primary-100 !text-white"}
              />
              <Socialbtns title={"Or Sign up with"} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default JoinUs;
