import { styled, Switch } from "@mui/material"

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)"
    }
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff"
      }
    }
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200
    })
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#EAF0F7" : "#EAF0F7",
    boxSizing: "border-box"
  }
}))

const SwitchButton = ({ Ltitle, nameId, Rtitle, checked, onChange, titleAttr, className }) => {
  return (
    <>
      <div className='relative'>
        <div id='user-switch' className={`flex w-fit items-center space-x-dicom-10 ${className}`}>
          {Ltitle ? <p>{Ltitle}</p> : ""}
          <label htmlFor={nameId} className='switch' title={titleAttr}>
            <input name={nameId} type='checkbox' id={nameId} checked={checked || ""} onChange={onChange} />
            <span className='slider round'></span>
          </label>
          {Rtitle ? <p>{Rtitle}</p> : ""}
        </div>
      </div>
    </>
  )
}

export { AntSwitch, SwitchButton }
