import React from "react";

const ErrAlert = ({ CloseHandler, message, isShow }) => {
  //   const [isShow, setIsShow] = useState(false);
  return (
    <>
      {isShow ? (
        <div className="flex justify-between py-[12px] px-[20px] border-2 border-[#FD2F02] rounded">
          {message ? <p>{message}</p> : ""}
          <button onClick={CloseHandler}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_932_3209)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.6979 8L14 12.3021L12.3021 14L8 9.6979L3.6979 14L2 12.3021L6.3021 8L2 3.6979L3.6979 2L8 6.3021L12.3021 2L14 3.6979L9.6979 8Z"
                  fill="#FD2F02"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_932_3209"
                  x="0"
                  y="0"
                  width="16"
                  height="17"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="1" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_932_3209"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_932_3209"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export { ErrAlert };
