import React from "react";

const Card = ({ title, icon, value, className, titleAttr }) => {
  return (
    <>
      <div
        title={titleAttr}
        className={`${className} space-y-dicom-10 flex shadow-dicom-shadow flex-col items-center justify-center py-[20px] rounded-dicom-10 odd:bg-dicom-primary-100 bg-white odd:text-white text-dicom-text-100`}
      >
        <p className="text-dicom-body2">{title}</p>
        <div className="flex items-center gap-x-dicom-10">
          {icon ? (
            <div className="odd:text-white text-dicom-text-100">{icon}</div>
          ) : (
            ""
          )}
          <p className="text-sm font-medium">{value}</p>
        </div>
      </div>
    </>
  );
};

export default Card;
