import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import {
  DicomButton,
  DicomButtonOutline,
} from "../../../components/DesignSystem/buttons";
import { fetchData } from "../../../services/fetching";
import { queryDeleteFilter } from "../../../utils/functions";
import { PaginationMeta, UserStudies, UserStudiesURL } from "../../../atom/userStudies";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

const DeleteStudies = ({ modalId, selectedData }) => {
  // const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const setStudies= useSetRecoilState(UserStudies)
  const [paginationMeta,setPaginationMeta] =useRecoilState(PaginationMeta)
  const studyUrl= useRecoilValue(UserStudiesURL)
  const navigate = useNavigate()
  let StudisaddToFolder = [];
  for (let key in selectedData) {
    StudisaddToFolder.push(selectedData[key].code);
  }



  const onSubmit = () => {
    setIsLoading(true);
    fetchData("DELETE", `/study/?${queryDeleteFilter(StudisaddToFolder)}`)
      .then((res) => {
        if (res.data.data) {
          if(res.data.data==true){
           return  navigate(0);

          }
          document.getElementById(modalId).checked = false;
          toast.success("The selected study/studies has been successfully deleted");
          fetchData("GET",studyUrl).then(res=>{
            setStudies(res.data.data)
           
            setPaginationMeta((old) => {
              let newPage= old.page
              if(old.page==res.data.data.count + res.data.data.folderCount)
               newPage=old.page - old.pageSize
              return {
                ...old,
                total: res.data.data.count + res.data.data.folderCount,
                page: newPage,
              };
            });

          })

        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="space-y-dicom-15">
        <p>
          Are you sure you want to permanently delete these{" "}
          <span className="font-bold">{selectedData.length}</span>
          studies from DicomWallet?
        </p>
        <div className="flex justify-center gap-5 w-full">
          <DicomButtonOutline
            onClick={() => (document.getElementById(modalId).checked = false)}
            btnClass={
              "!border-dicom-secondary-100 !text-dicom-secondary-100 hover:!bg-dicom-secondary-100/5"
            }
            title={"Cancel"}
          />
          <DicomButton
            isLoading={isLoading}
            onClick={onSubmit}
            btnClass={
              "!border-dicom-secondary-100 !bg-dicom-secondary-100 !text-white"
            }
            title={"Delete"}
          />
        </div>
      </div>
    </>
  );
};

export default DeleteStudies;
