import { useEffect, useRef, useState } from "react"
import {
  IconBlackCopy,
  IconBlackCopyed,
  IconBlackCut,
  IconBlackDelete,
  IconBlackRename,
  IconOptionsDotted,
  IconShareBlack
} from "../../DesignSystem/svgIcons"
import { useRecoilState, useRecoilValue } from "recoil"
import { contextMenuAtom } from "../../../atom/contextMenu"
import toast from "react-hot-toast"
import { userSelectedData } from "../../../atom/userStudies"
import { Modal } from "@mui/material"
import EditFolder from "./editFolder"

export const FolderOptionsListView = ({ meta, handleAction, setRenameFolder }) => {
  const contextMenu = useRecoilValue(contextMenuAtom)
  const [selected, setSelected] = useState(false)
  const dropdownRef = useRef()
  const [selectedStudies, setSelectedStudeis] = useRecoilState(userSelectedData)

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown()
    }
  }

  useEffect(() => {
    if (selected) {
      setSelectedStudeis([meta])
    }
  }, [selected])

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [contextMenu])

  const closeDropdown = () => {
    setSelected(false)
  }

  return (
    <div
      ref={dropdownRef}
      onClick={(e) => {
        setSelected(!selected)
      }}
      className={`relative cursor-pointer`}
      id={`folder-dropdown-list-${meta.id}`}>
      <div className='flex space-x-2'>
        <IconOptionsDotted />
      </div>
      {selected && (
        <div className='absolute -right-2 z-[10] xl:z-[1] !font-normal cursor-pointer shadow bg-base-100 rounded-box w-28 xl:w-40  max-xl:text-xs'>
          <div className=' overflow-hidden bg-slate-50/50 rounded-b-lg'>
            <div className='overflow-hidden  bg-slate-50/50 rounded-t-lg '>
              <span
                onClick={() => {
                  document.getElementById(`share-study-with-table`).checked = true
                }}
                className='flex anim hover:bg-slate-100 px-4 py-2 gap-2 items-center justify-start'>
                <IconShareBlack width={18} height={18} /> Share
              </span>
              <span
                onClick={() => {
                  document.getElementById(`delete-folder`).checked = true
                }}
                className='flex anim hover:bg-slate-100 px-4 py-2 gap-2 items-center justify-start'>
                <IconBlackDelete /> Delete
              </span>
            </div>
            <div className='border border-slate-100' />

            <span
              onClick={() => {
                handleAction("copy", meta.id, "folder")
              }}
              className='flex anim hover:bg-slate-100 px-4 py-2 gap-2 items-center justify-start'>
              <IconBlackCopy /> <span>Copy</span>
            </span>
            <span
              onClick={() => {
                handleAction("move", meta.id, "folder")
              }}
              className='flex anim hover:bg-slate-100 px-4 py-2 gap-2  items-center justify-start'>
              <IconBlackCut /> Cut
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
