import { useFormik } from "formik"
import React, { useContext, useEffect, useState } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"

import { DicomButtonListAction } from "../../../../components/DesignSystem/buttons"

import { AutoCompleteMultiInput, TeaxtArea } from "../../../../components/DesignSystem/inputs"
import { Context } from "../../../../Context"
import { fetchData } from "../../../../services/fetching"
import { AccessDropdown } from "./Assets"

const OnlyPeopleWithEmailAccess = ({ studyOrFolder, setStudy, modalId, getSubscribers, getFoldersubscribers }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { modalState } = useContext(Context)
  const [access, setAccess] = useState(0)

  const { shareInputValue, setShareInputValue } = useContext(Context)

  useEffect(() => {
    setShareInputValue([])
  }, [modalState, setShareInputValue])

  const initialValues = {
    studyCode: studyOrFolder?.code,
    emails: [],
    message: ""
  }

  const validationSchema = Yup.object({
    emails: Yup.array().min(1, "At least one email is required").required("email is required")
  })

  const onSubmit = () => {
    formik.values.emails = shareInputValue
    if (shareInputValue.length > 0 && studyOrFolder?.type === "study") {
      setIsLoading(true)
      setTimeout(() => {
        fetchData("POST", "/study/share", { ...formik.values, access })
          .then((res) => {
            getSubscribers()

            if (res.data.data.study) {
              fetchData("GET", `/study/${studyOrFolder?.code}`).then((res) => setStudy(res.data.data.study))
              setTimeout(() => {
                document.getElementById("email").value = ""
                setShareInputValue([])
                document.getElementById(modalId).checked = false
              }, 20)
              toast.success("The study was successfully shared")
              formik.resetForm()
              setIsLoading(false)
            }
          })
          .catch((err) => {
            console.error(err)
            toast.error("You cannot share this study")
            setIsLoading(false)
          })
      }, 100)
    } else if (shareInputValue.length > 0 && studyOrFolder?.type === "folder") {
      setIsLoading(true)
      setTimeout(() => {
        fetchData("POST", "/folder/share", {
          access,
          emails: formik.values.emails,
          message: formik.values.message,
          folderId: studyOrFolder.id
        })
          .then((res) => {
            if (res.data) {
              getFoldersubscribers()

              setTimeout(() => {
                document.getElementById("email").value = ""
                setShareInputValue([])
                document.getElementById(modalId).checked = false
              }, 20)
              toast.success("The folder was successfully shared")
              formik.resetForm()
              setIsLoading(false)
            }
          })
          .catch((err) => {
            console.error(err)
            toast.error("You cannot share this folder")
            setIsLoading(false)
          })
      }, 100)
    } else {
      toast.error("Please enter an email", { duration: 10000 })
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  })

  const handleAccessChange = (meta) => {
    setAccess(meta.value)
  }
  return (
    <>
      <div className=''>
        <span className='font-semibold text-sm '>Share with email</span>

        <div className='flex items-center border rounded-lg  border-[#85b7d9] '>
          <div className='relative flex-1'>
            <AutoCompleteMultiInput
              nameId={"email"}
              type={"email"}
              options={shareInputValue}
              placeholder={"Add email for access"}
              classInput=''
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                )
              }}
            />
          </div>
          <div className='w-fit bg-white'>
            <AccessDropdown
              handleAccessChange={handleAccessChange}
              innerClassName='right-0'
              initialAccessLevel={access}
            />
          </div>
        </div>
        {shareInputValue.length > 0 && (
          <>
            <TeaxtArea
              formik={formik}
              nameId={"message"}
              placeholder={"Type the message that will be sent in the invitation email..."}
              className=' !mt-0 border '
              classInput='!bg-transparent !border-dicom-secondary-100'
            />

            <div className='ml-auto mt-3 flex max-w-[200px]'>
              <DicomButtonListAction
                onClick={onSubmit}
                btnClass={
                  "!w-fit !px-[10px]  !bg-transparent  !text-dicom-secondary-100 !border !border-dicom-secondary-100"
                }
                title={"Cancle"}
              />
              <DicomButtonListAction
                isLoading={isLoading}
                onClick={onSubmit}
                btnClass={"!w-fit !px-[10px] !bg-dicom-secondary-100 !text-white "}
                title={"Share"}
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default OnlyPeopleWithEmailAccess
