const sidebarData = [
  {
    title: "My DICOM Wallet",
    titleAttr: "Click to see DICOM Wallet",
    url: "my-dicom-wallet",
    icons: (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.5 12C8.5 11.4477 8.94772 11 9.5 11H15.5C16.0523 11 16.5 11.4477 16.5 12C16.5 12.5523 16.0523 13 15.5 13H9.5C8.94772 13 8.5 12.5523 8.5 12Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 3C3.39543 3 2.5 3.89543 2.5 5V7C2.5 8.10457 3.39543 9 4.5 9L4.5 18C4.5 19.6569 5.84315 21 7.5 21H17.5C19.1569 21 20.5 19.6569 20.5 18V9C21.6046 9 22.5 8.10457 22.5 7V5C22.5 3.89543 21.6046 3 20.5 3H4.5ZM18.5 9H6.5V18C6.5 18.5523 6.94772 19 7.5 19H17.5C18.0523 19 18.5 18.5523 18.5 18V9ZM20.5 7V5H4.5V7H20.5Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    title: "Shared with You",
    titleAttr: "Click to see studies shared with you",
    url: "shared-with-you",
    icons: (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.0578 12.7433C18.4683 12.3738 18.5016 11.7415 18.1321 11.331C17.7627 10.9205 17.1304 10.8872 16.7199 11.2567L18.0578 12.7433ZM13.8333 15.2L13.1643 15.9433L13.8333 16.5454L14.5022 15.9433L13.8333 15.2ZM10.9467 11.2567C10.5362 10.8872 9.90387 10.9205 9.53442 11.331C9.16496 11.7415 9.19824 12.3738 9.60875 12.7433L10.9467 11.2567ZM14.8333 4C14.8333 3.44772 14.3856 3 13.8333 3C13.281 3 12.8333 3.44772 12.8333 4H14.8333ZM16.7199 11.2567L13.1643 14.4567L14.5022 15.9433L18.0578 12.7433L16.7199 11.2567ZM14.5022 14.4567L10.9467 11.2567L9.60875 12.7433L13.1643 15.9433L14.5022 14.4567ZM14.8333 15.2V4H12.8333V15.2H14.8333Z"
          fill="white"
        />
        <path
          d="M8.49993 7.19996C8.49993 6.75814 8.14176 6.39996 7.69993 6.39996H7.61104C4.66552 6.39996 2.27771 8.549 2.27771 11.2V17.6C2.27771 20.2509 4.66552 22.4 7.61104 22.4H14.7222C17.6677 22.4 20.0555 20.2509 20.0555 17.6C20.0555 17.1581 19.6973 16.8 19.2555 16.8H19.0777C18.6359 16.8 18.2777 17.1581 18.2777 17.6C18.2777 19.3673 16.6858 20.8 14.7222 20.8H7.61104C5.64736 20.8 4.05549 19.3673 4.05549 17.6V11.2C4.05549 9.43265 5.64736 7.99996 7.61104 7.99996H7.69993C8.14176 7.99996 8.49993 7.64179 8.49993 7.19996Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    title: "Shared by you",
    titleAttr: "Click to see studies shared by you",
    url: "shared-by-you",
    icons: (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.1034 6.46896C9.69961 6.84579 9.67779 7.47858 10.0546 7.88233C10.4315 8.28608 11.0642 8.3079 11.468 7.93107L10.1034 6.46896ZM14.2143 4.00001L14.8966 3.26896L14.2143 2.63213L13.5319 3.26896L14.2143 4.00001ZM16.9605 7.93107C17.3643 8.3079 17.997 8.28608 18.3739 7.88233C18.7507 7.47858 18.7289 6.84579 18.3251 6.46896L16.9605 7.93107ZM13.2143 15.2C13.2143 15.7523 13.662 16.2 14.2143 16.2C14.7665 16.2 15.2143 15.7523 15.2143 15.2L13.2143 15.2ZM11.468 7.93107L14.8966 4.73107L13.5319 3.26896L10.1034 6.46896L11.468 7.93107ZM13.5319 4.73107L16.9605 7.93107L18.3251 6.46896L14.8966 3.26896L13.5319 4.73107ZM13.2143 4.00001L13.2143 15.2L15.2143 15.2L15.2143 4.00001L13.2143 4.00001Z"
          fill="white"
        />
        <path
          d="M8.21411 7.19996C8.21411 6.75814 7.85594 6.39996 7.41411 6.39996H7.35697C4.51665 6.39996 2.21411 8.549 2.21411 11.2V17.6C2.21411 20.2509 4.51665 22.4 7.35697 22.4H14.2141C17.0544 22.4 19.357 20.2509 19.357 17.6C19.357 17.1581 18.9988 16.8 18.557 16.8H18.4427C18.0009 16.8 17.6427 17.1581 17.6427 17.6C17.6427 19.3673 16.1077 20.8 14.2141 20.8H7.35697C5.46342 20.8 3.9284 19.3673 3.9284 17.6V11.2C3.9284 9.43265 5.46342 7.99996 7.35697 7.99996H7.41411C7.85594 7.99996 8.21411 7.64179 8.21411 7.19996Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    title: "Starred",
    titleAttr: "Click to see starred studies",
    url: "starred",
    icons: (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5748 3.25583C11.9141 2.42845 13.0859 2.42845 13.4252 3.25583L15.1493 7.45955C15.293 7.80979 15.6221 8.04889 15.9995 8.07727L20.5303 8.41798C21.422 8.48504 21.7841 9.59942 21.1021 10.1778L17.6369 13.1166C17.3482 13.3614 17.2225 13.7483 17.3122 14.1161L18.3882 18.5304C18.6 19.3992 17.652 20.0879 16.8912 19.618L13.0255 17.2305C12.7034 17.0316 12.2966 17.0316 11.9745 17.2305L8.10881 19.618C7.34796 20.0879 6.40001 19.3992 6.6118 18.5304L7.68785 14.1161C7.7775 13.7483 7.6518 13.3614 7.36309 13.1166L3.8979 10.1778C3.21588 9.59942 3.57796 8.48504 4.46971 8.41798L9.00046 8.07727C9.37794 8.04889 9.70704 7.80979 9.85068 7.45955L11.5748 3.25583Z"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
    ),
  },
  // {
  //   title: "Folders",
  //   titleAttr: "Manage folders",
  //   url: "folders",
  //   icons: (
  //     <svg
  //       width="25"
  //       height="24"
  //       viewBox="0 0 25 24"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M9.5 4H12.5L14.5 6H19.5C20.0304 6 20.5391 6.21071 20.9142 6.58579C21.2893 6.96086 21.5 7.46957 21.5 8V15C21.5 15.5304 21.2893 16.0391 20.9142 16.4142C20.5391 16.7893 20.0304 17 19.5 17H9.5C8.96957 17 8.46086 16.7893 8.08579 16.4142C7.71071 16.0391 7.5 15.5304 7.5 15V6C7.5 5.46957 7.71071 4.96086 8.08579 4.58579C8.46086 4.21071 8.96957 4 9.5 4Z"
  //         stroke="white"
  //         strokeWidth="2"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //       <path
  //         d="M17.5 17V19C17.5 19.5304 17.2893 20.0391 16.9142 20.4142C16.5391 20.7893 16.0304 21 15.5 21H5.5C4.96957 21 4.46086 20.7893 4.08579 20.4142C3.71071 20.0391 3.5 19.5304 3.5 19V10C3.5 9.46957 3.71071 8.96086 4.08579 8.58579C4.46086 8.21071 4.96957 8 5.5 8H7.5"
  //         stroke="white"
  //         strokeWidth="2"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //     </svg>
  //   ),
  // },
  {
    title: "Users",
    url: "users",
    icons: (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5 7C15.5 8.65685 14.1569 10 12.5 10C10.8431 10 9.5 8.65685 9.5 7C9.5 5.34315 10.8431 4 12.5 4C14.1569 4 15.5 5.34315 15.5 7Z"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M5.5 19.5C5.5 15.9101 8.41015 13 12 13H13C16.5899 13 19.5 15.9101 19.5 19.5V20C19.5 20.5523 19.0523 21 18.5 21H6.5C5.94772 21 5.5 20.5523 5.5 20V19.5Z"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
    ),
  },
  {
    title: "Reports",
    url: "reports",
    icons: (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5 11C17.5 10.4477 17.0523 10 16.5 10C15.9477 10 15.5 10.4477 15.5 11H17.5ZM15.5 16C15.5 16.5523 15.9477 17 16.5 17C17.0523 17 17.5 16.5523 17.5 16H15.5ZM9.5 11C9.5 10.4477 9.05228 10 8.5 10C7.94772 10 7.5 10.4477 7.5 11H9.5ZM7.5 16C7.5 16.5523 7.94772 17 8.5 17C9.05228 17 9.5 16.5523 9.5 16H7.5ZM13.5 8C13.5 7.44772 13.0523 7 12.5 7C11.9477 7 11.5 7.44772 11.5 8H13.5ZM11.5 16C11.5 16.5523 11.9477 17 12.5 17C13.0523 17 13.5 16.5523 13.5 16H11.5ZM7.5 4H17.5V2H7.5V4ZM20.5 7V17H22.5V7H20.5ZM17.5 20H7.5V22H17.5V20ZM4.5 17V7H2.5V17H4.5ZM7.5 20C5.84315 20 4.5 18.6569 4.5 17H2.5C2.5 19.7614 4.73858 22 7.5 22V20ZM20.5 17C20.5 18.6569 19.1569 20 17.5 20V22C20.2614 22 22.5 19.7614 22.5 17H20.5ZM17.5 4C19.1569 4 20.5 5.34315 20.5 7H22.5C22.5 4.23858 20.2614 2 17.5 2V4ZM7.5 2C4.73858 2 2.5 4.23858 2.5 7H4.5C4.5 5.34315 5.84315 4 7.5 4V2ZM15.5 11V16H17.5V11H15.5ZM7.5 11V16H9.5V11H7.5ZM11.5 8V16H13.5V8H11.5Z"
          fill="white"
        />
      </svg>
    ),
  },
  // {
  //   title: "Setting",
  //   url: "setting",
  //   icons: (
  //     <svg
  //       width="25"
  //       height="24"
  //       viewBox="0 0 25 24"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M11.0213 3.62368C11.8147 2.75255 13.1853 2.75255 13.9787 3.62368L14.7142 4.43128C15.1151 4.87154 15.6914 5.11025 16.2862 5.08245L17.3774 5.03146C18.5543 4.97645 19.5236 5.94568 19.4685 7.12264L19.4176 8.21377C19.3898 8.80859 19.6285 9.38487 20.0687 9.78582L20.8763 10.5213C21.7475 11.3147 21.7475 12.6853 20.8763 13.4787L20.0687 14.2142C19.6285 14.6151 19.3898 15.1914 19.4176 15.7862L19.4685 16.8774C19.5236 18.0543 18.5543 19.0236 17.3774 18.9685L16.2862 18.9176C15.6914 18.8898 15.1151 19.1285 14.7142 19.5687L13.9787 20.3763C13.1853 21.2475 11.8147 21.2475 11.0213 20.3763L10.2858 19.5687C9.88487 19.1285 9.30859 18.8898 8.71376 18.9176L7.62264 18.9685C6.44568 19.0236 5.47645 18.0543 5.53146 16.8774L5.58245 15.7862C5.61025 15.1914 5.37154 14.6151 4.93128 14.2142L4.12368 13.4787C3.25255 12.6853 3.25255 11.3147 4.12368 10.5213L4.93128 9.78582C5.37154 9.38487 5.61025 8.80859 5.58245 8.21376L5.53146 7.12264C5.47645 5.94568 6.44568 4.97645 7.62264 5.03146L8.71376 5.08245C9.30859 5.11025 9.88487 4.87154 10.2858 4.43128L11.0213 3.62368Z"
  //         stroke="white"
  //         strokeWidth="2"
  //       />
  //       <circle cx="12.5" cy="12" r="3" stroke="white" strokeWidth="2" />
  //     </svg>
  //   ),
  // },
];

export { sidebarData };
