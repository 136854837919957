import React, { useState } from "react";
import fileDownload from "js-file-download";

import { fetchDownloadFile } from "../../../services/fetching";
import { FormatSizeUnitsSpace } from "../../../utils/SizeHandler";

const Commentfiles = ({ file }) => {
  const [downloadIsLoading, setdownloadIsLoading] = useState(false);
  return (
    <>
      <div key={file.id} className="flex items-center justify-between">
        <div className="flex !items-center gap-x-[5px]">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_299_18586)">
              <path
                d="M14 3.94501V7.94501C14 8.21022 14.1054 8.46458 14.2929 8.65211C14.4804 8.83965 14.7348 8.94501 15 8.94501H19"
                stroke="#2C3E50"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17 21.945H7C6.46957 21.945 5.96086 21.7343 5.58579 21.3592C5.21071 20.9841 5 20.4754 5 19.945V5.94501C5 5.41457 5.21071 4.90587 5.58579 4.53079C5.96086 4.15572 6.46957 3.94501 7 3.94501H14L19 8.94501V19.945C19 20.4754 18.7893 20.9841 18.4142 21.3592C18.0391 21.7343 17.5304 21.945 17 21.945Z"
                stroke="#2C3E50"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_299_18586">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0 0.945007)"
                />
              </clipPath>
            </defs>
          </svg>
          <p className="text-black text-sm">{file.name}</p>
          <p className="text-dicom-caption-100 text-xs">
            {FormatSizeUnitsSpace(file.size)}
          </p>
        </div>

        <button
          onClick={() => {
            setdownloadIsLoading(true);

            fetchDownloadFile("GET", `/comment/file/${file.id}/download`).then(
              (response) => {
                setdownloadIsLoading(false);
                fileDownload(response.data, file.name);
              }
            );
          }}
        >
          {downloadIsLoading ? (
            <svg
              className="text-black animate-spin"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_801_5077)">
                <path
                  d="M16.8396 21.9227C17.072 22.3992 16.8753 22.9778 16.3817 23.1714C14.7785 23.8002 13.055 24.078 11.3281 23.9812C9.28099 23.8664 7.29743 23.229 5.56664 22.1298C3.83586 21.0305 2.41559 19.5062 1.44131 17.7021C0.467042 15.898 -0.0287318 13.8744 0.00128644 11.8243C0.0313048 9.77419 0.586114 7.76596 1.61279 5.99119C2.63946 4.21641 4.10376 2.7343 5.86598 1.68623C7.62821 0.638166 9.62958 0.0591049 11.6792 0.00429001C13.4082 -0.04195 15.1228 0.286166 16.7069 0.961651C17.1946 1.16961 17.3743 1.75374 17.1281 2.22326C16.8818 2.69279 16.3029 2.86912 15.812 2.66861C14.5211 2.14126 13.1314 1.88614 11.7305 1.9236C10.0088 1.96965 8.32769 2.45606 6.84742 3.33644C5.36715 4.21681 4.13715 5.46179 3.27474 6.9526C2.41234 8.44341 1.9463 10.1303 1.92108 11.8524C1.89587 13.5745 2.31232 15.2744 3.1307 16.7898C3.94909 18.3052 5.14212 19.5857 6.59598 20.509C8.04984 21.4323 9.71603 21.9678 11.4356 22.0642C12.8348 22.1427 14.2314 21.9283 15.5372 21.439C16.0337 21.2529 16.6072 21.4462 16.8396 21.9227Z"
                  fill="currentColor"
                />
                <path
                  d="M22.7657 14.4446C23.2827 14.562 23.8011 14.2382 23.8768 13.7134C24.1505 11.8158 23.9662 9.87512 23.3329 8.05557C22.6996 6.23601 21.6391 4.60027 20.2463 3.28267C19.8611 2.91831 19.2538 2.98635 18.9214 3.3994C18.5889 3.81244 18.6581 4.41368 19.0376 4.78386C20.1531 5.87179 21.0045 7.20688 21.5196 8.68669C22.0346 10.1665 22.1962 11.7417 21.9972 13.2871C21.9295 13.813 22.2486 14.3272 22.7657 14.4446Z"
                  fill="currentColor"
                  fillOpacity="0.3"
                />
                <path
                  d="M18.0967 21.204C18.3895 21.646 18.9879 21.7699 19.4051 21.4428C20.7442 20.3927 21.8448 19.0696 22.6334 17.5617C22.8792 17.0919 22.6483 16.526 22.1604 16.3186C21.6724 16.1112 21.1127 16.3414 20.8599 16.8074C20.2275 17.9728 19.3713 19.0022 18.3405 19.8362C17.9283 20.1697 17.8039 20.762 18.0967 21.204Z"
                  fill="currentColor"
                  fillOpacity="0.3"
                />
              </g>
              <defs>
                <clipPath id="clip0_801_5077">
                  <rect width="24" height="24" fill="currentColor" />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.2598 3.95754C11.2581 3.40526 11.7044 2.95614 12.2566 2.95441C12.8089 2.95268 13.258 3.39899 13.2598 3.95127L11.2598 3.95754Z"
                fill="#1DD3B0"
              />
              <path
                d="M14.5755 11.2401L13.2867 12.537L13.2598 3.95127L11.2598 3.95754L11.2867 12.5433L9.98979 11.2545C9.59805 10.8652 8.96486 10.8671 8.57557 11.2589C8.18627 11.6506 8.18826 12.2838 8.58 12.6731L8.58252 12.6756L8.58351 12.6766L12.2987 16.3686L15.9804 12.6637L15.986 12.6581L15.9868 12.6573L15.9875 12.6565L15.9934 12.6506L15.9942 12.6499L14.5755 11.2401Z"
                fill="#1DD3B0"
              />
              <path
                d="M15.9942 12.6499C16.3835 12.2581 16.3815 11.6249 15.9897 11.2356C15.598 10.8463 14.9648 10.8483 14.5755 11.2401L15.9942 12.6499Z"
                fill="#1DD3B0"
              />
              <path
                d="M4.25977 12.945C4.25977 11.8404 5.1552 10.945 6.25977 10.945C6.81205 10.945 7.25977 10.4973 7.25977 9.945C7.25977 9.39271 6.81205 8.945 6.25977 8.945C4.05063 8.945 2.25977 10.7359 2.25977 12.945V18.945C2.25977 21.1541 4.05063 22.945 6.25977 22.945H17.2598C20.0212 22.945 22.2598 20.7064 22.2598 17.945V12.945C22.2598 10.7359 20.4689 8.945 18.2598 8.945C17.7075 8.945 17.2598 9.39271 17.2598 9.945C17.2598 10.4973 17.7075 10.945 18.2598 10.945C19.3643 10.945 20.2598 11.8404 20.2598 12.945V17.945C20.2598 19.6019 18.9166 20.945 17.2598 20.945H6.25977C5.1552 20.945 4.25977 20.0496 4.25977 18.945V12.945Z"
                fill="#1DD3B0"
              />
            </svg>
          )}
        </button>
      </div>
    </>
  );
};

export default Commentfiles;
