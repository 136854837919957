import React from "react"

export const DropDown = ({ ActionBtn, tabIndex, children, disabled = false }) => {
  return (
    <>
      <div className={`${disabled ? "" : "dropdown dropdown-end"}  z-10`}>
        {ActionBtn}
        {!disabled && (
          <div
            tabIndex={tabIndex}
            className='dropdown-content menu p-2 shadow !bg-dicom-primary-100 !rounded-dicom-10 w-72 text-white'>
            {children}
          </div>
        )}
      </div>
    </>
  )
}
