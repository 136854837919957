import React from "react";

const TermOfService = () => {
  return (
    <>
      <h2>
        <strong>DICOMWallet Terms of Service</strong>
      </h2>

      <p>Last Update: July 2022</p>
      <p>
        Thank you for choosing to be part of our community at&nbsp;Precious MD
        Ltd, doing business as&nbsp;DICOMWallet. DICOMWallet provides an image
        management platform designed to offer users secure and simple access to
        medical images (CT-scan / MRI / X-ray) via the cloud. The goal is to
        offer users an integrated platform to store and share medical images.
        DICOMWallet help users to store and share medical images while adhering
        to their legal obligations. Specifically, it has been designed to be
        used by clinicians (radiologists, Oncologists, etc.) for research,
        teaching and seeking second opinion purposes, and patients to securely
        store and share their own medical data.
        <br />
        These terms of service (&lsquo;Terms&rsquo;) cover your use and access
        to our services, client software and websites (&lsquo;Services&rsquo;).
        Our&nbsp;<a href="privacy-policy">Privacy Policy</a>
        &nbsp;explains how we collect and use your information while our&nbsp;
        <a href="acceptable-use">Acceptable Use Policy</a>&nbsp;outlines your
        responsibilities when using our Services. By using our Services,
        you&rsquo;re agreeing to be bound by these Terms, our&nbsp;
        <a href="privacy-policy">Privacy Policy</a>&nbsp;and&nbsp;
        <a href="acceptable-use">Acceptable Use Policy</a>.<br />
        &nbsp;
      </p>

      <h2>Your Stuff &amp; Your Permissions</h2>

      <p>
        When you use our Services, you provide us with things like your files,
        content, messages, contacts and so on (&ldquo;Your Stuff&rdquo;). Your
        Stuff is yours. These Terms don&rsquo;t give us any rights to Your Stuff
        except for the limited rights that enable us to offer the Services.
        <br />
        We need your permission to do things like hosting Your Stuff, backing it
        up and sharing it when you ask us to. Our Services also provide you with
        features like commenting, sharing, searching, image thumbnails, easy
        sorting and organisation to help reduce busywork. To provide these and
        other features, DICOMWallet accesses, stores and scans Your Stuff. You
        give us permission to do those things, and this permission extends to
        our affiliates and trusted third parties we work with.
        <br />
        &nbsp;
      </p>

      <h2>Your Responsibilities</h2>

      <p>
        Your use of our Services must comply with our{" "}
        <a href="acceptable-use">Acceptable Use Policy</a>. Content in the
        Services may be protected by others&rsquo; intellectual property rights.
        Please don&rsquo;t copy, upload, download or share content unless you
        have the right to do so.
      </p>

      <p>
        DICOMWallet may review your conduct and content for compliance with
        these Terms and our&nbsp;
        <a href="acceptable-use">Acceptable Use Policy</a>. We are not
        responsible for the content people post and share via the Services.
        <br />
        Help us keep Your Stuff protected. Safeguard your password to the
        Services, and keep your account information current. Don&rsquo;t share
        your account credentials or give others access to your account.
        <br />
        &nbsp;
      </p>

      <p>
        You may use our Services only as permitted by applicable law, including
        export control laws and regulations. Finally, to use our Services, you
        must be at least 18. If the law where you reside requires that you must
        be older in order for DICOMWallet to lawfully provide the Services to
        you without parental consent (including use of your personal data), then
        you must be that older age.
      </p>

      <p>&nbsp;</p>

      <h2>Additional Features</h2>

      <p>
        From time to time, DICOMWallet will add additional features to enhance
        the user experience of our storage service at no additional charge.
        However, these free features may be withdrawn without further notice.
      </p>

      <p>&nbsp;</p>

      <h2>CORRECTIONS</h2>

      <p>
        There may be information on the Site that contains typographical errors,
        inaccuracies, or omissions, including descriptions, pricing,
        availability, and various other information. We reserve the right to
        correct any errors, inaccuracies, or omissions and to change or update
        the information on the Site at any time, without prior notice.
      </p>

      <p>&nbsp;</p>

      <h2>Our Stuff</h2>

      <p>
        The Services are protected by copyright, trademark, and other UK and
        foreign laws. These Terms don&rsquo;t grant you any right, title or
        interest in the Services, others&rsquo; content in the Services,
        DICOMWallet trademarks, logos and other brand features. We welcome
        feedback, but please note that we may use comments or suggestions
        without any obligation to you.
      </p>

      <p>&nbsp;</p>

      <h2>Copyright</h2>

      <p>
        We respect the intellectual property of others and ask that you do too.
        We respond to notices of alleged copyright infringement if they comply
        with the law, and such notices should be reported to us using{" "}
        <a href="mailto:CopyRight@DICOMWallet.com">CopyRight@DICOMWallet.com</a>{" "}
        . We reserve the right to delete or disable content alleged to be
        infringing and terminate accounts of repeat infringers. Our designated
        agent for notice of alleged copyright infringement on the Services is:
      </p>

      <p>
        <strong>Copyright Agent</strong>
        <br />
        Precious MD Ltd
        <br />
        20-22 Wenlock Road,
        <br />
        London, England N1 7GU
        <br />
        England
        <br />
        &nbsp;
      </p>

      <p>&nbsp;</p>

      <h2>Termination</h2>

      <p>
        You&rsquo;re free to stop using our Services at any time. We reserve the
        right to suspend or terminate your access to the Services with notice to
        you if:
      </p>

      <ul>
        <li>you&rsquo;re in breach of these Terms,</li>
        <li>
          your use of the Services would cause a real risk of harm or loss to us
          or other users, or
        </li>
        <li>
          you don&rsquo;t have a Paid Account and haven&#39;t accessed our
          Services for 12 consecutive months.
        </li>
      </ul>

      <p>
        We&rsquo;ll provide you with reasonable advance notice via the email
        address associated with your account to remedy the activity that
        prompted us to contact you and give you the opportunity to export Your
        Stuff from our Services. If after such notice you fail to take the steps
        we ask of you, we&rsquo;ll terminate or suspend your access to the
        Services.
      </p>

      <p>
        We won&rsquo;t provide notice or an opportunity to export Your Stuff
        before termination or suspension of access to the Services where:
      </p>

      <ul>
        <li>you&rsquo;re in material breach of these Terms,</li>
        <li>
          Doing so would cause us legal liability or compromise our ability to
          provide the Services to our other users, or
        </li>
        <li>We are prohibited from doing so by law.</li>
      </ul>

      <p>&nbsp;</p>

      <h2>Discontinuation of Services</h2>

      <p>
        We may decide to discontinue the Services in response to exceptional
        unforeseen circumstances, events beyond DICOMWallet control (for example
        a natural disaster, fire or explosion) or to comply with a legal
        requirement. If we do so, we&rsquo;ll give you reasonable prior notice
        so that you can export Your Stuff from our systems (we will give you no
        less than 30 days&rsquo; notice where possible under the
        circumstances).&nbsp;
      </p>

      <p>&nbsp;</p>

      <h2>Services &ldquo;AS IS&rdquo;</h2>

      <p>
        We strive to provide great Services, but there are certain things that
        we can&#39;t guarantee. TO THE FULLEST EXTENT PERMITTED BY LAW,
        DICOMWallet AND ITS AFFILIATES, SUPPLIERS AND DISTRIBUTORS MAKE NO
        WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE SERVICES. THE SERVICES
        ARE PROVIDED &lsquo;AS IS&rsquo;. WE ALSO DISCLAIM ANY WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
        Some places don&rsquo;t allow the disclaimers in this paragraph, so they
        may not apply to you. For example, these disclaimers do not override the
        legal protections, including statutory warranties granted to consumers
        by EU law.
      </p>

      <p>&nbsp;</p>

      <h2>Limitation of Liability</h2>

      <p>
        WE DON&rsquo;T EXCLUDE OR LIMIT OUR LIABILITY TO YOU WHERE IT WOULD BE
        ILLEGAL TO DO SO &ndash; THIS INCLUDES ANY LIABILITY FOR
        DICOMWallet&rsquo;S OR ITS AFFILIATES&rsquo; FRAUD OR FRAUDULENT
        MISREPRESENTATION IN PROVIDING THE SERVICES. IN COUNTRIES WHERE THE
        FOLLOWING TYPES OF EXCLUSIONS AREN&rsquo;T ALLOWED, WE&#39;RE
        RESPONSIBLE TO YOU ONLY FOR LOSSES AND DAMAGES THAT ARE A REASONABLY
        FORESEEABLE RESULT OF OUR FAILURE TO USE REASONABLE CARE AND SKILL OR
        OUR BREACH OF OUR CONTRACT WITH YOU. THIS PARAGRAPH DOESN&rsquo;T AFFECT
        CONSUMER RIGHTS THAT CAN&#39;T BE WAIVED OR LIMITED BY ANY CONTRACT OR
        AGREEMENT. IF YOU ARE AN EU OR UK CONSUMER, THESE TERMS DO NOT EXCLUDE
        DICOMWallet&rsquo;S LIABILITY FOR LOSSES AND DAMAGES THAT ARE A RESULT
        OF OUR FAILURE TO USE REASONABLE CARE AND SKILL IN PROVIDING THE
        SERVICES OR OF OUR BREACH OF OUR CONTRACT WITH YOU, AS LONG AS THOSE
        LOSSES AND DAMAGES ARE REASONABLY FORESEEABLE.
      </p>

      <p>
        IN COUNTRIES WHERE EXCLUSIONS OR LIMITATIONS OF LIABILITY ARE ALLOWED,
        DICOMWallet WON&rsquo;T BE LIABLE FOR:
      </p>

      <ul>
        <li>
          ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR
          CONSEQUENTIAL DAMAGES, OR
        </li>
        <li>
          ANY LOSS OF USE, DATA, BUSINESS OR PROFITS, REGARDLESS OF LEGAL
          THEORY.
        </li>
      </ul>

      <p>
        THESE EXCLUSIONS OR LIMITATIONS WILL APPLY REGARDLESS OF WHETHER OR NOT
        DICOMWallet OR ANY OF ITS AFFILIATES HAVE BEEN WARNED OF THE POSSIBILITY
        OF SUCH DAMAGES.
      </p>

      <p>
        IF YOU USE THE SERVICES FOR ANY COMMERCIAL, BUSINESS OR RE-SALE PURPOSE,
        DICOMWallet, ITS WILL HAVE NO LIABILITY TO YOU FOR ANY LOSS OF PROFIT,
        LOSS OF BUSINESS, BUSINESS INTERRUPTION OR LOSS OF BUSINESS OPPORTUNITY.
        DICOMWallet IS NOT RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR
        OFFLINE, OF ANY USERS OF THE SERVICES.
      </p>

      <p>
        OTHER THAN FOR THE TYPES OF LIABILITY WE CANNOT LIMIT BY LAW (AS
        DESCRIBED IN THIS SECTION), WE LIMIT OUR LIABILITY TO YOU TO THE GREATER
        OF &pound;20.THIS PROVISION DOES NOT APPLY TO EU CONSUMERS WHERE
        PROHIBITED BY APPLICABLE LAW
      </p>

      <p>&nbsp;</p>

      <h2>Resolving Disputes</h2>

      <p>
        Let&rsquo;s try to sort things out first.&nbsp;We want to address your
        concerns without needing a formal legal case. Before filing a claim
        against DICOMWallet, you agree to try to resolve the dispute informally
        by sending us a written Notice of Dispute at
        dispute-notice@DICOMWallet.com that includes your name, a detailed
        description of the dispute and the relief you seek. We&rsquo;ll try to
        resolve the dispute informally by contacting you via email. If a dispute
        is not resolved within 60 days after submission, you or DICOMWallet may
        bring a formal proceeding.
        <br />
        Judicial Forum for Disputes.&nbsp;You and DICOMWallet agree that any
        judicial proceeding to resolve claims relating to these Terms or the
        Services will be brought in the non-exclusive jurisdiction of the courts
        of London, London, subject to the mandatory arbitration provisions
        below. Both you and DICOMWallet consent to venue and personal
        jurisdiction in such courts. If you reside in a country (for example, a
        member state of the European Union) with laws that give consumers the
        right to bring disputes in their local courts, this paragraph
        doesn&rsquo;t affect those requirements.
      </p>

      <p>&nbsp;</p>

      <h2>Binding Arbitration&nbsp;</h2>

      <p>
        Any dispute arising from the relationships between the Parties to this
        contract shall be determined by one arbitrator who will be chosen in
        accordance with the Arbitration and Internal Rules of the European Court
        of Arbitration being part of the European Centre of Arbitration having
        its seat in Strasbourg, and which are in force at the time the
        application for arbitration is filed, and of which adoption of this
        clause constitutes acceptance. The seat of arbitration shall be London,
        United Kingdom. The language of the proceedings shall be English.
        Applicable rules of substantive law shall be the law of the United
        Kingdom.
      </p>

      <p>&nbsp;</p>

      <h2>Controlling Law</h2>

      <p>
        These conditions are governed by and interpreted following the laws of
        the United Kingdom, and the use of the United Nations Convention of
        Contracts for the International Sale of Goods is expressly excluded. If
        your habitual residence is in the EU, and you are a consumer, you
        additionally possess the protection provided to you by obligatory
        provisions of the law of your country of residence. DICOMWallet and
        yourself both agree to submit to the non-exclusive jurisdiction of the
        courts of London, which means that you may make a claim to defend your
        consumer protection rights in regards to these Conditions of Use in the
        United Kingdom, or in the EU country in which you reside.
      </p>

      <p>&nbsp;</p>

      <h2>Indemnification</h2>

      <p>
        You agree to defend, indemnify, and hold us harmless, including our
        subsidiaries, affiliates, and all of our respective officers, agents,
        partners, and employees, from and against any loss, damage, liability,
        claim, or demand, including reasonable attorneys&rsquo; fees and
        expenses, made by any third party due to or arising out of:&nbsp;
        <br />
        (1) your Contributions; (2) use of the Site; (3) breach of these Terms
        of Use; (4) any breach of your representations and warranties set forth
        in these Terms of Use; (5) your violation of the rights of a third
        party, including but not limited to intellectual property rights; or (6)
        any overt harmful act toward any other user of the Site with whom you
        connected via the Site. Notwithstanding the foregoing, we reserve the
        right, at your expense, to assume the exclusive defense and control of
        any matter for which you are required to indemnify us, and you agree to
        cooperate, at your expense, with our defense of such claims. We will use
        reasonable efforts to notify you of any such claim, action, or
        proceeding which is subject to this indemnification upon becoming aware
        of it.
      </p>

      <p>&nbsp;</p>

      <h2>Entire Agreement</h2>

      <p>
        These Terms constitute the entire agreement between you and DICOMWallet
        with respect to the subject matter of these Terms and supersede and
        replace any other prior or contemporaneous agreements or terms and
        conditions applicable to the subject matter of these Terms. Our past,
        present and future affiliates and agents can invoke our rights under
        this agreement in the event they become involved in a dispute with you.
        Otherwise, these Terms do not give rights to any third parties.
      </p>

      <p>&nbsp;</p>

      <h2>Waiver, Severability &amp; Assignment</h2>

      <p>
        DICOMWallet&rsquo;s failure to enforce a provision is not a waiver of
        its right to do so later. If a provision is found to be unenforceable,
        the remaining provisions of the Terms will remain in full effect and an
        enforceable term will be substituted reflecting our intent as closely as
        possible. You may not assign any of your rights under these Terms, and
        any such attempt will be void. DICOMWallet may assign its rights to any
        of its affiliates or subsidiaries, or to any successor in the interest
        of any business associated with the Services.
      </p>

      <p>&nbsp;</p>

      <h2>Modifications</h2>

      <p>We may revise these Terms from time to time to better reflect:</p>

      <ul>
        <li>changes to the law,</li>
        <li>new regulatory requirements, or</li>
        <li>improvements or enhancements made to our Services.</li>
      </ul>

      <p>
        If an update affects your use of the Services or your legal rights as a
        user of our Services, we&rsquo;ll notify you prior to the update&#39;s
        effective date by sending an email to the email address associated with
        your account or via an in-product notification. These updated terms will
        be effective no less than 30 days from when we notify you.
      </p>

      <p>
        If you do not agree to the updates we make, please cancel your account
        and stop using the Services before the updated Terms become effective.
        By continuing to use or access the Services after the updates come into
        effect, you agree to be bound by the revised Terms.
      </p>
    </>
  );
};

export default TermOfService;
