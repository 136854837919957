import { useFormik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  DicomButtonLabel,
  DicomButtonOutlineLabel,
} from "../../../../components/DesignSystem/buttons";
import { Input } from "../../../../components/DesignSystem/inputs";
import { fetchData } from "../../../../services/fetching";

const Original = ({setUpdatedStudies,originalStudies, study, setStudyPage, modalId }) => {
  // const [study, setStudy] = useState({});
  /*
  const [Remove, setRemove] = useState({
    title: [],
  });
  */
  const [ErrorMessage, setErrorMessage] = useState("");
  const [IsLoading, setIsLoading] = useState(false);
  // const { id } = useParams();

  // useEffect(() => {
  //   fetchData("GET", `/study/${id}`).then((res) =>
  //     setStudy(res.data.data.study)
  //   );
  // }, [id]);

  const initialValues = {
    PatientID: study?.patientTags?.PatientID,
    PatientName: study?.patientTags?.PatientName,
    PatientAge: study?.patientTags?.PatientAge,
    PatientSex: study?.patientTags?.PatientSex,
    StudyDate: study?.mainTags?.StudyDate,
    description: study?.mainTags?.StudyDescription,
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
  });

  const OnClickHandler = () => {
    setIsLoading(true);
    fetchData("POST", `/study/anonymize/none`, {
      studyCode: study.code,
      // Remove: Remove.title,
      PatientName: formik.values.PatientName,
      PatientSex: formik.values.PatientSex,
      PatientAge: formik.values.PatientAge,
      StudyDate: formik.values.StudyDate,
      StudyDescription: formik.values.description,
    })
      .then((res) => {
        if (res.data.data.study) {
          setIsLoading(false);
          toast.success("Changed anonymity status to Original");
          // setStudy(res.data.data.study);
          // setStudyPage(res.data.data.study);
          let newStudies= originalStudies.studies;
        newStudies= newStudies.map((item) =>
        item.code === study.code ? { ...item, anonymization: 0 } : item
      )
        setUpdatedStudies((prev)=>(
          {...prev,studies:newStudies}
        ))
          document.getElementById(modalId).checked = false;
        }
      })
      .catch((err) => {
        if (err.response.data.data) {
          setIsLoading(false);
          setErrorMessage(err.response.data.data);
        }
      });
  };

  return (
    <>
      <div className="space-y-dicom-10">
        <Input
          error={ErrorMessage?.PatientID}
          disable={true}
          formik={formik}
          nameId={"PatientID"}
          label={"Patient ID"}
        />
        <Input
          error={ErrorMessage?.PatientName}
          badgeTitle={
            <button
              onClick={() =>
                formik.setFieldValue ('PatientName',  '')
                // setRemove({ title: [...Remove.title, "PatientName"] })
              }
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.16565 10.1633C5.07629 9.00169 5.99473 8.00989 7.15975 8.00989H16.8402C18.0053 8.00989 18.9237 9.00169 18.8344 10.1633L18.142 19.1633C18.0619 20.2053 17.193 21.0099 16.1479 21.0099H7.85206C6.80699 21.0099 5.93811 20.2053 5.85795 19.1633L5.16565 10.1633Z"
                  stroke="#575757"
                  strokeWidth="2"
                />
                <path
                  d="M19.5 5.00989H4.5"
                  stroke="#575757"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M10 3.00989C10 2.4576 10.4477 2.00989 11 2.00989H13C13.5523 2.00989 14 2.4576 14 3.00989V5.00989H10V3.00989Z"
                  stroke="#575757"
                  strokeWidth="2"
                />
              </svg>
            </button>
          }
          formik={formik}
          nameId={"PatientName"}
          label={"Patient Name"}
        />
        <Input
          error={ErrorMessage?.PatientAge}
          badgeTitle={
            <button
              onClick={() =>
                formik.setFieldValue ('PatientAge',  '')
                // setRemove({ title: [...Remove.title, "PatientAge"] })
              }
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.16565 10.1633C5.07629 9.00169 5.99473 8.00989 7.15975 8.00989H16.8402C18.0053 8.00989 18.9237 9.00169 18.8344 10.1633L18.142 19.1633C18.0619 20.2053 17.193 21.0099 16.1479 21.0099H7.85206C6.80699 21.0099 5.93811 20.2053 5.85795 19.1633L5.16565 10.1633Z"
                  stroke="#575757"
                  strokeWidth="2"
                />
                <path
                  d="M19.5 5.00989H4.5"
                  stroke="#575757"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M10 3.00989C10 2.4576 10.4477 2.00989 11 2.00989H13C13.5523 2.00989 14 2.4576 14 3.00989V5.00989H10V3.00989Z"
                  stroke="#575757"
                  strokeWidth="2"
                />
              </svg>
            </button>
          }
          formik={formik}
          nameId={"PatientAge"}
          label={"Age"}
        />
        <Input
          error={ErrorMessage?.PatientSex}
          badgeTitle={
            <button
              onClick={() =>
                formik.setFieldValue ('PatientSex',  '')
                // setRemove({ title: [...Remove.title, "PatientSex"] })
              }
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.16565 10.1633C5.07629 9.00169 5.99473 8.00989 7.15975 8.00989H16.8402C18.0053 8.00989 18.9237 9.00169 18.8344 10.1633L18.142 19.1633C18.0619 20.2053 17.193 21.0099 16.1479 21.0099H7.85206C6.80699 21.0099 5.93811 20.2053 5.85795 19.1633L5.16565 10.1633Z"
                  stroke="#575757"
                  strokeWidth="2"
                />
                <path
                  d="M19.5 5.00989H4.5"
                  stroke="#575757"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M10 3.00989C10 2.4576 10.4477 2.00989 11 2.00989H13C13.5523 2.00989 14 2.4576 14 3.00989V5.00989H10V3.00989Z"
                  stroke="#575757"
                  strokeWidth="2"
                />
              </svg>
            </button>
          }
          formik={formik}
          nameId={"PatientSex"}
          label={"Sex"}
        />
        <Input
          error={ErrorMessage?.StudyDate}
          badgeTitle={
            <button
              onClick={() =>
                formik.setFieldValue ('StudyDate',  '')
                // setRemove({ title: [...Remove.title, "StudyDate"] })
              }
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.16565 10.1633C5.07629 9.00169 5.99473 8.00989 7.15975 8.00989H16.8402C18.0053 8.00989 18.9237 9.00169 18.8344 10.1633L18.142 19.1633C18.0619 20.2053 17.193 21.0099 16.1479 21.0099H7.85206C6.80699 21.0099 5.93811 20.2053 5.85795 19.1633L5.16565 10.1633Z"
                  stroke="#575757"
                  strokeWidth="2"
                />
                <path
                  d="M19.5 5.00989H4.5"
                  stroke="#575757"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M10 3.00989C10 2.4576 10.4477 2.00989 11 2.00989H13C13.5523 2.00989 14 2.4576 14 3.00989V5.00989H10V3.00989Z"
                  stroke="#575757"
                  strokeWidth="2"
                />
              </svg>
            </button>
          }
          formik={formik}
          nameId={"StudyDate"}
          label={"Study Date"}
        />
        <Input
          error={ErrorMessage?.StudyDescription}
          badgeTitle={
            <button
              onClick={() =>
                formik.setFieldValue ('StudyDescription',  '')
                // setRemove({ title: [...Remove.title, "StudyDescription"] })
              }
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.16565 10.1633C5.07629 9.00169 5.99473 8.00989 7.15975 8.00989H16.8402C18.0053 8.00989 18.9237 9.00169 18.8344 10.1633L18.142 19.1633C18.0619 20.2053 17.193 21.0099 16.1479 21.0099H7.85206C6.80699 21.0099 5.93811 20.2053 5.85795 19.1633L5.16565 10.1633Z"
                  stroke="#575757"
                  strokeWidth="2"
                />
                <path
                  d="M19.5 5.00989H4.5"
                  stroke="#575757"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M10 3.00989C10 2.4576 10.4477 2.00989 11 2.00989H13C13.5523 2.00989 14 2.4576 14 3.00989V5.00989H10V3.00989Z"
                  stroke="#575757"
                  strokeWidth="2"
                />
              </svg>
            </button>
          }
          formik={formik}
          nameId={"description"}
          label={"Study Description"}
        />
        <div className="flex justify-center gap-x-dicom-10">
          <DicomButtonOutlineLabel
            htmlFor={modalId}
            btnClass={
              "!border-dicom-secondary-100 !text-dicom-secondary-100 hover:!bg-dicom-secondary-100/5"
            }
            title={"Cancel"}
          />
          <DicomButtonLabel
            isLoading={IsLoading}
            onClick={OnClickHandler}
            btnClass={
              "!border-dicom-secondary-100 !bg-dicom-secondary-100 hover:!bg-dicom-secondary-hover-100"
            }
            title={"Apply"}
          />
        </div>
      </div>
    </>
  );
};

export default Original;
