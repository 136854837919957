import React from "react";
import { FormatSizeUnitsSpace } from "../../../../utils/SizeHandler";
import Card from "./card";

const AnalyticsSection = ({ data, studyData }) => {
  let AnalyticsData = [
    {
      id: 1,
      title: "Users",
      titleAttr: "Report on the number of users",
      value: data?.usersCount,
      icon: (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5 7C15.5 8.65685 14.1569 10 12.5 10C10.8431 10 9.5 8.65685 9.5 7C9.5 5.34315 10.8431 4 12.5 4C14.1569 4 15.5 5.34315 15.5 7Z"
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            d="M5.5 19.5C5.5 15.9101 8.41015 13 12 13H13C16.5899 13 19.5 15.9101 19.5 19.5V20C19.5 20.5523 19.0523 21 18.5 21H6.5C5.94772 21 5.5 20.5523 5.5 20V19.5Z"
            stroke="currentColor"
            strokeWidth="2"
          />
        </svg>
      ),
    },
    {
      id: 2,
      title: "Studies",
      titleAttr: "Report on the number of studies",
      value: studyData?.studiesCount,
      icon: (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.5 7C6.94772 7 6.5 7.44772 6.5 8C6.5 8.55228 6.94772 9 7.5 9H13.5C14.0523 9 14.5 8.55228 14.5 8C14.5 7.44772 14.0523 7 13.5 7H7.5Z"
            fill="#575757"
          />
          <path
            d="M6.5 12C6.5 11.4477 6.94772 11 7.5 11H13.5C14.0523 11 14.5 11.4477 14.5 12C14.5 12.5523 14.0523 13 13.5 13H7.5C6.94772 13 6.5 12.5523 6.5 12Z"
            fill="#575757"
          />
          <path
            d="M7.5 15C6.94772 15 6.5 15.4477 6.5 16C6.5 16.5523 6.94772 17 7.5 17H13.5C14.0523 17 14.5 16.5523 14.5 16C14.5 15.4477 14.0523 15 13.5 15H7.5Z"
            fill="#575757"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.125 3H7.53369C8.21014 2.37135 9.1473 2 10.125 2H18.875C20.763 2 22.5 3.38491 22.5 5.33333V14.6667C22.5 16.6151 20.763 18 18.875 18H18.44C18.131 19.697 16.6637 21 14.875 21H6.125C4.10818 21 2.5 19.3435 2.5 17.3333V6.66667C2.5 4.65653 4.10818 3 6.125 3ZM18.875 16H18.5V6.66667C18.5 5.62093 18.0648 4.67089 17.3632 4H18.875C19.8864 4 20.5 4.70443 20.5 5.33333V14.6667C20.5 15.2956 19.8864 16 18.875 16ZM6.125 5C5.24233 5 4.5 5.73129 4.5 6.66667V17.3333C4.5 18.2687 5.24233 19 6.125 19H14.875C15.7577 19 16.5 18.2687 16.5 17.3333V6.66667C16.5 5.73129 15.7577 5 14.875 5H6.125Z"
            fill="#575757"
          />
        </svg>
      ),
    },
    {
      id: 3,
      title: "Number of logins",
      titleAttr: "Report on the number of users logins",
      value: data?.loginsCount,
      icon: (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.5 2C10.7386 2 8.5 4.23858 8.5 7C8.5 7.55228 8.94772 8 9.5 8C10.0523 8 10.5 7.55228 10.5 7C10.5 5.34315 11.8431 4 13.5 4H17.5C19.1569 4 20.5 5.34315 20.5 7V17C20.5 18.6569 19.1569 20 17.5 20H13.5C11.8431 20 10.5 18.6569 10.5 17C10.5 16.4477 10.0523 16 9.5 16C8.94772 16 8.5 16.4477 8.5 17C8.5 19.7614 10.7386 22 13.5 22H17.5C20.2614 22 22.5 19.7614 22.5 17V7C22.5 4.23858 20.2614 2 17.5 2H13.5Z"
            fill="white"
          />
          <path
            d="M3.5 11C2.94772 11 2.5 11.4477 2.5 12C2.5 12.5523 2.94772 13 3.5 13H11.7821C11.6931 13.1098 11.6078 13.2163 11.5271 13.318C11.2816 13.6277 11.0738 13.8996 10.927 14.0945C10.8536 14.1921 10.7952 14.2705 10.755 14.3251L10.7084 14.3884L10.6959 14.4055L10.6915 14.4115C10.6914 14.4116 10.691 14.4122 11.5 15L10.6915 14.4115C10.3669 14.8583 10.4654 15.4844 10.9122 15.809C11.359 16.1336 11.9843 16.0346 12.309 15.5879L12.3118 15.584L12.322 15.57L12.3638 15.5132C12.4007 15.4632 12.4553 15.3897 12.5247 15.2975C12.6637 15.113 12.8612 14.8546 13.0942 14.5606C13.5655 13.9663 14.1623 13.2519 14.7071 12.7071L15.4142 12L14.7071 11.2929C14.1623 10.7481 13.5655 10.0337 13.0942 9.43937C12.8612 9.14542 12.6637 8.88702 12.5247 8.7025C12.4553 8.61033 12.4007 8.53682 12.3638 8.48679L12.322 8.43002L12.3118 8.41602L12.3095 8.41281C11.9848 7.96606 11.359 7.86637 10.9122 8.19098C10.4654 8.51561 10.3664 9.14098 10.691 9.58778L11.5 9C10.691 9.58778 10.6909 9.58773 10.691 9.58778L10.6925 9.58985L10.6959 9.59454L10.7084 9.61162L10.755 9.67492C10.7952 9.72946 10.8536 9.80795 10.927 9.90549C11.0738 10.1004 11.2816 10.3723 11.5271 10.682C11.6078 10.7837 11.6931 10.8902 11.7821 11H3.5Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      id: 4,
      title: "Used storage",
      titleAttr: "Total storage used by users",
      value: `${FormatSizeUnitsSpace(data?.totalUsedSpace)} of 16 TB`,
      // value: `${FormatSizeUnitsSpace(data?.totalUsedSpace)}`,
      icon: (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_346_13568)">
            <path
              d="M6.91424 18.6207C5.62333 18.6207 4.3853 18.1301 3.47249 17.257C2.55969 16.3839 2.04687 15.1997 2.04688 13.9649C2.04687 12.7301 2.55969 11.5459 3.47249 10.6728C4.3853 9.7997 5.62333 9.30918 6.91424 9.30918C7.21912 7.95093 8.11104 6.7573 9.39377 5.9909C10.0289 5.61141 10.7409 5.34823 11.489 5.21639C12.2372 5.08454 13.0069 5.08661 13.7542 5.22248C14.5014 5.35835 15.2116 5.62535 15.8442 6.00824C16.4769 6.39114 17.0195 6.88243 17.4411 7.45406C17.8628 8.02569 18.1552 8.66646 18.3017 9.33981C18.4482 10.0131 18.4459 10.7059 18.2949 11.3784H19.3295C20.2899 11.3784 21.211 11.7599 21.8901 12.439C22.5692 13.1181 22.9507 14.0391 22.9507 14.9995C22.9507 15.9599 22.5692 16.881 21.8901 17.5601C21.211 18.2392 20.2899 18.6207 19.3295 18.6207H6.91424Z"
              stroke="#575757"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_346_13568">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
    },
    {
      id: 5,
      title: "Views",
      titleAttr: "Total number of views by users",
      value: data?.views,
      icon: (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 9C10.8431 9 9.5 10.3431 9.5 12C9.5 13.6569 10.8431 15 12.5 15C14.1569 15 15.5 13.6569 15.5 12C15.5 10.3431 14.1569 9 12.5 9ZM11.5 12C11.5 11.4477 11.9477 11 12.5 11C13.0523 11 13.5 11.4477 13.5 12C13.5 12.5523 13.0523 13 12.5 13C11.9477 13 11.5 12.5523 11.5 12Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.33 11.2807C20.042 7.15186 16.3122 5 12.5 5C8.68777 5 4.95796 7.15186 2.67003 11.2807C2.44637 11.6844 2.44361 12.1821 2.66029 12.5876C4.91183 16.8013 8.6628 19 12.5 19C16.3372 19 20.0882 16.8013 22.3397 12.5876C22.5564 12.1821 22.5536 11.6844 22.33 11.2807ZM12.5 17C9.56097 17 6.54052 15.3724 4.59173 11.9487C6.56862 8.59614 9.57319 7 12.5 7C15.4268 7 18.4314 8.59614 20.4083 11.9487C18.4595 15.3724 15.439 17 12.5 17Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      id: 6,
      title: "Full anoymization",
      titleAttr: "Total number of full anonymization files",
      value: studyData?.studiesAnonymization?.[2]?.count,
      icon: (
        <svg
          width="31"
          height="26"
          viewBox="0 0 31 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_494_91375)">
            <path
              d="M22.8592 15.1359C20.441 15.1359 18.4083 16.9077 17.9555 19.2503C17.31 18.9648 16.4911 18.7385 15.5278 18.7385C14.5355 18.7286 13.6877 18.9648 13.0326 19.2503C12.5798 16.9077 10.5567 15.1359 8.12891 15.1359C5.37361 15.1359 3.12891 17.4293 3.12891 20.2444C3.12891 23.0595 5.37361 25.3529 8.12891 25.3529C10.759 25.3529 12.9266 23.2662 13.1096 20.6185C13.678 20.3035 14.4969 19.9984 15.5181 20.0082C16.5104 20.018 17.31 20.3133 17.8688 20.6185C18.0615 23.2564 20.2195 25.3529 22.8495 25.3529C25.6048 25.3529 27.8495 23.0595 27.8495 20.2444C27.8592 17.4293 25.6145 15.1359 22.8592 15.1359ZM8.13854 24.0832C6.06725 24.0832 4.38131 22.3607 4.38131 20.2444C4.38131 18.1282 6.06725 16.4057 8.13854 16.4057C10.2098 16.4057 11.8958 18.1282 11.8958 20.2444C11.8958 22.3607 10.2098 24.0832 8.13854 24.0832ZM22.8592 24.0832C20.7879 24.0832 19.1019 22.3607 19.1019 20.2444C19.1019 18.1282 20.7879 16.4057 22.8592 16.4057C24.9304 16.4057 26.6164 18.1282 26.6164 20.2444C26.6164 22.3607 24.9304 24.0832 22.8592 24.0832Z"
              fill="#212121"
            />
            <path
              d="M29.8834 12.3799H1.1262C0.779383 12.3799 0.5 12.6653 0.5 13.0197C0.5 13.374 0.779383 13.6595 1.1262 13.6595H29.8834C30.2303 13.6595 30.5096 13.374 30.5096 13.0197C30.5 12.6653 30.2206 12.3799 29.8834 12.3799Z"
              fill="#212121"
            />
            <path
              d="M8.14092 10.8838H22.8712C23.0638 10.8838 23.2373 10.7952 23.3625 10.6475C23.4781 10.4999 23.5263 10.2932 23.4781 10.1062L21.85 3.00937C21.6766 2.27115 21.2527 1.62151 20.6361 1.18842C20.0195 0.755329 19.2777 0.568312 18.5455 0.676585C17.5436 0.814387 16.5128 0.883288 15.5012 0.883288C15.4916 0.883288 15.482 0.883288 15.4723 0.883288C14.4704 0.883288 13.4492 0.814387 12.4569 0.676585C11.7151 0.578155 10.9733 0.755329 10.3664 1.18842C9.74978 1.62151 9.32589 2.26131 9.15248 3.00937L7.53398 10.1062C7.49545 10.2932 7.53398 10.49 7.64959 10.6475C7.77483 10.7952 7.94824 10.8838 8.14092 10.8838ZM10.3664 3.29482C10.5687 2.39911 11.3972 1.80853 12.2931 1.93649C13.3432 2.08413 14.4126 2.15303 15.4723 2.15303C15.482 2.15303 15.4916 2.15303 15.5012 2.15303C16.5706 2.15303 17.6496 2.07429 18.719 1.93649C19.6149 1.80853 20.4338 2.39911 20.6457 3.29482L22.0908 9.61401H8.92127L10.3664 3.29482Z"
              fill="#212121"
            />
          </g>
          <defs>
            <clipPath id="clip0_494_91375">
              <rect
                width="30"
                height="24.7059"
                fill="white"
                transform="translate(0.5 0.647057)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
    },
    {
      id: 7,
      title: "Sharing anonymization ",
      titleAttr: "Total number of sharing anonymization files",
      value: studyData?.studiesAnonymization?.[1]?.count,
      icon: (
        <svg
          width="31"
          height="26"
          viewBox="0 0 31 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_494_91370)">
            <path
              d="M22.8592 15.1359C20.441 15.1359 18.4083 16.9077 17.9555 19.2503C17.31 18.9648 16.4911 18.7385 15.5278 18.7385C14.5355 18.7286 13.6877 18.9648 13.0326 19.2503C12.5798 16.9077 10.5567 15.1359 8.12891 15.1359C5.37361 15.1359 3.12891 17.4293 3.12891 20.2444C3.12891 23.0595 5.37361 25.3529 8.12891 25.3529C10.759 25.3529 12.9266 23.2662 13.1096 20.6185C13.678 20.3035 14.4969 19.9984 15.5181 20.0082C16.5104 20.018 17.31 20.3133 17.8688 20.6185C18.0615 23.2564 20.2195 25.3529 22.8495 25.3529C25.6048 25.3529 27.8495 23.0595 27.8495 20.2444C27.8592 17.4293 25.6145 15.1359 22.8592 15.1359ZM8.13854 24.0832C6.06725 24.0832 4.38131 22.3607 4.38131 20.2444C4.38131 18.1282 6.06725 16.4057 8.13854 16.4057C10.2098 16.4057 11.8958 18.1282 11.8958 20.2444C11.8958 22.3607 10.2098 24.0832 8.13854 24.0832ZM22.8592 24.0832C20.7879 24.0832 19.1019 22.3607 19.1019 20.2444C19.1019 18.1282 20.7879 16.4057 22.8592 16.4057C24.9304 16.4057 26.6164 18.1282 26.6164 20.2444C26.6164 22.3607 24.9304 24.0832 22.8592 24.0832Z"
              fill="white"
            />
            <path
              d="M29.8834 12.3799H1.1262C0.779383 12.3799 0.5 12.6653 0.5 13.0197C0.5 13.374 0.779383 13.6595 1.1262 13.6595H29.8834C30.2303 13.6595 30.5096 13.374 30.5096 13.0197C30.5 12.6653 30.2206 12.3799 29.8834 12.3799Z"
              fill="white"
            />
            <path
              d="M8.14092 10.8838H22.8712C23.0638 10.8838 23.2373 10.7952 23.3625 10.6475C23.4781 10.4999 23.5263 10.2932 23.4781 10.1062L21.85 3.00937C21.6766 2.27115 21.2527 1.62151 20.6361 1.18842C20.0195 0.755329 19.2777 0.568312 18.5455 0.676585C17.5436 0.814387 16.5128 0.883288 15.5012 0.883288C15.4916 0.883288 15.482 0.883288 15.4723 0.883288C14.4704 0.883288 13.4492 0.814387 12.4569 0.676585C11.7151 0.578155 10.9733 0.755329 10.3664 1.18842C9.74978 1.62151 9.32589 2.26131 9.15248 3.00937L7.53398 10.1062C7.49545 10.2932 7.53398 10.49 7.64959 10.6475C7.77483 10.7952 7.94824 10.8838 8.14092 10.8838ZM10.3664 3.29482C10.5687 2.39911 11.3972 1.80853 12.2931 1.93649C13.3432 2.08413 14.4126 2.15303 15.4723 2.15303C15.482 2.15303 15.4916 2.15303 15.5012 2.15303C16.5706 2.15303 17.6496 2.07429 18.719 1.93649C19.6149 1.80853 20.4338 2.39911 20.6457 3.29482L22.0908 9.61401H8.92127L10.3664 3.29482Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_494_91370">
              <rect
                width="30"
                height="24.7059"
                fill="white"
                transform="translate(0.5 0.647057)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
    },
    {
      id: 8,
      title: "Original",
      titleAttr: "Total number of original files",
      value: studyData?.studiesAnonymization?.[0]?.count,
      icon: (
        <svg
          width="31"
          height="26"
          viewBox="0 0 31 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_494_91375)">
            <path
              d="M22.8592 15.1359C20.441 15.1359 18.4083 16.9077 17.9555 19.2503C17.31 18.9648 16.4911 18.7385 15.5278 18.7385C14.5355 18.7286 13.6877 18.9648 13.0326 19.2503C12.5798 16.9077 10.5567 15.1359 8.12891 15.1359C5.37361 15.1359 3.12891 17.4293 3.12891 20.2444C3.12891 23.0595 5.37361 25.3529 8.12891 25.3529C10.759 25.3529 12.9266 23.2662 13.1096 20.6185C13.678 20.3035 14.4969 19.9984 15.5181 20.0082C16.5104 20.018 17.31 20.3133 17.8688 20.6185C18.0615 23.2564 20.2195 25.3529 22.8495 25.3529C25.6048 25.3529 27.8495 23.0595 27.8495 20.2444C27.8592 17.4293 25.6145 15.1359 22.8592 15.1359ZM8.13854 24.0832C6.06725 24.0832 4.38131 22.3607 4.38131 20.2444C4.38131 18.1282 6.06725 16.4057 8.13854 16.4057C10.2098 16.4057 11.8958 18.1282 11.8958 20.2444C11.8958 22.3607 10.2098 24.0832 8.13854 24.0832ZM22.8592 24.0832C20.7879 24.0832 19.1019 22.3607 19.1019 20.2444C19.1019 18.1282 20.7879 16.4057 22.8592 16.4057C24.9304 16.4057 26.6164 18.1282 26.6164 20.2444C26.6164 22.3607 24.9304 24.0832 22.8592 24.0832Z"
              fill="#212121"
            />
            <path
              d="M29.8834 12.3799H1.1262C0.779383 12.3799 0.5 12.6653 0.5 13.0197C0.5 13.374 0.779383 13.6595 1.1262 13.6595H29.8834C30.2303 13.6595 30.5096 13.374 30.5096 13.0197C30.5 12.6653 30.2206 12.3799 29.8834 12.3799Z"
              fill="#212121"
            />
            <path
              d="M8.14092 10.8838H22.8712C23.0638 10.8838 23.2373 10.7952 23.3625 10.6475C23.4781 10.4999 23.5263 10.2932 23.4781 10.1062L21.85 3.00937C21.6766 2.27115 21.2527 1.62151 20.6361 1.18842C20.0195 0.755329 19.2777 0.568312 18.5455 0.676585C17.5436 0.814387 16.5128 0.883288 15.5012 0.883288C15.4916 0.883288 15.482 0.883288 15.4723 0.883288C14.4704 0.883288 13.4492 0.814387 12.4569 0.676585C11.7151 0.578155 10.9733 0.755329 10.3664 1.18842C9.74978 1.62151 9.32589 2.26131 9.15248 3.00937L7.53398 10.1062C7.49545 10.2932 7.53398 10.49 7.64959 10.6475C7.77483 10.7952 7.94824 10.8838 8.14092 10.8838ZM10.3664 3.29482C10.5687 2.39911 11.3972 1.80853 12.2931 1.93649C13.3432 2.08413 14.4126 2.15303 15.4723 2.15303C15.482 2.15303 15.4916 2.15303 15.5012 2.15303C16.5706 2.15303 17.6496 2.07429 18.719 1.93649C19.6149 1.80853 20.4338 2.39911 20.6457 3.29482L22.0908 9.61401H8.92127L10.3664 3.29482Z"
              fill="#212121"
            />
          </g>
          <defs>
            <clipPath id="clip0_494_91375">
              <rect
                width="30"
                height="24.7059"
                fill="white"
                transform="translate(0.5 0.647057)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
    },
  ];
  return (
    <>
      <div className="grid grid-cols-6 lg:grid-cols-12 gap-dicom-30">
        {AnalyticsData.map((item, index) => (
          <Card
            key={index}
            className={
              "col-span-6 md:col-span-3 lg:col-span-4 xl:lg:col-span-2"
            }
            title={item.title}
            titleAttr={item.titleAttr}
            icon={item.icon}
            value={item.value}
          />
        ))}
      </div>
    </>
  );
};

export default AnalyticsSection;
