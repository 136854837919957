import { atom } from "recoil"

export const UserStudies = atom({
  key: "userStudies",
  default: { count: 0, folders: [], studies: [], folderCount: 0 }
})

export const UserStudiesURL = atom({
  key: "userStudiesUrl",
  default: null
})

export const PaginationMeta = atom({
  key: "paginationMet",
  default: {
    total: 0,
    page: 0,
    pageSize: 20
  }
})

export const showUploadStudy = atom({
  key: "ShowUploadStudy",
  default: false
})
export const userSelectedData = atom({
  key: "UserSelectedData",
  default: []
})
export const latestStudyUploaded = atom({
  key: "LatestStudyUploaded",
  default: undefined
})
export const resultType = atom({
  key: "ResultType",
  default: "initial"
})
