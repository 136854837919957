import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

import { TeaxtAreaComments } from "../../../components/DesignSystem/inputs";
import { fetchData } from "../../../services/fetching";
import { DicomButton } from "../../../components/DesignSystem/buttons";
import toast from "react-hot-toast";
import {
  GetLocalStorage,
  RemoveLocalStorage,
} from "../../../services/LocalStorage";
import { useNavigate } from "react-router-dom";
import Commentfiles from "../../../components/dashboard/comments/commentfiles";

const Comments = ({
  studyCode,
  className,
  StudyComments,
  setStudyComments,
  hideHeadTitle,
}) => {

  const [IsLoading, setIsLoading] = useState(false);
  const [Files, setFiles] = useState([]);

  const navigate = useNavigate();

  const initialValues = {
    text: "",
  };

  const validationSchema = Yup.object({
    text: Yup.string().required("Comment text is required"),
  });

  const CommentFilesOnChangeHandler = (e) => {
    let newFiles = [];
    for (let i = newFiles.length; i < e.target.files.length; i++) {
      newFiles.push(e.target.files[i]);
      setFiles([...Files, ...newFiles]);
    }
  };

  const onSubmit = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("studyCode", studyCode);
    formData.append("text", formik.values.text);
    for (let i = 0; i < Files.length; i++) {
      formData.append("files", Files[i]);
    }
    fetchData("POST", "/comment", formData, {
      "content-type": "multipart/form-data",
    })
      .then((res) => {
        if (res.data.data.message) {
          setIsLoading(false);
          setFiles([]);
          formik.resetForm();
          toast.success("Your comment has been registered successfully");
          if(setStudyComments!==undefined)
          {

            fetchData("GET", `/comment/?studyCode=${studyCode}`).then((res) =>
            setStudyComments(res.data.data.comments)
            );
          }
          // force refresh
          window.location.reload();
        }
      })
      .catch((err) => {
        if (err.response.status === 500) {
          toast.error("Internal Server Error");
        }
        if (err.response.status === 401) {
          toast.error("Please login to your account");
          RemoveLocalStorage("login_key");
          RemoveLocalStorage("login_user");
          navigate("/auth/login", { replace: true });
        }
        setIsLoading(false);
        toast.error(err.response.data.data.text);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const CommentDeleteHandler = (id) => {
    fetchData("DELETE", `/comment/${id}`).then((res) => {
      if(setStudyComments!==undefined)
      {
        fetchData("GET", `/comment/?studyCode=${studyCode}`).then((res) =>
        setStudyComments(res.data.data.comments)
        );
      }
      else {
        window.location.reload()
      }
    });
  };

  return (
    <>
      {!hideHeadTitle && (
        <p className="text-dicom-secondary-100 text-dicom-headline4">
          Comments and Documents
        </p>
      )}
      {GetLocalStorage("login_user") !=null && (

     
      <div className="relative">
        <TeaxtAreaComments rows="5" nameId={"text"} formik={formik} />
        <input
          type="file"
          id="CommentFiles"
          className="hidden"
          multiple
          name="files"
          onChange={CommentFilesOnChangeHandler}
        />
        <div className="absolute bottom-dicom-15 right-dicom-15 flex w-full !justify-end">
          <div className="flex items-center gap-x-dicom-10">
            <label
              className="px-dicom-10 cursor-pointer"
              htmlFor="CommentFiles"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.62305 5.26558V19.5139C6.62305 24.7597 14.7419 24.8062 14.7419 19.5139C14.7419 5.66105 15.6083 2.7067 12.1305 2.7067C8.65275 2.7067 9.51923 6.24261 9.51923 15.5826C9.51923 16.548 11.0148 16.5596 11.0148 15.5826C11.0148 4.77707 10.6231 4.17224 12.1543 4.17224C13.6855 4.18387 13.2582 4.77707 13.2582 19.5139C13.2582 20.933 12.0712 22.0379 10.7181 22.0379C7.26401 22.0379 8.13049 19.2115 8.13049 5.26558C8.11862 0.229227 15.8813 0.217595 15.8813 5.26558C15.8813 12.698 15.8813 12.256 15.8813 19.5139C15.8813 20.4793 17.3769 20.4793 17.3769 19.5139C17.3769 16.1641 17.3769 8.62703 17.3769 5.26558C17.365 -1.7132 6.62305 -1.7132 6.62305 5.26558Z"
                  fill="#C4C4C4"
                />
              </svg>
            </label>
            <DicomButton
              isLoading={IsLoading}
              onClick={onSubmit}
              title={"Send"}
              btnClass={
                "!bg-dicom-secondary-100 hover:!bg-dicom-secondary-hover-100 !px-[10px]"
              }
            />
          </div>
        </div>
      </div>
       )}
      <div className="flex gap-2 flex-wrap">
        {Files.map((file, index) => (
          <div
            className="flex px-2 bg-dicom-caption-100 rounded-full"
            key={index}
          >
            {file.name}
            <button
              onClick={() => {
                let filteredFiles = Files.filter(
                  (findItem) => findItem.name !== file.name
                );
                setFiles(filteredFiles);
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 8L8 16M8 8L16 16"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        ))}
      </div>
      <div
        className={`!space-y-dicom-15 !h-[300px] ${className} !overflow-y-scroll`}
      >
        {StudyComments.map((comment) => (
          <div key={comment.id} className={"p-dicom-10 space-y-[8px]"}>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-dicom-text-100 text-[14px] font-medium">
                  {comment.user.firstName} {comment.user.lastName}
                </p>
                <p className="text-dicom-caption-100 text-[10px]">
                  {moment(comment.createDate).format("ddd, MMM DD, YYYY HH:mm")}
                </p>
              </div>
              {GetLocalStorage("login_user")!=null&& GetLocalStorage("login_user").id === comment.userId ? (
                <button onClick={() => CommentDeleteHandler(comment.id)}>
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.16565 10.1849C5.07629 9.02335 5.99473 8.03154 7.15975 8.03154H16.8402C18.0053 8.03154 18.9237 9.02334 18.8344 10.1849L18.142 19.1849C18.0619 20.2269 17.193 21.0315 16.1479 21.0315H7.85206C6.80699 21.0315 5.93811 20.2269 5.85795 19.1849L5.16565 10.1849Z"
                      stroke="#C4C4C4"
                      strokeWidth="2"
                    />
                    <path
                      d="M19.5 5.03154H4.5"
                      stroke="#C4C4C4"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M10 3.03154C10 2.47926 10.4477 2.03154 11 2.03154H13C13.5523 2.03154 14 2.47926 14 3.03154V5.03154H10V3.03154Z"
                      stroke="#C4C4C4"
                      strokeWidth="2"
                    />
                    <path
                      d="M14 12.0315V17.0315"
                      stroke="#C4C4C4"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M10 12.0315V17.0315"
                      stroke="#C4C4C4"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              ) : (
                ""
              )}
            </div>
            <p className="text-xs text-black">{comment.text}</p>
            <div className="!space-y-dicom-10">
              {comment.files
                ? comment?.files?.map((file, index) => (
                    <Commentfiles key={index} file={file} />
                  ))
                : ""}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Comments;
