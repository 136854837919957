import React from "react";

const CookiePolicy = () => {
  return (
    <>
      <h2>
        <strong>DICOMWallet Cookie Policy</strong>
      </h2>

      <p>Last Update: July 2022</p>

      <p>
        Precious MD Ltd, doing business as DICOMWallet and our third-party
        partners, including our advertising and analytics partners, use various
        technologies to collect information, such as cookies and web beacons.
      </p>

      <h2>What types of technologies do we use?</h2>

      <p>
        We use cookies, web beacons and other technologies to improve and
        customize our products and websites (the &ldquo;Services&rdquo;) and
        your experience. This allows you, for example, to access and use the
        Services without re-entering your username or password. It allows us to
        better understand the usage of our Services and the interests of our
        customers, to determine whether an email has been opened and acted upon,
        and to present you with advertising that is relevant to your interests.
      </p>

      <p>&nbsp;</p>

      <h2>How do we use them?</h2>

      <ul>
        <li>
          <strong>Where strictly necessary.</strong> &nbsp;These cookies and
          other technologies are essential in order to enable the Services to
          provide the feature you have requested, such as remembering you have
          logged in.
        </li>
        <li>
          <strong>For performance and analytics. </strong>&nbsp;These cookies
          and similar technologies collect information on how users interact
          with the Services and enable us to improve how the Services operate.
          For example, we use Google Analytics cookies to help us understand how
          visitors arrive at and browse our service and website to identify
          areas for improvement such as navigation, user experience, and
          marketing campaigns.
        </li>
      </ul>

      <h2>How can you opt out?</h2>

      <p>
        To opt-out of our use of cookies, you can instruct your browser, by
        changing its options, to stop accepting cookies or to prompt you before
        accepting a cookie from websites you visit. If you do not accept
        cookies, however, you may not be able to use all aspects of our
        Services. Solace and our third-party partners also collect information
        using web beacons (also known as &ldquo;tracking pixels&rdquo;).
        <br />
        &nbsp;
      </p>

      <p>
        Many browsers include their own management tools for removing HTML5
        local storage objects. To manage &ldquo;flash cookies&rdquo; please{" "}
        <a href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">
          click
        </a>{" "}
        here.
      </p>

      <p>
        You will not be able to opt-out of any cookies or other technologies
        that are &ldquo;strictly necessary&rdquo; for the Services.
      </p>

      <h2>Updates to this notice</h2>

      <p>
        This Cookies and Tracking notice may be updated from time to time. If we
        make any changes, we will notify you by revising the &ldquo;Last
        update&rdquo; date at the top of this notice.
      </p>
    </>
  );
};

export default CookiePolicy;
