import fileDownload from "js-file-download";
import React from "react";
import { useState } from "react";
import { fetchDownloadFile } from "../../../services/fetching";
import { DicomButton, DicomButtonN } from "../../DesignSystem/buttons";
import { IconDownload } from "../../DesignSystem/svgIcons";
import { StudyShareByLink } from "../../../atom/studyShare";
import { useRecoilValue } from "recoil";
import toast from "react-hot-toast";

const DownloadStudy = ({ study, btnClass }) => {
  const [downloadIsLoading, setdownloadIsLoading] = useState(false);
  const studyShareByLink =useRecoilValue(StudyShareByLink)

  return (
    <>
      <DicomButton
        onClick={() => {
          setdownloadIsLoading(true);
          fetchDownloadFile("GET", `/study/${study.code}/download`,{'x-share-id':studyShareByLink.linkId}).then(
            (response) => {
              setdownloadIsLoading(false);
              fileDownload(response.data, `${study.title}.zip`);
            }
            ).catch(err=>{
            setdownloadIsLoading(false);
            toast.error("Failed to download")
          });
        }}
        btnClass={`!bg-dicom-secondary-100 hover:!bg-dicom-secondary-hover-100 ${btnClass}`}
        iconL={
          downloadIsLoading ? (
            <svg
              className="text-black animate-spin"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_801_5077)">
                <path
                  d="M16.8396 21.9227C17.072 22.3992 16.8753 22.9778 16.3817 23.1714C14.7785 23.8002 13.055 24.078 11.3281 23.9812C9.28099 23.8664 7.29743 23.229 5.56664 22.1298C3.83586 21.0305 2.41559 19.5062 1.44131 17.7021C0.467042 15.898 -0.0287318 13.8744 0.00128644 11.8243C0.0313048 9.77419 0.586114 7.76596 1.61279 5.99119C2.63946 4.21641 4.10376 2.7343 5.86598 1.68623C7.62821 0.638166 9.62958 0.0591049 11.6792 0.00429001C13.4082 -0.04195 15.1228 0.286166 16.7069 0.961651C17.1946 1.16961 17.3743 1.75374 17.1281 2.22326C16.8818 2.69279 16.3029 2.86912 15.812 2.66861C14.5211 2.14126 13.1314 1.88614 11.7305 1.9236C10.0088 1.96965 8.32769 2.45606 6.84742 3.33644C5.36715 4.21681 4.13715 5.46179 3.27474 6.9526C2.41234 8.44341 1.9463 10.1303 1.92108 11.8524C1.89587 13.5745 2.31232 15.2744 3.1307 16.7898C3.94909 18.3052 5.14212 19.5857 6.59598 20.509C8.04984 21.4323 9.71603 21.9678 11.4356 22.0642C12.8348 22.1427 14.2314 21.9283 15.5372 21.439C16.0337 21.2529 16.6072 21.4462 16.8396 21.9227Z"
                  fill="currentColor"
                />
                <path
                  d="M22.7657 14.4446C23.2827 14.562 23.8011 14.2382 23.8768 13.7134C24.1505 11.8158 23.9662 9.87512 23.3329 8.05557C22.6996 6.23601 21.6391 4.60027 20.2463 3.28267C19.8611 2.91831 19.2538 2.98635 18.9214 3.3994C18.5889 3.81244 18.6581 4.41368 19.0376 4.78386C20.1531 5.87179 21.0045 7.20688 21.5196 8.68669C22.0346 10.1665 22.1962 11.7417 21.9972 13.2871C21.9295 13.813 22.2486 14.3272 22.7657 14.4446Z"
                  fill="currentColor"
                  fillOpacity="0.3"
                />
                <path
                  d="M18.0967 21.204C18.3895 21.646 18.9879 21.7699 19.4051 21.4428C20.7442 20.3927 21.8448 19.0696 22.6334 17.5617C22.8792 17.0919 22.6483 16.526 22.1604 16.3186C21.6724 16.1112 21.1127 16.3414 20.8599 16.8074C20.2275 17.9728 19.3713 19.0022 18.3405 19.8362C17.9283 20.1697 17.8039 20.762 18.0967 21.204Z"
                  fill="currentColor"
                  fillOpacity="0.3"
                />
              </g>
              <defs>
                <clipPath id="clip0_801_5077">
                  <rect width="24" height="24" fill="currentColor" />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.3106 3.01255C11.3088 2.46027 11.7551 2.01115 12.3074 2.00942C12.8597 2.00768 13.3088 2.45399 13.3106 3.00627L11.3106 3.01255Z"
                fill="white"
              />
              <path
                d="M14.6263 10.2951L13.3375 11.592L13.3106 3.00627L11.3106 3.01255L11.3375 11.5983L10.0406 10.3095C9.64883 9.92018 9.01565 9.92215 8.62635 10.3139C8.23705 10.7056 8.23904 11.3388 8.63078 11.7281L8.6333 11.7306L8.63429 11.7316L12.3495 15.4236L16.0312 11.7187L16.0368 11.7131L16.0376 11.7123L16.0383 11.7115L16.0442 11.7056L16.0449 11.7049L14.6263 10.2951Z"
                fill="white"
              />
              <path
                d="M16.0449 11.7049C16.4342 11.3131 16.4323 10.6799 16.0405 10.2906C15.6488 9.90134 15.0156 9.90335 14.6263 10.2951L16.0449 11.7049Z"
                fill="white"
              />
              <path
                d="M4.31055 12C4.31055 10.8954 5.20598 10 6.31055 10C6.86283 10 7.31055 9.55229 7.31055 9C7.31055 8.44772 6.86283 8 6.31055 8C4.10141 8 2.31055 9.79086 2.31055 12V18C2.31055 20.2091 4.10141 22 6.31055 22H17.3105C20.072 22 22.3105 19.7614 22.3105 17V12C22.3105 9.79086 20.5197 8 18.3105 8C17.7583 8 17.3105 8.44772 17.3105 9C17.3105 9.55229 17.7583 10 18.3105 10C19.4151 10 20.3105 10.8954 20.3105 12V17C20.3105 18.6569 18.9674 20 17.3105 20H6.31055C5.20598 20 4.31055 19.1046 4.31055 18V12Z"
                fill="white"
              />
            </svg>
          )
        }
        title={"Download"}
      />
    </>
  );
};

const DownloadStudyOnList = ({ study }) => {
  const [downloadIsLoading, setdownloadIsLoading] = useState(false);
  return (
    <>
      <DicomButtonN
        btnClass={"force-padding-5"}
        onClick={() => {
          setdownloadIsLoading(true);
          fetchDownloadFile("GET", `/study/${study.code}/download`).then(
            (response) => {
              setdownloadIsLoading(false);
              fileDownload(response.data, `${study.title}.zip`);
            }
          ).catch(err=>{
            setdownloadIsLoading(false);
            toast.error("Failed to download")
          });;
        }}
        iconL={
          downloadIsLoading ? (
            <svg
              className="text-black animate-spin"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_801_5077)">
                <path
                  d="M16.8396 21.9227C17.072 22.3992 16.8753 22.9778 16.3817 23.1714C14.7785 23.8002 13.055 24.078 11.3281 23.9812C9.28099 23.8664 7.29743 23.229 5.56664 22.1298C3.83586 21.0305 2.41559 19.5062 1.44131 17.7021C0.467042 15.898 -0.0287318 13.8744 0.00128644 11.8243C0.0313048 9.77419 0.586114 7.76596 1.61279 5.99119C2.63946 4.21641 4.10376 2.7343 5.86598 1.68623C7.62821 0.638166 9.62958 0.0591049 11.6792 0.00429001C13.4082 -0.04195 15.1228 0.286166 16.7069 0.961651C17.1946 1.16961 17.3743 1.75374 17.1281 2.22326C16.8818 2.69279 16.3029 2.86912 15.812 2.66861C14.5211 2.14126 13.1314 1.88614 11.7305 1.9236C10.0088 1.96965 8.32769 2.45606 6.84742 3.33644C5.36715 4.21681 4.13715 5.46179 3.27474 6.9526C2.41234 8.44341 1.9463 10.1303 1.92108 11.8524C1.89587 13.5745 2.31232 15.2744 3.1307 16.7898C3.94909 18.3052 5.14212 19.5857 6.59598 20.509C8.04984 21.4323 9.71603 21.9678 11.4356 22.0642C12.8348 22.1427 14.2314 21.9283 15.5372 21.439C16.0337 21.2529 16.6072 21.4462 16.8396 21.9227Z"
                  fill="currentColor"
                />
                <path
                  d="M22.7657 14.4446C23.2827 14.562 23.8011 14.2382 23.8768 13.7134C24.1505 11.8158 23.9662 9.87512 23.3329 8.05557C22.6996 6.23601 21.6391 4.60027 20.2463 3.28267C19.8611 2.91831 19.2538 2.98635 18.9214 3.3994C18.5889 3.81244 18.6581 4.41368 19.0376 4.78386C20.1531 5.87179 21.0045 7.20688 21.5196 8.68669C22.0346 10.1665 22.1962 11.7417 21.9972 13.2871C21.9295 13.813 22.2486 14.3272 22.7657 14.4446Z"
                  fill="currentColor"
                  fillOpacity="0.3"
                />
                <path
                  d="M18.0967 21.204C18.3895 21.646 18.9879 21.7699 19.4051 21.4428C20.7442 20.3927 21.8448 19.0696 22.6334 17.5617C22.8792 17.0919 22.6483 16.526 22.1604 16.3186C21.6724 16.1112 21.1127 16.3414 20.8599 16.8074C20.2275 17.9728 19.3713 19.0022 18.3405 19.8362C17.9283 20.1697 17.8039 20.762 18.0967 21.204Z"
                  fill="currentColor"
                  fillOpacity="0.3"
                />
              </g>
              <defs>
                <clipPath id="clip0_801_5077">
                  <rect width="24" height="24" fill="currentColor" />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <IconDownload />
          )
        }
      />
    </>
  );
};

const DownloadStudyOnViewerPage = ({ study }) => {
  const [downloadIsLoading, setdownloadIsLoading] = useState(false);
  const studyShareByLink =useRecoilValue(StudyShareByLink)

  return (
    <>
      <button
        title="Download"
        onClick={() => {
          setdownloadIsLoading(true);
          fetchDownloadFile("GET", `/study/${study.code}/download`,{'x-share-id':studyShareByLink.linkId}).then(
            (response) => {
              setdownloadIsLoading(false);
              fileDownload(response.data, `${study.title}.zip`);
            }
          ).catch(err=>{
            setdownloadIsLoading(false);
            toast.error("Failed to download")
          });;
        }}
        className="flex flex-col items-center justify-center md:px-[18px] py-dicom-10 text-white"
      >
        {downloadIsLoading ? (
          <svg
            className="text-black animate-spin"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_801_5077)">
              <path
                d="M16.8396 21.9227C17.072 22.3992 16.8753 22.9778 16.3817 23.1714C14.7785 23.8002 13.055 24.078 11.3281 23.9812C9.28099 23.8664 7.29743 23.229 5.56664 22.1298C3.83586 21.0305 2.41559 19.5062 1.44131 17.7021C0.467042 15.898 -0.0287318 13.8744 0.00128644 11.8243C0.0313048 9.77419 0.586114 7.76596 1.61279 5.99119C2.63946 4.21641 4.10376 2.7343 5.86598 1.68623C7.62821 0.638166 9.62958 0.0591049 11.6792 0.00429001C13.4082 -0.04195 15.1228 0.286166 16.7069 0.961651C17.1946 1.16961 17.3743 1.75374 17.1281 2.22326C16.8818 2.69279 16.3029 2.86912 15.812 2.66861C14.5211 2.14126 13.1314 1.88614 11.7305 1.9236C10.0088 1.96965 8.32769 2.45606 6.84742 3.33644C5.36715 4.21681 4.13715 5.46179 3.27474 6.9526C2.41234 8.44341 1.9463 10.1303 1.92108 11.8524C1.89587 13.5745 2.31232 15.2744 3.1307 16.7898C3.94909 18.3052 5.14212 19.5857 6.59598 20.509C8.04984 21.4323 9.71603 21.9678 11.4356 22.0642C12.8348 22.1427 14.2314 21.9283 15.5372 21.439C16.0337 21.2529 16.6072 21.4462 16.8396 21.9227Z"
                fill="currentColor"
              />
              <path
                d="M22.7657 14.4446C23.2827 14.562 23.8011 14.2382 23.8768 13.7134C24.1505 11.8158 23.9662 9.87512 23.3329 8.05557C22.6996 6.23601 21.6391 4.60027 20.2463 3.28267C19.8611 2.91831 19.2538 2.98635 18.9214 3.3994C18.5889 3.81244 18.6581 4.41368 19.0376 4.78386C20.1531 5.87179 21.0045 7.20688 21.5196 8.68669C22.0346 10.1665 22.1962 11.7417 21.9972 13.2871C21.9295 13.813 22.2486 14.3272 22.7657 14.4446Z"
                fill="currentColor"
                fillOpacity="0.3"
              />
              <path
                d="M18.0967 21.204C18.3895 21.646 18.9879 21.7699 19.4051 21.4428C20.7442 20.3927 21.8448 19.0696 22.6334 17.5617C22.8792 17.0919 22.6483 16.526 22.1604 16.3186C21.6724 16.1112 21.1127 16.3414 20.8599 16.8074C20.2275 17.9728 19.3713 19.0022 18.3405 19.8362C17.9283 20.1697 17.8039 20.762 18.0967 21.204Z"
                fill="currentColor"
                fillOpacity="0.3"
              />
            </g>
            <defs>
              <clipPath id="clip0_801_5077">
                <rect width="24" height="24" fill="currentColor" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 3.01255C10.9983 2.46027 11.4446 2.01115 11.9969 2.00942C12.5492 2.00769 12.9983 2.454 13 3.00628L11 3.01255Z"
              fill="white"
            />
            <path
              d="M14.3158 10.2951L13.0269 11.592L13 3.00628L11 3.01255L11.0269 11.5983L9.73003 10.3095C9.33828 9.92019 8.7051 9.92215 8.3158 10.3139C7.9265 10.7056 7.92849 11.3388 8.32024 11.7281L8.32275 11.7306L8.32374 11.7316L12.039 15.4236L15.7206 11.7187L15.7262 11.7131L15.727 11.7123L15.7278 11.7115L15.7337 11.7056L15.7344 11.7049L14.3158 10.2951Z"
              fill="white"
            />
            <path
              d="M15.7344 11.7049C16.1237 11.3131 16.1217 10.6799 15.73 10.2906C15.3382 9.90135 14.705 9.90335 14.3158 10.2951L15.7344 11.7049Z"
              fill="white"
            />
            <path
              d="M4 12C4 10.8954 4.89543 10 6 10C6.55228 10 7 9.55229 7 9.00001C7 8.44772 6.55228 8.00001 6 8.00001C3.79086 8.00001 2 9.79087 2 12V18C2 20.2091 3.79086 22 6 22H17C19.7614 22 22 19.7614 22 17V12C22 9.79087 20.2091 8.00001 18 8.00001C17.4477 8.00001 17 8.44772 17 9.00001C17 9.55229 17.4477 10 18 10C19.1046 10 20 10.8954 20 12V17C20 18.6569 18.6569 20 17 20H6C4.89543 20 4 19.1046 4 18V12Z"
              fill="white"
            />
          </svg>
        )}
        <p className=" hidden md:block text-[10px]">Download</p>
      </button>
    </>
  );
};

export { DownloadStudy, DownloadStudyOnList, DownloadStudyOnViewerPage };
