import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { fetchData } from "../../../services/fetching"
import toast from "react-hot-toast"

export default function PaymentSuccessful() {
  const location = useLocation()
  const navigate = useNavigate()
  const query = new URLSearchParams(location.search)
  if (!query.get("payment_intent")) navigate(-1)
  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState({ item: <></> })

  useEffect(() => {
    fetchData("GET", `/payments/paymentIntent/status/${query.get("payment_intent")}`)
      .then((res) => {
        setLoading(false)
        if (res.data.data === "succeeded")
          setStatus(
            <div className='flex flex-col items-center'>
              <div className='ui-success'>
                <svg
                  viewBox='0 0 87 87'
                  version='1.1'
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'>
                  <g id='Page-1' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
                    <g id='Group-3' transform='translate(2.000000, 2.000000)'>
                      <circle
                        id='Oval-2'
                        stroke='rgba(165, 220, 134, 0.2)'
                        strokeWidth={4}
                        cx='41.5'
                        cy='41.5'
                        r='41.5'
                      />
                      <circle
                        className='ui-success-circle'
                        id='Oval-2'
                        stroke='#A5DC86'
                        strokeWidth={4}
                        cx='41.5'
                        cy='41.5'
                        r='41.5'
                      />
                      <polyline
                        className='ui-success-path'
                        id='Path-2'
                        stroke='#A5DC86'
                        strokeWidth={4}
                        points='19 38.8036813 31.1020744 54.8046875 63.299221 28'
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <p className='w-full font-semibold text-gray-600'>Payment was successful, redirecting...</p>
            </div>
          )
        else if (res.data.data === "canceled") {
          setStatus(
            <div className='flex flex-col items-center'>
              <div className='ui-error'>
                <svg
                  viewBox='0 0 87 87'
                  version='1.1'
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'>
                  <g id='Page-1' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
                    <g id='Group-2' transform='translate(2.000000, 2.000000)'>
                      <circle
                        id='Oval-2'
                        stroke='rgba(252, 191, 191, .5)'
                        strokeWidth={4}
                        cx='41.5'
                        cy='41.5'
                        r='41.5'
                      />
                      <circle
                        className='ui-error-circle'
                        stroke='#F74444'
                        strokeWidth={4}
                        cx='41.5'
                        cy='41.5'
                        r='41.5'
                      />
                      <path
                        className='ui-error-line1'
                        d='M22.244224,22 L60.4279902,60.1837662'
                        id='Line'
                        stroke='#F74444'
                        strokeWidth={3}
                        strokeLinecap='square'
                      />
                      <path
                        className='ui-error-line2'
                        d='M60.755776,21 L23.244224,59.8443492'
                        id='Line'
                        stroke='#F74444'
                        strokeWidth={3}
                        strokeLinecap='square'
                      />
                    </g>
                  </g>
                </svg>

                <p className='w-full font-semibold text-gray-600'>Payment was canceled, redirecting...</p>
              </div>
            </div>
          )
        } else {
          setStatus(
            <div className='flex flex-col items-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                enableBackground='new 0 0 47.5 47.5'
                viewBox='0 0 47.5 47.5'
                id='warning'>
                <defs>
                  <clipPath id='a'>
                    <path d='M0 38h38V0H0v38Z' />
                  </clipPath>
                </defs>
                <g clipPath='url(#a)' transform='matrix(1.25 0 0 -1.25 0 47.5)'>
                  <path
                    fill='#ffcc4d'
                    d='M0 0c-1.842 0-2.654 1.338-1.806 2.973l15.609 30.055c.848 1.635 2.238 1.635 3.087 0L32.499 2.973C33.349 1.338 32.536 0 30.693 0H0Z'
                    transform='translate(3.653 2)'
                  />
                  <path
                    fill='#231f20'
                    d='M0 0c0 1.302.961 2.108 2.232 2.108 1.241 0 2.233-.837 2.233-2.108v-11.938c0-1.271-.992-2.108-2.233-2.108-1.271 0-2.232.807-2.232 2.108V0Zm-.187-18.293a2.422 2.422 0 0 0 2.419 2.418 2.422 2.422 0 0 0 2.419-2.418 2.422 2.422 0 0 0-2.419-2.419 2.422 2.422 0 0 0-2.419 2.419'
                    transform='translate(16.769 26.34)'
                  />
                </g>
              </svg>
              <p className='w-full font-semibold text-gray-600'>
                Your payment is still being processed, redirecting...
              </p>
            </div>
          )
        }
        setTimeout(() => {
          window.location.replace("/dashboard/my-dicom-wallet/get-more-storage/payments")
        }, [5000])
      })
      .catch((err) => {
        toast.error("An error occurred during the check")
        setLoading(false)
      })
  }, [])
  return (
    <div className='bg-white h-screen'>
      <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
        {loading ? (
          <div className='flex flex-col items-center text-gray-700'>
            Checking your payment status
            <span className='loading loading-dots loading-lg text-gray-700'></span>
          </div>
        ) : (
          <div className=''>{status}</div>
        )}
      </div>
    </div>
  )
}
